import { motion } from "framer-motion";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useOutletContext } from "react-router-dom";
import { SearchField } from "src/components/SearchField";
import { StepWrapper } from "src/pages/configurator/components";
import { UserAddresses } from "src/pages/demo/configuratorLotStep/Lot.layout";
import { Map, MapMarker, MapProps, NewFieldStyleOverrides, TextField } from "~components";
import { Css } from "~generated/css";
import { Maybe, UwProperty } from "~generated/graphql";
import { numberFormatter, titleCase } from "~utils";
import { FT_MEYER_PROPERTIES, LotSelectionTitleDesc } from "./components";

type PropertyLot = Omit<UwProperty, "dpid"> & {
  id?: Maybe<string>;
  formattedAddress?: string;
  aduEligible: boolean;
};

// Hardcoded until we can get property-api integration working AND with florida properties
const PROPERTIES = FT_MEYER_PROPERTIES.map((property, i) => {
  return {
    id: property.dpid,
    formattedAddress: formatPropertyAddress(property),
    aduEligible: i % 2 === 0,
    ...property,
  };
});

const LotTextFieldOverrides = {
  ...NewFieldStyleOverrides,
  // These are barely different from newfieldstyle overrides, for clarity if/when we update our entire app:
  // Changes to base style:
  // - white background
  // - py
  // - fsPx(16) is on base style but only for mobile table
  // - lhPx(24)
  // Changes to new style:
  // - hPx is 4px taller (this does match the combo box selector on prev step)
  // - border is 100 lighter
  ...Css.hPx(56).lhPx(24).py2.fsPx(16).bGray700.bgWhite.$,
};

// AKA "No, I do not need a lot" step
export function BoylLotStep() {
  const {
    userAddresses: [userData, setUserData],
  } = useOutletContext<{ userAddresses: [UserAddresses, Dispatch<SetStateAction<UserAddresses>>] }>();

  const [addressInternal, setAddressInternal] = useState<string>("");
  const [emailInternal, setEmailInternal] = useState<string>("");
  const [selectedProperty, setSelectedProperty] = useState<PropertyLot | null>(null);
  // Not going to bother updating <SearchField> to accept fwdRef, so we'll work around it
  const [searchFocused, setSearchFocused] = useState(false);

  // SearchField expects an array of options OR null. Otherwise it will show the listbox constantly
  const searchProperties = useMemo(() => {
    if (!addressInternal || !searchFocused) return null;
    const filteredLots = PROPERTIES.filter((property) => property.formattedAddress.includes(addressInternal));
    return filteredLots;
  }, [addressInternal, searchFocused]);

  useEffect(() => {
    if (selectedProperty) {
      const { formattedAddress } = selectedProperty;
      setUserData((prev) => ({
        ...prev,
        lotAddress: formattedAddress,
      }));
    }
  }, [selectedProperty, setUserData]);

  useEffect(() => {
    if (emailInternal) {
      setUserData((prev) => ({
        ...prev,
        validEmail: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailInternal),
      }));
    }
  }, [emailInternal, setUserData]);

  const rightContent = (
    <div css={Css.w100.df.fdc.gap2.$}>
      {/* SearchField is tightly couple with the state machine library used on Checkout */}
      <SearchField
        placeholder="Enter your address"
        value={addressInternal}
        onChange={(_, address) => {
          setAddressInternal(address);
          address === "" && setSelectedProperty(null);
        }}
        options={searchProperties}
        onSelect={(propertyAddress) => {
          setAddressInternal(propertyAddress.formattedAddress);
          setSelectedProperty(propertyAddress);
          setSearchFocused(false);
        }}
        cssOverrides={LotTextFieldOverrides}
        onFocus={() => setSearchFocused(true)}
        onBlur={(e) => {
          const { relatedTarget } = e as unknown as FocusEvent;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (relatedTarget && relatedTarget?.id.includes("listbox-option")) return;
          setSearchFocused(false);
        }}
      >
        {{
          option: PropertyOptionRenderer,
          noOption: () => (
            <p css={Css.px2.py1.body14.fsPx(16).lhPx(24).br4.$}>
              No properties found for address <i>"{addressInternal}"</i>
            </p>
          ),
        }}
      </SearchField>
      <TextField
        value={emailInternal}
        required
        type="email"
        autoComplete="email"
        onChange={(e, value) => setEmailInternal(value)}
        placeholder="Email address"
        cssOverrides={LotTextFieldOverrides}
      />
      <LotDataBox {...selectedProperty} />
    </div>
  );

  const memoizedMap = useMemo(() => {
    const props: MapProps = {};
    if (selectedProperty) {
      props.markers = [
        {
          id: "selectedProperty",
          center: { lat: Number(selectedProperty.latitude), lng: Number(selectedProperty.longitude) },
          isActive: true,
          marker: <MapMarker isActive={true} data-testid={`mapMarker-${selectedProperty.id}`} />,
        },
      ];
    } else {
      props.center = { lat: 26.45182917025719, lng: -81.94824993610384 };
    }
    return (
      <div css={Css.w100.h100.$}>
        <Map {...props} />
      </div>
    );
  }, [selectedProperty]);

  return (
    <>
      <Helmet>
        <title>Configurator | BOYL</title>
      </Helmet>
      <StepWrapper
        hideBorder
        left={memoizedMap}
        title=""
        description={<LotSelectionTitleDesc largeText="What's your lot address?" />}
        right={rightContent}
      />
    </>
  );
}

function LotDataBox({
  lotSize,
  finishedSqft,
  zoning,
  aduEligible,
  lotFrontageFeet,
  lotDepthFeet,
}: Partial<PropertyLot>) {
  return (
    <div
      css={{
        // gtc auto gives columns more breathing room, especially center
        ...Css.mt4.mhPx(167).dg.gtc("auto auto auto").gtr("auto auto").$,
        // Controlling border logic in parent container
        ...Css.addIn("> div", Css.bGray700.$)
          .addIn("> :nth-child(-n + 3)", Css.bb.$)
          .addIn("> :nth-child(2), > :nth-child(5)", Css.br.bl.$).$,
      }}
    >
      <div css={Css.df.fdc.pl2.py2.gap2.$}>
        <div css={Css.header16.fsPx(14).lhPx(20).$}>{lotSize ? numberFormatter(lotSize) : "--"}</div>
        <div css={Css.body12.lhPx(20).gray700.$}>Lot Size Sq ft</div>
      </div>
      <div css={Css.df.fdc.pl2.py2.gap2.$}>
        <div css={Css.header16.fsPx(14).lhPx(20).$}>{finishedSqft ? numberFormatter(finishedSqft) : "--"}</div>
        <div css={Css.body12.lhPx(20).gray700.$}>Buildable Sq ft</div>
      </div>
      <div css={Css.df.fdc.pl2.py2.gap2.$}>
        <div css={Css.header16.fsPx(14).lhPx(20).$}>{zoning ? zoning : "--"}</div>
        <div css={Css.body12.lhPx(20).gray700.$}>Zoning</div>
      </div>
      <div css={Css.df.fdc.pl2.py2.gap2.$}>
        <div css={Css.header16.fsPx(14).lhPx(20).$}>{aduEligible ? (aduEligible ? "Yes" : "No") : "--"}</div>
        <div css={Css.body12.lhPx(20).gray700.$}>ADU Eligible</div>
      </div>
      <div css={Css.df.fdc.pl2.py2.gap2.$}>
        <div css={Css.header16.fsPx(14).lhPx(20).$}>{lotFrontageFeet ? numberFormatter(lotFrontageFeet) : "--"}</div>
        <div css={Css.body12.lhPx(20).gray700.$}>Lot Frontage ft</div>
      </div>
      <div css={Css.df.fdc.pl2.py2.gap2.$}>
        <div css={Css.header16.fsPx(14).lhPx(20).$}>{lotDepthFeet ? numberFormatter(lotDepthFeet) : "--"}</div>
        <div css={Css.body12.lhPx(20).gray700.$}>Lot Depth ft</div>
      </div>
    </div>
  );
}

function PropertyOptionRenderer(props: PropertyLot) {
  return (
    <motion.div
      css={{
        ...Css.cursorPointer.px2.py1.ttc.$,
        ...Css.bshNone.body14.fsPx(16).lhPx(24).br4.addIn(":disabled", Css.bgGray200.$).$,
        ...{
          borderLeftWidth: 4,
          borderLeftStyle: "solid",
          borderLeftColor: "transparent",
        },
        ":hover": Css.bgGray200.bGray900.$,
      }}
    >
      {props.formattedAddress}
    </motion.div>
  );
}

function formatPropertyAddress(property: UwProperty) {
  const { fullStreetAddress, cityName, state, zipCode } = property;
  return titleCase(`${fullStreetAddress}, ${cityName}, ${state} ${zipCode}`.toLowerCase());
}

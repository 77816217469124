import { ReactNode } from "react";

type PDFPageProps = {
  children: ReactNode;
};

/** Wraps content in the correct PDF width and page break for easy printing. */
export function PDFPage({ children }: PDFPageProps) {
  return (
    <div
      style={{
        width: "8.5in",
        marginLeft: "auto",
        marginRight: "auto",
        breakBefore: "page", // Start this report on a new page.
        breakInside: "avoid", // Avoid breaking the report on multiple pages if possible.
      }}
    >
      {children}
    </div>
  );
}

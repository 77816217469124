import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Xss } from "~generated/css";
import { useTestIds } from "~utils";

type FloorPlanProps = {
  // URL to a .svg file representing the floor plan
  src: string;
  // Allow for styles to be applied to the container
  xss?: Xss<"height" | "width">;
  // Allow override of highlight color
  highlightHexColor?: string;
  // Allow override of mask color
  maskHexColor?: string;
  // Specific options to show geometry for
  selectedOptionCodes?: string[];
  // Specific options to highlight
  highlightedOptionCodes?: string[];
  // Whether to reverse the floor plan
  isReversed?: boolean;
  // Used to scope styles
  id: string;
};

// recursively check parents and remove any nodes that are children of textGroups nodes
const removeChildren = (svgElements: SVGGElement[]): SVGGElement[] => {
  const hasParentInArray = (element: SVGGElement | HTMLElement, array: SVGGElement[]): boolean => {
    const parent = element.parentElement;

    if (parent && array.some((arrElement) => parent.id === arrElement.id)) {
      return true;
    }

    if (parent) {
      return hasParentInArray(parent, array);
    }
    return false;
  };
  const arrayClone = [...svgElements];
  svgElements.forEach((element) => {
    // if this node is a child of another in the array, remove it
    if (hasParentInArray(element, svgElements)) {
      arrayClone.splice(
        arrayClone.findIndex((el) => el.id === element.id),
        1,
      );
    }
  });

  return arrayClone;
};

export const FloorPlan = (props: FloorPlanProps) => {
  const {
    src,
    xss,
    selectedOptionCodes = [],
    highlightedOptionCodes = [],
    highlightHexColor = "#FFFF00",
    maskHexColor = "#FFFFFF",
    isReversed = false,
    id,
  } = props;

  const tid = useTestIds(props, "floorPlan");

  // stores the SVG text that will be injected into the page via `dangerouslySetInnerHTML`
  const [svgText, setSvgText] = useState<string>("");
  // stores the reference to the `div` containing the `svg` element
  const svgContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // fetch the .svg file content and inject into page via state.
    // We do this since using `<img />`, `<object />` or `<iframe />`
    // does not offer the ability to customize its styles
    void (async () => {
      let svgText = await (await fetch(src)).text();

      // TODO: generated asset to contain scope styles but for now we are
      // programmatically adding id and updating the style ourselves to
      // avoid conflicts when more than 1 floor plan displayed at a time
      svgText = svgText.replaceAll("<svg", `<svg id="${id}"`);
      svgText = svgText.replaceAll('<style type="text/css">', `<style type="text/css">#${id} {`);
      svgText = svgText.replaceAll("</style>", "} </style>");
      // TODO: improve this hacky way to change highlight color
      if (highlightHexColor) {
        svgText = svgText.replaceAll("#FFFF00", highlightHexColor);
      }
      setSvgText(svgText);
    })();
  }, [highlightHexColor, id, src]);

  useLayoutEffect(() => {
    if (svgContainerRef.current && svgText) {
      const svg = svgContainerRef.current.querySelector("svg")!;
      isReversed ? reverseFloorPlanDirection(svg) : resetFloorPlanDirection(svg);
      hideMainText(svg);
      setMaskColor(svg, maskHexColor);
      highlightOptions(highlightedOptionCodes, maskHexColor, selectedOptionCodes, svg);
    }
  }, [highlightedOptionCodes, isReversed, maskHexColor, selectedOptionCodes, svgText]);

  return (
    <div
      css={{
        ...xss,
        svg: {
          height: "inherit",
          width: "inherit",
        },
      }}
      ref={svgContainerRef}
      dangerouslySetInnerHTML={{ __html: svgText }}
      {...tid}
    />
  );
};

function getReversableElements(svg: SVGSVGElement) {
  // correct the text - find all the `<g />` elements with the proper ids
  let textGroups = [
    ...Array.from(svg.querySelectorAll<SVGGElement>(`g[id*="-_TEXT"]`)),
    ...Array.from(svg.querySelectorAll<SVGGElement>(`g[id*="-_ELEVATOR"]`)),
    ...Array.from(svg.querySelectorAll<SVGGElement>(`g[id*="A-ANNO-NOTE"]`)),
  ];

  textGroups = removeChildren(textGroups);

  return textGroups;
}

function resetFloorPlanDirection(svg: SVGSVGElement) {
  // flip the SVG horizontally
  svg.style.transform = "scaleX(1)";

  const textGroups = getReversableElements(svg);

  textGroups.forEach((group) => {
    group.style.transform = "rotateY(0deg)";
    group.style.transformOrigin = "0";
    group.style.transformBox = "view-box";
  });
}

function reverseFloorPlanDirection(svg: SVGSVGElement) {
  // flip the SVG horizontally
  svg.style.transform = "scaleX(-1)";

  const textGroups = getReversableElements(svg);

  textGroups.forEach((group) => {
    group.style.transform = "rotateY(180deg)";
    group.style.transformOrigin = "50%";
    group.style.transformBox = "fill-box";
  });
}

function hideMainText(svg: SVGSVGElement) {
  // hide all MAIN_TEXT to dedupe content
  const mainTextGroups = Array.from(svg.querySelectorAll<SVGGElement>(`g[id*="MAIN_TEXT"]`));
  mainTextGroups.forEach((group) => (group.style.display = "none"));
}

function setMaskColor(svg: SVGSVGElement, maskHexColor: string) {
  const maskWhiteouts = Array.from(svg.querySelectorAll<SVGGElement>(`g[id*="HB-MASK-WHITEOUT"] *`));
  maskWhiteouts.forEach((group) => (group.style.fill = maskHexColor));
}

function highlightOptions(
  highlightedOptionCodes: FloorPlanProps["highlightedOptionCodes"],
  maskHexColor: string,
  selectedOptionCodes: FloorPlanProps["selectedOptionCodes"],
  svg: SVGSVGElement,
) {
  // find all the `<g />` elements that include `OPTION-CODE` in their `id`
  const optionGroups = Array.from(svg.querySelectorAll<SVGGElement>(`g[id*="OPTION-CODE"]`));
  // for each of them, hide them if they are not in the `selectedOptionCodes` and show them if they are
  optionGroups.forEach((group) => {
    let optionCode = group.id.split("-").at(-1) ?? "";
    // handle when additional dynamic numbers exist after option code in SVG, e.g. OPTION-CODE-<OptionCode_123456789_>
    if (optionCode.includes("_")) {
      optionCode = optionCode.split("_")[0];
    }
    // hide all options that are not selected
    if (!selectedOptionCodes?.includes(optionCode)) {
      group.style.display = "none";
    }
    // determine which options to only show geometry for
    // note: when highlightedOptionCodes is not provided then all selected options will be highlighted
    if (
      selectedOptionCodes?.includes(optionCode) &&
      highlightedOptionCodes?.length &&
      !highlightedOptionCodes?.includes(optionCode)
    ) {
      // find option on SVG
      const optionEl = svg.querySelector<SVGGElement>(`g[id*="${optionCode}"]`);
      // find its child highlight group elements
      const highlightGroupEls = Array.from(
        optionEl?.querySelectorAll<SVGGElement>(`[id*="HIGHLIGHT"], [id*="HIGHLIGHT"] *`) || [],
      );
      // set highlights fill color to match mask color so it's as if it's not highlighted
      if (highlightGroupEls) {
        highlightGroupEls.forEach((highlightGroupEl) => {
          highlightGroupEl.style.fill = maskHexColor;
        });
      }
    }
    // show selected options
    if (selectedOptionCodes?.includes(optionCode)) {
      group.style.display = "block";
    }
  });
}

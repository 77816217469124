import React, { useEffect, useRef, useState } from "react";
import { ImageDisclaimer, PreviewCard } from "src/pages/configurator/components";
import { Carousel, CarouselRef, FloorPlan, IconButton, ImagePlaceholder, TooltipV2 } from "~components";
import { Css } from "~generated/css";
import { OptionImage } from "~generated/graphql";
import { defaultTestId, getContainerHeight, isDefined, useContainerBreakpoints, useTestIds } from "~utils";
// FIXME: most components using this type need to do an `as Type` somewhere to get rid of TS errors.
//  Why are we receiving undefined imageUrls from the server if our graphql schema set it as required?
export type ImageDisplayImageProps = { isReversed?: boolean } & Pick<
  OptionImage,
  "id" | "name" | "imageUrl" | "description"
>;

type ImageDisplayProps = {
  images: ImageDisplayImageProps[];
  title?: string;
  inlineNavBtns?: boolean;
  disclaimerOverride?: React.ReactNode;
  placeholderOverride?: React.ReactElement;
  showDisclaimer?: boolean;
  // NOTE: lightText is essentially interchangeable `darkMode` but we have no concept of that design in our app yet;
  lightText?: boolean;
  displayingStaticSvgs?: boolean;
  displayingDynamicSvgs?: boolean;
  selectedOptionCodes?: string[];
  imageIndex?: number;
  isReversed?: boolean;
  onClick?: (index: number) => void;
  shrinkWidthToFit?: boolean;
};

const VERTICAL_HEIGHT_BREAKPOINT = 800; // Sweet spot to trigger hiding the preview cards image;
const HORIZONTAL_BREAKPOINT = 782; // mainly for preview cards;
export function ImageDisplay(props: ImageDisplayProps) {
  const {
    images,
    title,
    inlineNavBtns = true,
    disclaimerOverride,
    placeholderOverride,
    showDisclaimer = true,
    lightText = false,
    displayingStaticSvgs,
    displayingDynamicSvgs,
    selectedOptionCodes,
    imageIndex,
    isReversed = false,
    onClick,
    shrinkWidthToFit = false,
  } = props;
  const tid = useTestIds(props, "image-display");
  const [imgExpanded, setImgExpanded] = useState(false);
  // allow setting of index from parent component
  const [imgDisplayIdx, setImgDisplayIdx] = useState(imageIndex ? imageIndex : 0);
  // JUST the images, not the cards
  const [hidePreviewImages, setHidePreviewImages] = useState(false);
  const carouselRef = useRef<CarouselRef>(null);
  const { ref: mainContainerRef, breakpoint } = useContainerBreakpoints<HTMLDivElement>({
    reduced: [0, HORIZONTAL_BREAKPOINT],
  });

  useEffect(() => {
    // set based on if provided value changes from parent component
    if (imageIndex) {
      setImgDisplayIdx(imageIndex);
      return;
    }
    setImgDisplayIdx(0);
    // adding images to dependency array to reset index when using
    // toggle to switch between image and floor plan since they may
    // have different number of images
  }, [imageIndex, images]);

  useEffect(() => {
    if (!mainContainerRef.current) return;
    const verticalResizeObserver = new ResizeObserver((entries) => {
      const height = getContainerHeight(entries[0]);
      setHidePreviewImages(height <= VERTICAL_HEIGHT_BREAKPOINT);
    });

    verticalResizeObserver.observe(mainContainerRef.current);
    return () => verticalResizeObserver.disconnect(); // clean up
  }, [mainContainerRef]);

  const COMPONENT_PLACEHOLDER = placeholderOverride ? placeholderOverride : <ImagePlaceholder />;

  if (images.length === 0) {
    return COMPONENT_PLACEHOLDER;
  }
  if (images.length === 1) {
    const image = images[0];
    return (
      <div
        ref={mainContainerRef}
        css={{
          ...Css.df.fg1.h100.overflowHidden.$,
          // Change the background color when in lightText mode
          ...Css.bgOffWhite.if(lightText).bgGray800.$,
        }}
        {...tid}
      >
        {/* Image Container */}
        {image.imageUrl ? (
          <div css={Css.df.fdc.w100.$}>
            <div css={Css.add("transition", "0.1s").relative.df.w100.overflowHidden.ar("5 / 3").fg1.$}>
              {displayingDynamicSvgs ? (
                <FloorPlan
                  id={image.id.replaceAll(":", "")}
                  key={image.id}
                  src={image.imageUrl}
                  xss={Css.h100.ma.if(shrinkWidthToFit).ifXs.w100.$}
                  highlightHexColor="#C59877"
                  maskHexColor="#F6F6F0"
                  selectedOptionCodes={selectedOptionCodes}
                  isReversed={isReversed || image.isReversed}
                />
              ) : (
                <>
                  <img
                    css={
                      Css.w100
                        .if(!displayingStaticSvgs)
                        .objectCover.if(isReversed || !!image.isReversed)
                        .add("transform", "scaleX(-1)").$
                    }
                    src={image.imageUrl}
                    alt={image.name}
                  />
                  {showDisclaimer && <ImageDisclaimer title={disclaimerOverride} />}
                </>
              )}
            </div>
            {/* 9% is sweetspot for padding. Overall looks better than trying to enforce a width here with mxa */}
            <div css={Css.df.fdc.jcc.maxw100.px("9%").bgOffWhite.mya.pt2.pb3.$}>
              <h6 css={Css.header20.lhPx(32).gray800.$}>{image.name}</h6>
              <div css={Css.mt1.body14.lhPx(22).gray600.$}>{image.description}</div>
            </div>
          </div>
        ) : (
          COMPONENT_PLACEHOLDER
        )}
      </div>
    );
  }

  const carouselItems = images.map(({ id, name, imageUrl, isReversed: imageIsReversed }, i) => (
    <PreviewCard
      key={id}
      lightText={lightText}
      hideImage={hidePreviewImages}
      image={{ id, name, imageUrl }}
      small={breakpoint.reduced}
      isActive={i === imgDisplayIdx}
      onClick={() => {
        // Tell the carousel the number of slides changed
        const iDiff = i - imgDisplayIdx;
        if (iDiff > 0) {
          carouselRef.current?.forward(iDiff);
        } else {
          carouselRef.current?.backward(Math.abs(iDiff));
        }
        setImgDisplayIdx(i);
        onClick?.(i);
      }}
      isReversed={isReversed || imageIsReversed}
      displayingStaticSvgs={displayingStaticSvgs}
      displayingDynamicSvgs={displayingDynamicSvgs}
      selectedOptionCodes={selectedOptionCodes}
      {...tid[`previewCard_${defaultTestId(name)}`]}
    />
  ));

  const FwdButton = () => (
    <IconButton
      size={breakpoint.reduced ? "small" : "medium"}
      icon={`chevron-right${!lightText ? "-white" : ""}`}
      color={lightText && imgDisplayIdx !== images.length - 1 ? "primary" : "secondary"}
      onClick={() => {
        carouselRef.current?.forward();
        setImgDisplayIdx(imgDisplayIdx + 1);
        onClick?.(imgDisplayIdx + 1);
      }}
      disabled={imgDisplayIdx === images.length - 1}
      {...tid.forward_iconButton}
    />
  );

  const BackButton = () => (
    <IconButton
      size={breakpoint.reduced ? "small" : "medium"}
      icon={`chevron-left${!lightText ? "-white" : ""}`}
      color={lightText && imgDisplayIdx !== 0 ? "primary" : "secondary"}
      onClick={() => {
        carouselRef.current?.backward();
        setImgDisplayIdx(imgDisplayIdx - 1);
        onClick?.(imgDisplayIdx - 1);
      }}
      disabled={imgDisplayIdx === 0}
      {...tid.backward_iconButton}
    />
  );

  return (
    <div
      ref={mainContainerRef}
      css={{
        ...Css.df.fdc.h100.w100.relative.$,
        // Change the background color when in lightText mode
        ...Css.bgOffWhite.if(lightText).bgGray800.$,
      }}
      {...tid}
    >
      <div css={Css.absolute.top3.right4.z1.$} id="expand-collapse">
        <IconButton
          icon={imgExpanded ? "collapse" : "expand"}
          color="primary"
          size="medium"
          shape="square"
          onClick={() => setImgExpanded(!imgExpanded)}
          {...tid.expand_button}
        />
        <TooltipV2 anchorSelect="#expand-collapse" cssOverrides={Css.px1.py("4px").bgGray800.$}>
          <div css={Css.white.z1.$}>{imgExpanded ? "Collapse" : "Expand"}</div>
        </TooltipV2>
      </div>
      {/* Image Container */}
      <div css={Css.relative.overflowHidden.df.w100.ar("5 / 3").if(imgExpanded).h100.else.fg1.$}>
        {isDefined(images[imgDisplayIdx]?.imageUrl) && displayingDynamicSvgs ? (
          <FloorPlan
            id={images[imgDisplayIdx].id.replaceAll(":", "")}
            key={images[imgDisplayIdx].id}
            src={images[imgDisplayIdx].imageUrl!}
            // avoid covering up SVG by toggle
            xss={Css.h100.ma.if(!imgExpanded).pt8.$}
            highlightHexColor="#C59877"
            maskHexColor="#F6F6F0"
            selectedOptionCodes={selectedOptionCodes}
            isReversed={isReversed || images[imgDisplayIdx].isReversed}
          />
        ) : isDefined(images[imgDisplayIdx]?.imageUrl) ? (
          <>
            <img
              css={{
                ...Css.w100.if(!displayingStaticSvgs).objectCover.$,
                // Making the image "contain" when in expanded state since we
                // don't want any of it to be cropped.
                ...Css.if(imgExpanded).objectContain.$,
                ...Css.if(isReversed || !!images[imgDisplayIdx].isReversed).add("transform", "scaleX(-1)").$,
              }}
              src={images[imgDisplayIdx].imageUrl || ""}
              alt={images[imgDisplayIdx].name}
            />
            {showDisclaimer && <ImageDisclaimer title={disclaimerOverride} />}
          </>
        ) : (
          COMPONENT_PLACEHOLDER
        )}
      </div>
      {/* Preview Cards Container */}
      {!imgExpanded && (
        <div id="preview-card-container" css={Css.w100.fs0.df.fdc.jcc.aic.p2.gap2.if(!inlineNavBtns).px1.$}>
          {inlineNavBtns && title && <span css={Css.body16.lhPx(24).fw500.if(lightText).white.$}>{title}</span>}
          <div css={Css.df.gap1.w100.aic.jcc.$}>
            {inlineNavBtns && (
              <div css={Css.px4.if(breakpoint.reduced).pl2.$}>
                <BackButton />
              </div>
            )}
            <div css={Css.df.fdc.overflowHidden.$}>
              {!inlineNavBtns && (
                <div css={Css.df.aic.jcsb.mb2.$}>
                  <span css={Css.body16.lhPx(24).fw500.if(lightText).white.$}>{title}</span>
                  <div css={Css.df.gap2.$}>
                    <BackButton />
                    <FwdButton />
                  </div>
                </div>
              )}
              <Carousel ref={carouselRef} items={carouselItems} />
            </div>
            {inlineNavBtns && (
              <div css={Css.px4.if(breakpoint.reduced).pr2.$}>
                <FwdButton />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

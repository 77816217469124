import { camelCase } from "change-case";

/**
 * @example
 * const tid = useTestIds({});
 * const tid = useTestIds({}, "defaultPrefix");
 * const tid = useTestIds(props);
 * const tid = useTestIds(props, "defaultPrefix");
 * <div {...tid}></div>
 * <div {...tid.something}></div>
 */
export function useTestIds(props: object, defaultPrefix?: string): TestIds {
  /**
   * when data-testid attribute passed to child component (data-testid="something") that contains a default then default not used
   * const tid = useTestIds(props, "somethingElse")
   * tid === { data-testid: "something" }
   *
   * when data-testid attribute passed to child component (data-testid="something") that contains no default
   * const tid = useTestIds(props)
   * tid === { data-testid: "something" }
   *
   * when data-testid attribute passed to child component (data-testid="something") that contains no default or props
   * const tid = useTestIds({})
   * tid === { data-testid: undefined }
   *
   * when data-testid attribute IS NOT passed to child component that contains a default
   * const tid = useTestIds(props, "somethingElse")
   * tid === { data-testid: "somethingElse" }
   *
   * when data-testid attribute IS NOT passed to child component that contains no default
   * const tid = useTestIds(props)
   * tid === { data-testid: undefined }
   *
   * when data-testid attribute IS NOT passed to child component that contains no default or props
   * const tid = useTestIds({})
   * tid === { data-testid: undefined }
   *
   */
  const prefix: string | undefined =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (props as any)["data-testid"] ||
    // Pass defaultPrefix through defaultTestId to allow `useTestIds(..., label)` usage
    (defaultPrefix ? defaultTestId(defaultPrefix) : undefined);
  const rootId = { "data-testid": prefix };
  return newMethodMissingProxy(rootId, (key) => {
    // If we get tagged ids, remove the colon so that we can do `r.foo_m2` for `m:2`
    key = key.replace(":", "");
    return { "data-testid": prefix ? `${prefix}_${key}` : key };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;
}

// Uses `object` for any keys that exist on it, otherwise calls `methodMissing` fn
function newMethodMissingProxy<T extends object, Y>(
  object: T,
  methodMissing: (key: string) => Y,
): T & Record<string, Y> {
  return new Proxy(object, {
    get(object, property) {
      if (Reflect.has(object, property)) {
        return Reflect.get(object, property);
      } else if (property === "then") {
        return undefined;
      } else {
        return methodMissing(String(property));
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;
}

// Guesses an id based on a label string, i.e. given `Something Cool` returns `somethingCool`
export function defaultTestId(label: string): string {
  return camelCase(label);
}

export type TestIds = Record<string, object>;

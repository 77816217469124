import { SyntheticEvent } from "react";

export function phoneMask() {
  let oldValue = "";
  let oldCursor: number | null = 0;

  function mask(value: string) {
    const output = [];
    for (let i = 0; i < value.length; i++) {
      if (i === 0) {
        output.push("(");
      }
      if (i === 3) {
        output.push(") ");
      }
      if (i === 6) {
        output.push("-");
      }
      output.push(value[i]);
    }
    return output.join("");
  }

  function unMask(value: string) {
    return value.replace(new RegExp(/[^\d]/, "g"), ""); // remove non-digit characters
  }

  function onKeydown(e: SyntheticEvent) {
    const el = e.target as HTMLInputElement;
    oldValue = el.value;
    oldCursor = el.selectionEnd;
    return { oldValue: el.value, oldCursor: el.selectionEnd };
  }

  function onInput(e: SyntheticEvent) {
    const el = e.target as HTMLInputElement;
    const regex = new RegExp(/^\d{0,10}$/g); // expect 0 to 10 digits;

    let newCursorPosition;
    let newValue = unMask(el.value);

    // make sure we have between 0 and 10 digits
    if (newValue.match(regex)) {
      newValue = mask(newValue);

      let cursor = oldCursor;
      const isInsert = unMask(newValue).length > unMask(oldValue).length;
      const isDelete = unMask(oldValue).length === unMask(newValue).length;

      if (isInsert && cursor === 0) {
        // skip 1 for "(" (paren)
        cursor = cursor + 1;
      }

      if (isInsert && cursor === 4) {
        // skip 2 for ") " (paren and space)
        cursor = cursor + 2;
      }

      if (isDelete && cursor === 5) {
        // convert to digits to handle ") " (paren and space)
        const unMaskedModifiedValue = unMask(newValue).slice(0, 2) + unMask(newValue).slice(3);
        newValue = mask(unMaskedModifiedValue);
      }

      if (isDelete && cursor === 6) {
        // convert to digits to handle ") " (paren and space)
        cursor = cursor - 1;
        const unMaskedModifiedValue = unMask(newValue).slice(0, 2) + unMask(newValue).slice(3);
        newValue = mask(unMaskedModifiedValue);
      }

      if (isInsert && cursor === 9) {
        // skip 1 for "-" (dash)
        cursor = cursor + 1;
      }

      if (isDelete && cursor === 10) {
        // convert to digits to handle "-" (dash)
        const unMaskedModified = unMask(newValue).slice(0, 5) + unMask(newValue).slice(6);
        newValue = mask(unMaskedModified);
      }

      // calculate cursor position - handle insert and delete
      newCursorPosition = cursor! + (unMask(newValue).length - unMask(oldValue).length);

      if (newValue !== "") {
        // some chars exist
        el.value = newValue;
      } else {
        // no chars exist as been deleted
        el.value = "";
      }
    } else {
      // all chars exist
      el.value = oldValue;
      newCursorPosition = oldCursor;
    }

    // set cursor position
    el.setSelectionRange(newCursorPosition, newCursorPosition);

    return { newCursorPosition, newValue: el.value };
  }

  return { onKeydown, onInput, mask, unMask };
}

import { Fragment, ReactNode } from "react";
import { Tag } from "~components";
import { Css } from "~generated/css";
import { homeboundStandard, standardSpec } from "../SpecDetails";

export function WhatsIncludedContent(props: { hideTitle?: boolean }) {
  const { hideTitle } = props;
  const details = homeboundStandard;
  return (
    <>
      {!hideTitle && (
        <div css={Css.mb5.px3.tc.$}>
          <h4 css={Css.header32.lhPx(48).$}>What's Included in my Home?</h4>
        </div>
      )}
      <div css={Css.bgWhite.mx8.mb4.relative.$}>
        {/* -12 is half of the height of the tag */}
        <div css={Css.absolute.top("-12px").left0.right0.tc.$}>
          <Tag backgroundColor="#DBEAFE">Homebound Standard</Tag>
        </div>
        <div css={Css.dg.gtc("1fr 2fr").p7.bshButton.$}>
          {details.map((detail, i) => {
            return (
              <Fragment key={detail.title}>
                <ColumLeft key={detail.title} title={detail.title} index={i} />
                <ColumnRight index={i}>
                  <ListItems features={detail.details || []} />
                </ColumnRight>
              </Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
}

function ColumLeft(props: { title: string; index: number }) {
  const { title, index } = props;
  return <div css={Css.bt.bGray200.pt2.pb2.header18.fw500.if(index === 0).bn.$}>{title}</div>;
}

function ColumnRight({ children, index }: { children: ReactNode; index: number }) {
  return <div css={Css.bt.bGray200.pt2.pb2.body14.lh("20px").gray600.if(index === 0).bn.$}>{children}</div>;
}

function ListItems(props: { features: (string | standardSpec)[] }) {
  const { features } = props;

  if (features.length === 0) return null;

  const formattedList = features.map((feature, i) => {
    if (i === 0 && typeof feature === "string") {
      return <li key={feature}>{feature}</li>;
    }
    if (typeof feature === "string") {
      return (
        <li key={feature} css={Css.mt1.$}>
          {feature}
        </li>
      );
    }

    return (
      <Fragment key={feature.title}>
        <li key={feature.title} css={Css.mt1.lhPx(16).$}>
          <div css={Css.pb1.$}>{feature.title}</div>
          {ListItems({ features: feature.details })}
        </li>
      </Fragment>
    );
  });

  return <ul css={Css.m0.pl2.$}>{formattedList}</ul>;
}

import { createMoodboardPath } from "src/RouteUrls";
import { Css } from "~generated/css";
import { ContractedCustomerConfigurationFragment, OptionImageType } from "~generated/graphql";

type MoodboardTabProps = {
  customerConfiguration: ContractedCustomerConfigurationFragment;
};

export function MoodboardTab(props: MoodboardTabProps) {
  const { customerConfiguration } = props;

  const moodboardImage = customerConfiguration.options
    .flatMap((o) => o.option.images)
    .find((i) => i.type.code === OptionImageType.Moodboard);

  if (!moodboardImage) {
    return (
      <div css={Css.df.fdc.aic.jcc.aic.gap2.overflowHidden.h("100vh").$}>
        <p>This configuration does not have an option with a moodboard image.</p>
      </div>
    );
  }

  return (
    <a href={createMoodboardPath(customerConfiguration.code)} target="_blank" rel="noreferrer">
      <img src={moodboardImage.imageUrl} alt={moodboardImage.name} css={Css.h("calc(100vh - 112px)").objectContain.$} />
    </a>
  );
}

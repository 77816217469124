import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { getStorageKey, setStorageKey } from "src/utils/storage";
import { BasicOverlay, Button, Icon, PhoneField, TextField } from "~components";
import { Container, Css } from "~generated/css";
import { ImageGalleryImage, Property, SaveCustomerInput, useCreateCustomerMutation } from "~generated/graphql";
import { useTestIds } from "~utils";
import "./ContactUsFlyout.css";
import { getTrackingParamsFromCookies } from "./ContactUsFlyout.utils";

type ContactUsFlyoutProps = {
  property: Pick<
    Property,
    "id" | "city" | "state" | "postalCode" | "streetAddress" | "streetAddress" | "bedrooms" | "bathrooms" | "sqft"
  > & { imageGallery: Pick<ImageGalleryImage, "imageUrl">[] };
  hideProperty?: boolean;
  onClose: () => void;
};

type FormState = Omit<SaveCustomerInput, "propertyId">;

const flyoutMaxWidth = 544;

export function ContactUsFlyout(props: ContactUsFlyoutProps) {
  const { onClose, hideProperty = false, property } = props;
  const { city, state, postalCode, imageGallery, streetAddress, bedrooms, bathrooms, sqft } = property;

  // use sessionStorage to prefill the form if available
  const sessionStorageFormData =
    getStorageKey<Pick<FormState, "firstName" | "lastName" | "email" | "phoneNumber">>("contactUsFlyout");
  const [form, setForm] = useState<FormState>(() => {
    const { firstName = "", lastName = "", email = "", phoneNumber = "" } = sessionStorageFormData ?? {};
    return { firstName, lastName, email, phoneNumber };
  });

  const [createCustomer] = useCreateCustomerMutation();
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const tid = useTestIds(props, "contactUsFlyout");
  const formTid = useTestIds(tid.form);

  // Persist updates to form data in sessionStorage
  useEffect(() => {
    setStorageKey("contactUsFlyout", form);
  }, [form]);

  useEffect(() => {
    // When the flyout is shown, we want to prevent scrolling
    document.body.style.overflow = "hidden";
    return () => {
      // When the flyout is hidden, we want to allow scrolling again
      document.body.style.overflow = "auto";
    };
  }, []);

  const onSubmit = async (form: FormState) => {
    const trackingParams = getTrackingParamsFromCookies();
    await createCustomer({
      variables: {
        input: {
          ...form,
          propertyId: property.id,
          ...trackingParams,
        },
      },
    })
      .then(() => setSuccess(true))
      .catch(() => setError(true));
  };

  return (
    <BasicOverlay onClick={onClose}>
      <motion.div
        css={
          Css.h("100vh")
            .wPx(flyoutMaxWidth)
            .overflowAuto.bgWhite.boxShadow("0px 4px 8px rgba(53, 53, 53, 0.1), 0px 2px 24px rgba(53, 53, 53, 0.08)")
            .fixed.container().overflowXHidden.ifMobileOrTablet.w100.$
        }
        initial={{ right: -flyoutMaxWidth }}
        animate={{ right: 0 }}
        transition={{ duration: 1 }}
        {...tid}
      >
        <div css={Css.py4.px6.ifMobileOrTablet.pt8.px3.$} onClick={(e) => e.stopPropagation()}>
          <button css={Css.absolute.topPx(30).rightPx(30).$} onClick={onClose} {...tid.closeButton}>
            <Icon name="x" />
          </button>
          {!success ? (
            <form {...formTid}>
              <div css={Css.header24.ifMobileOrTablet.header40.$}>Contact us</div>
              {!hideProperty && (
                <div
                  css={{
                    ...Css.mt3.bgOffWhite.br4.p2.df.$,
                    [Container({ lt: flyoutMaxWidth - 1 })]: Css.dn.$,
                  }}
                >
                  <div>
                    <img
                      css={Css.wPx(150).hPx(85).objectCover.br4.$}
                      src={imageGallery[0].imageUrl}
                      alt={streetAddress}
                    />
                  </div>
                  <div css={Css.ml2.$}>
                    <span css={Css.black.body16.mt1.$}>
                      {streetAddress}
                      <br />
                      {`${city}, ${state} ${postalCode}`}
                    </span>

                    <div css={Css.df.jcsb.body14.black.mt1.gap2.$}>
                      <span>{sqft} Sq. ft</span>
                      <span>{bedrooms} beds</span>
                      <span>{bathrooms} baths</span>
                    </div>
                  </div>
                </div>
              )}
              <div css={Css.mt3.gray900.body18.fw500.ifMobileOrTablet.fsPx(24).$}>
                Interested in <span css={Css.ifMobileOrTablet.dn.$}>learning more about</span> this house?
              </div>
              <div css={Css.mt1.gray700.fw300.$}>
                Provide us with your contact information below and a sales rep will be in touch. Exact level of
                customization available is based on address, and can be confirmed by a Homebound representative.
              </div>
              <div css={{ [Container({ lt: flyoutMaxWidth - 1 })]: Css.df.gap3.$ }}>
                <div css={Css.mt3.w100.$}>
                  <TextField
                    placeholder="First Name*"
                    required
                    value={form.firstName}
                    onChange={(_, firstName) => setForm((prevForm) => ({ ...prevForm, firstName }))}
                    autoComplete="given-name"
                    {...formTid.firstName}
                  />
                </div>
                <div css={Css.mt3.w100.$}>
                  <TextField
                    placeholder="Last Name*"
                    required
                    value={form.lastName}
                    onChange={(_, lastName) => setForm((prevForm) => ({ ...prevForm, lastName }))}
                    autoComplete="family-name"
                    {...formTid.lastName}
                  />
                </div>
              </div>
              <div css={Css.mt3.$}>
                <TextField
                  placeholder="Email address*"
                  required
                  type="email"
                  value={form.email}
                  onChange={(_, email) => setForm((prevForm) => ({ ...prevForm, email }))}
                  autoComplete="email"
                  {...formTid.email}
                />
              </div>
              <div css={Css.mt3.$}>
                <PhoneField
                  placeholder="Phone number"
                  onChange={(phoneNumber) => setForm((prevForm) => ({ ...prevForm, phoneNumber }))}
                  value={form.phoneNumber || undefined}
                  {...formTid.phoneNumber}
                />
              </div>

              {error ? (
                <div css={Css.mt5.body14.fw400.redError.$}>Oops! Something went wrong, please resubmit form</div>
              ) : (
                <div css={Css.mt6.$}></div>
              )}

              <div css={Css.mt1.$}>
                <Button
                  disabled={
                    !(
                      form.firstName?.length > 0 &&
                      form.lastName?.length > 0 &&
                      form?.email?.match(/.+@.+\..+/) &&
                      // if raw phone number is not empty then must be 10 digits
                      (form.phoneNumber ? form.phoneNumber.length === 10 : true)
                    )
                  }
                  onClick={() => onSubmit(form)}
                  type="button"
                  {...formTid.submitButton}
                >
                  Submit
                </Button>
              </div>
              <div css={Css.mt3.black.body14.$}>
                By submitting this form, you’re authorizing Homebound to contact you via your preferred contact method,
                in accordance with our <a href="https://www.homebound.com/terms-of-use">Terms of Use</a> and{" "}
                <a href="https://www.homebound.com/privacy-policy">Privacy Policy</a>. If you provide us with your phone
                number, you consent to receiving communications from Homebound by call or text and confirm that you have
                the authority to consent to receive such communication.
              </div>
            </form>
          ) : (
            <div css={Css.df.fdc.aic.$}>
              <div css={Css.hPx(130.16).wPx(145).mt("91px").$}>
                <Icon name="successMail" />
              </div>
              <div css={Css.fw500.fs("32px").mt6.gray900.$}>Success!</div>
              <div css={Css.fw300.fs("18px").mt2.gray700.tc.mx7.$}>
                We saved your information and will be in touch with you soon.
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </BasicOverlay>
  );
}

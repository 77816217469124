import { useEffect, useMemo, useState } from "react";
import Div100vh from "react-div-100vh";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { HeaderStep } from "src/pages/configurator/components";
import { ConfiguratorHeaderSteps } from "src/pages/configurator/Configurator.context";
import { createConfiguratorUrl, internalConfiguratorLotsPath } from "src/RouteUrls";
import { Button, TooltipV2 } from "~components";
import { Css } from "~generated/css";
import { useTestIds } from "~utils";

// This layout is meant to assist stakeholders in highlighting HB differentiations provided by configurator
// aka it's a "dumb" page on purpose but if it gets added to our normal flow should slide into configurator.context nicely

// Note: This is a copy of Configurator.layout.tsx with the following changes:
// - No Configuration Provider, header, or stepper
//   - We import <HeaderStep>
//   - Custom stepper is mostly copy paste with reallll basic continue logic
//   - Copy pasted stepper code from stepper component.
// - No <ToastProvider> and <ModalProvider> from the layout

// allowing undefined mainly for ts when setting previous values. might need to split instead
export type UserAddresses = { lotAddress?: string; validEmail?: boolean } | null;

export function LotLayout() {
  const isBoylFlowState = useState<boolean | null>(null);
  const userAddresses = useState<UserAddresses>(null);
  const selectedLotId = useState<string | null>(null);

  return (
    <Div100vh css={Css.df.fdc.overflowHidden.bgWhite.$}>
      <ConfiguratorLotHeader />
      <Outlet context={{ isBoylFlowState, userAddresses, selectedLotId }} />
      <ConfigurationLotStepper
        isBoylFlow={isBoylFlowState[0]}
        userAddresses={userAddresses[0]}
        selectedLotId={selectedLotId[0]}
      />
    </Div100vh>
  );
}

function ConfiguratorLotHeader() {
  const headerSteps = Object.entries({
    lot: {
      stepTitles: ["Lot"],
      sortOrder: 0,
      stepKey: "plans",
    },
    ...ConfiguratorHeaderSteps,
  });

  return (
    <div css={Css.z1.bshBasic.hPx(72).w100.df.asfs.px4.fs0.$}>
      <div css={Css.w100.df.aic.jcsb.relative.$}>
        <img width="153" height="26" src="/logo.svg" alt="Homebound" />
        <div css={Css.absolute.left("50%").tx("-50%").df.jcse.wPx(526).body14.fw400.h100.aic.$}>
          {headerSteps.map(([key, value], i) => {
            return (
              <HeaderStep
                key={key}
                menuTitle={key!}
                isActiveTitle={i === 0}
                isLast={i === headerSteps.length - 1}
                onClick={() => null}
                isDisabled={i !== 0}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

// using a static path with our create url logic just in case
const SW_FL_CONFIGURATOR_PATH = createConfiguratorUrl({ name: "Southwest Florida" });

function ConfigurationLotStepper(props: {
  isBoylFlow: boolean | null;
  userAddresses: UserAddresses;
  selectedLotId: string | null;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const tid = useTestIds(props, "configurator-lot-stepper");

  const { isBoylFlow, userAddresses, selectedLotId } = props;
  const [nextStep, setNextStep] = useState("boyl");
  const [disabledReason, setDisabledReason] = useState<string | null>(null);
  // Lot configurator step order:
  // "Select Build Type" ->
  // "Enter Lot (via boyl user entry || bool lot select)" ->
  // enter actual sw-fl configugator
  useEffect(() => {
    if (location.pathname === internalConfiguratorLotsPath) {
      setNextStep(isBoylFlow ? "boyl" : "bool");
    } else {
      setNextStep(SW_FL_CONFIGURATOR_PATH);
    }
  }, [location, isBoylFlow]);

  const enableContinue = useMemo(() => {
    setDisabledReason(null);
    let valid = false;
    switch (location.pathname) {
      case internalConfiguratorLotsPath:
        valid = isBoylFlow !== null;
        if (!valid) {
          setDisabledReason("Please select your build type");
        }
        return valid;
      case internalConfiguratorLotsPath + "/boyl":
        valid = Boolean(userAddresses?.lotAddress && userAddresses?.validEmail);
        if (!valid) {
          setDisabledReason(
            !userAddresses?.lotAddress ? "Please enter an address" : "Please enter a valid email address",
          );
        }
        return valid;
      case internalConfiguratorLotsPath + "/bool":
        valid = selectedLotId !== null;
        if (!valid) {
          setDisabledReason("Please select your lot");
        }
        return valid;
      default:
        return valid;
    }
  }, [location.pathname, isBoylFlow, userAddresses?.lotAddress, userAddresses?.validEmail, selectedLotId]);

  return (
    <div {...tid} css={Css.bgGray800.px3.py2.hPx(72).w100.df.jcsb.asfe.aic.mta.z2.fs0.$}>
      <div css={Css.df.$}></div>
      <div css={Css.df.gap2.$}>
        {location.pathname !== internalConfiguratorLotsPath && (
          <div css={Css.wPx(114).$}>
            <Button
              size="large"
              outline="gray200"
              btnFontSize="sm"
              variant="link"
              color="white"
              onClick={() => navigate(internalConfiguratorLotsPath)}
              {...tid.back_button}
            >
              Back
            </Button>
          </div>
        )}
        <div css={Css.wPx(114).$} id="stepper-next-btn">
          <Button
            outline="gray200"
            size="large"
            btnFontSize="sm"
            variant="secondary"
            disabled={!enableContinue}
            onClick={() => navigate(nextStep)}
            {...tid.Next_Button}
          >
            Next
          </Button>
          {disabledReason && <TooltipV2 anchorSelect="#stepper-next-btn">{disabledReason}</TooltipV2>}
        </div>
      </div>
    </div>
  );
}

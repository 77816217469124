import { motion } from "framer-motion";
import { ReactNode, useMemo, useState } from "react";
import { Button } from "~components";
import { Css } from "~generated/css";

type HopTabProps = {
  currentTabName?: string;
  tabs: {
    tabName: string;
    tabContent: ReactNode;
  }[];
};

export function HopTabs(props: HopTabProps) {
  const { currentTabName, tabs } = props;
  const [selectedTabName, setSelectedTabName] = useState<string>(currentTabName || tabs[0].tabName);

  const tabContent = useMemo(() => {
    return (
      <motion.div
        key={`motion-content-${selectedTabName}`} // setting key to the same dependency as memo hook will ensure fade transition between tabs
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        css={Css.pb4.m0.h100.$}
      >
        {selectedTabName ? tabs.find((tab) => tab.tabName === selectedTabName)?.tabContent : tabs[0].tabContent}
      </motion.div>
    );
  }, [selectedTabName, tabs]);

  return (
    <>
      <div css={Css.mb4.bb.bGray300.df.gap2.$}>
        {tabs.map(({ tabName }) => (
          <TabButton
            key={tabName}
            label={tabName}
            isActive={selectedTabName === tabName}
            onClick={() => setSelectedTabName(tabName)}
          />
        ))}
      </div>
      {tabContent}
    </>
  );
}

type TabButtonProps = {
  onClick: () => void;
  label: string;
  isActive: boolean;
};

function TabButton({ onClick, label, isActive }: TabButtonProps) {
  return (
    <span>
      <Button
        // override color, underline
        xss={Css.add("transition", "0.2s").bb.bw2.bTransparent.fw400.gray.noUnderline.pb1.if(isActive).black.bBlack.$}
        variant="inline"
        size="small"
        onClick={onClick}
      >
        {label}
      </Button>
    </span>
  );
}

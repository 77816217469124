import { useMemo, useState } from "react";
import { ImageDisplay, StepWrapper } from "src/pages/configurator/components";
import { OptionListContainer, OptionListItem } from "src/pages/configurator/components/options";
import { useConfiguratorContext } from "src/pages/configurator/Configurator.context";
import {
  getReservedOptionPrice,
  useMemoizedConflictsByConflictingOptionCode,
} from "src/pages/configurator/Configurator.utils";
import { AccordionOptionCard, CardSupportingText } from "~components";
import { Css } from "~generated/css";
import { ConfiguratorOptionFragment, OptionImageType } from "~generated/graphql";
import { defaultTestId, partition, useTestIds } from "~utils";

export function AddonsStep() {
  const [expandedCardTitle, setExpandedCardTitle] = useState<string>("Add-Ons");
  const [lastOptionId, setLastOptionId] = useState<string>();
  const {
    loading,
    toggleOption,
    selectedOptions,
    availableOptionsByOptionType,
    optionConflictsByOptionCode,
    currentStep,
  } = useConfiguratorContext();
  const tid = useTestIds({}, "add-ons-step");

  const selectedOptionIds = useMemo(() => selectedOptions.map((option) => option.option.id), [selectedOptions]);
  const allOptions: ConfiguratorOptionFragment[] = useMemo(() => {
    return availableOptionsByOptionType
      .filter((option) => currentStep.optionTypes.includes(option.name))
      .flatMap((optionType) => optionType.options)
      .filter(
        ({ parentOption }, i, options) =>
          !parentOption || selectedOptionIds.includes(parentOption.id) || options.some((o) => o.id === parentOption.id),
      );
  }, [availableOptionsByOptionType, currentStep, selectedOptionIds]);

  // Get option images for hero image
  const displayImages = useMemo(() => {
    const option = selectedOptions.find((option) => option.option.id === lastOptionId);
    if (option) {
      return option.option.images.filter((i) => i.type.code === OptionImageType.Display);
    }
  }, [lastOptionId, selectedOptions]);

  const [addOnOptions, siteConditionOptions] = partition(
    allOptions,
    (option) => option.planOptionType.name !== "Site Condition",
  );

  const soConflictsByConflictingOptionCode = useMemoizedConflictsByConflictingOptionCode(
    selectedOptions,
    optionConflictsByOptionCode,
  );

  return (
    <StepWrapper
      title="Home Add-ons"
      loading={loading}
      description="Further enhance your home by adding bonus features."
      left={
        <ImageDisplay
          images={displayImages ? displayImages : []}
          disclaimerOverride="Image is representative for color only. Actual plan features and color locations may vary."
          placeholderOverride={<div css={Css.absolute.w100.h100.bgWhite.$}></div>}
          {...tid.imageDisplay}
        />
      }
      right={
        <>
          <AccordionOptionCard
            title="Add-Ons"
            expanded={expandedCardTitle === "Add-Ons"}
            onExpanded={() => setExpandedCardTitle("Add-Ons")}
            counter={{
              current: addOnOptions.filter((o) => selectedOptionIds.includes(o.id)).length,
              total: addOnOptions.length,
            }}
            {...tid.accordionOptionCard}
          >
            <OptionListContainer>
              {addOnOptions.map((option, i, options) => {
                if (option.parentOption && options.some((o) => o.id === option.parentOption!.id)) {
                  return null;
                }
                const conflictData = soConflictsByConflictingOptionCode[option.optionCode];
                const filteredOptions = option.options
                  .map((co) => allOptions.find((o) => o.id === co.id)!)
                  .filter((co) => !!co);

                const selected = selectedOptionIds.includes(option.id);
                const displayPriceInCents = selected
                  ? getReservedOptionPrice(selectedOptions, option.id)
                  : option.priceInCents;

                return (
                  <OptionListContainer key={option.id}>
                    <OptionListItem
                      key={option.optionCode}
                      conflict={conflictData}
                      optionId={option.id}
                      title={option.name}
                      description={option.description}
                      selected={selected}
                      onClick={() => {
                        toggleOption(option);
                        setLastOptionId(option.id);
                      }}
                      priceInCents={displayPriceInCents}
                      {...tid[`optionListItem_${defaultTestId(option.name)}`]}
                    />
                    {filteredOptions.map((co, i, allCos) => {
                      const fOptSelected = selectedOptionIds.includes(co.id);
                      const fOptDisplayPriceInCents = fOptSelected
                        ? getReservedOptionPrice(selectedOptions, co.id)
                        : co.priceInCents;
                      return (
                        <OptionListItem
                          key={co.optionCode}
                          optionId={co.id}
                          conflict={soConflictsByConflictingOptionCode[co.optionCode]}
                          title={co.name}
                          description={co.description}
                          selected={fOptSelected}
                          onClick={() => {
                            toggleOption(co);
                            setLastOptionId(co.id);
                          }}
                          priceInCents={fOptDisplayPriceInCents}
                          child
                          {...tid[`optionListItem_${defaultTestId(co.name)}`]}
                        />
                      );
                    })}
                  </OptionListContainer>
                );
              })}
            </OptionListContainer>
          </AccordionOptionCard>
          <AccordionOptionCard
            title="Site Conditions"
            expanded={expandedCardTitle === "Site Conditions"}
            onExpanded={() => setExpandedCardTitle("Site Conditions")}
            description={
              <CardSupportingText>
                These options are dependent on the size, soil conditions, and coastal zone of your lot. Estimated costs
                are shown below and will be revised per your site conditions prior to contracting.
              </CardSupportingText>
            }
            counter={{
              current: siteConditionOptions.filter((o) => selectedOptionIds.includes(o.id)).length,
              total: siteConditionOptions.length,
            }}
            {...tid.accordionOptionCard}
          >
            <OptionListContainer>
              {siteConditionOptions.map((option, i, options) => {
                if (option.parentOption && options.some((o) => o.id === option.parentOption!.id)) {
                  return null;
                }
                const conflictData = soConflictsByConflictingOptionCode[option.optionCode];
                const filteredOptions = option.options
                  .map((co) => allOptions.find((o) => o.id === co.id)!)
                  .filter((co) => !!co);

                const selected = selectedOptionIds.includes(option.id);
                const displayPriceInCents = selected
                  ? getReservedOptionPrice(selectedOptions, option.id)
                  : option.priceInCents;

                return (
                  <OptionListContainer key={option.id}>
                    <OptionListItem
                      key={option.optionCode}
                      conflict={conflictData}
                      optionId={option.id}
                      title={option.name}
                      description={option.description}
                      selected={selectedOptionIds.includes(option.id)}
                      onClick={() => {
                        toggleOption(option);
                        setLastOptionId(option.id);
                      }}
                      priceInCents={displayPriceInCents}
                      showTBD
                      {...tid[`optionListItem_${defaultTestId(option.name)}`]}
                    />
                    {filteredOptions.map((co, i, allCos) => {
                      const fOptSelected = selectedOptionIds.includes(co.id);
                      const fOptDisplayPriceInCents = fOptSelected
                        ? getReservedOptionPrice(selectedOptions, co.id)
                        : co.priceInCents;
                      return (
                        <OptionListItem
                          key={co.optionCode}
                          optionId={co.id}
                          conflict={soConflictsByConflictingOptionCode[co.optionCode]}
                          title={co.name}
                          description={co.description}
                          selected={selectedOptionIds.includes(co.id)}
                          onClick={() => {
                            toggleOption(co);
                            setLastOptionId(co.id);
                          }}
                          priceInCents={fOptDisplayPriceInCents}
                          child
                          showTBD
                          {...tid[`optionListItem_${defaultTestId(co.name)}`]}
                        />
                      );
                    })}
                  </OptionListContainer>
                );
              })}
            </OptionListContainer>
          </AccordionOptionCard>
        </>
      }
    />
  );
}

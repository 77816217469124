import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-video.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lightgallery.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import LightGallery from "lightgallery/react";
import "./AssetGallery.css";

import { InitDetail } from "lightgallery/lg-events";
import { LightGalleryCoreSettings, LightGallerySettings } from "lightgallery/lg-settings";
import { useCallback, useEffect, useRef } from "react";
import { Css } from "~generated/css";
import { defaultTestId, useTestIds } from "~utils";

export type ImageAsset = {
  id: string;
  downloadUrl: string;
  contentType?: string;
  attachmentUrl?: string;
  previewUrl?: string | null | undefined;
};

export type OpenGallery = (asset?: ImageAsset) => void;

type AssetGalleryProps = {
  assets: ImageAsset[];
  children?: (openGallery: OpenGallery) => JSX.Element;
  caption?: (asset: ImageAsset) => string;
  showThumbnails?: boolean;
  previewDimensions?: {
    width: number;
    height: number;
  };
} & Partial<LightGallerySettings>;

export function AssetGallery(props: AssetGalleryProps) {
  const { assets, children, caption, previewDimensions, showThumbnails = true, ...lightGalleryOthers } = props;
  const lightGallery = useRef<InitDetail["instance"]>({ refresh: () => undefined } as InitDetail["instance"]);
  const plugins: LightGalleryCoreSettings["plugins"] = [lgZoom];
  const tid = useTestIds(props, "assetGallery");

  if (showThumbnails) {
    plugins.push(lgThumbnail);
  }

  const onInit = useCallback((detail: InitDetail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  useEffect(() => {
    lightGallery.current?.refresh();
  }, [assets]);

  const showAssetId = (asset: ImageAsset) => `show-${asset.id}`;

  const openGallery = (asset?: ImageAsset) => {
    asset ? document.getElementById(showAssetId(asset!))?.click() : lightGallery.current?.openGallery();
  };

  const renderSlides = useCallback(
    (openGallery: OpenGallery) => {
      return (
        <>
          {assets.map((asset) => {
            const imagePreviewTid = defaultTestId(`preview${asset.id}`);
            return (
              <div key={asset.id} css={Css.mx1.mt1.relative.$}>
                <ImagePreview
                  onClick={() => openGallery(asset)}
                  asset={asset}
                  dimensions={previewDimensions}
                  {...tid[imagePreviewTid]}
                />
              </div>
            );
          })}
        </>
      );
    },
    [assets, previewDimensions, tid],
  );

  return (
    <div css={Css.addIn("&>div", Css.df.overflowYAuto.$).$}>
      <LightGallery
        plugins={plugins}
        licenseKey={LIGHT_GALLERY_KEY}
        selector=".asset-preview"
        addClass="homeowner-asset-gallery"
        onInit={onInit}
        counter={false}
        download={false}
        mobileSettings={{
          controls: false,
          showCloseIcon: true,
          download: false,
        }}
        loop={false}
        slideEndAnimation={false}
        hideScrollbar={true}
        hideControlOnEnd
        {...lightGalleryOthers}
      >
        {children ? children(openGallery) : renderSlides(openGallery)}
        {assets.map((asset) => (
          <div
            id={showAssetId(asset)}
            key={showAssetId(asset)}
            className="asset-preview"
            data-src={asset.downloadUrl}
            data-download-url={asset?.attachmentUrl}
            data-sub-html={caption ? caption(asset) : ""}
            data-iframe={asset?.contentType === "application/pdf" ? "true" : ""}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            hidden
            {...tid[`lightGallery_${asset.id}`]}
          >
            <ImagePreview asset={asset} dimensions={previewDimensions} {...tid.imagePreview} />
          </div>
        ))}
      </LightGallery>
    </div>
  );
}

interface AssetPreviewProps {
  onClick?: Function;
  asset: ImageAsset;
  dimensions?: {
    width: number;
    height: number;
  };
}

export function ImagePreview(props: AssetPreviewProps) {
  const { asset, onClick, dimensions } = props;
  const { previewUrl, downloadUrl, contentType } = asset;
  const src = contentType === "image/gif" ? downloadUrl : previewUrl || downloadUrl;
  const tid = useTestIds(props, "imagePreview");
  return (
    <img
      src={src}
      // FIXME: We should include an alt attribute here, but we don't have the data to do so.
      alt=""
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick && onClick(e);
        }
      }}
      css={{
        ...previewStyles,
        ...Css.objectCover.$,
        ...(dimensions ? Css.wPx(dimensions!.width).mwPx(dimensions!.width).$ : Css.w100.$),
        ...Css.hPx(dimensions?.height ?? 208).mhPx(dimensions?.height ?? 208).$,
      }}
      {...tid}
    />
  );
}

const previewStyles = Css.bn.br4.df.aic.jcc.cursorPointer.$;

// Copied from Blueprint
// https://www.lightgalleryjs.com/docs/license/
// Homebound Light Gallery License Key.
const LIGHT_GALLERY_KEY = "3AA3D7F6-B4F64FA5-886A68F8-B0DF2E97";

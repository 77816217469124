import { useMemo } from "react";
import { Carousel } from "~components";
import { Css } from "~generated/css";
import { SummaryPropertyFragment } from "~generated/graphql";
import { defaultTestId, useContainerBreakpoints, useTestIds } from "~utils";
import { LocationPreviewCard } from "./LocationPreviewCard";

type ExploreSpacesProps = {
  locations: { id: string; name: string; imageUrl: string }[];
  onPreviewClick: (location: string) => void;
} & Pick<SummaryPropertyFragment, "imageGallery">;

/* Chosen as the mobile breakpoint, local to this component  */
const LOCAL_BREAKPOINT = 550;

export function ExploreSpaces(props: ExploreSpacesProps) {
  const { locations, onPreviewClick, imageGallery } = props;
  const tid = useTestIds(props, "exploreSpaces");
  const { ref, breakpoint } = useContainerBreakpoints<HTMLDivElement>({
    mobile: [0, LOCAL_BREAKPOINT],
    desktop: [LOCAL_BREAKPOINT + 1],
  });

  const igWLocations = useMemo(() => {
    return imageGallery.filter((ig) => ig.planLocation !== null);
  }, [imageGallery]);

  return (
    <div ref={ref} css={Css.container().$} {...tid}>
      <div
        css={Css.df.fdc.gap2.maxwPx(1280).w100.pxPx(106).mx("auto").ifContainer({ lt: LOCAL_BREAKPOINT }).pxPx(24).$}
      >
        {breakpoint.desktop && <h2 css={Css.fw400.fsPx(24).lhPx(40).black.$}>Explore Spaces</h2>}
        <Carousel
          title={breakpoint.mobile ? <div css={Css.header24.$}>Explore Spaces</div> : undefined}
          items={locations.map(({ name, imageUrl, id }) => {
            const locationTestId = defaultTestId(name);
            // Always prioritize showing the first real image associated to planLocation, so it matches locationSection
            // Our imageGallery is already sorted when queried
            const previewImageUrl = igWLocations.find((igg) => igg.planLocation?.id === id)?.imageUrl ?? imageUrl;
            return (
              <LocationPreviewCard
                key={`location-preview-${id}`}
                previewImage={previewImageUrl}
                name={name}
                onClick={onPreviewClick}
                {...tid[`${locationTestId}Button`]}
              />
            );
          })}
        />
      </div>
    </div>
  );
}

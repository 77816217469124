import { AnimatePresence, motion, Variants } from "framer-motion";
import { useState } from "react";
import { Button, Icon } from "~components";
import { Css } from "~generated/css";
import { useContainerBreakpoints } from "~utils";

const LINKS = [
  { label: "About us", href: "https://www.homebound.com/about" },
  { label: "Browse Homes", href: "https://www.homebound.com/browse-homes" },
  { label: "Build On Your Lot", href: "https://www.homebound.com/build-on-your-lot" },
  { label: "Sell to us", href: "https://www.homebound.com/property-purchase" },
  { label: "Learn", href: "https://www.homebound.com/learn" },
  { label: "Partnerships", href: "https://www.homebound.com/partnerships" },
];

/** The Homebound.com responsive navigation */
export function Nav() {
  const { ref, breakpoint } = useContainerBreakpoints({
    mobile: [0, 1024],
    desktop: [1025],
  });

  return (
    <nav
      ref={ref}
      css={Css.bgOffWhite.top0.z999.if(breakpoint.desktop).sticky.$}
      // https://www.accede-web.com/en/guidelines/rich-interface-components/hamburger-menu/
      role="navigation"
      aria-label="main-menu"
    >
      {breakpoint.desktop && <DesktopNav />}
      {breakpoint.mobile && <MobileNav />}
    </nav>
  );
}

/** Desktop version of the Homebound.com navigation */
function DesktopNav() {
  return (
    <div css={Css.p3.df.aic.jcsb.$}>
      {/* Logo */}
      <a href="https://www.homebound.com/">
        <img width="158" height="26" src="/logo.svg" alt="Homebound" />
      </a>
      {/* Link Container */}
      <div css={Css.df.$}>
        {/* Text Link Container (gap: 32px) */}
        <div css={Css.df.aic.gap4.mr5.$}>
          {LINKS.map(({ label, href }) => (
            <a key={label} href={href} css={Css.body14.wsnw.$}>
              {label}
            </a>
          ))}
        </div>
        {/* Button Link Container (gap: 16px) */}
        <div css={Css.df.aic.gap2.$}>
          <Button variant="secondary" href="https://app.homebound.com">
            Sign in
          </Button>
          <Button href="https://www.homebound.com/get-started">Get Started</Button>
        </div>
      </div>
    </div>
  );
}

/** Framer Motion Variants for the MobileNav */
const MobileContainerVariant: Variants = {
  closed: {
    height: 0,
    ...Css.p0.$,
    transition: {
      staggerChildren: 0,
      delayChildren: 0,
    },
  },
  open: {
    height: "auto",
    ...Css.p3.z1.$,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.1,
      bounce: 0,
    },
  },
};

const MobileLinkVariant: Variants = {
  closed: {
    opacity: 0,
    y: -10,
    transition: {
      duration: 0.1,
    },
  },
  open: {
    opacity: 1,
    y: 0,
  },
};

/** The mobile version of the Homebound.com navigation */
function MobileNav() {
  // The open state of the mobile menu
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div css={Css.p3.df.aic.jcsb.relative.$}>
      {/* Logo */}
      <a href="https://www.homebound.com/">
        <img width="158" height="26" src="/logo.svg" alt="Homebound" />
      </a>
      {/* Menu Icon */}
      <button
        aria-expanded={isOpen ? "true" : "false"}
        aria-controls="main-menu"
        aria-label="Open main menu"
        data-testid="mobile-menu-button"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <Icon name="menu" />
      </button>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            role="menu"
            variants={MobileContainerVariant}
            initial="closed"
            animate="open"
            exit="closed"
            css={Css.absolute.top("100%").left0.right0.df.fdc.bgOffWhite.overflowHidden.$}
          >
            {/* Text Link Container (gap: 32px) */}
            <div css={Css.df.fdc.gap4.mb5.$}>
              {LINKS.map(({ label, href }) => (
                <motion.a key={label} href={href} css={Css.body14.wsnw.$} variants={MobileLinkVariant}>
                  {label}
                </motion.a>
              ))}
            </div>
            {/* Button Link Container (gap: 16px) */}
            <div css={Css.df.fdc.gap2.$}>
              {/* TODO: Maybe the Button component could be a motion component to skip this wrapping? */}
              <motion.div variants={MobileLinkVariant}>
                <Button variant="secondary" href="https://app.homebound.com">
                  Sign In
                </Button>
              </motion.div>
              <motion.div variants={MobileLinkVariant}>
                <Button href="https://www.homebound.com/get-started">Get Started</Button>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

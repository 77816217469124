import { useMemo, useState } from "react";
import {
  ImageDisplay,
  ImageDisplayImageProps,
  StepWrapper,
  SummaryCardList,
  ToggleComponent,
} from "src/pages/configurator/components";
import { useConfiguratorContext } from "src/pages/configurator/Configurator.context";
import { Css, Palette } from "~generated/css";
import { CustomerConfigurationStatus, OptionImageType } from "~generated/graphql";
import { useTestIds } from "~utils";

export function SummaryStep() {
  const { selectedOptions, basePlanPriceInCents, goToStep, reservedAt, status, plan, isReversed } =
    useConfiguratorContext();
  const tid = useTestIds({}, `summary-step`);

  // used to determine whether image or floor plan is shown (toggle)
  const [viewFloorplan, setViewFloorplan] = useState(false);

  const images: ImageDisplayImageProps[] = useMemo(() => {
    const exteriorScheme = selectedOptions
      .find((o) => o.option.planOptionType.name === "Exterior Scheme")
      ?.option.images.find((i) => i.type.code === OptionImageType.Display);
    const exteriorOption = selectedOptions.find((o) => o.option.planOptionType.name === "Exterior")?.option;
    const interiorImages = selectedOptions
      .filter((o) => o.option.planOptionType.name === "Spec Level")
      .flatMap((o) => o.option.images)
      .filter((i) => i.type.code === OptionImageType.Display);
    if (exteriorScheme && exteriorOption) {
      // This is to set a presentable name for the exterior image
      const previewCardData = {
        id: exteriorOption.id,
        name: exteriorOption.name,
        imageUrl: exteriorScheme.imageUrl,
        isReversed: isReversed,
      } as ImageDisplayImageProps;
      return [previewCardData, ...interiorImages];
    }
    return interiorImages;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const planFloorPlans: ImageDisplayImageProps[] = useMemo(() => plan?.floorPlans?.filter((fp) => !!fp) || [], [plan]);
  // passed into floor plan component to highlight selected options
  const selectedOptionCodes = useMemo(
    () => selectedOptions.map((option) => option.option.optionCode),
    [selectedOptions],
  );

  let { title, description, tag } = {
    title: "Home Summary",
    description: `Reserve your home to lock in pricing for ${plan?.expirationDays} days and view your build at any time.`,
    tag: {
      children: "Not Reserved",
      backgroundColor: Palette.Yellow200,
    },
  };

  if (status === CustomerConfigurationStatus.Reserved) {
    title = "You're all set!";
    description = `Your pricing is locked in for ${plan?.expirationDays} days. Download your PDF below to attach to a contract.`;
    tag = {
      children: "Reserved",
      backgroundColor: Palette.Green100,
    };
  } else if (status === CustomerConfigurationStatus.Expired) {
    title = "Configuration expired";
    description = `Configuration pricing expires after ${plan?.expirationDays} days. Design another home for new pricing.`;
    tag = {
      children: "Expired",
      backgroundColor: Palette.Red100,
    };
  }

  return (
    <StepWrapper
      title={title}
      description={description}
      tag={tag}
      left={
        <>
          {/* Toggle Container */}
          <div css={Css.z1.absolute.topPx(24).left("50%").add("transform", "translate(-50%)").$}>
            <ToggleComponent
              offLabel="Images"
              onLabel="Floor Plans"
              value={viewFloorplan}
              onClick={() => setViewFloorplan(!viewFloorplan)}
              {...tid.toggle}
            />
          </div>
          <div css={Css.df.fdc.h100.w100.if(viewFloorplan).bgOffWhite.$}>
            <ImageDisplay
              images={viewFloorplan ? planFloorPlans : images}
              showDisclaimer={!viewFloorplan}
              displayingDynamicSvgs={viewFloorplan}
              selectedOptionCodes={selectedOptionCodes}
              isReversed={isReversed}
              {...tid.imageDisplay}
            />
          </div>
        </>
      }
      right={
        <SummaryCardList
          plan={plan}
          selectedOptions={selectedOptions}
          goToStep={goToStep}
          reservedAt={reservedAt}
          basePlanPriceInCents={basePlanPriceInCents}
          isReversed={isReversed}
        />
      }
    />
  );
}

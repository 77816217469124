import { AnimatePresence, motion, Variants } from "framer-motion";
import { useMemo } from "react";
import { TextField, TextFieldProps } from "~components";
import { Css } from "~generated/css";
import { HasId, useTestIds } from "~utils";

type Props<Option extends string | HasId> = Omit<TextFieldProps, "type"> & {
  /** Array of strings representing the Option */
  options?: Option[] | null;
  /** Called when selecting an Option */
  onSelect?: (item: Option) => void;
  /** (Optional) indicate if the loading renderer should be rendered. */
  isLoading?: boolean;
};

type Renderer<Option extends string | HasId> = {
  /** (Optional) Renderers for various states the search field can be in */
  children?: {
    // (Optional) Option renderer when there are options
    option?: (option: Option) => React.ReactNode;
    // (Optional) Option renderer when `isLoading` is true
    loading?: () => React.ReactNode;
    // (Optional) No option renderer when passing `[]` to `options`
    noOption?: () => React.ReactNode;
  };
};

type SearchFieldProps<Option extends string | HasId> = Option extends string
  ? Props<Option> & Renderer<Option>
  : // When using `object` type options, a `children` renderer is required
    Props<Option> & Required<Renderer<Option>>;

/**
 * TextField with a ListBox.
 *
 * Can support string or object's with an `id` property as options.
 *
 * TODO: IMPROVEMENTS
 * - Add loading indicator when options are loading
 */
export function SearchField<Option extends string | HasId>(props: SearchFieldProps<Option>) {
  const { options, onSelect, children, isLoading, ...textFieldProps } = props;
  const tid = useTestIds(props, "searchField");

  const renderers = useMemo(
    () => ({
      // Used to render an option inside a listbox
      option: children?.option ?? StringOptionRenderer,
      loading: children?.loading ?? LoadingRenderer,
      // Used to render a component when `options` is `[]` (empty)
      noOption: children?.noOption ?? NoOptionRenderer,
    }),
    [children],
  );

  return (
    <div css={Css.relative.w100.$} {...tid}>
      {/* Input */}
      <TextField {...textFieldProps} type="search" />

      <AnimatePresence>
        {/* ListBox */}
        <motion.div
          // TODO: Update `listbox` to `ListBox` when it becomes a component
          css={Css.absolute.mt1.top("100%").left0.right0.br4.bgGray50.br4.bshModal.overflowAuto.maxhPx(300).$}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          {...tid.listBox}
        >
          {/* Renderers */}
          {isLoading && renderers.loading({ ...tid.loadingRenderer })}
          {!isLoading && options && options.length === 0 && renderers.noOption()}
          {!isLoading &&
            options &&
            options.length > 0 &&
            options.map((option) => {
              // An option can either be a string or an object with a `id`
              const key = typeof option === "string" ? option : option.id;
              return (
                // Option
                <motion.button
                  key={key}
                  id={`listbox-option-${key}`}
                  css={Css.cursorPointer.w100.db.tl.$}
                  // @ts-expect-error since `onSelect` cannot accept string | HasID
                  onClick={() => onSelect?.(option)}
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                >
                  {/* Option renderer */}
                  {/* @ts-expect-error since `children` will be defined for `object` option types */}
                  {renderers.option(option)}
                </motion.button>
              );
            })}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

/** Default string Option renderer */
function StringOptionRenderer<Option extends string>(option: Option) {
  return (
    <motion.div
      css={{
        ...Css.cursorPointer.px2.py1.body14.fw500.$,
        ...{
          borderLeftWidth: 4,
          borderLeftStyle: "solid",
          borderLeftColor: "transparent",
        },
        ":hover": Css.bgGray200.bGray900.$,
      }}
    >
      {option}
    </motion.div>
  );
}

/** Default no option renderer */
function NoOptionRenderer() {
  return <p css={Css.px2.py1.body14.fw500.$}>No options found.</p>;
}

const LoaderWrapperVariants: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { staggerChildren: 0.2 } },
};
const LoaderDotVariants: Variants = {
  animate: {
    y: [4, -4],
    transition: {
      repeat: Infinity,
      duration: 0.5,
      repeatType: "reverse",
    },
  },
};

/**
 * Default loading renderer
 *
 * TODO: This could be a generic component which could be used on all pages.
 */
function LoadingRenderer(props: any) {
  const tid = useTestIds(props, "loadingRenderer");

  return (
    <motion.div
      css={Css.df.aic.jcc.gapPx(4).py2.$}
      initial="initial"
      animate="animate"
      variants={LoaderWrapperVariants}
      {...tid}
    >
      <motion.div css={Css.wPx(8).hPx(8).br100.bgGray900.$} variants={LoaderDotVariants} />
      <motion.div css={Css.wPx(8).hPx(8).br100.bgGray900.$} variants={LoaderDotVariants} />
      <motion.div css={Css.wPx(8).hPx(8).br100.bgGray900.$} variants={LoaderDotVariants} />
    </motion.div>
  );
}

import { FloorPlan } from "~components";
import { Css } from "~generated/css";
import { isDefined, useTestIds } from "~utils";

const IMG_PLACEHOLDER = "/Placeholder-Image-tile.png";

type PreviewCardProps = {
  image: {
    id: string;
    name: string;
    imageUrl?: string | null | undefined;
  };
  onClick: () => void;
  isActive?: boolean;
  lightText?: boolean;
  small?: boolean;
  displayingStaticSvgs?: boolean;
  displayingDynamicSvgs?: boolean;
  selectedOptionCodes?: string[];
  hideImage?: boolean;
  isReversed?: boolean;
};

// Configurator Preview Card
// Almost identical to LocationPreviewCard.tsx. Difference being size and active states
export function PreviewCard(props: PreviewCardProps) {
  const {
    image,
    onClick,
    isActive = false,
    lightText = false,
    small = false,
    displayingStaticSvgs = false,
    displayingDynamicSvgs = false,
    selectedOptionCodes = [],
    hideImage = false,
    isReversed = false,
  } = props;
  const tid = useTestIds(props, "preview-card");

  return (
    <button
      onClick={() => onClick()}
      css={{
        ...Css.relative.z0.df.fdc.mb1.mxPx(4).wPx(163).hPx(153).cursorPointer.bshBasic.br4.add("transition", "0.2s").$,
        ...Css.if(small).wPx(137).hPx(128).$,
        ...Css.if(hideImage).hPx(47).$,
      }}
      {...tid}
    >
      {/* Highlight Bar */}
      {isActive && (
        <div css={Css.absolute.z1.left0.right0.top0.hPx(4).bgYellow400.add("borderRadius", "4px 4px 0 0").$} />
      )}
      {/* Image Container */}
      {!hideImage && (
        <div css={Css.relative.df.fg1.$}>
          {displayingDynamicSvgs ? (
            <div css={Css.w100.$}>
              <FloorPlan
                id={image.id.replaceAll(":", "")}
                key={image.id}
                src={image.imageUrl!}
                xss={Css.w100.h100.ar("16 / 9").$}
                highlightHexColor="#C59877"
                maskHexColor="#F6F6F0"
                selectedOptionCodes={selectedOptionCodes}
                isReversed={isReversed}
              />
            </div>
          ) : (
            <img
              css={
                Css.w100
                  .ar("16 / 9")
                  .add("borderRadius", "4px 4px 0 0")
                  .if(!displayingStaticSvgs)
                  .objectCover.if(isReversed)
                  .add("transform", "scaleX(-1)").$
              }
              src={isDefined(image.imageUrl) ? image.imageUrl : IMG_PLACEHOLDER}
              alt={`${image.name} Preview`}
            ></img>
          )}
        </div>
      )}
      {/* Text Container */}
      <div
        css={
          Css.bgWhite.w100.df.py2.pl2.pr1.aic.ttc
            .hPx(47)
            .if(hideImage)
            .br4.else.add("borderBottomLeftRadius", "inherit")
            .add("borderBottomRightRadius", "inherit").$
        }
      >
        <span css={Css.add("transition", "0.2s").lhPx(22).gray700.fw300.fsPx(14).if(isActive).fw500.gray800.$}>
          {image.name}
        </span>
      </div>
    </button>
  );
}

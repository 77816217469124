import { ConfiguratorConfigurationOptionFragment, Plan } from "~generated/graphql";

/**
 * Given a Plan fragment, return a string representing the bedroom range.
 *
 * @returns
 * - "X Beds"
 * - "X - Y Beds"
 */
export function getPlanBedroomRangeString(plan: Pick<Plan, "minBedrooms" | "maxBedrooms">): string {
  const { minBedrooms, maxBedrooms } = plan;
  if (minBedrooms === maxBedrooms) return `${minBedrooms} Beds`;
  return `${plan.minBedrooms} - ${plan.maxBedrooms} Beds`;
}

/**
 * Given a Plan fragment, return a string representing the bathroom range.
 * @returns
 * - "A.B Baths" if the full and half baths are the same
 * - "A.B - A.C Baths" if full baths are the same and the half baths are different
 * - "A.B - C.B Baths" if full baths are different and the half baths are the same
 * - "A.B - C.D Baths" if full and half baths are different
 */
export function getPlanBathroomRangeString(
  plan: Pick<Plan, "minFullBathrooms" | "maxFullBathrooms" | "minHalfBathrooms" | "maxHalfBathrooms">,
): string {
  const { minFullBathrooms, maxFullBathrooms, minHalfBathrooms, maxHalfBathrooms } = plan;

  let rangeText = "";

  // If the full and half bathrooms are the same, no need to return a range.
  if (minFullBathrooms === maxFullBathrooms && minHalfBathrooms === maxHalfBathrooms) {
    rangeText += minFullBathrooms + minHalfBathrooms / 2;
  }
  // Otherwise, return a range.
  else {
    rangeText += `${minFullBathrooms + minHalfBathrooms / 2} - ${maxFullBathrooms + maxHalfBathrooms / 2}`;
  }

  return rangeText + " Baths";
}

/**
 * Given a Plan Fragment, return a string representing the square footage range.
 * @returns
 * - "A Sq Ft" if the min and max sqft are the same
 * - "A+ Sq Ft" if the min and max sqft are different
 */
export function getPlanSqftRangeString(plan: Pick<Plan, "minSqft" | "maxSqft">): string {
  const { minSqft, maxSqft } = plan;
  if (minSqft === maxSqft) return `${minSqft} Sq Ft`;
  return `${minSqft}+ Sq Ft`;
}

/**
 * Given the selected options, returns the program data totals.
 * @returns an object with the following properties:
 * - sqft: string
 * - bedrooms: string
 * - bathrooms: string
 */
export function getProgramDataTotalStrings(
  selectedOptions: {
    option: Pick<
      ConfiguratorConfigurationOptionFragment["option"],
      "sqft" | "bedrooms" | "fullBathrooms" | "halfBathrooms"
    >;
  }[],
) {
  const totals = selectedOptions.reduce(
    (acc, so) => ({
      sqft: so.option.sqft + acc.sqft,
      bedrooms: so.option.bedrooms + acc.bedrooms,
      fullBathrooms: so.option.fullBathrooms + acc.fullBathrooms,
      halfBathrooms: so.option.halfBathrooms + acc.halfBathrooms,
    }),
    { sqft: 0, bedrooms: 0, fullBathrooms: 0, halfBathrooms: 0 },
  );

  return {
    bedrooms: totals.bedrooms === 1 ? `${totals.bedrooms} Bed` : `${totals.bedrooms} Beds`,
    bathrooms: `${totals.fullBathrooms + totals.halfBathrooms / 2} Baths`,
    sqft: `${totals.sqft}+ Sq Ft`,
  };
}

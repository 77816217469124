import { Dispatch, SetStateAction, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useOutletContext } from "react-router-dom";
import { StepWrapper } from "src/pages/configurator/components";
import { Map } from "~components";
import { Css, Palette } from "~generated/css";
import { LotSelectionTitleDesc } from "./components";

// AKA "Do you need a lot step"
export function PickLotTypeStep() {
  const {
    isBoylFlowState: [isBoylFlow, setIsBoylFlow],
  } = useOutletContext<{ isBoylFlowState: [boolean | null, Dispatch<SetStateAction<boolean | null>>] }>();

  // TODO: Combobox component use case...or is it a toggle? Seems too big for that :shrug:
  // This needs to be pixel perfect to the design
  const ComboSelectBox = (
    <>
      <div css={Css.df.jcc.aic.w100.hPx(56).bGray700.ba.br4.overflowHidden.$}>
        <div
          onClick={() => setIsBoylFlow(false)}
          css={{
            ...Css.add("transition", "0.1s").df.w50.h100.aic.jcc.cursorPointer.onHover.bgBlue50.$,
            ...Css.borderRadius("inherit 0 0 inherit")
              .add("borderRight", `1px solid ${Palette.Gray300}`)
              // Note: This is an intentional hard type check
              .if(isBoylFlow === false)
              .bgBlue50.boxShadow(`inset 0 0 0px 2px ${Palette.Gray800}`)
              .add("borderRight", `1px solid ${Palette.Gray800}`).$,
          }}
        >
          <span css={Css.body16.lhPx(20).gray700.$}>Yes</span>
        </div>
        <div
          onClick={() => setIsBoylFlow(true)}
          css={{
            ...Css.add("transition", "0.1s").df.w50.h100.aic.jcc.cursorPointer.onHover.bgBlue50.$,
            // Note: This is an intentional hard type check
            ...Css.borderRadius("0 inherit inherit 0")
              .add("borderLeft", `1px solid ${Palette.Gray300}`)
              .if(isBoylFlow === true)
              .bgBlue50.boxShadow(`inset 0 0 0px 2px ${Palette.Gray800}`)
              .add("borderLeft", `1px solid ${Palette.Gray800}`).$,
          }}
        >
          <span css={Css.body16.lhPx(20).gray700.$}>No</span>
        </div>
      </div>
    </>
  );

  // Using state vars for selections was causing map to jump around on rerender because we gave it a center prop
  const memoizedMap = useMemo(() => {
    return (
      <div css={Css.w100.h100.$}>
        <Map center={{ lat: 26.45182917025719, lng: -81.94824993610384 }} />
      </div>
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>Configurator | Lots</title>
      </Helmet>
      <StepWrapper
        hideBorder
        left={memoizedMap}
        title=""
        description={<LotSelectionTitleDesc largeText="Do you need a lot?" />}
        right={ComboSelectBox}
      />
    </>
  );
}

import { Fragment, ReactNode } from "react";

import { homeboundStandard, standardSpec } from "src/pages/configurator/SpecDetails";
import { Css } from "~generated/css";
import { SummaryPrintConfigurationQuery } from "~generated/graphql";
import { PDFPage } from "./PDFPage";
import { PrintListItem } from "./PrintListItem";

export function StandardsReport({
  shouldDisplayPricing,
}: {
  data: SummaryPrintConfigurationQuery;
  shouldDisplayPricing: boolean;
}) {
  const details = homeboundStandard;
  return (
    <PDFPage>
      <div css={Css.relative.h100.w100.bgWhite.$}>
        <div css={Css.df.ma.fdc.gap2.p1.pb4.asc.maxwPx(1440).$}>
          <PrintListItem
            title="Homebound Standard"
            priceInCents={shouldDisplayPricing ? "Included" : ""}
            items={[{ title: `Included in every home we build` }]}
            headerStyling
          />

          <div css={Css.dg.gtc("1fr 3fr").$}>
            {details.map((detail) => {
              return (
                <Fragment key={detail.title}>
                  <ColumLeft key={detail.title} title={detail.title} />
                  <ColumnRight>
                    <ListItems features={detail.details} />
                  </ColumnRight>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </PDFPage>
  );
}

function ColumLeft(props: { title: string }) {
  const { title } = props;
  return <div css={Css.py1.body10.fw500.gray800.lh("20px").$}>{title}</div>;
}

function ColumnRight({ children }: { children: ReactNode }) {
  return <div css={Css.py1.body10.lh("16px").gray600.$}>{children}</div>;
}

function ListItems(props: { features: (string | standardSpec)[] }) {
  const { features } = props;

  if (features.length === 0) return null;

  const formattedList = features.map((feature, i) => {
    if (i === 0 && typeof feature === "string") {
      return <li key={feature}>{feature}</li>;
    }
    if (typeof feature === "string") {
      return (
        <li key={feature} css={Css.mt1.$}>
          {feature}
        </li>
      );
    }

    return (
      <Fragment key={feature.title}>
        <li key={feature.title} css={Css.mt1.lhPx(16).$}>
          <div css={Css.pb1.$}>{feature.title}</div>
          {ListItems({ features: feature.details || [] })}
        </li>
      </Fragment>
    );
  });

  return <ul css={Css.m0.pl2.$}>{formattedList}</ul>;
}

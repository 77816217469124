import { Css } from "~generated/css";
import useCanvas, { DrawFunction, Options } from "./useCanvas";

export default ({
  draw,
  options = {},
  width = 8096,
  height = 4096,
  ...rest
}: {
  draw: DrawFunction;
  options: Options;
  width?: number;
  height?: number;
}) => {
  const canvasRef = useCanvas(draw, options);

  return <canvas css={Css.w100.h100.$} width={width} height={height} ref={canvasRef} {...rest} />;
};

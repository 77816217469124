import { CSSProperties, HTMLInputTypeAttribute, InputHTMLAttributes, ReactNode, SyntheticEvent } from "react";
import { Css } from "~generated/css";
import { useTestIds } from "~utils";

// Input types that are supported via a text input.
type TextInputType = Extract<HTMLInputTypeAttribute, "email" | "password" | "search" | "tel" | "text" | "number">;

// A TextField can either be disabled, readOnly, or neither.
type DisabledTextField = {
  disabled?: boolean;
};
type ReadOnlyTextField = {
  readOnly?: boolean;
};
export type TextFieldProps = Pick<
  InputHTMLAttributes<HTMLInputElement>,
  "autoComplete" | "placeholder" | "required"
> & {
  label?: ReactNode;
  onFocus?: (e: SyntheticEvent) => void;
  onBlur?: (e: SyntheticEvent) => void;
  onChange?: (e: SyntheticEvent, value: string) => void;
  onKeyDown?: (e: SyntheticEvent, key: string) => void;
  /** @default "text" */
  type?: TextInputType;
  value?: string | number;
  inputId?: string;
  cssOverrides?: CSSProperties;
} & (DisabledTextField | ReadOnlyTextField);

export function TextField(props: TextFieldProps) {
  const {
    autoComplete,
    label,
    onBlur,
    onChange,
    onKeyDown,
    placeholder,
    required,
    type = "text",
    value = "",
    inputId = "text-input",
    cssOverrides,
    ...inputOthers
  } = props;
  const disabled = "disabled" in props ? props.disabled ?? false : false;
  const readOnly = "readOnly" in props ? props.readOnly ?? false : false;
  const tid = useTestIds(props, "textField");

  const customLabel =
    label && typeof label === "string" ? (
      <span {...tid.label} css={Css.body10.fw400.mbPx(4).db.if(disabled).gray700.$}>
        {label}
      </span>
    ) : (
      label
    );

  return (
    <label>
      {/* Label */}
      {label && customLabel}

      {/* Input */}
      <input
        id={inputId}
        css={{
          ...Css.bgGray50.br4.px2.py1.bshBasic.body14.w100.outline0.ifMobileOrTablet
            .fs("16px")
            .else.if(disabled)
            .gray700.cursorNotAllowed.if(readOnly).bgTransparent.bshNone.pl0.$,
          ...(cssOverrides ? cssOverrides : {}),
        }}
        disabled={disabled}
        value={value}
        onBlur={(e) => onBlur?.(e)}
        onChange={(e) => onChange?.(e, e.target.value)}
        onKeyDown={(e) => onKeyDown?.(e, e.key)}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        type={type}
        autoComplete={autoComplete}
        {...inputOthers}
        {...tid.input}
      />
    </label>
  );
}

// TODO: This will become the new standard for text fields soon-ish
//   until we have all the states and variants from design we'll export overrides
export const NewFieldLabel = (title: string, noMb = false) => (
  <div css={Css.body14.fw500.lhPx(22).gray900.ttn.if(!noMb).mb1.$}>{title}</div>
);
export const NewFieldStyleOverrides = Css.bshNone.bGray600.ba.br4.hPx(52).addIn(":disabled", Css.bgGray200.$).$;

import { useRef, useState } from "react";
import Canvas from "./common/Canvas";
import { SphereModel } from "./common/geometry/proceduralMeshes";
import { CreateAndLinkProgramWithShaders, LoadGeometry, LoadTexture } from "./common/utils";
import { IdentityMatrix, mat4Mult, ProjectionMatrix, RotationMatrix, ViewMatrix } from "./common/vectorMath";
import { fragment as panoFragmentShader, vertex as panoVertexShader } from "./shaders/panoShaderSource";

const MOUSE_ROT_SPEED = 0.33;
const X_ROT_DEGREE_CLAMP = [-75, 75];

const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);

export default ({ image }: { image: string }) => {
  const [indexCount, setIndexCount] = useState(0);
  const [mouseDown, _setMouseDown] = useState(false);
  const [mouseDownPos, _setMouseDownPos] = useState<number[]>([]);
  const [rotXY, _setRotXY] = useState<number[]>([0, 0]);
  const [sphereTransformMatrix, _setSphereTransformMatrix] = useState<number[]>(IdentityMatrix());
  const [sphereTransformDirty, setSphereTransformDirty] = useState(true);
  const [glProgram, setGLProgram] = useState<WebGLProgram | null>(null);
  const [texSize, setTexSize] = useState([0, 0]);

  const mouseDownRef = useRef(mouseDown);
  const setMouseDown = (down: boolean) => {
    mouseDownRef.current = down;
    _setMouseDown(down);
  };

  const mouseDownPosRef = useRef(mouseDownPos);
  const setMouseDownPos = (pos: number[]) => {
    mouseDownPosRef.current = pos;
    _setMouseDownPos(pos);
  };

  const rotXYRef = useRef(rotXY);
  const setRotXY = (rot: number[]) => {
    rotXYRef.current = rot;
    _setRotXY(rot);
  };

  const sphereTransformMatrixRef = useRef(sphereTransformMatrix);
  const setSphereTransformMatrix = (mat: number[]) => {
    sphereTransformMatrixRef.current = mat;
    _setSphereTransformMatrix(mat);
  };

  const mouseDownHandler = (event: Event) => {
    const mouseEvent = event as MouseEvent;
    event.preventDefault();
    setMouseDown(true);
    setMouseDownPos([mouseEvent.clientX, mouseEvent.clientY]);
  };

  const mouseUpHandler = (event: Event) => {
    const mouseEvent = event as MouseEvent;
    setRotXY([
      rotXYRef.current[0] + (mouseEvent.clientX - mouseDownPosRef.current[0]),
      clamp(
        rotXYRef.current[1] + (mouseEvent.clientY - mouseDownPosRef.current[1]),
        X_ROT_DEGREE_CLAMP[0] / MOUSE_ROT_SPEED,
        X_ROT_DEGREE_CLAMP[1] / MOUSE_ROT_SPEED,
      ),
    ]);
    setMouseDown(false);
  };

  const mouseOutHandler = (event: Event) => {
    const mouseEvent = event as MouseEvent;
    if (mouseDownRef.current) {
      setRotXY([
        rotXYRef.current[0] + (mouseEvent.clientX - mouseDownPosRef.current[0]),
        clamp(
          rotXYRef.current[1] + (mouseEvent.clientY - mouseDownPosRef.current[1]),
          X_ROT_DEGREE_CLAMP[0] / MOUSE_ROT_SPEED,
          X_ROT_DEGREE_CLAMP[1] / MOUSE_ROT_SPEED,
        ),
      ]);
      setMouseDown(false);
    }
  };

  const mouseOverHandler = (event: Event) => {
    const mouseEvent = event as MouseEvent;
    if (mouseEvent.buttons === 1 && mouseEvent.button === 0) {
      setMouseDown(true);
      setMouseDownPos([mouseEvent.clientX, mouseEvent.clientY]);
    }
  };

  const mouseMoveHandler = (event: Event) => {
    const mouseEvent = event as MouseEvent;
    if (mouseDownRef.current) {
      // set sphereTransformMatrix rotation by mouse delta
      const dMouse = [mouseEvent.clientX - mouseDownPosRef.current[0], mouseEvent.clientY - mouseDownPosRef.current[1]];

      // don't allow rotation past -75 in x
      setSphereTransformMatrix(
        mat4Mult(
          RotationMatrix((dMouse[0] + rotXYRef.current[0]) * MOUSE_ROT_SPEED, [0, 1, 0]),
          RotationMatrix(
            clamp((dMouse[1] + rotXYRef.current[1]) * MOUSE_ROT_SPEED, X_ROT_DEGREE_CLAMP[0], X_ROT_DEGREE_CLAMP[1]),
            [1, 0, 0],
          ),
        ),
      );
      setSphereTransformDirty(true);
    }
  };

  const draw = (gl: RenderingContext) => {
    if (!gl) {
      window.alert("WebGL not supported");
      return null;
    }

    const webgl = gl as WebGLRenderingContext;

    // clear to transparent black so we can blend with background elements
    webgl.clearColor(0, 0, 0, 0);
    webgl.clear(webgl.COLOR_BUFFER_BIT);

    if (indexCount <= 0) {
      return;
    }

    if (glProgram && sphereTransformDirty) {
      // only set MVP if it has changed since last draw
      const mvpUniform = webgl.getUniformLocation(glProgram, "uMVP");
      webgl.uniformMatrix4fv(
        mvpUniform,
        false,
        new Float32Array(
          mat4Mult(
            sphereTransformMatrix,
            mat4Mult(
              ViewMatrix([0, 0, -1, 0], [0, 0, 1, 0], [0, 1, 0, 0]),
              ProjectionMatrix(70, webgl.canvas.width / webgl.canvas.height, 0.00000001, 1000),
            ),
          ),
        ),
      );
      setSphereTransformDirty(false);
    }

    // draw a sphere with the given image as its texture with a spherical projection
    webgl.drawElements(webgl.TRIANGLE_STRIP, indexCount, webgl.UNSIGNED_SHORT, 0);
  };

  const init = (gl: RenderingContext) => {
    if (!gl) {
      window.alert("WebGL not supported");
      return null;
    }

    const webgl = gl as WebGLRenderingContext;
    // load program
    const program = CreateAndLinkProgramWithShaders(webgl, panoVertexShader, panoFragmentShader);

    webgl.useProgram(program);
    setGLProgram(program);

    // set uniforms, attributes, etc.
    const mvpUniform = webgl.getUniformLocation(program, "uMVP");
    webgl.uniformMatrix4fv(
      mvpUniform || [],
      false,
      new Float32Array(
        mat4Mult(
          sphereTransformMatrix,
          mat4Mult(
            ViewMatrix([0, 0, -1, 0], [0, 0, 1, 0], [0, 1, 0, 0]),
            ProjectionMatrix(70, webgl.canvas.width / webgl.canvas.height, 0.00000001, 1000),
          ),
        ),
      ),
    );

    // load model
    let { vertData, indices } = SphereModel(32, 32, 10);
    // apparently we need a vert buffer big enough to hold
    // indices * 3 verts
    // even though we use less than this by reusing verts
    // this may only happen on mac os?
    vertData = [...vertData, ...new Array<number>(indices.length * 3 * 4 - vertData.length).fill(0)];

    LoadGeometry(webgl, program, vertData, indices, 4, 2);

    setIndexCount(indices.length);

    // load texture
    LoadTexture(webgl, program, image, 0);

    webgl.canvas.addEventListener("mousedown", mouseDownHandler);
    webgl.canvas.addEventListener("mouseup", mouseUpHandler);
    webgl.canvas.addEventListener("mouseout", mouseOutHandler);
    webgl.canvas.addEventListener("mouseover", mouseOverHandler);
    webgl.canvas.addEventListener("mousemove", mouseMoveHandler);
  };

  // TODO:
  // accept component(s) in props that can render over the 360 pano
  // converting image space positions to polar coordinate positions
  // so they can be placed over the 360 correctly and can move when the sphere is rotated
  return <Canvas draw={draw} options={{ contextType: "webgl", init }} />;
};

import { BasicOverlay, Icon, Map } from "~components";
import { Css } from "~generated/css";
import { PropertyCoordinatesFragment } from "~generated/graphql";

interface MapLightBoxProps {
  locationMetaData: PropertyCoordinatesFragment;
  expandMap: boolean;
  triggerClose: () => void;
}

export function MapLightBox({ locationMetaData, expandMap, triggerClose }: MapLightBoxProps) {
  const { id, latitude, longitude, postalCode, streetAddress, city, state } = locationMetaData;

  return (
    <div css={Css.df.add("transition", "1s").h100.w100.$}>
      {/* Smaller map that takes up size of parent container and shifts center to the right */}
      <div css={Css.df.hPx(150).w100.$}>
        <Map
          // Specifying a center since we want to market to appear on th left
          // side of the map. The default center is the center of the map.
          center={{ lat: Number(latitude), lng: Number(longitude) + 0.001 }}
          markers={[
            {
              id,
              center: { lat: Number(latitude), lng: Number(longitude) },
              isActive: true,
            },
          ]}
          features={{
            scrollWheelZoom: false,
            doubleClickZoom: false,
            touchZoom: false,
            zoomControl: false,
            dragging: false,
            tap: false,
          }}
        />
      </div>
      {expandMap && (
        // TODO: Modal
        <BasicOverlay onClick={triggerClose}>
          <div css={Css.df.fdc.jcc.px6.pb5.aic.vh100.w("100vw").$} onClick={triggerClose}>
            <div css={Css.df.asfe.p2.gap2.$}>
              {triggerClose && (
                <button onClick={triggerClose}>
                  <Icon name="x-white" />
                </button>
              )}
            </div>
            <div css={Css.df.fdc.aic.jcc.h100.w100.$} onClick={(e) => e.stopPropagation()}>
              <Map
                markers={[
                  {
                    id,
                    isActive: true,
                    center: { lat: Number(locationMetaData.latitude), lng: Number(locationMetaData.longitude) },
                  },
                ]}
              />
            </div>
            <div css={Css.white.asfs.py2.$}>
              {streetAddress}
              <br />
              {city}, {state} {postalCode}
            </div>
          </div>
        </BasicOverlay>
      )}
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

const DEFAULT_CONTEXT_TYPE = "webgl"; // '2d';

export type DrawFunction = (ctx: RenderingContext, frameCount: number) => void;

export type Options = {
  contextType?: string;
  preDraw?: () => void;
  postDraw?: () => void;
  init?: (ctx: RenderingContext) => void;
  exit?: (canvas: HTMLCanvasElement | null) => void;
};

const useCanvas = (draw: DrawFunction, options: Options = {}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { contextType, preDraw, postDraw, init, exit } = options;

  useEffect(() => {
    if (canvasRef) {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext(contextType || DEFAULT_CONTEXT_TYPE);

      ctx && init && init(ctx);
    }
  }, [canvasRef]);

  useEffect(() => {
    if (canvasRef) {
      const canvas = canvasRef.current;
      const ctx = canvas?.getContext(contextType || DEFAULT_CONTEXT_TYPE);
      let frameCount = 0;
      let animationFrameId: number;

      const render = () => {
        frameCount++;
        preDraw && preDraw();
        ctx && draw(ctx, frameCount);
        postDraw && postDraw();
        animationFrameId = window.requestAnimationFrame(render);
      };
      render();
      return () => {
        window.cancelAnimationFrame(animationFrameId);
      };
    }
  }, [draw]);

  useEffect(() => {
    return () => {
      const canvas = canvasRef.current;
      exit && exit(canvas);
    };
  }, []);

  return canvasRef;
};

export default useCanvas;

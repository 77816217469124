import { motion, Variants } from "framer-motion";
import { Icon } from "src/components/atoms/Icon";
import { Css, Palette } from "~generated/css";
import { useTestIds } from "~utils";

type CheckboxProps = {
  selected: boolean;
  onClick?: () => void;
  disabled?: boolean | string;
  variant?: "primary" | "secondary";
};

const checkboxPrimaryVariants: Variants = {
  selected: (disabled?: boolean) => ({
    backgroundColor: disabled ? Palette.Gray400 : Palette.Gray800,
    transition: {
      duration: 0.2,
    },
  }),
  unselected: (disabled?: boolean) => ({
    backgroundColor: disabled ? Palette.Gray200 : Palette.White,
    transition: {
      duration: 0.2,
    },
  }),
};

const checkboxSecondaryVariants: Variants = {
  selected: (disabled?: boolean) => ({
    backgroundColor: disabled ? Palette.Gray300 : Palette.White,
    transition: {
      duration: 0.2,
    },
  }),
  unselected: (disabled?: boolean) => ({
    backgroundColor: disabled ? Palette.Gray400 : Palette.White,
    transition: {
      duration: 0.2,
    },
  }),
};

// TODO: make this more A11Y friendly - use an input and label etc
export function Checkbox(props: CheckboxProps) {
  const { selected, onClick, disabled, variant = "primary" } = props;

  const tid = useTestIds(props, "checkbox");
  const isPrimary = variant === "primary";

  return (
    <motion.button
      {...tid}
      type="button"
      onClick={() => onClick?.()}
      custom={disabled}
      variants={isPrimary ? checkboxPrimaryVariants : checkboxSecondaryVariants}
      initial={false}
      animate={selected ? "selected" : "unselected"}
      disabled={!!disabled}
      css={Css.df.jcc.aic.wPx(20).hPx(20).br4.ba.bGray700.if(!!disabled).bGray400.$}
    >
      <motion.span
        initial={false}
        variants={{
          selected: {
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          },
          unselected: {
            opacity: 0,
            transition: {
              duration: 0.2,
            },
          },
        }}
      >
        <Icon name={isPrimary ? "checkmark-white" : "checkmark-black"} />
      </motion.span>
    </motion.button>
  );
}

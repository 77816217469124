import { Css } from "~generated/css";
import { useTestIds } from "~utils";

type LocationPreviewCardProps = {
  name: string;
  onClick: (space: string) => void;
  previewImage: string;
};

/* Chosen as the mobile breakpoint, local to this component  */
const LOCAL_BREAKPOINT = 550;

export function LocationPreviewCard(props: LocationPreviewCardProps) {
  const { name, onClick, previewImage } = props;
  const label = name.toLowerCase().substring(0, 4) === "the " ? name.slice(4) : name;
  const tid = useTestIds(props, "locationPreviewCard");

  return (
    <button
      onClick={() => onClick(name)}
      css={
        Css.df.fdc.mb1
          .mxPx(4)
          .wPx(200)
          .hPx(176)
          .cursorPointer.onHover.bshHover.ifContainer({ lt: LOCAL_BREAKPOINT })
          .wPx(127)
          .hPx(150)
          .mxPx(0).$
      }
      {...tid}
    >
      <img
        id="imageid"
        css={Css.w100.hPx(120).ar("16 / 9").ifContainer({ lt: LOCAL_BREAKPOINT }).hPx(80).$}
        src={previewImage}
        alt={`${name} Preview`}
      />
      <div css={Css.df.py2.pl2.pr1.aic.ttc.ifContainer({ lt: LOCAL_BREAKPOINT }).ptPx(12).pl0.pb1.underline.$}>
        <span
          css={
            Css.lhPx(24)
              .gray900.fw400.fsPx(16)
              .add("fontStyle", "normal")
              .ifContainer({ lt: LOCAL_BREAKPOINT })
              .fsPx(14).$
          }
        >
          {label}
        </span>
      </div>
    </button>
  );
}

import { Icon } from "~components";
import { Css } from "~generated/css";
import { UwProperty } from "~generated/graphql";

export const FT_MEYER_PROPERTIES: UwProperty[] = [
  {
    dpid: "1",
    latitude: 26.44140156562412,
    longitude: -81.92635370273698,
    fullStreetAddress: "138 Hibiscus Dr",
    cityName: "Fort Myers Beach",
    state: "FL",
    zipCode: "33931",
    lotSize: 6098,
    finishedSqft: 1296,
    // aduEligible: undefined,
    zoning: undefined,
    lotFrontageFeet: undefined,
  },
  {
    dpid: "2",
    latitude: 26.452256433752797,
    longitude: -81.94605536225922,
    fullStreetAddress: "210 Pearl St",
    cityName: "Fort Myers Beach",
    state: "FL",
    zipCode: "33931",
    lotSize: 4051,
    finishedSqft: 643,
    // aduEligible: undefined,
    zoning: undefined,
    lotFrontageFeet: undefined,
  },
  {
    dpid: "3",
    latitude: 26.451037679503028,
    longitude: -81.94471197390094,
    fullStreetAddress: "210 Fairweather Ln",
    cityName: "Fort Myers Beach",
    state: "FL",
    zipCode: "33931",
    lotSize: 4356,
    finishedSqft: 946,
    // aduEligible: undefined,
    zoning: undefined,
    lotFrontageFeet: undefined,
  },
  {
    dpid: "4",
    latitude: 26.456409187211044,
    longitude: -81.9574771315725,
    fullStreetAddress: "401 Harbor Ct",
    cityName: "Fort Myers Beach",
    state: "FL",
    zipCode: "33931",
    lotSize: 4356,
    finishedSqft: 702,
    // aduEligible: undefined,
    zoning: undefined,
    lotFrontageFeet: undefined,
  },
  {
    dpid: "5",
    latitude: 26.446325086580483,
    longitude: -81.9321898450652,
    fullStreetAddress: "3251 Shell Mound Blvd",
    cityName: "Fort Myers Beach",
    state: "FL",
    zipCode: "33931",
    lotSize: 8934,
    finishedSqft: 2327,
    // aduEligible: undefined,
    zoning: undefined,
    lotFrontageFeet: undefined,
  },
];

export const LotSelectionTitleDesc = ({ largeText }: { largeText: string }) => (
  <div css={Css.df.fdc.w100.gap2.aic.$}>
    {/* Icon */}
    <div css={Css.df.aic.jcc.wPx(75).hPx(75).br100.bgTaupe.$}>
      {/* The exported svg is a little wonky and at a weird size of 44x. 44 / 24 ~ 1.84 */}
      <span css={Css.add("transform", "scale(1.84)").$}>
        <Icon name="home" />
      </span>
    </div>
    {/* Title */}
    <div css={Css.ttu.body14.fw400.lhPx(24).add("letterSpacing", "0.56px").$}>lot selection</div>
    {/* Text */}
    <div css={Css.body16.lhPx(24).gray700.tc.$}>
      Our experts will help find a plan that fits your lot or help you find a lot that fits your needs.
    </div>
    <div css={Css.header24.lhPx(40).add("letterSpacing", "0.48px").$}>{largeText}</div>
  </div>
);

import { useMemo, useState } from "react";
import { ImageDisclaimer, ImageDisplayImageProps, SummaryCardList } from "src/pages/configurator/components";
import { Css } from "~generated/css";
import {
  ConfiguratorConfigurationOptionFragment,
  ContractedCustomerConfigurationFragment,
  OptionImageType,
} from "~generated/graphql";
import { TourCard } from "./TourCard";

type YourHomeTabProps = {
  customerConfiguration: ContractedCustomerConfigurationFragment;
};

// This tab uses single custom disclaimer which is placed over the tour,
// exterior and interior images.
export const YOUR_HOME_TAB_IMAGE_DISCLAIMER =
  "Renderings are for illustrative purposes only. Actual home build specifications are subject to what is outlined in your contract and may vary.";

export const TRUNCATED_IMAGE_DISCLAIMER =
  "*Renderings are for illustrative purposes only. Actual home builds vary by contract.";

export function YourHomeTab({ customerConfiguration }: YourHomeTabProps) {
  const {
    options: selectedOptions,
    plan,
    planPriceInCents: basePlanPriceInCents,
    isReversed,
    reservedAt,
  } = customerConfiguration;
  const { tourUrl } = plan;

  const images = useMemo(() => {
    return getDisplayImages(selectedOptions, isReversed);
  }, [selectedOptions, isReversed]);

  const [displayImage, setDisplayImage] = useState(images[0]);

  return (
    <>
      {/* NOTE: only sw-fl has tourUrls atm */}
      {tourUrl && <TourCard tourUrl={tourUrl} />}
      <div css={Css.df.jcc.aic.fdc.gap4.br4.mt6.$}>
        <div css={Css.w100.df.fdc.jcc.aic.gap2.$}>
          <div css={Css.df.w75.aic.jcc.ifContainer({ lt: 684 }).w100.$}>
            <div css={Css.df.fdc.overflowHidden.$}>
              <div css={Css.w100.ar("5 / 3").relative.br4.$}>
                <img css={Css.ar("5 / 3").objectCover.br4.$} src={displayImage.imageUrl} alt="placeholder" />
                <ImageDisclaimer ltHide={1024} title={YOUR_HOME_TAB_IMAGE_DISCLAIMER} />
              </div>
              <span
                css={{
                  ...Css.asc.body16.add("letterSpacing", "0.32px").lhPx(24).fw500.mt3.mb2.$,
                  ...Css.ifContainer({ lt: 684 }).my1.$,
                }}
              >
                Explore Rooms
                <span css={Css.ifContainer({ gt: 1024 }).display("none").$}>*</span>
              </span>
              <div
                css={{
                  ...Css.dif.jcc.aic.$,
                  ...Css.ifContainer({ lt: 684 })
                    .add("borderRadius", "4px")
                    .gap2.dg.gtr("auto auto")
                    .gtc("auto auto auto").$,
                  ...Css.ifContainer({ lt: 500 }).gap1.$,
                }}
              >
                {images.map((image, i) => (
                  <div
                    style={{
                      ...Css.bw1.bGray700.bsSolid.$,
                      ...Css.ifContainer({ gt: 684 }).add(
                        "borderRadius",
                        i === 0 ? "4px 0 0 4px" : i === images.length - 1 ? "0 4px 4px 0" : "none",
                      ).$,
                    }}
                    css={{
                      ...Css.pPx(12).bshButton.cursorPointer.$,
                      ...Css.ifContainer({ lt: 684 }).tc.borderRadius("inherit").$,
                    }}
                    key={image.id}
                    onClick={() => {
                      setDisplayImage(image);
                    }}
                  >
                    <span css={Css.body14.lhPx(22).gray700.if(displayImage.id === image.id).gray800.$}>
                      {image.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          css={{
            ...Css.fg1.relative.z1.df.fdc.w100.mb2.$,
            ...Css.bGray700.ba.br4.addIn("> div:not(:last-child)", Css.br0.bb.bGray700.$).$,
            ...Css.ifContainer({ gt: 950 }).maxw("65%").$, // adding this as a gt instead of unsetting
            ...Css.ifContainer({ lt: 950 }).maxw("80%").$,
            ...Css.ifContainer({ lt: 684 }).maxw100.$,
          }}
        >
          <SummaryCardList
            basePlanPriceInCents={basePlanPriceInCents}
            goToStep={() => null}
            hideTotals={true}
            // A reservedAt will exist. Forcing it here instead of refactoring all our configurator step types
            isReversed={isReversed}
            plan={plan}
            reservedAt={reservedAt!}
            selectedOptions={selectedOptions}
          />
        </div>
        {/* Move disclaimer to bottom of page when tablet and below */}
        <div css={Css.absolute.bottom0.left0.pl4.ifContainer({ gt: 1024 }).display("none").$}>
          <i css={Css.body10.gray700.$}>{TRUNCATED_IMAGE_DISCLAIMER}</i>
        </div>
      </div>
    </>
  );
}

const getDisplayImages = (
  selectedOptions: ConfiguratorConfigurationOptionFragment[],
  isReversed: boolean,
): ImageDisplayImageProps[] => {
  const exteriorScheme = selectedOptions
    .find((o) => o.option.planOptionType.name === "Exterior Scheme")
    ?.option.images.find((i: { type: { code: OptionImageType } }) => i.type.code === OptionImageType.Display);
  const exteriorOption = selectedOptions.find((o) => o.option.planOptionType.name === "Exterior")?.option;
  const interiorImages = selectedOptions
    .filter((o) => o.option.planOptionType.name === "Spec Level")
    .flatMap((o) => o.option.images)
    .filter((i) => i.type.code === OptionImageType.Display);
  if (exteriorScheme && exteriorOption) {
    // This is to set a presentable name for the exterior image
    const previewCardData = {
      id: exteriorOption.id,
      name: "Exterior",
      imageUrl: exteriorScheme.imageUrl,
      isReversed: isReversed,
    } as ImageDisplayImageProps;
    return [previewCardData, ...interiorImages];
  }
  return interiorImages;
};

import { Css } from "~generated/css";
import {
  CustomerConfigurationStatus,
  SummaryPrintConfigurationFragment,
  SummaryPrintConfigurationQuery,
} from "~generated/graphql";

import { ImageDisplay, ImageDisplayImageProps } from "src/pages/configurator/components";
import { currencyFormatter, getProgramDataTotalStrings } from "~utils";
import { PDFPage } from "./PDFPage";
import { PrintHeader } from "./PrintHeader";
import { PrintListItem } from "./PrintListItem";

export function FloorPlanReport({
  data,
  shouldDisplayPricing,
}: {
  data: SummaryPrintConfigurationQuery;
  shouldDisplayPricing: boolean;
}) {
  const {
    customerConfiguration: {
      name,
      options,
      plan,
      planPriceInCents,
      customer,
      reservedAt,
      expiredAt,
      status,
      isReversed,
    },
  } = data;

  // only show one floor plan image
  // different pages for different levels
  const planFloorPlans: ImageDisplayImageProps[] = plan.floorPlans?.filter((fp) => !!fp) || [];

  return (
    <>
      {planFloorPlans.map((floorplan) => (
        <PDFPage key={floorplan.name}>
          <div css={Css.relative.h100.w100.bgWhite.$}>
            <div css={Css.df.ma.fdc.gap2.p1.pb4.asc.maxwPx(1440).addIn("> div", { breakInside: "avoid-page" }).$}>
              <PrintHeader
                expiredAt={expiredAt!}
                isConfigurationExpired={status === CustomerConfigurationStatus.Expired}
                reservedAt={reservedAt!}
                configurationName={name}
                customer={customer!}
              />
              <FloorPlanCard
                plan={plan}
                planPriceInCents={planPriceInCents}
                shouldDisplayPricing={shouldDisplayPricing}
                options={options}
                isReversed={isReversed}
              />
              <div css={Css.df.fdc.hPx(800).w(75).ma.$}>
                <p css={Css.gray700.mb2.$}>{floorplan.name}</p>
                <ImageDisplay
                  images={[{ ...floorplan, name: "" }]}
                  title={"\xa0"}
                  showDisclaimer={false}
                  selectedOptionCodes={options.map((o) => o.option.optionCode)}
                  displayingStaticSvgs={false}
                  displayingDynamicSvgs={true}
                  isReversed={isReversed}
                />
              </div>
            </div>
          </div>
        </PDFPage>
      ))}
    </>
  );
}

type FloorPlanCardProps = { shouldDisplayPricing: boolean } & Pick<
  SummaryPrintConfigurationFragment,
  "plan" | "planPriceInCents" | "options" | "isReversed"
>;

export const FloorPlanCard = ({
  plan,
  planPriceInCents,
  options,
  isReversed,
  shouldDisplayPricing,
}: FloorPlanCardProps) => {
  const { bedrooms, bathrooms, sqft } = getProgramDataTotalStrings(options);
  const planDescription = `${plan?.name}\n${bedrooms}, ${bathrooms} & ${sqft} sqft`;

  return (
    <div css={Css.df.fdc.$}>
      <PrintListItem
        title="Floor Plan"
        priceInCents={shouldDisplayPricing ? `${currencyFormatter(planPriceInCents!)}` : ""}
        items={[{ title: planDescription, priceInCents: isReversed ? "Reversed" : undefined }]}
      />
    </div>
  );
};

import { Dispatch, SetStateAction, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useOutletContext } from "react-router-dom";
import { StepWrapper } from "src/pages/configurator/components";
import { FT_MEYER_PROPERTIES } from "src/pages/demo/configuratorLotStep/lotSteps/components";
import { Map, MapMarker, SelectableOptionCard, Tag } from "~components";
import { Css, Palette } from "~generated/css";

const PROPERTIES = FT_MEYER_PROPERTIES.map((x, i) => {
  const modulo = i % 3;
  return { compatiblePlans: 4, priceInCents: modulo === 0 ? 41200000 : modulo === 1 ? 58100000 : 67700000, ...x };
});

export function BoolLotStep() {
  const {
    selectedLotId: [selectedLotId, setSelectedLotId],
  } = useOutletContext<{ selectedLotId: [string | null, Dispatch<SetStateAction<string | null>>] }>();
  const [hoveredProperty, setHoveredProperty] = useState<string>("");

  const theMapMarkers = FT_MEYER_PROPERTIES.map(({ dpid, latitude, longitude }) => {
    const isActive = selectedLotId === dpid || hoveredProperty === dpid;
    return {
      id: dpid,
      center: { lat: Number(latitude), lng: Number(longitude) },
      isActive,
      marker: (
        <MapMarker
          onHoverStart={() => setHoveredProperty(dpid)}
          onHoverEnd={() => setHoveredProperty("")}
          onClick={() => setSelectedLotId(dpid)}
          isActive={isActive}
          data-testid={`mapMarker-${dpid}`}
        />
      ),
    };
  });

  return (
    <>
      <Helmet>
        <title>Configurator | BOOL</title>
      </Helmet>
      <StepWrapper
        left={
          <div css={Css.w100.h100.$}>
            <Map markers={theMapMarkers} />
          </div>
        }
        title="Select a lot"
        description={
          <div css={Css.header18.lhPx(28).add("letterSpacing", "0.36px").mt2.$}>({PROPERTIES.length} results)</div>
        }
        right={PROPERTIES.map((p) => {
          const { dpid, compatiblePlans, fullStreetAddress, priceInCents } = p;
          return (
            <SelectableOptionCard
              key={dpid}
              selected={selectedLotId === dpid}
              title={
                <span css={Css.df.$}>
                  <Tag size="sm" backgroundColor={Palette.Green100} children={`${compatiblePlans} PLANS FIT`} />
                </span>
              }
              description={
                <div css={Css.df.fdc.gap1.$}>
                  <div css={Css.header20.lhPx(32).add("letterSpacing", "0.4px").$}>{fullStreetAddress}</div>
                  <div css={Css.body16.lhPx(24).fw300.gray700.$}>
                    Starting at {formatAmount(priceInCents)} with base home
                  </div>
                </div>
              }
              onClick={() => setSelectedLotId(dpid)}
              onHoverStart={() => setHoveredProperty(dpid)}
              onHoverEnd={() => setHoveredProperty("")}
              isHovered={hoveredProperty === dpid}
            />
          );
        })}
      />
    </>
  );
}

// TODO: These are sprinkled throughout the codebase. We need another purge for a single utility
function formatAmount(priceInCents: number | string) {
  if (typeof priceInCents === "string") return priceInCents;

  return `${(priceInCents / 100).toLocaleString("EN", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    notation: "compact",
  })}`;
}

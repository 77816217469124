import { motion } from "framer-motion";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { createInventoryUrl } from "src/RouteUrls";
import { Footer, Icon, Nav } from "~components";
import { Css, Palette } from "~generated/css";
import { LandingMarketFragment, useLandingMarketsQuery } from "~generated/graphql";
import { compactCurrencyFormatter, defaultTestId, useTestIds } from "~utils";

const LOCAL_BREAKPOINT = 600;

export function LandingPage() {
  const { data } = useLandingMarketsQuery();
  const markets = data?.markets || [];
  const tid = useTestIds({}, "landingPage");

  return (
    <>
      <Helmet>
        <title>Browse Homes | Homebound</title>
        <meta name="description" content="Choose a finished home or customize your own. We make it simple." />
      </Helmet>
      <Nav />
      <div css={Css.bgWhite.df.fdc.$} {...tid}>
        <main css={Css.maxwPx(1280).w100.mx("auto").py8.df.fdc.aic.relative.container().$}>
          <div css={Css.px2.ifContainer({ lt: LOCAL_BREAKPOINT }).px3.$}>
            <h1 {...tid.title} css={Css.header40.serif.tc.ifContainer({ lt: LOCAL_BREAKPOINT }).header32.serif.$}>
              Select a location where we build
            </h1>

            <p
              css={
                Css.mt2.body18.sansSerif.lhPx(28).tc.gray80.ifContainer({ lt: LOCAL_BREAKPOINT }).lhPx(24).fsPx(16).$
              }
            >
              Our team has hundreds of new home projects planned and underway in popular markets across the United
              States. Choose a city below to learn more and see what's available or coming soon to a market near you.
            </p>

            <div css={Css.mt6.dg.gtc("1fr 1fr 1fr").gap3.ifContainer({ lt: LOCAL_BREAKPOINT }).gtc("1fr").$}>
              {markets.map((m) => (
                <MarketCard market={m} key={m.id} {...tid} />
              ))}
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}

export type PropertyCardProps = {
  market: LandingMarketFragment;
};

export function MarketCard(props: PropertyCardProps) {
  const { market } = props;
  const { imageUrl, name, propertyPriceMinInCents } = market;
  const [hovered, setHovered] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const tid = useTestIds(props, "marketCard");
  const nameSplit = name.split(" ");

  return (
    <a
      href={createInventoryUrl(market)}
      css={
        Css.cursorPointer.br4.relative
          .boxShadow("0px 4px 8px rgba(53, 53, 53, 0.1), 0px 2px 24px rgba(53, 53, 53, 0.08)")
          .onHover.outline1.add("outlineColor", Palette.Gray900)
          .ifContainer({ lt: LOCAL_BREAKPOINT }).df.w100.$
      }
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...tid[`marketCard-${defaultTestId(name)}`]}
    >
      <img
        onLoad={() => setImageLoading(false)}
        loading="lazy"
        css={
          Css.h("60")
            .z0.w100.objectCover.borderRadius("4px 4px 0 0")
            .ifContainer({ lt: LOCAL_BREAKPOINT })
            .br0.h100.w("50%").$
        }
        src={imageUrl}
        alt={name}
      />
      <LoadingMask loading={imageLoading} />
      <div css={Css.relative.z2.bgWhite.px3.py2.h("40").ifContainer({ lt: LOCAL_BREAKPOINT }).px2.my("auto").$}>
        <div css={Css.df.$}>
          <div css={Css.dib.body16.fw500.gray900.add("letterSpacing", "0.02em").lh("24px").$}>
            {nameSplit.map((n, i) => {
              if (i === nameSplit.length - 1) {
                return (
                  <span key={n} css={Css.dif.$}>
                    {n}
                    <i css={Css.df.relative.p("4px").aife.$}>
                      <motion.span
                        initial={{ paddingLeft: 0 }}
                        animate={hovered ? { paddingLeft: ["0px", "15px", "0px"] } : { paddingLeft: "0px" }}
                        transition={{ duration: 0.5 }}
                      >
                        <Icon name="chevron-right" />
                      </motion.span>
                    </i>
                  </span>
                );
              } else {
                return <span key={n}>{n} </span>;
              }
            })}
          </div>
        </div>
        <div css={Css.body14.gray700.lh("22px").$}>
          Homes starting at {compactCurrencyFormatter(propertyPriceMinInCents || undefined)}
        </div>
      </div>
    </a>
  );
}

const LoadingMask = ({ loading }: { loading: boolean }) => {
  return (
    <motion.div
      initial={{ opacity: 1 }}
      css={
        Css.absolute.bgOffWhite.z1.w100.top0.left0.h100
          .borderRadius("4px 4px 0 0")
          .ifContainer({ lt: LOCAL_BREAKPOINT }).br0.h100.w50.$
      }
      animate={!loading && { opacity: 0, transition: { duration: 0.4 } }}
    />
  );
};

import { css as stitchesCss, VariantProps } from "@stitches/react";
import { MouseEvent, useRef } from "react";
import { Tooltip, TooltipProps } from "~components";
import { Css } from "~generated/css";
import { useTestIds } from "~utils";
import { Icon, IconKeyProp } from "./Icon";

const styles = stitchesCss("button", {
  // TODO: Add stories for iconbutotn

  // mini reset
  appearance: "none",
  border: "none",

  // base button style
  ...Css.df.relative.p("4px").br100.aic.jcc.w3.h3.boxShadow("0px 6px 6px -6px #00000029, 0px 0px 1px #00000066").$,

  variants: {
    color: {
      primary: {
        ...Css.bgGray50.$,
        "&:hover:not(:disabled)": Css.bgGray400.$,
      },
      secondary: {
        ...Css.bgGray900.$,
        "&:hover:not(:disabled)": Css.bgGray800.$,
      },
    },
    size: {
      small: Css.w3.h3.$,
      medium: Css.w4.h4.$,
    },
    disabled: {
      true: Css.cursorNotAllowed.$,
    },
    /** Removes box shadow in addition to border */
    hideBorder: {
      true: Css.bshNone.$,
    },
    state: {
      active: Css.$,
    },
    shape: {
      square: Css.br4.$,
    },
  },

  defaultVariants: {
    size: "small",
    color: "primary",
  },

  compoundVariants: [
    {
      color: "primary",
      disabled: true,
      css: Css.bgGray100.$,
    },
    {
      color: "secondary",
      disabled: true,
      css: Css.bgGray400.$,
    },
    {
      color: "primary",
      state: "active",
      css: Css.bgGray200.$,
    },
    {
      color: "primary",
      state: "active",
      css: Css.bgGray700.$,
    },
  ],
});

export type IconButtonProps = Omit<VariantProps<typeof styles>, "disabled"> & {
  onClick: () => void;
  icon: IconKeyProp;
  disabled?: boolean | string;
  tooltipPlacement?: TooltipProps["placement"];

  /** Prevents default and stops propagation of the event. Defaults to FALSE*/
  stopPropagation?: boolean;
};

// TODO: Mimic Beam IconButton aria logic to use <a> for link || <button> for rest
export function IconButton(props: IconButtonProps) {
  const {
    onClick,
    icon,
    disabled,
    color,
    tooltipPlacement,
    size,
    hideBorder = false,
    stopPropagation = false,
    state,
    shape,
  } = props;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const showDisabledTooltip = typeof disabled === "string";
  const tid = useTestIds(props, "iconButton");

  function handleClick(event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) {
    if (stopPropagation) {
      event.preventDefault();
      event.stopPropagation();
    }
    onClick?.();
  }

  return (
    <button
      onClick={handleClick}
      disabled={!!disabled}
      ref={buttonRef}
      className={styles({ size, color, disabled: !!disabled, hideBorder: !!hideBorder, state, shape })}
      {...tid}
    >
      <Icon name={icon} />
      {showDisabledTooltip && (
        <Tooltip triggerRef={buttonRef} placement={tooltipPlacement} {...tid.tooltip}>
          {disabled}
        </Tooltip>
      )}
    </button>
  );
}

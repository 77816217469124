import Div100vh from "react-div-100vh";
import { Helmet } from "react-helmet-async";
import { Navigate, Outlet, useMatch, useParams, useSearchParams } from "react-router-dom";
import { ToastProvider } from "src/contexts";
import { ConfiguratorHeader, ConfiguratorStepper } from "src/pages/configurator/components";
import { ConfiguratorProvider } from "src/pages/configurator/Configurator.context";
import { AddonsStep, ExteriorStep, InteriorStep, InteriorUpgradesStep } from "src/pages/configurator/stepTemplates";
import { FloorPlanOptionsStep } from "src/pages/configurator/stepTemplates/FloorPlanOptions.step";
import { configuratorPath } from "src/RouteUrls";
import { Css } from "~generated/css";

export function ConfiguratorLayout() {
  const matchConfiguratorLayoutPath = useMatch(configuratorPath);

  const [searchParams] = useSearchParams();
  const { marketNameSlug } = useParams();
  const code = searchParams.get("code");

  // If we don't have a code past plans step, redirect to the market's plan page for now
  if (!code && !matchConfiguratorLayoutPath) {
    return <Navigate replace to={`/${marketNameSlug}/configurations`} />;
  }

  return (
    <Div100vh css={Css.df.fdc.overflowHidden.bgWhite.$}>
      {/* Need metadata info for flow if this becomes public facing */}
      <Helmet>
        <title>Configurator</title>
      </Helmet>
      <ToastProvider>
        <ConfiguratorProvider code={code as string}>
          <ConfiguratorHeader />
          <Outlet />
          {/* Note: We're iterating this. For now `MarketPlansStep` will use its own stepper */}
          {!matchConfiguratorLayoutPath && <ConfiguratorStepper />}
        </ConfiguratorProvider>
      </ToastProvider>
    </Div100vh>
  );
}

export const ConfiguratorStep = () => {
  const { planStepId } = useParams();

  switch (planStepId) {
    case "exterior":
      return <ExteriorStep />;
    case "interior":
      return <InteriorStep />;
    case "floor-plan-options":
      return <FloorPlanOptionsStep />;
    case "upgrades":
      return <InteriorUpgradesStep />;
    case "add-ons":
      return <AddonsStep />;
    default:
      return null;
  }
};

import { useEffect } from "react";

// Call callback when esc is pressed
export function useEscCapture(callback: () => void) {
  useEffect(() => {
    const escClose = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        callback();
      }
    };

    window.addEventListener("keydown", escClose);

    return () => {
      window.removeEventListener("keydown", escClose);
    };
  }, [callback]);
}

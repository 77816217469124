import { camelCase as _camelCase } from "change-case";

export function capitalize(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function titleCase(value: string) {
  return value.split(" ").map(capitalize).join(" ");
}

export function kebabCase(s: string) {
  return s
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, "-")
    .toLowerCase();
}

export function camelCase(s: string) {
  return _camelCase(s);
}

import { CSSProperties, ReactNode } from "react";
import { ITooltip, Tooltip as ReactTooltip } from "react-tooltip";
import { Icon, IconKeyProp } from "~components";
import { Css } from "~generated/css";
import { useTestIds } from "~utils";

export type TooltipV2Props = {
  children: ReactNode;
  icon?: IconKeyProp | ReactNode;
  /** Defaults to "max-content". */
  width?: string;
  /** Note: Depending on use case for override, it may be better to update the default styling and start using type variants
   *
   * https://react-tooltip.com/docs/examples/styling#explanation
   * */
  cssOverrides?: CSSProperties;
} & Pick<ITooltip, "clickable" | "positionStrategy" | "anchorSelect" | "noArrow" | "openOnClick" | "place" | "offset">;

// TODO: We'll implement this as v2 while we do configurator work and deprecate the rest as we go
//  - Button.tsx
//  - LocationSection.tsx
//  - IconButton.tsx

/** Note: Most cutoff problems caused by overflow hidden can be fixed by adding a `positionStrategy: fixed`.
 * In this case you need to guarantee `anchorSelect` is unique to prevent multiple tooltips from rendering stacked.
 * */
export function TooltipV2(props: TooltipV2Props) {
  const { children, icon, width = "max-content", cssOverrides = {}, ...others } = props;

  const tid = useTestIds(props, "tooltipV2");

  return (
    <ReactTooltip
      css={{
        // default react-tooltip style overrides
        ...Css.fs("100%")
          .p3.add("opacity", 1)
          .boxShadow("-4px -4px 10px rgba(0, 0, 0, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.1)")
          .br4.w(width)
          .maxwPx(374)
          // `important` needed override inline `bottom` calculation of arrow
          .addIn(".react-tooltip-arrow", Css.wPx(20).hPx(20).bottomPx(-10).important.$).$,
        // HB
        ...Css.df.fdc.aic.jcc.bgWhite.z999.$,
        ...cssOverrides,
      }}
      offset={25}
      {...others}
      {...tid}
    >
      <div css={Css.df.gap1.body14.gray700.lhPx(22).tl.if(!!icon).aifs.else.aic.$}>
        {icon && <div css={Css.df.$}>{typeof icon === "string" ? <Icon name={icon as IconKeyProp} /> : icon}</div>}
        {children}
      </div>
    </ReactTooltip>
  );
}

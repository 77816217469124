import { useState } from "react";
import { Checkbox, Modal, Switch } from "~components";
import { Css } from "~generated/css";

export type PDFPrintModalProps = {
  onClose: () => void;
  onContinue: (exports: string[]) => void;
};

const REPORTS_MAP: { [key: string]: string } = {
  summary: "Summary receipt",
  floorPlans: "Floor plan (as configured)",
  exterior: "Exterior renderings",
  interior: "Interior renderings",
  finishLevelComparison: "Finish level comparison sheet",
  standards: "Homebound standards sheet",
  pricing: "Include pricing", // this isn't a report page, it toggles pricing on the above reports
};

export function PDFPrintModal(props: PDFPrintModalProps) {
  const { onClose, onContinue } = props;

  // default state for all of these is falsey so we don't need keys or values here
  const defaultState: { [key: string]: boolean } = { summary: true };
  const [checkValues, setCheckValues] = useState(defaultState);

  return (
    <Modal
      size={{ width: "696px", height: "498" }}
      title={
        <div css={Css.df.jcfs.gap2.pb4.$}>
          <span css={Css.header24.lhPx(40).$}>Select what to include in PDF export</span>
        </div>
      }
      description={
        <div css={Css.body18.fw300.lhPx(28).wPx(568).$}>
          {Object.keys(REPORTS_MAP).map((key: string) => (
            <div css={Css.df.mb2.$} key={key}>
              {key === "pricing" ? (
                <Switch
                  value={checkValues[key]}
                  onClick={() => setCheckValues({ ...checkValues, [key]: !checkValues[key] })}
                />
              ) : (
                <Checkbox
                  selected={checkValues[key]}
                  onClick={() => setCheckValues({ ...checkValues, [key]: !checkValues[key] })}
                />
              )}
              <p css={Css.body14.gray700.ml2.lhPx(22).$}>{REPORTS_MAP[key]}</p>
            </div>
          ))}
        </div>
      }
      actionBtns={[
        { variant: "link", children: "Cancel", onClick: onClose },
        {
          children: "Export",
          disabled: Object.values(checkValues).every((value) => !value),
          onClick: () => {
            const exports = Object.keys(checkValues).reduce((acc: string[], current) => {
              if (checkValues[current]) {
                acc.push(current);
              }
              return acc;
            }, []);

            onContinue(exports);
            onClose();
          },
        },
      ]}
      isOpen
      close={onClose}
    ></Modal>
  );
}

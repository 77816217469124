import { Navigate, Route } from "react-router-dom";
import {
  ConfiguratorLayout,
  ConfiguratorStep,
  FloorPlanPage,
  HopSelectionsPage,
  InventoryPage,
  LandingPage,
  PickLotTypeStep,
  SummaryPage,
  SummaryPrintPage,
} from "~pages";
import {
  configuratorPath,
  floorPlanPath,
  hopSelectionsPath,
  internalConfiguratorLotsPath,
  inventoryPath,
  landingPath,
  listingPath,
  moodboardPath,
} from "./RouteUrls";

import { MarketPlansStep, SummaryStep } from "src/pages/configurator/stepTemplates";
import { BoolLotStep, BoylLotStep, LotLayout } from "src/pages/demo";
import { getEnv } from "src/utils";
import { AnimatedRoutes } from "~components";
import { MoodboardPage } from "./pages/hop/Moodboard.page";

export default function App() {
  const nonProdFlag = getEnv() !== "prod";

  return (
    <AnimatedRoutes>
      {[
        { path: landingPath, element: <LandingPage /> },
        { path: inventoryPath, element: <InventoryPage /> },
        { path: listingPath, element: <SummaryPage /> },
        // Redirect all unmatched traffic to `/inventory/`
        { path: "*", element: <Navigate to={landingPath} /> },
        {
          path: `${configuratorPath}`,
          element: <ConfiguratorLayout />,
          children: [
            <Route key="plans" index element={<MarketPlansStep />} />,
            <Route key="planStepId" path="steps/:planStepId" element={<ConfiguratorStep />} />,
            <Route key="summary" path="summary" element={<SummaryStep />} />,
          ],
        },
        {
          path: "configurations/download/:code",
          element: <SummaryPrintPage />,
        },
        {
          path: internalConfiguratorLotsPath,
          element: <LotLayout />,
          children: [
            <Route key="lots" index element={<PickLotTypeStep />} />,
            <Route key="boylLots" path="boyl" element={<BoylLotStep />} />,
            <Route key="boolLots" path="bool" element={<BoolLotStep />} />,
          ],
        },
        { path: hopSelectionsPath, element: <HopSelectionsPage /> },
        { path: floorPlanPath, element: <FloorPlanPage /> },
        { path: moodboardPath, element: <MoodboardPage /> },
      ]}
    </AnimatedRoutes>
  );
}

import { SaveCustomerInput } from "~generated/graphql";
const referrerCookieName = "__gtm_referrer";
const utmCookieName = "initialTrafficSource";

function getCookie(name: string) {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
}

export function getTrackingParamsFromCookies(): Pick<
  SaveCustomerInput,
  "referrer" | "utmSource" | "utmCampaign" | "utmMedium"
> {
  const referrer = getCookie(referrerCookieName);
  const utm = getCookie(utmCookieName) || "";
  const utmParts = utm.split("|");

  let utmSource = undefined;
  let utmMedium = undefined;
  let utmCampaign = undefined;

  for (const part of utmParts) {
    const [key, value] = part.split("=");
    if (value !== "(not set)") {
      if (key === "utmcsr") {
        utmSource = value;
      } else if (key === "utmcmd") {
        utmMedium = value;
      } else if (key === "utmccn") {
        utmCampaign = value;
      }
    }
  }

  return { referrer, utmSource, utmMedium, utmCampaign };
}

import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { OverlayProvider } from "react-aria";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { getEnv } from "src/utils";
import { CssReset } from "~components";
import App from "./App";
import { FeatureFlagProvider, ModalProvider } from "./contexts";

const client = new ApolloClient({ uri: getAPIUrl(), cache: new InMemoryCache() });
if (getEnv() !== "local") startDatadog();

const root = createRoot(document.getElementById("root")!);
root.render(
  // Providers
  <ApolloProvider client={client}>
    <React.StrictMode>
      {/* Moved AnimatePresence here from Animated Route component as it was causing rendering issues */}
      <AnimatePresence mode="wait">
        <HelmetProvider>
          <BrowserRouter>
            <FeatureFlagProvider>
              <OverlayProvider>
                <ModalProvider>
                  <CssReset />
                  <App />
                </ModalProvider>
              </OverlayProvider>
            </FeatureFlagProvider>
          </BrowserRouter>
        </HelmetProvider>
      </AnimatePresence>
    </React.StrictMode>
  </ApolloProvider>,
);

function getAPIUrl() {
  const stage = getEnv();
  const hostname = window.location.hostname;

  // @ts-expect-error TODO: Fix this typing issue via vite global variables.
  if (stage === "local") return VITE_GRAPHQL_SERVICE ?? "http://0.0.0.0:4004/graphql";
  // PR Previews and development
  else if (hostname.includes("dev-homebound")) return "https://explore-graphql.dev-homebound.com/graphql";
  // Production
  else return "https://explore-graphql.homebound.com/graphql";
}

function startDatadog() {
  // Configure Datadog RUM
  // https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
  datadogRum.init({
    applicationId: "5c20972d-3478-4d63-8cb4-a1447e1c4b2a",
    clientToken: "pub5673dcee7cd1c2d2eceba6b93dc84788",
    site: "datadoghq.com",
    service: "explore.homebound.com",
    env: getEnv(),
    // @ts-expect-error TODO: Fix this typing issue via vite global variables.
    version: VITE_GIT_COMMIT ?? "local",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [getAPIUrl()],
    defaultPrivacyLevel: "mask-user-input",
    // Enabling the beta HeatMap feature
    enableExperimentalFeatures: ["clickmap"],
  });

  // The Session Replay does not start recording automatically when calling
  // init(). To start the recording, call startSessionReplayRecording().
  datadogRum.startSessionReplayRecording();
}

import { ReactNode } from "react";
import { Button, ButtonProps, HBLoadingSpinnerOnly, Icon } from "~components";
import { Css, Palette } from "~generated/css";
import { useTestIds } from "~utils";

export type ModalProps = {
  children?: ReactNode;
  isOpen: boolean;
  close: () => void;
  title: ReactNode;
  description?: ReactNode;
  bg?: Palette;
  size?: {
    width: string;
    height: string;
    maxWidth?: string;
  };
  actionBtns?: ButtonProps[];
  // Shows left aligned loading spinner when actionBts exist
  loading?: boolean;
};

export function Modal(props: ModalProps) {
  const {
    children,
    isOpen,
    close,
    title,
    bg = Palette.White,
    size = { width: "100%", height: "100%", maxWidth: "auto" },
    description,
    actionBtns,
    loading,
  } = props;

  const tid = useTestIds(props, "modal");

  return (
    <>
      {isOpen && (
        <div
          css={
            Css.w(size.width).h(size.height).maxw(size.maxWidth).bgColor(bg).df.ma.fdc.px3.if(!actionBtns).py4.else.pt4
              .$
          }
          // Prevent modal interactions from closing overlay layer
          onClick={(e) => e.stopPropagation()}
        >
          <div css={Css.asfe.cursorPointer.$} onClick={close}>
            <Icon name="x-32" />
          </div>
          <div css={Css.px3.df.fdc.gap1.mb4.$}>
            {typeof title === "string" ? <h4 css={Css.header32.lhPx(48).$}>{title}</h4> : title}
            {description && typeof description === "string" ? (
              <div css={Css.body16.fw300.gray700.$}>{description}</div>
            ) : (
              description
            )}
          </div>
          {children}
          {actionBtns && (
            <div css={Css.df.aic.px3.py3.mt3.gap1.bt.bGray300.if(!!loading).jcsb.else.jcfe.$}>
              {loading && (
                <div id="spinner-only" css={Css.df.wPx(48).hPx(48).$}>
                  <HBLoadingSpinnerOnly />
                </div>
              )}
              <div css={Css.df.$}>
                {actionBtns?.map((btn, i) => (
                  <div css={Css.wPx(110).$} key={`Modal ${title} button ${i}`}>
                    <Button size="xl" btnFontSize="sm" {...btn} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

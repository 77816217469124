// TODO:
// configure vite to serve glsl files as text so we can
// write shaders in glsl files

export const vertex = `
uniform mat4 uMVP;

attribute vec4 aVertPos;
attribute vec2 aTexCoords;

varying vec2 vTexCoords;

void main()
{
  gl_Position = uMVP * vec4(aVertPos.xyz, 1);
  vTexCoords = aTexCoords;
}
`;

export const fragment = `
precision highp float;

uniform sampler2D uTex2d; 

varying vec2 vTexCoords;

void main(void)
{
	gl_FragColor = texture2D(uTex2d, vTexCoords.st);
}
`;

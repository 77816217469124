import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from "react";
import { BasicOverlay } from "~components";
import { useTestIds } from "~utils";

type ModalContextActions = {
  showModal: (content: ReactNode) => void;
  closeModal: () => void;
};

const ModalContext = createContext<ModalContextActions>({} as ModalContextActions);

export type ModalContextProps = {
  children: ReactNode;
};

// Todo: Refactor to useModal hook when calling modal directly sc-36701
export function ModalProvider(props: ModalContextProps) {
  const { children } = props;
  const [modalContent, setModalContent] = useState<null | ReactNode>(null);

  // Use callback to prevent unnecessary re-renders
  const showModal = useCallback((_modalContent: ReactNode) => {
    setModalContent(_modalContent);
  }, []);

  // Use callback to prevent unnecessary re-renders
  const closeModal = useCallback(() => {
    setModalContent(null);
  }, []);

  // Context Value (purposefully using `useMemo` so `value` keeps the same object identifier through state changes)
  const value = useMemo(
    () => ({
      showModal,
      closeModal,
    }),
    [showModal, closeModal],
  );

  const tid = useTestIds(props, "modalProvider");

  return (
    <ModalContext.Provider value={value}>
      {modalContent && (
        <BasicOverlay onClick={closeModal} {...tid}>
          {modalContent}
        </BasicOverlay>
      )}
      {children}
    </ModalContext.Provider>
  );
}

export function useModal() {
  const context = useContext(ModalContext);
  if (context === null) throw new Error("useModal must be used within a ModalConfiguratorProvider");
  return context;
}

import { AnimatePresence, motion } from "framer-motion";
import { HTMLProps, ReactNode, useEffect, useState } from "react";
import { Icon, Radio, Tag } from "src/components/atoms";
import { IconTooltip } from "src/components/molecules/IconTooltip";
import { Css, Palette } from "~generated/css";
import { useTestIds } from "~utils";

type CommonOptionCardProps = {
  title?: ReactNode;
  titleAction?: {
    onClick: () => void;
    triggerText: ReactNode;
  };
  infoTooltip?: string;
  description?: ReactNode;
  lightText?: boolean;
};

const cardVariants = {
  closed: {
    delayChildren: 0,
    staggerChildren: 0,
    transition: {
      staggerChildren: 0,
      delayChildren: 0,
    },
  },
  open: {
    delayChildren: 0,
    staggerChildren: 0,
    transition: {
      staggerChildren: 0.0,
      delayChildren: 0.0,
      bounce: 0,
    },
  },
};

type AccordionOptionCardProps = {
  children: ReactNode;
  counter?: {
    current: number;
    total: number;
  };
  /** Defaults to true */
  expanded?: boolean;
  onExpanded?: () => void;
} & CommonOptionCardProps;

export function AccordionOptionCard(props: AccordionOptionCardProps) {
  const {
    title,
    description,
    children,
    titleAction,
    infoTooltip,
    counter,
    lightText = false,
    expanded = true,
    onExpanded,
  } = props;
  const [open, setOpen] = useState(expanded);

  const tid = useTestIds(props, "accordion-option-card");

  useEffect(() => {
    setOpen(expanded);
  }, [expanded]);

  return (
    <motion.div
      id="accordion-option-card"
      initial={expanded ? "open" : "closed"}
      variants={cardVariants}
      animate={open ? "open" : "closed"}
      css={Css.px3.py2.w100.gap1.br4.df.fdc.$}
      {...tid}
    >
      <div
        css={Css.cursorPointer.df.fdc.gap1.if(open).add("borderBottom", `1px solid ${Palette.Gray200}`).pb2.$}
        onClick={() => {
          setOpen((prev) => !prev);
          onExpanded?.();
        }}
      >
        {/* Header Area */}
        {title && (
          <div css={Css.df.jcsb.relative.top0.pt1.$}>
            <div css={Css.df.gap1.aic.$}>
              <div
                css={{
                  ...Css.gap1.body12.fw400.lhPx(16).gray700.ttu.$,
                  ...Css.if(lightText).white.$,
                }}
              >
                {title}
              </div>
              {titleAction && (
                <TitleActionText
                  lightText={lightText}
                  triggerText={titleAction.triggerText}
                  onClick={titleAction.onClick}
                  {...tid.titleActionText}
                />
              )}
              {infoTooltip && <IconTooltip message={infoTooltip} type="info" {...tid.infoTooltip} />}
            </div>
            {/* Using isolation here to prevent the tag from being higher than tooltips*/}
            <div id="card-icon-container" css={Css.df.aic.gap1.add({ isolation: "isolate" }).$}>
              {counter && (
                <Tag size="med" backgroundColor={Palette.Blue50}>{`${counter.current} of ${counter.total}`}</Tag>
              )}
              <motion.div
                initial={false}
                css={
                  Css.addIn("svg path", {
                    ...Css.fGray700.$,
                    ...Css.if(lightText).fWhite.$,
                  }).$
                }
                variants={{
                  closed: {
                    rotate: 0,
                  },
                  open: {
                    rotate: 180,
                  },
                }}
              >
                <Icon name="arrow-up" />
              </motion.div>
            </div>
          </div>
        )}
        {/* Main body */}
        {open && description}
      </div>
      {/* Expandable Children */}
      <AnimatePresence>
        {open && (
          <motion.div
            layout="position"
            initial={expanded ? "open" : "closed"}
            animate="open"
            exit="closed"
            css={Css.relative.overflowHidden.$}
            variants={{
              closed: {
                height: 0,
                transition: {
                  duration: 0.4,
                  bounce: 1,
                },
                transitionEnd: {
                  zIndex: -1,
                },
              },
              // FIXME: This transition is instant
              // -  We can fix it by passing variants ({ "open", "closed" }) down to our option list and list items
              // -  Bonus points if applying the same to `StepWrapper` will fix the scroll bar resizing all crazy
              open: {
                height: "auto",
                transition: {
                  duration: 0.4,
                },
                transitionEnd: {
                  zIndex: 0,
                },
              },
            }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

type SelectableOptionCardProps = {
  selected?: boolean;
  onClick?: () => void;
  priceLabel?: string;
  disabled?: boolean;
  /** To trigger state from paired map marker interaction */
  isHovered?: boolean;
  onHoverStart?: () => void;
  onHoverEnd?: () => void;
} & CommonOptionCardProps;

// FIXME: This is interim value to satisfy views < 1280 until we've gotten tablet responsiveness in
const REDUCE_TITLE_SIZE_BREAKPOINT = 270;

export function SelectableOptionCard(props: SelectableOptionCardProps) {
  const {
    title,
    selected,
    description,
    onClick,
    titleAction,
    infoTooltip,
    priceLabel,
    disabled = false,
    lightText = false,
    isHovered = false,
    onHoverStart,
    onHoverEnd,
  } = props;

  const tid = useTestIds(props, "selectable-option-card");

  return (
    <motion.div
      id="selectable-option-card"
      variants={cardVariants}
      css={{
        ...Css.px3.py2.w100.gap1.br4.df.fdc.add("transition", "0.2s").$,
        // Border needs to be 2px when selected so using box shadow on top to prevent layout shift
        ...Css.if(!!selected).boxShadow(`inset 0 0 0px 1px ${Palette.Gray800}`).$,
        ...Css.cursorPointer.if(disabled).cursorNotAllowed.bgGray200.bGray400.gray400.else.onHover.bgBlue50.$,
        // Keeping this logic out of the above if else onHover logic
        ...Css.if(!disabled && isHovered).bgBlue50.$,
      }}
      onClick={() => {
        if (!disabled) {
          onClick?.();
        }
      }}
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverEnd}
      {...tid}
    >
      {/* Setting a container here to use with the title for a smaller font */}
      <div css={Css.df.fdc.gap1.container().$}>
        {/* Header Area */}
        {title && (
          <div css={Css.df.jcsb.relative.top0.pt1.$}>
            <div css={Css.df.gap1.aic.$}>
              <div
                css={{
                  ...Css.overflowHidden.gap1.header18.fw500.lhPx(28).gray800.$,
                  ...Css.if(disabled).gray400.$,
                  ...Css.if(lightText).white.$,
                  ...Css.ifContainer({ lt: REDUCE_TITLE_SIZE_BREAKPOINT }).fsPx(14).fw500.lhPx(22).$,
                }}
              >
                {title}
              </div>
              {titleAction && (
                <TitleActionText
                  lightText={lightText}
                  triggerText={titleAction.triggerText}
                  onClick={titleAction.onClick}
                  {...tid.titleActionText}
                />
              )}
              {infoTooltip && <IconTooltip message={infoTooltip} type="info" {...tid.infoTooltip} />}
            </div>
            <div css={Css.df.gapPx(12).$}>
              {priceLabel && (
                <span
                  css={{
                    ...Css.body16.lhPx(24).gray700.whiteSpace("nowrap").$,
                    ...Css.ifContainer({ lt: REDUCE_TITLE_SIZE_BREAKPOINT }).body14.lhPx(22).$,
                    ...(selected && Css.gray800.fw500.$),
                  }}
                >
                  {priceLabel}
                </span>
              )}
              <Radio containerBreakpoint={REDUCE_TITLE_SIZE_BREAKPOINT} selected={!!selected} disabled={disabled} />
            </div>
          </div>
        )}
        {/* Main body */}
        {description}
      </div>
    </motion.div>
  );
}

// Common style wrapper for OptionCard.description labeld `SupportingText` in design
export const CardSupportingText = (props: HTMLProps<HTMLSpanElement>) => {
  const { children, ...others } = props;
  return (
    <span css={Css.body14.lhPx(22).gray700.$} {...others}>
      {children}
    </span>
  );
};

// Mainly triggers overlays
type TitleActionTextProps = { triggerText: ReactNode; onClick: () => void; lightText?: boolean };
const TitleActionText = (props: TitleActionTextProps) => {
  const { triggerText, onClick, lightText = false } = props;
  const tid = useTestIds(props, "title-action-text");
  return (
    <span
      css={Css.body14.fw300.lhPx(22).blue600.underline.if(lightText).blue300.$}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      {...tid}
    >
      {triggerText}
    </span>
  );
};

import { useEffect, useRef } from "react";
import { MobilePropertyCard, PropertyCard } from "~components";
import { Css } from "~generated/css";
import { InputMaybe, InventoryPropertyFragment, PropertyFilter } from "~generated/graphql";
import { currencyFormatter, defaultTestId, propertyButtonProps, useTestIds } from "~utils";

export const priceHiddenTags = ["Personalization Available", "Coming Soon"];

export const priceOptions: { [key: string]: { min: number; max?: number } } = {
  "$500K - $1.5M": { min: 500_000_00, max: 1_500_000_00 },
  "$1.5M - $2.5M": { min: 1_500_000_00, max: 2_500_000_00 },
  "$2.5M+": { min: 2_500_000_00 },
};

export const minBedOptions: { [key: string]: number } = {
  "2+": 2,
  "3+": 3,
  "4+": 4,
  "5+": 5,
};

export const minBathOptions: { [key: string]: number } = {
  "2+": 2,
  "3+": 3,
  "4+": 4,
  "5+": 5,
};

export const squareFootageOptions: { [key: string]: { min: number; max?: number } } = {
  "1000 - 2000": { min: 1000, max: 2000 },
  "2000 - 3000": { min: 2000, max: 3000 },
  "3000 - 4000": { min: 3000, max: 4000 },
  "4000+": { min: 4000 },
};

export type FilterState = {
  price?: string;
  minBeds?: string;
  minBaths?: string;
  tags?: string;
  squareFootage?: string;
};

export function convertFormToFilter(filters: FilterState, marketSelected: InputMaybe<string>): PropertyFilter {
  const { minBaths, minBeds, price, squareFootage } = filters;
  return {
    minBathrooms: !!minBaths ? minBathOptions[minBaths] : undefined,
    minBedrooms: !!minBeds ? minBedOptions[minBeds] : undefined,
    market: [marketSelected!],
    minPriceInCents: !!price ? priceOptions[price].min : undefined,
    maxPriceInCents: !!price ? priceOptions[price].max : undefined,
    minSqft: !!squareFootage ? squareFootageOptions[squareFootage].min : undefined,
    maxSqft: !!squareFootage ? squareFootageOptions[squareFootage].max : undefined,
  };
}

// FE filter by property cutoffs
export function propertyTagFilter(filters: FilterState, properties: InventoryPropertyFragment[]) {
  let filteredProperties = properties;
  if (filters.tags) {
    filteredProperties = properties.filter(
      (property) => property.mostRecentCutOff?.cutOff.marketingName === filters.tags,
    );
  }
  // If filtering by price we don't want to show personalization available as the price is hidden
  if (filters.price) {
    filteredProperties = filteredProperties.filter(
      (property) => !priceHiddenTags.includes(property.mostRecentCutOff?.cutOff.marketingName.trim() || ""),
    );
  }
  return filteredProperties;
}

// Note: Inventory.page Intersection observer relies on this, so we'll export it
export const PropertyCardContainerPrefix = "property-";

/**
 * PropertyCard wrapper which handles all the logic around the card's complex
 * state. These are things such as cutOff logic, description logic, and
 * button logic.
 */
type InventoryPropertyCardProps = {
  property: InventoryPropertyFragment;
  url: string;
  hoveredPropertyState?: {
    setHoveredPropertyId: (id: string | null) => void;
    hoveredPropertyId: string | null;
  };
  selectedPropertyState: {
    setSelectedPropertyId: (id: string | null) => void;
    selectedPropertyId: string | null;
  };

  mobile?: {
    horizontal: boolean;
  };

  intersectionObserver?: IntersectionObserver;
};

export function InventoryPropertyCard(props: InventoryPropertyCardProps) {
  const { property, url, hoveredPropertyState, selectedPropertyState, mobile, intersectionObserver } = props;
  const { id, priceInCents, streetAddress, city, state, postalCode, blueprintProjectId } = property;
  const { setHoveredPropertyId, hoveredPropertyId } = hoveredPropertyState ?? {};
  const { setSelectedPropertyId, selectedPropertyId } = selectedPropertyState;

  const propCardRef = useRef<HTMLDivElement>(null);

  const tid = useTestIds(props, "marketInventory");

  const tag = property.mostRecentCutOff
    ? {
        children: property.mostRecentCutOff.cutOff.marketingName,
        backgroundColor: property.mostRecentCutOff.cutOff.marketingHexColor,
      }
    : undefined;

  const hidePrice = priceHiddenTags.includes(property.mostRecentCutOff?.cutOff.marketingName.trim() || "");

  useEffect(() => {
    const currentPropCard = propCardRef?.current;
    if (intersectionObserver && currentPropCard) {
      intersectionObserver.observe(currentPropCard);
    }

    return () => {
      if (intersectionObserver && currentPropCard) {
        intersectionObserver.unobserve(currentPropCard);
      }
    };
  }, [intersectionObserver]);

  return (
    <>
      {mobile ? (
        <div
          ref={propCardRef}
          key={id}
          id={`${PropertyCardContainerPrefix}${id}`}
          css={Css.add("scrollSnapAlign", "start").$}
        >
          <MobilePropertyCard
            {...tid[defaultTestId(`propertyCard${blueprintProjectId}`)]}
            {...property}
            // pass the query string along as it might contain tracking info, this will work unless we start having query params in our urls
            url={url}
            isActive={selectedPropertyId === id}
            price={hidePrice ? "Inquire for Pricing" : `Offered at ${currencyFormatter(priceInCents)}`}
            address={`${streetAddress}`}
            tag={tag}
            buttons={propertyButtonProps(property, () => setSelectedPropertyId(id), false)}
            horizontal={mobile.horizontal}
          />
        </div>
      ) : (
        <div key={id} id={`${PropertyCardContainerPrefix}${id}`}>
          <PropertyCard
            {...tid[defaultTestId(`propertyCard${blueprintProjectId}`)]}
            {...property}
            // pass the query string along as it might contain tracking info, this will work unless we start having query params in our urls
            url={url}
            isHovered={hoveredPropertyId === id}
            isActive={selectedPropertyId === id}
            price={hidePrice ? "Inquire for Pricing" : `Offered at ${currencyFormatter(priceInCents)}`}
            address={`${streetAddress}, ${city}, ${state} ${postalCode}`}
            tag={tag}
            // Will always be defined for desktop
            onHoverStart={() => setHoveredPropertyId!(id)}
            onHoverEnd={() => setHoveredPropertyId!(null)}
            buttons={propertyButtonProps(property, () => setSelectedPropertyId(id), false)}
          />
        </div>
      )}
    </>
  );
}

import { motion, Variants } from "framer-motion";
import { useState } from "react";
import { Button, ButtonProps, Tag, TagProps } from "~components";
import { Css } from "~generated/css";
import { InventoryPropertyFragment } from "~generated/graphql";
import { defaultTestId, useTestIds } from "~utils";

export type PropertyCardProps = {
  address: string;
  /** @default false */
  isActive: boolean;
  price: string;
  /**
   * Define required buttons, requires an `href` or `onClick`
   */
  buttons: ButtonProps[];
  tag?: TagProps;
  /** To trigger state from paired map marker interaction */
  isHovered?: boolean;
  onHoverStart: () => void;
  onHoverEnd: () => void;
  /** property url */
  url: string;
  /** MOBILE: Triggers carousel friendly layout */
  horizontal?: boolean;
} & Pick<InventoryPropertyFragment, "bathrooms" | "bedrooms" | "blueprintProjectId" | "imageGallery" | "sqft">;

export function PropertyCard(props: PropertyCardProps) {
  const {
    isActive = false,
    buttons,
    isHovered,
    onHoverStart,
    onHoverEnd,
    url,
    tag,
    imageGallery,
    address,
    price,
    bedrooms,
    bathrooms,
    sqft,
  } = props;

  const tid = useTestIds(props, "propertyCard");
  const [imageLoading, setImageLoading] = useState(true);

  return (
    <a
      href={url}
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverEnd}
      css={{
        ...Css.df.bgWhite.fdc.add("transition", "0.2s").wPx(300).hPx(393).ba.br8.bGray600.cursorPointer.overflowHidden
          .dropShadow.$,
        // technically still causing rerender but doesn't effect map markers
        ...(isActive && Css.bw2.bGray900.bshNone.$),
        ...(isHovered && Css.bgGray100.bGray900.$),
        ":hover, &.hovered-card": Css.bgGray100.bGray900.$,
      }}
      {...tid}
    >
      <div css={Css.w100.fg1.relative.$}>
        {/* Property Highlights */}
        <div css={Css.relative.top0.left0.bottom0.right0.$}>
          <div css={Css.absolute.ml1.mt1.df.gap1.wPx(284).flexW.z1.$}>{tag && <Tag key={tag.children} {...tag} />}</div>
          <motion.div css={Css.relative.w100.objectCover.hPx(225).$}>
            <motion.img
              onLoad={() => setImageLoading(false)}
              loading="lazy"
              css={Css.w100.objectCover.hPx(225).absolute.$}
              src={imageGallery[0].imageUrl}
              alt={address}
            />
            {imageGallery.length > 1 && (
              <motion.img
                loading="lazy"
                whileHover={{ opacity: 1 }}
                animate={{ opacity: 0 }}
                initial={false}
                transition={{ duration: 0.3 }}
                css={Css.w100.objectCover.hPx(225).absolute.$}
                src={imageGallery[1].imageUrl}
                alt={address}
              />
            )}
            {/* LoadingMask */}
            <motion.div
              initial={{ opacity: 1 }}
              css={Css.absolute.bgOffWhite.z1.w100.h100.$}
              animate={
                !imageLoading && {
                  opacity: 0,
                  transition: { duration: 0.4 },
                  transitionEnd: { display: "none", zIndex: -1 },
                }
              }
            />
          </motion.div>
          <div id="whateveryouwant" css={Css.df.fdc.pt2.px3.$}>
            <h3 css={Css.body18.fw500.lhPx(24).$}>{price}</h3>
            <div css={Css.pt1.df.jcsb.body16.lhPx(24).mr3.$}>
              <span>{bedrooms} beds</span>
              <span>{bathrooms} baths</span>
              <span>{sqft} Sq. ft</span>
            </div>
            <span css={Css.pt1.body12.lhPx(16).lineClamp1.$}>{address}</span>
          </div>
        </div>
      </div>
      <div css={Css.df.fdc.gap2.aic.pb2.asStretch.px3.pt2.$}>
        {buttons.map(({ children, ...other }, idx) => {
          let buttonTid, key;
          // determine whether it's the contact us or customize button
          if (typeof children === "string") {
            buttonTid = defaultTestId(children);
            key = children;
          } else {
            buttonTid = "";
            key = `${address}-property-card-button-${idx.toString()}`;
          }
          return (
            <Button key={key} {...other} {...tid[`${buttonTid}Button`]} size="small" stopPropagation>
              {children}
            </Button>
          );
        })}
      </div>
    </a>
  );
}

// ----------------MOBILE----------------------//
const cardVariants: Variants = {
  horizontal: {
    height: "112px",
    transition: {
      duration: 0.4,
      staggerChildren: 0.1,
      delayChildren: 0.5,
      bounce: 0,
    },
  },
  vertical: {
    height: "350px",
    transition: {
      duration: 0.4,
      staggerChildren: 0.1,
      delayChildren: 0.5,
      bounce: 0,
    },
  },
};

const cardImageVariants: Variants = {
  horizontal: {
    height: "100%",
    width: "120px",
    transition: { duration: 0.0 },
    objectFit: "cover",
  },
  vertical: {
    height: "191px",
    width: "100%",
    transition: { duration: 0.0 },
    objectFit: "cover",
  },
};

export function MobilePropertyCard(props: Omit<PropertyCardProps, "isHovered" | "onHoverEnd" | "onHoverStart">) {
  const {
    isActive = false,
    buttons,
    horizontal = false,
    url,
    tag,
    imageGallery,
    address,
    price,
    bedrooms,
    bathrooms,
    sqft,
  } = props;
  const tid = useTestIds(props, "propertyCard");

  const [imageLoading, setImageLoading] = useState(true);

  return (
    <motion.a
      variants={cardVariants}
      initial={false}
      animate={horizontal ? "horizontal" : "vertical"}
      href={url}
      css={{
        ...Css.df.bgWhite.fdc.w("88vw").ba.br8.bGray600.overflowHidden.dropShadow.$,
        ...(isActive && Css.bw2.bGray900.bshNone.$),
      }}
      {...tid}
    >
      <motion.div css={{ ...Css.w100.h("288px").relative.$, ...(horizontal && Css.df.h100.$) }}>
        <motion.div css={Css.df.fdc.if(horizontal).fdr.$}>
          {!horizontal && (
            <div css={Css.absolute.z0.ml1.mt1.df.gap1.wPx(284).flexW.$}>
              {tag && <Tag key={tag.children} {...tag} />}
            </div>
          )}
          {/* Property Image */}
          <motion.img
            onLoad={() => setImageLoading(false)}
            loading="lazy"
            css={Css.relative.z1.$}
            variants={cardImageVariants}
            initial={false}
            src={imageGallery[0].imageUrl}
            alt={address}
          />
          <MobileLoadingMask loading={imageLoading} horizontal={horizontal} />
          {/* Property Details */}
          <div
            css={{
              ...Css.df.fdc.pt2.px2.gap1.$,
              ...(horizontal && Css.gap0.px1.py0.jcc.$),
            }}
          >
            <h3 css={Css.header16.fw500.if(horizontal).fsPx(14).$}>{address}</h3>
            <div css={Css.df.body14.fsPx(13).lhPx(24).gap2.if(horizontal).fsPx(12).$}>
              {horizontal ? (
                <span css={Css.whiteSpace("nowrap").$}>
                  {bedrooms} beds, {bathrooms} baths, {sqft} Sq. Ft
                </span>
              ) : (
                <>
                  <span>{bedrooms} beds</span>
                  <span>{bathrooms} baths</span>
                  <span>{sqft} Sq. ft</span>
                </>
              )}
            </div>
            <span
              css={{
                ...Css.body12.lhPx(24).if(horizontal).midGray.pt1.$,
              }}
            >
              {price}
            </span>
          </div>
        </motion.div>
      </motion.div>
      {/* Buttons in vertical view only */}
      {!horizontal && (
        <div css={Css.df.asStretch.px3.gap2.pt1.pb2.$}>
          {buttons.map(({ children, ...other }, idx) => {
            let buttonTid, key;
            // determine whether it's the contact us or customize button
            if (typeof children === "string") {
              buttonTid = defaultTestId(children);
              key = children;
            } else {
              buttonTid = "";
              key = `${address}-property-card-button-${idx.toString()}`;
            }
            return (
              <Button key={key} {...other} {...tid[`${buttonTid}Button`]} size="small" stopPropagation>
                {children}
              </Button>
            );
          })}
        </div>
      )}
    </motion.a>
  );
}

const MobileLoadingMask = ({ loading, horizontal }: { loading: boolean; horizontal: boolean }) => {
  return (
    <motion.div
      initial={{ opacity: 1 }}
      css={{
        ...Css.absolute.bgOffWhite.z1.w100.hPx(191).$,
        ...(horizontal && Css.h100.wPx(120).$),
      }}
      animate={!loading ? { opacity: 0, transition: { duration: 0.4 } } : { opacity: 1, transition: { duration: 0.0 } }}
    />
  );
};

import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useModal } from "src/contexts";
import { ReserveYourBuildFlyout } from "src/pages/configurator/components/ReserveYourBuildFlyout";
import { SummaryDrawer } from "src/pages/configurator/components/SummaryDrawer";
import { useConfiguratorContext } from "src/pages/configurator/Configurator.context";
import { getStepDisabledReasons } from "src/pages/configurator/Configurator.utils";
import { PDFPrintModal } from "src/pages/configuratorPrint/PDFPrintModal";
import { Button, ButtonProps, IconButton, TooltipV2 } from "~components";
import { Css } from "~generated/css";
import { CustomerConfigurationStatus } from "~generated/graphql";
import { currencyFormatter, getProgramDataTotalStrings, useTestIds } from "~utils";

type ConfiguratorStepperProps = {
  actionBts?: ButtonProps[];
};

export function ConfiguratorStepper({ actionBts }: ConfiguratorStepperProps) {
  const location = useLocation();
  const {
    goToNextStep,
    goToPreviousStep,
    currentStep,
    status,
    loading,
    plan,
    totalPriceInCents,
    selectedOptions,
    saveOptions,
    saveAndExit,
    duplicateCurrentConfiguration,
  } = useConfiguratorContext();
  const tid = useTestIds({}, "configurator-stepper");

  const [showReserveFlyout, setShowReserveFlyout] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const { showModal, closeModal } = useModal();

  const disabledReasons = useMemo(() => {
    return getStepDisabledReasons(selectedOptions, plan?.optionTypes || [], currentStep).map(
      ({ disabledReason }) => disabledReason,
    );
  }, [currentStep, plan?.optionTypes, selectedOptions]);

  const isSummaryStep = currentStep.path === "summary";

  if (status === CustomerConfigurationStatus.Expired) {
    return null;
  }

  const totalData = getProgramDataTotalStrings(selectedOptions);

  return (
    <>
      {!isSummaryStep && <SummaryDrawer onClose={() => setShowDrawer(false)} isOpen={showDrawer} />}
      <div css={Css.bgGray800.px3.py2.hPx(72).w100.df.jcsb.asfe.aic.mta.z2.fs0.$}>
        <div css={Css.df.$}>
          {plan && (
            <>
              <div css={Css.df.fdc.gap1.pr6.bGray700.br.$}>
                <div css={Css.header16.white.fw500.$}>{plan.name}</div>
                <div css={Css.df.gap("20px").body12.white.$}>
                  <div>{totalData.bedrooms}</div>
                  <div>{totalData.bathrooms}</div>
                  <div>{totalData.sqft}</div>
                </div>
              </div>

              <div css={Css.df.fdc.gap1.px6.$}>
                <div css={Css.header16.white.fw500.$}>{currencyFormatter(totalPriceInCents)}</div>
                <div
                  onClick={() => !isSummaryStep && setShowDrawer(!showDrawer)}
                  css={Css.df.gap("20px").body12.white.if(!isSummaryStep).underline.cursorPointer.$}
                >
                  Estimated Price
                </div>
              </div>
              {!isSummaryStep && (
                <div css={Css.asc.if(showDrawer).add("transform", "rotate(180deg)").$}>
                  <IconButton
                    size="medium"
                    onClick={() => setShowDrawer(!showDrawer)}
                    icon="arrow-up"
                    {...tid.summary_button}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div css={Css.df.gap2.$}>
          {actionBts ? (
            actionBts.map((props, i) => <Button {...props} key={`stepper-button-${i}`} {...tid.next_button} />)
          ) : (
            <>
              {loading || status === CustomerConfigurationStatus.Draft ? (
                <>
                  {currentStep.sortOrder !== 1 && (
                    <Button
                      size="large"
                      btnFontSize="sm"
                      variant="link"
                      color="white"
                      disabled={loading}
                      onClick={() => {
                        setShowDrawer(false);
                        goToPreviousStep();
                      }}
                      {...tid.back_button}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    outline="gray200"
                    size="large"
                    btnFontSize="sm"
                    variant="link"
                    color="white"
                    onClick={() => {
                      setShowDrawer(false);
                      // FIXME: Confirm that we shouldnt need `status === CustomerConfigurationStatus.Draft` in front of this in case they want to change name/email etc
                      saveAndExit();
                    }}
                    disabled={loading}
                    {...tid.save_and_exit_button}
                  >
                    Save & Exit
                  </Button>
                  {/* If the button is disabled tooltip v2 won't work, it would be awesome if this could be pulled into the Button component */}
                  <span id="stepper-next-btn">
                    <Button
                      variant="secondary"
                      size="large"
                      btnFontSize="sm"
                      disabled={disabledReasons.length > 0}
                      tooltipPlacement="top"
                      onClick={() => {
                        setShowDrawer(false);
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        isSummaryStep ? setShowReserveFlyout(true) : saveOptions(() => goToNextStep());
                      }}
                      isLoading={loading}
                      loadingText="Saving"
                      {...tid[`${isSummaryStep ? "reserve" : "next"}_button`]}
                    >
                      {isSummaryStep ? "Reserve" : "Next"}
                    </Button>
                  </span>
                  {disabledReasons.length > 0 && (
                    <TooltipV2 anchorSelect="#stepper-next-btn">
                      <ul css={Css.add("paddingInlineStart", "24px").add("marginBlock", "0px").$}>
                        {disabledReasons.map((reason) => (
                          <li key={reason}>{reason}</li>
                        ))}
                      </ul>
                    </TooltipV2>
                  )}
                </>
              ) : (
                <>
                  {status === CustomerConfigurationStatus.Reserved && (
                    <Button
                      outline="gray200"
                      size="large"
                      btnFontSize="sm"
                      variant="link"
                      color="white"
                      onClick={async () => {
                        setShowDrawer(false);
                        await duplicateCurrentConfiguration();
                      }}
                      disabled={loading}
                      {...tid.duplicate_build_button}
                    >
                      Duplicate Build
                    </Button>
                  )}
                  <Button
                    variant="secondary"
                    size="large"
                    btnFontSize="sm"
                    {...tid.download_button}
                    onClick={() =>
                      showModal(
                        <PDFPrintModal
                          onClose={closeModal}
                          onContinue={(exports) =>
                            window.open(
                              `/configurations/download/${location.search.replace("?code=", "")}?reports=${exports.join(
                                ",",
                              )}`,
                              "_blank",
                            )
                          }
                        />,
                      )
                    }
                  >
                    Download PDF
                  </Button>
                </>
              )}
            </>
          )}
        </div>
      </div>
      {showReserveFlyout && <ReserveYourBuildFlyout onClose={() => setShowReserveFlyout(false)} />}
    </>
  );
}

import { generatePath } from "react-router-dom";
import slug from "slug";
import { Market, Property } from "src/__generated__/graphql";

// change handling of "/" to be "-" instead of default empty space ""
slug.charmap["/"] = "-";

export const landingPath = "/inventory";
export const inventoryPath = `${landingPath}/:marketSlug`;
export const listingPath = `${inventoryPath}/:propertySlug`;

/**
 * HOP Selections Paths
 * These routes are intended for use within the HOP iFrame only.
 */
export const hopSelectionsPath = `hop-selections/:checkoutConfigurationId`;
// This route is intended to be opened in a new tab from HOP (via the HOP floor plans tab)
export const floorPlanPath = `floor-plan/:checkoutConfigurationId/:floorPlanIndex`;
// This route is intended to be opened in a new tab from HOP (via the HOP design package tab)
export const moodboardPath = `/moodboard/:code`;
export const createMoodboardPath = (code: string) => generatePath(moodboardPath, { code });

export const internalConfiguratorLotsPath = `/configurator/lots`;

export const configuratorPath = `/:marketNameSlug/configurations`;

/**
 * Path Factories/Generators
 */
// Code is only present for internal use while developing and not as part of initial configurator iteration
export function createConfiguratorUrl(m: Pick<Market, "name">, code = "") {
  const path = generatePath(configuratorPath, {
    marketNameSlug: slug(`${m.name}`),
  });
  return `${path}${code ? `?code=${code}` : ""}`;
}

// TODO: Check if react-router has a useURLParams that's better fit for this
export function createNewDuplicateConfiguratorUrl(m: Pick<Market, "name">, code: string) {
  const path = generatePath(configuratorPath, {
    marketNameSlug: slug(`${m.name}`),
  });
  return `${path}?code=${code}&newDuplicate=true`;
}

export function createConfiguratoryUrlFromSlug(marketNameSlug: string, code = "") {
  const path = generatePath(configuratorPath, {
    marketNameSlug,
  });
  return `${path}${code ? `?code=${code}` : ""}`;
}

export function createInventoryUrl(m: Pick<Market, "blueprintMarketId" | "name">) {
  return generatePath(inventoryPath, { marketSlug: slug(`${m.name} ${m.blueprintMarketId}`) });
}

export function createListingUrl(
  m: Pick<Market, "blueprintMarketId" | "name">,
  p: Pick<Property, "streetAddress" | "city" | "state" | "postalCode" | "blueprintProjectId">,
) {
  return generatePath(listingPath, {
    marketSlug: slug(`${m.name} ${m.blueprintMarketId}`),
    propertySlug: slug(`${p.streetAddress} ${p.city} ${p.state} ${p.postalCode} ${p.blueprintProjectId}`),
  });
}

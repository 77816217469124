import { motion } from "framer-motion";
import { Css } from "~generated/css";
import { useTestIds } from "~utils";

type ToggleComponentProps = {
  offLabel: string;
  onLabel: string;
  value: boolean;
  onClick: () => void;
  // disabled?: boolean;
};

export const ToggleComponent = (props: ToggleComponentProps) => {
  const { onLabel, offLabel, value, onClick } = props;
  const tid = useTestIds(props, "toggle");
  return (
    <div onClick={onClick} css={Css.cursorPointer.df.relative.wPx(190).hPx(32).pPx(4).bshBasic.br16.bgWhite.$}>
      <motion.div
        initial={{ x: 0 }}
        animate={{ x: value ? 90 : 0 }}
        css={Css.df.z0.absolute.hPx(24).br16.wPx(90).bgGray800.$}
      />
      <div css={Css.df.w100.relative.z1.jcsb.aic.$} {...tid}>
        <span
          css={Css.wPx(90).tc.body12.lhPx(22).add("transition", "0.2s").if(!value).white.else.gray700.$}
          {...tid.offLabel}
        >
          {offLabel}
        </span>
        <span
          css={Css.wPx(90).tc.body12.lhPx(22).add("transition", "0.2s").if(value).white.else.gray700.$}
          {...tid.onLabel}
        >
          {onLabel}
        </span>
      </div>
    </div>
  );
};

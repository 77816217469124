import { ButtonProps } from "~components";
import { Property } from "~generated/graphql";

type propertyWithCustomization = Pick<Property, "url" | "isCustomizable">;

export function propertyButtonProps(
  { isCustomizable, url }: propertyWithCustomization,
  onClick: () => void,
  showCustomizeButton?: boolean,
): ButtonProps[] {
  const contactUsBtn: ButtonProps = {
    children: "Contact Us",
    onClick,
  };

  const customizeBtn: ButtonProps = {
    children: "Learn about personalization",
    variant: "inline",
    ...{ href: "/personalize-your-home", openInNewTab: true },
  };

  return isCustomizable && showCustomizeButton ? [contactUsBtn, customizeBtn] : [contactUsBtn];
}

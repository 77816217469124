import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { CustomerConfigurationByCodeQuery, useSummaryPrintConfigurationQuery } from "~generated/graphql";
import { DisclaimerReport } from "./components/Disclaimer.report";
import { ExteriorReport } from "./components/Exterior.report";
import { FinishLevelComparisonReport } from "./components/FinishLevelComparison.report";
import { FloorPlanReport } from "./components/FloorPlan.report";
import { InteriorReport } from "./components/Interior.report";
import { StandardsReport } from "./components/Standards.report";
import { SummaryReport } from "./components/Summary.report";

export const PrintSummaryReports = {
  summary: SummaryReport,
  floorPlans: FloorPlanReport,
  exterior: ExteriorReport,
  finishLevelComparison: FinishLevelComparisonReport,
  interior: InteriorReport,
  standards: StandardsReport,
  pricing: null, // this isn't a report page, it toggles pricing on the above reports
};

export type PrintSummaryReportSlugs = keyof typeof PrintSummaryReports;

export function SummaryPrintPage() {
  const { code = "" } = useParams<{ code: string }>();
  const [searchParams] = useSearchParams();

  const reports = (searchParams.get("reports")?.split(",") || []) as PrintSummaryReportSlugs[];
  const shouldDisplayPricing = !!searchParams.get("reports")?.includes("pricing");

  const { data } = useSummaryPrintConfigurationQuery({
    variables: { code },
    skip: !code,
  });

  if (!code) {
    return <div>Missing code</div>;
  }

  if (data?.customerConfiguration && !data?.customerConfiguration?.reservedAt) {
    return <div>Config must be reserved to print things.</div>;
  }

  return data ? (
    <SummaryPrintPageView data={data} reports={reports} shouldDisplayPricing={shouldDisplayPricing} />
  ) : null;
}

type SummaryPrintPageViewProps = {
  data: CustomerConfigurationByCodeQuery;
  reports: PrintSummaryReportSlugs[];
  // This is just used for testing
  print?: boolean;
};

export function SummaryPrintPageView({
  data,
  reports,
  shouldDisplayPricing,
  print = true,
}: SummaryPrintPageViewProps & { shouldDisplayPricing: boolean }) {
  useEffect(() => {
    setTimeout(() => {
      if (print) {
        window.print();
      }
    }, 1200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {reports.map((report) => {
        const Report = PrintSummaryReports[report];
        return Report && <Report data={data} key={report} shouldDisplayPricing={shouldDisplayPricing} />;
      })}
      <DisclaimerReport data={data} />
    </>
  );
}

import { ReactNode } from "react";
import { Css } from "~generated/css";

type ImageDisclaimerProps = {
  title?: ReactNode;
  // When present will use Css.ifContainer() to hide disclaimer based on ancestor
  ltHide?: number;
};
export const ImageDisclaimer = ({
  title = "All images, design layouts, and renderings are intended for illustrative purposes only and may be subject to change",
  ltHide = 0,
}: ImageDisclaimerProps) => (
  <div
    css={{
      ...Css.absolute.z1
        .w("max-content")
        .maxw("calc(100% - 32px)") // 32px from px2
        .bottomPx(24)
        .left("50%")
        .add("transform", "translate(-50%)")
        .br4.py1.px2.bgColor("rgba(246, 246, 240, 0.8)").$, // translucent `OffWhite`
      ...Css.ifContainer({ lt: ltHide }).display("none").$,
    }}
  >
    <span css={Css.tc.df.body12.lhPx(20).black.add("fontStyle", "italic").$}>{title}</span>
  </div>
);

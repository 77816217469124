import { motion, Variants } from "framer-motion";
import { Icon } from "src/components/atoms/Icon";
import { Css, Palette } from "~generated/css";
import { useTestIds } from "~utils";

type RadioProps = {
  selected: boolean;
  onClick?: () => void;
  disabled?: boolean | string;
  variant?: "primary" | "secondary";
  // Will add an `ifContainer` to css to reduce size of radio from 24px -> 20px
  containerBreakpoint?: number;
};

const radioPrimaryVariants: Variants = {
  selected: (disabled?: boolean) => ({
    backgroundColor: disabled ? Palette.Gray400 : Palette.Gray800,
    transition: {
      duration: 0.2,
    },
  }),
  unselected: (disabled?: boolean) => ({
    backgroundColor: disabled ? Palette.Gray200 : Palette.White,
    transition: {
      duration: 0.2,
    },
  }),
};

const radioSecondaryVariants: Variants = {
  selected: (disabled?: boolean) => ({
    backgroundColor: disabled ? Palette.Gray300 : Palette.White,
    transition: {
      duration: 0.2,
    },
  }),
  unselected: (disabled?: boolean) => ({
    backgroundColor: disabled ? Palette.Gray400 : Palette.White,
    transition: {
      duration: 0.2,
    },
  }),
};

// TODO: We can make this more A11Y friendly. RN its not actually a `radio`
export function Radio(props: RadioProps) {
  const { selected, onClick, disabled, variant = "primary", containerBreakpoint } = props;

  const tid = useTestIds(props, "radio-checkbox");
  const isPrimary = variant === "primary";

  return (
    <motion.button
      {...tid}
      type="button"
      onClick={() => onClick?.()}
      custom={disabled}
      variants={isPrimary ? radioPrimaryVariants : radioSecondaryVariants}
      initial={false}
      animate={selected ? "selected" : "unselected"}
      disabled={!!disabled}
      css={{
        ...Css.df.jcc.aic.wPx(24).hPx(24).br100.ba.bGray800.if(!!disabled).bGray400.$,
        ...(containerBreakpoint && Css.ifContainer({ lt: containerBreakpoint }).wPx(20).hPx(20).$),
      }}
    >
      <motion.span
        initial={false}
        variants={{
          selected: {
            opacity: 1,
            transition: {
              duration: 0.2,
            },
          },
          unselected: {
            opacity: 0,
            transition: {
              duration: 0.2,
            },
          },
        }}
      >
        <Icon name={isPrimary ? "checkmark-white" : "checkmark-black"} />
      </motion.span>
    </motion.button>
  );
}

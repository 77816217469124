import { useParams } from "react-router-dom";
import { Button } from "~components";
import { Css } from "~generated/css";
import { useCustomerConfigurationByCodeQuery } from "~generated/graphql";
import { ImageDisplay } from "../configurator/components";

// single page to display a floor plan image like a modal
// meant to be opened in a new tab from HOP

export function FloorPlanPage({ onDownloadClick }: { onDownloadClick?: () => void | undefined }) {
  const { checkoutConfigurationId, floorPlanIndex } = useParams();

  const { data, loading } = useCustomerConfigurationByCodeQuery({
    variables: { code: checkoutConfigurationId || "" },
    skip: !checkoutConfigurationId,
  });

  // need config code and floor plan index
  const floorplans = data?.customerConfiguration?.plan?.floorPlans || [];
  const planOptions = data?.customerConfiguration?.options.map((option) => option.option.optionCode) || [];
  const isReversed = !!data?.customerConfiguration?.isReversed;

  const floorplan = floorplans[Number(floorPlanIndex)];

  if (loading || !floorplan) {
    return null;
  }

  if (data?.customerConfiguration && !data?.customerConfiguration?.reservedAt) {
    return <div>Config must be reserved to view floor plan.</div>;
  }

  return (
    <div css={Css.df.fdc.overflowHidden.vh100.$}>
      <div
        css={
          Css.df.fdr
            .position("sticky")
            .z1.px("40px")
            .py("32px")
            .add("justifyContent", "space-between")
            .add("alignItems", "center").bgWhite.$
        }
      >
        {floorplan.name}
        <div>
          <Button
            size="small"
            variant="link"
            onClick={() => {
              onDownloadClick ? onDownloadClick() : window.print();
            }}
          >
            DOWNLOAD
          </Button>
        </div>
      </div>
      <div css={Css.h("calc(100vh - 72px)").w100.$}>
        <ImageDisplay
          images={[{ ...floorplan, name: "" }]}
          displayingDynamicSvgs
          selectedOptionCodes={planOptions}
          isReversed={isReversed}
          shrinkWidthToFit
        />
      </div>
      <div css={Css.absolute.bottom5.w100.body14.fw400.tc.$}>
        *Exact Floor Plan(s) may vary from what is shown due to developments during the construction process.
      </div>
    </div>
  );
}

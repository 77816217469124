import { Icon, Modal } from "~components";
import { Css } from "~generated/css";

export type WarningModalProps = {
  onClose: () => void;
  onContinue: () => void;
};

export function WarningModal(props: WarningModalProps) {
  const { onClose, onContinue } = props;
  return (
    <Modal
      size={{ width: "696px", height: "376" }}
      title={
        <div css={Css.df.jcfs.gap2.pb4.$}>
          <Icon name="warning-icon-large" />
          <span css={Css.header32.$}>Are you sure?</span>
        </div>
      }
      description={
        <div css={Css.body18.fw300.lhPx(28).wPx(568).$}>
          It looks like you've already made some selections beyond this step. Changing your selection here requires you
          to re-select those options because prices may be affected. Are you sure you want to continue?
        </div>
      }
      actionBtns={[
        { variant: "link", children: "Cancel", onClick: onClose },
        {
          children: "Continue",
          onClick: () => {
            onContinue();
            onClose();
          },
        },
      ]}
      isOpen
      close={onClose}
    ></Modal>
  );
}

import { useParams } from "react-router-dom";
import { HBLoadingSpinner } from "~components";
import { Breakpoints, Css } from "~generated/css";
import { useContractedCustomerConfigurationByCodeQuery } from "~generated/graphql";
import { FloorPlansTab, HopTabs, MoodboardTab, YourHomeTab } from "./components";

export function HopSelectionsPage() {
  const { checkoutConfigurationId } = useParams();
  const code = checkoutConfigurationId || "";
  const { data, loading, error } = useContractedCustomerConfigurationByCodeQuery({
    variables: { code },
  });

  if (!loading && error) {
    return (
      <div css={Css.df.fdc.aic.jcc.aic.gap2.overflowHidden.h("100vh").$}>
        Something went wrong. Please try again later.
      </div>
    );
  }

  if (loading || !data) {
    return (
      <div css={Css.df.fdc.fg1.h("100vh").$}>
        <HBLoadingSpinner />;
      </div>
    );
  }

  if (data.customerConfiguration && !data.customerConfiguration.reservedAt) {
    return (
      <div css={Css.df.fdc.aic.jcc.aic.gap2.overflowHidden.h("100vh").$}>
        <div>Config must be reserved to view HOP selections.</div>;
      </div>
    );
  }

  const floorPlans = data.customerConfiguration?.plan?.floorPlans || [];
  const planOptions = data.customerConfiguration?.options.map((option) => option.option.optionCode) || [];
  const isReversed = !!data.customerConfiguration?.isReversed;

  // These breakpoints are copied from PageLayout/PageContent in HOP
  return (
    <div
      css={{
        ...Css.dg.container().$,
        [Breakpoints.max]: Css.gtc("repeat(12, 1fr)").gap3.mxPx(120).$,
        [Breakpoints.xl]: Css.gtc("repeat(12, 1fr)").gap3.mxPx(100).$,
        [Breakpoints.lg]: Css.gtc("repeat(12, 1fr)").gap3.mxPx(80).$,
        [Breakpoints.md]: Css.gtc("repeat(12, 1fr)").gap2.mx8.$,
        [Breakpoints.sm]: Css.gtc("repeat(8, 1fr)").gap2.mx6.$,
        [Breakpoints.xs]: Css.gtc("repeat(4, 1fr)").gap2.mx3.$,
      }}
    >
      <div
        css={{
          ...Css.gc("1 / -1").$,
          [Breakpoints.lgAndUp]: Css.gc("3 / -3").$,
          [Breakpoints.md]: Css.gc("2 / -2").$,
        }}
      >
        <div css={Css.mtPx(60).mb4.header24.ifXs.mt6.mt3.$}>Selections</div>
        <div css={Css.df.fdc.fg1.$}>
          <HopTabs
            tabs={[
              {
                tabName: "Your Home",
                tabContent: <YourHomeTab customerConfiguration={data.customerConfiguration} />,
              },
              {
                tabName: "Floor Plans",
                tabContent: (
                  <FloorPlansTab
                    configCode={code}
                    floorPlans={floorPlans}
                    planOptions={planOptions}
                    isReversed={isReversed}
                  />
                ),
              },
              {
                tabName: "Moodboard ",
                tabContent: <MoodboardTab customerConfiguration={data.customerConfiguration} />,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

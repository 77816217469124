import { RefObject, useEffect, useMemo, useState } from "react";

interface InteractionObserverOptionsProps {
  root?: Element;
  rootMargin?: string;
  threshold?: number | number[];
}

// Uses IntersectionObserver to check if an element is visible within the dom and returns a boolean
export function useElementVisible(elementRef: RefObject<HTMLElement>, options: InteractionObserverOptionsProps) {
  const { root = undefined, rootMargin = "0px 0px 0px 0px", threshold = 0 } = options;
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), { root, rootMargin, threshold }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elementRef],
  );

  useEffect(() => {
    elementRef?.current && observer.observe(elementRef.current);
    return () => observer.disconnect();
    // Excluding elementRef as observer already watches
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observer]);

  return isIntersecting;
}

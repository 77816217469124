import React, { ReactNode, useState } from "react";
import { Button, Tag } from "~components";
import { Css, Palette } from "~generated/css";
import { ConfiguratorOptionFragment, OptionImageType } from "~generated/graphql";
import { currencyFormatter, useTestIds } from "~utils";
import { SpecDetails } from "../SpecDetails";
import { ModalWrapper } from "./ModalWrapper";

export function FinishLevelComparisonContent(props: {
  finishLevel: ConfiguratorOptionFragment | undefined;
  options: ConfiguratorOptionFragment[];
}) {
  const { finishLevel, options } = props;
  const tid = useTestIds(props, "finish-level-comparison-content");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedFinishLevel, setSelectedFinishLevel] = useState<ConfiguratorOptionFragment | undefined>();
  const moodBoardImage = selectedFinishLevel?.images.find((i) => i.type.code === OptionImageType.Moodboard);

  function openModal(finishLevel: "Essential" | "Deluxe" | "Premium") {
    setSelectedFinishLevel(options.find((option) => option.name === finishLevel));
    setShowModal(true);
  }

  const deluxeExists = !!options.find((o) => o.name === "Deluxe");

  const deluxePrice = options.find((o) => o.name === "Deluxe")?.priceInCents;
  const premiumPrice = options.find((o) => o.name === "Premium")?.priceInCents;

  return (
    <>
      <div
        css={{
          ...Css.dg
            .gtc("1fr 1fr 1fr 1fr")
            .add("columnGap", "16px")
            .add("alignItems", "flex-start")
            // highlight border of currently chosen finish level
            .pt1.pb7.if(deluxeExists && finishLevel?.name.toLowerCase() === "essential")
            .addIn("> div:nth-of-type(4n + 2)", Css.bBlack.$)
            .if(deluxeExists && finishLevel?.name.toLowerCase() === "deluxe")
            .addIn("> div:nth-of-type(4n + 3)", Css.bBlack.$)
            .if(deluxeExists && finishLevel?.name.toLowerCase() === "premium")
            .addIn("> div:nth-of-type(4n + 4)", Css.bBlack.$).$,
          // additions for when deluxe does not exist
          ...Css.if(!deluxeExists)
            .gtc("1fr 1fr 1fr")
            .if(!deluxeExists && finishLevel?.name.toLowerCase() === "essential")
            .addIn("> div:nth-of-type(3n + 2)", Css.bBlack.$)
            .if(!deluxeExists && finishLevel?.name.toLowerCase() === "premium")
            .addIn("> div:nth-of-type(3n + 3)", Css.bBlack.$).$,
        }}
        {...tid}
      >
        {/* row 1 */}
        <div></div>
        <div
          css={
            Css.header20.fw500.bt.bl.br.bGray200
              .blc(Palette.Gray200)
              .brc(Palette.Gray200)
              .pt4.pb1.px2.add("borderTopLeftRadius", "8px")
              .add("borderTopRightRadius", "8px")
              .bgWhite.tc.lhPx(32).$
          }
        >
          Essential
        </div>
        {deluxeExists && (
          <div
            css={
              Css.relative.header20.fw500.bt.bl.br.bGray200
                .blc(Palette.Gray200)
                .brc(Palette.Gray200)
                .pt4.pb1.px2.add("borderTopLeftRadius", "8px")
                .add("borderTopRightRadius", "8px")
                .bgWhite.tc.lhPx(32).$
            }
          >
            Deluxe
            <div css={Css.absolute.top("-15px").left0.right0.tc.$}>
              <Tag backgroundColor="#DBEAFE">Most Selected</Tag>
            </div>
          </div>
        )}
        <div
          css={
            Css.header20.fw500.bt.bl.br.bGray200
              .blc(Palette.Gray200)
              .brc(Palette.Gray200)
              .pt4.pb1.px2.add("borderTopLeftRadius", "8px")
              .add("borderTopRightRadius", "8px")
              .bgWhite.tc.lhPx(32).$
          }
        >
          Premium
        </div>
        {/* row 2 */}
        <div></div>
        <div css={Css.fsPx(16).lhPx(24).fw300.bl.br.bGray200.gray700.py1.px2.bgWhite.tc.$}>Included in base price</div>
        {deluxeExists && (
          <div css={Css.fsPx(16).lhPx(24).fw300.bl.br.bGray200.gray700.py1.px2.bgWhite.tc.$}>
            {deluxePrice && currencyFormatter(deluxePrice)}
          </div>
        )}
        <div css={Css.fsPx(16).lhPx(24).fw300.bl.br.bGray200.gray700.py1.px2.bgWhite.tc.$}>
          {premiumPrice && currencyFormatter(premiumPrice)}
        </div>
        {/* row 3 */}
        <div></div>
        <div css={Css.fsPx(14).fw500.bl.br.bGray200.py1.px2.bgWhite.tc.$}>
          <Button variant="inline" onClick={() => openModal("Essential")}>
            View mood board
          </Button>
        </div>
        {deluxeExists && (
          <div css={Css.fsPx(14).fw500.bl.br.bGray200.py1.px2.bgWhite.tc.$}>
            <Button variant="inline" onClick={() => openModal("Deluxe")}>
              View mood board
            </Button>
          </div>
        )}
        <div css={Css.fsPx(14).fw500.bl.br.bGray200.py1.px2.bgWhite.tc.$}>
          <Button variant="inline" onClick={() => openModal("Premium")}>
            View mood board
          </Button>
        </div>
        {SpecDetails.map((specDetail, i) => {
          // if spec detial does not have children, return empty fragment
          if (!specDetail.children) {
            return <></>;
          } else {
            return (
              <React.Fragment key={specDetail.title}>
                {/* row 3 */}
                <Header text="Whole House" />
                <CellEmpty />
                {deluxeExists && <CellEmpty />}
                <CellEmpty />
                {specDetail.children.map((child) => {
                  if (!child.children) {
                    return (
                      <React.Fragment key={child.title}>
                        <div css={Css.fsPx(16).fw500.py1.$}>
                          {child.title}
                          {child.disclaimer && <sup>*</sup>}
                          {child.disclaimer && (
                            <div css={Css.fsPx(12).pb1.fw300.pt1.$}>
                              <sup>*</sup>
                              {child.disclaimer}
                            </div>
                          )}
                        </div>
                        {child.essential ? (
                          child.essential.length === 1 ? (
                            <Cell>{child.essential}</Cell>
                          ) : (
                            <CellList itemList={child.essential} />
                          )
                        ) : (
                          <CellEmpty />
                        )}
                        {deluxeExists &&
                          (child.deluxe ? (
                            child.deluxe.length === 1 ? (
                              <Cell>{child.deluxe}</Cell>
                            ) : (
                              <CellList itemList={child.deluxe} />
                            )
                          ) : (
                            <CellEmpty />
                          ))}
                        {child.premium ? (
                          child.premium.length === 1 ? (
                            <Cell>{child.premium}</Cell>
                          ) : (
                            <CellList itemList={child.premium} />
                          )
                        ) : (
                          <CellEmpty />
                        )}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <React.Fragment key={child.title}>
                        <div css={Css.fsPx(16).fw500.py1.$}>
                          {child.title}
                          {child.disclaimer && <div css={Css.fsPx(9).fw300.pt1.$}>{child.disclaimer}</div>}
                        </div>
                        <CellEmpty />
                        {deluxeExists && <CellEmpty />}
                        <CellEmpty />
                        {child.children.map((grandChild) => {
                          return (
                            <React.Fragment key={grandChild.title}>
                              <SmallHeader text={grandChild.title} />
                              <Cell>{grandChild.essential}</Cell>
                              {deluxeExists && <Cell>{grandChild.deluxe}</Cell>}
                              <Cell>{grandChild.premium}</Cell>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  }
                })}
              </React.Fragment>
            );
          }
        })}
        <div></div>
        <div
          css={
            Css.bb.bGray200.bl.br
              .blc(Palette.Gray200)
              .brc(Palette.Gray200)
              .h100.pt4.bgWhite.add("borderBottomLeftRadius", "8px")
              .add("borderBottomRightRadius", "8px").$
          }
        >
          &nbsp;
        </div>
        {deluxeExists && (
          <div
            css={
              Css.bb.bGray200.bl.br
                .blc(Palette.Gray200)
                .brc(Palette.Gray200)
                .h100.pt4.bgWhite.add("borderBottomLeftRadius", "8px")
                .add("borderBottomRightRadius", "8px").$
            }
          >
            &nbsp;
          </div>
        )}
        <div
          css={
            Css.bb.bGray200.bl.br
              .blc(Palette.Gray200)
              .brc(Palette.Gray200)
              .h100.pt4.bgWhite.add("borderBottomLeftRadius", "8px")
              .add("borderBottomRightRadius", "8px").$
          }
        >
          &nbsp;
        </div>
      </div>
      {/* Modals */}
      <ModalWrapper
        isOpen={showModal}
        close={() => setShowModal(false)}
        bg={Palette.White}
        {...tid.modalWrapper_moodBoard}
      >
        <div css={Css.maxh("90%").$}>
          <img css={Css.w100.h100.objectContain.$} src={moodBoardImage?.imageUrl} alt={moodBoardImage?.name} />
        </div>
      </ModalWrapper>
    </>
  );
}

function Header({ text }: { text: string }) {
  return <div css={Css.header20.py2.h100.$}>{text}</div>;
}

function SubHeader({ text }: { text: string }) {
  return <div css={Css.fsPx(16).fw500.bb.bGray200.py1.h100.$}>{text}</div>;
}

function SmallHeader({ text }: { text: string }) {
  return <div css={Css.fsPx(14).fw500.bb.bGray200.py1.gray700.h100.$}>{text}</div>;
}
function Cell({ children }: { children: ReactNode }) {
  return (
    <div
      css={
        Css.relative.h100.bgWhite
          .fsPx(14)
          .fw300.bl.br.bGray200.blc(Palette.Gray200)
          .brc(Palette.Gray200)
          .py1.px2.tc.addIn(
            "&::after",
            Css.add("content", `""`).add(Css.hPx(1).w("80%").absolute.mla.mra.bottom0.left0.right0.bgGray200.$).$,
          ).$
      }
    >
      {children}
    </div>
  );
}

function CellEmpty() {
  return <div css={Css.bl.br.blc(Palette.Gray200).brc(Palette.Gray200).h100.bgWhite.$}>&nbsp;</div>;
}

function CellList({ itemList }: { itemList: string[] }) {
  return (
    <Cell>
      <ul css={Css.listReset.$}>
        {itemList.map((il) => (
          <li key={il} css={Css.mb1.$}>
            {il}
          </li>
        ))}
      </ul>
    </Cell>
  );
}

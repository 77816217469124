import { css as stitchesCss, VariantProps } from "@stitches/react";
import { Css } from "~generated/css";
import { useTestIds } from "~utils";

const style = stitchesCss("div", {
  // mini reset
  appearance: "none",
  border: "none",
  backgroundColor: "transparent",
  // The Truss we know and love
  ...Css.br4.px1.pyPx(2).body12.fw400.dib.tc.ttu.z3.$,

  variants: {
    size: {
      sm: Css.lhPx(14).fw500.gray800.pyPx(2).$,
      med: Css.lhPx(14).fw500.gray800.ttl.pyPx(5).$,
      large: Css.pxPx(12).pyPx(5).$,
    },
  },

  defaultVariants: {
    size: "large",
  },
});

// Add the Variant Props AND other props
export type TagProps = VariantProps<typeof style> & {
  backgroundColor?: string;
  children: string;
};

export function Tag(props: TagProps) {
  const { children, backgroundColor, size } = props;
  const tid = useTestIds(props, "tag");
  // styles are passed in via the 'className'
  return (
    <div className={style({ size })} {...tid} css={backgroundColor ? Css.bgColor(backgroundColor).$ : undefined}>
      {children}
    </div>
  );
}

import { motion } from "framer-motion";
import { Css } from "~generated/css";
import { useTestIds } from "~utils";

/**
 * Framer Motion Variants
 *
 * These define a key/animations pair which is used to animate many components
 * using the same keys.
 */
const shadowVariants = {
  hover: { scale: 1.2 },
};
const circleVariants = {
  hover: Css.bgOrange400.$,
};

type MapMarkerProps = {
  isActive?: boolean;
  onClick?: () => void;
  onHoverStart?: () => void;
  onHoverEnd?: () => void;
  testId?: string;
};
/**
 * Circular component which is used to represent a point of interest on the map.
 * When hovered, the marker will grow in size and change color. This can be used
 * as inspiration for a custom marker.
 */
export function MapMarker(props: MapMarkerProps) {
  const { isActive, onClick, onHoverStart, onHoverEnd } = props;
  const tid = useTestIds(props, "mapMarker");

  // Conditionally render a `div` or `button` based on whether the marker is
  // interactive.
  const Wrapper = onClick ? motion.button : motion.div;

  return (
    // This is enabling the `hover` variant which all children with `variants`
    // will attempt to animate.
    <Wrapper
      {...tid}
      whileHover="hover"
      animate={isActive ? "hover" : ""}
      onClick={() => onClick?.()}
      onHoverStart={() => onHoverStart?.()}
      onHoverEnd={() => onHoverEnd?.()}
    >
      {/* Shadow */}
      <motion.div
        css={Css.bgColor("rgba(53, 53, 53, .2)").br100.hPx(50).wPx(50).df.aic.jcc.cursorPointer.$}
        variants={shadowVariants}
      >
        {/* White circle */}
        <div css={Css.bgWhite.br100.h50.w50.df.aic.jcc.$}>
          {/* Inner coloured circle */}
          <motion.div css={Css.bgGray800.br100.h50.w50.$} variants={circleVariants} />
        </div>
      </motion.div>
    </Wrapper>
  );
}

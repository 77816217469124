import { useMemo, useState } from "react";
import {
  FinishLevelComparisonContent,
  ImageDisplay,
  ModalWrapper,
  ModalWrapperTabs,
  StepWrapper,
  WhatsIncludedContent,
} from "src/pages/configurator/components";
import { OptionListContainer, OptionListItem } from "src/pages/configurator/components/options";
import { useConfiguratorContext } from "src/pages/configurator/Configurator.context";
import {
  getReservedOptionPrice,
  useMemoizedConflictsByConflictingOptionCode,
} from "src/pages/configurator/Configurator.utils";
import { AccordionOptionCard } from "~components";
import { Css, Palette } from "~generated/css";
import { OptionImageType } from "~generated/graphql";
import { defaultTestId, useTestIds } from "~utils";

const topStepOptionType = "Design Package";

export function InteriorStep() {
  const {
    loading,
    availableOptionsByOptionType,
    toggleOption,
    selectedOptions,
    optionConflictsByOptionCode,
    currentStep,
  } = useConfiguratorContext();
  const tid = useTestIds({}, "interior-step");

  // compare spec level / mood board modal
  const [showModal, setShowModal] = useState<boolean>(false);

  const [allOptions, topOptions] = useMemo(() => {
    // first filter to the only option types that should render on this step
    const filteredOptionTypes = availableOptionsByOptionType.filter((ot) => currentStep.optionTypes.includes(ot.name));
    // return the options for all steps and filter to the top step option type
    return [
      filteredOptionTypes.flatMap((optionType) => optionType.options),
      availableOptionsByOptionType.find((option) => option.name === topStepOptionType)?.options || [],
    ];
  }, [availableOptionsByOptionType, currentStep.optionTypes]);

  const selectedOptionIds = useMemo(() => selectedOptions.map((option) => option.option.id), [selectedOptions]);
  const selectedTopOption = useMemo(
    () => topOptions.find((o) => selectedOptions.map((so) => so.option.id).includes(o.id)),
    [topOptions, selectedOptions],
  );

  const selectedTopChildren = useMemo(
    () =>
      selectedTopOption?.options.map((o) => allOptions.find((option) => option.id === o.id)!).filter((o) => !!o) || [],
    [allOptions, selectedTopOption?.options],
  );

  const selectedFinishLevel = selectedTopChildren.find((o) => selectedOptionIds.includes(o.id));

  const images = useMemo(
    () =>
      selectedOptions
        .filter((o) => o.option.planOptionType.name === "Spec Level")
        .flatMap((o) => o.option.images)
        .filter((i) => i.type.code === OptionImageType.Display),
    [selectedOptions],
  );

  const soConflictsByConflictingOptionCode = useMemoizedConflictsByConflictingOptionCode(
    selectedOptions,
    optionConflictsByOptionCode,
  );

  return (
    <>
      <StepWrapper
        loading={loading}
        title="Interior Package"
        description="Select your design scheme and finish level. You will be able to swap individual finishes later."
        left={
          <ImageDisplay
            images={images}
            title="Explore Rooms"
            disclaimerOverride={
              <span>
                <span css={Css.fw500.$}>Please note</span>: Interior renderings only shown in the Daniel plan. All
                images, design layouts, and renderings are intended for illustrative purposes only and may be subject to
                change.
              </span>
            }
            {...tid.imageDisplay}
          />
        }
        right={
          <>
            <AccordionOptionCard
              title="Design scheme"
              children={
                <OptionListContainer>
                  {topOptions.map((option, index) => {
                    const { id, optionCode, name } = option;
                    const conflictData = soConflictsByConflictingOptionCode[optionCode];
                    return (
                      <OptionListItem
                        isRadio
                        key={name}
                        optionId={id}
                        conflict={conflictData}
                        title={name}
                        description={option.description}
                        selected={selectedOptionIds.includes(id)}
                        onClick={() => toggleOption(option)}
                        priceInCents=""
                        {...tid[`optionListItem_${defaultTestId(name)}`]}
                      />
                    );
                  })}
                </OptionListContainer>
              }
              {...tid.accordionOptionCard}
            />
            {selectedTopOption ? (
              <AccordionOptionCard
                title="Finish level"
                titleAction={{
                  triggerText: "Compare",
                  onClick: () => setShowModal(true),
                }}
                children={
                  <OptionListContainer>
                    {selectedTopChildren.map((option, index) => {
                      const { id, optionCode, name, priceInCents: planOptionPriceInCents } = option;
                      const conflictData = soConflictsByConflictingOptionCode[optionCode];
                      const selected = selectedOptionIds.includes(id);

                      const displayPriceInCents = selected
                        ? getReservedOptionPrice(selectedOptions, id)
                        : planOptionPriceInCents;

                      return (
                        <OptionListItem
                          isRadio
                          key={name}
                          optionId={id}
                          conflict={conflictData}
                          title={name}
                          description={option.description}
                          selected={selected}
                          onClick={() => toggleOption(option)}
                          priceInCents={displayPriceInCents}
                          {...tid[`optionListItem_${defaultTestId(name)}`]}
                        />
                      );
                    })}
                  </OptionListContainer>
                }
                {...tid.accordionOptionCard}
              />
            ) : null}
          </>
        }
      />
      {/* Modals */}
      <ModalWrapper
        isOpen={showModal}
        close={() => setShowModal(false)}
        bg={Palette.OffWhite}
        {...tid.modalWrapper_finishLevelComparison}
      >
        <>
          <div css={Css.mb5.px7.$}>
            <h4 css={Css.header32.lhPx(48).$}>Compare Finish Levels</h4>
          </div>
          <div css={Css.px7.$}>
            <ModalWrapperTabs
              currentTabName="Compare Finish Levels"
              tabs={[
                {
                  tabName: "Compare Finish Levels",
                  tabContent: (
                    <FinishLevelComparisonContent finishLevel={selectedFinishLevel} options={selectedTopChildren} />
                  ),
                },
                { tabName: "Homebound Standard", tabContent: <WhatsIncludedContent hideTitle /> },
              ]}
            />
          </div>
        </>
      </ModalWrapper>
    </>
  );
}

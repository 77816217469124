import { useEffect, useRef, useState } from "react";

/**
 * Given a container ref, this hook will return the current breakpoint based on
 * the container's width.
 *
 * @example
 * ```
 *  const { ref, breakpoint } = useContainerQuery({
 *    mobile: [0, 390], // [min, max], inclusive min and inclusive max
 *    desktop: [391], // [min], inclusive and no max
 *  });
 *
 *  console.log(breakpoint.mobile);  // true || false
 *  console.log(breakpoint.desktop); // true || false
 *
 *  return <div ref={ref} />
 * ```
 */
export function useContainerBreakpoints<
  T extends HTMLElement = HTMLElement,
  Breakpoints extends Record<string, [number, number] | [number]> = Record<string, [number, number] | [number]>,
>(
  breakpoints: Breakpoints,
): {
  ref: React.RefObject<T>;
  breakpoint: Record<keyof Breakpoints, boolean>;
} {
  // Create a ref to track the containers width which will be passed to the caller
  const ref = useRef<T>(null);

  // Creating the return object and initializing all breakpoints to false.
  // This is done in a ref so that it's only initialized once and can be used in
  // the useEffect below.
  const initialState = useRef<Record<keyof Breakpoints, boolean>>(
    Object.keys(breakpoints).reduce(
      (acc, key: keyof Breakpoints) => {
        acc[key] = false;
        return acc;
      },
      {} as Record<keyof Breakpoints, boolean>,
    ),
  ).current;
  const [breakpoint, setBreakpoint] = useState<Record<keyof Breakpoints, boolean>>(initialState);

  // Triggers when the container ref is set.
  useEffect(() => {
    const container = ref.current;
    if (!container) return; // Bail if it's nulled out

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];

      const width = getContainerWidth(entry);

      // Find the first breakpoint that matches the width
      for (const [breakpoint, [min, max]] of Object.entries(breakpoints)) {
        // If the max is defined, we check for an inclusive range
        if (max !== undefined) {
          if (min <= width && width <= max) {
            setBreakpoint({ ...initialState, [breakpoint]: true });
            break; // Breaking so that we don't set multiple breakpoints to true
          }
        }
        // If the max is undefined, we check for a min width inclusive with no max
        else {
          if (min <= width) {
            setBreakpoint({ ...initialState, [breakpoint]: true });
            break; // Breaking so that we don't set multiple breakpoints to true
          }
        }
      }
    });

    // Observe the container (this will be called anytime the container's width changes)
    resizeObserver.observe(container);

    // When unmounting, disconnect the observer
    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return { ref, breakpoint };
}

/**
 * Helper to deal with compatibility issues.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry
 *
 * Not all browser versions support borderBoxSize, so we fallback to contentRect.
 * If that fails the browser version is too old and is not supported.
 */
function getContainerWidth(entry: ResizeObserverEntry) {
  const { borderBoxSize, contentRect } = entry;

  if (borderBoxSize) {
    return borderBoxSize[0].inlineSize;
  } else if (contentRect) {
    return contentRect.width;
  } else {
    throw new Error("Unsupported browser version when attempting to useContainerBreakpoint");
  }
}

// TODO: Update useContainerBreakpoints to support height breakpoints
export function getContainerHeight(entry: ResizeObserverEntry) {
  const { borderBoxSize, contentRect } = entry;

  if (borderBoxSize) {
    return borderBoxSize[0].blockSize;
  } else if (contentRect) {
    return contentRect.height;
  } else {
    throw new Error("Unsupported browser version when attempting for use with resizeObserver");
  }
}

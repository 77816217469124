import { priceHiddenTags } from "src/pages/inventory/Inventory.utils";
import { propertyButtonProps } from "src/utils/property.utils";
import { Button, Icon, Tag } from "~components";
import { Css } from "~generated/css";
import { SummaryPropertyFragment } from "~generated/graphql";
import { currencyFormatter, defaultTestId, TestIds, useContainerBreakpoints, useTestIds } from "~utils";
import { openAboutThisHomeGallery } from "./AboutThisHome";

/** Gather common props for both mobile and desktop hero views */
type HeroViewProps = {
  /** Elements with render logic we will always show */
  ctaBtns: JSX.Element[];
  heroImgElement: JSX.Element;
  propertyTag: JSX.Element;
  /** Formatted strings we will always show */
  formattedOfferPrice: string;
  formattedSqft: string;
  formattedBedrooms: string;
  formattedBathrooms: string;
  /** Strings that display conditionally */
  formattedGarageAttached: false | string;
  formattedGaragePort: false | string;
  /** Property address is formatted differently in each view */
} & Pick<SummaryPropertyFragment, "streetAddress" | "city" | "state" | "postalCode">;

/** Summary Page Hero renders one of two distinct components at 1024 breakpoint */
export function Hero(props: { property: SummaryPropertyFragment; showContactUs: () => void }) {
  const { ref, breakpoint } = useContainerBreakpoints<HTMLDivElement>({
    mobile: [0, 1024],
    desktop: [1025],
  });
  const tid = useTestIds(props, "hero");

  return (
    <div ref={ref} css={{ ...Css.container().$, ...Css.relative.if(breakpoint.desktop).mbPx(116).$ }} {...tid}>
      {breakpoint.desktop ? (
        <DesktopHero
          {...tid.desktop}
          {...gatherCommonViewProps({ ...props, showCustomize: true, testId: { ...tid.desktop } })}
        />
      ) : (
        <MobileHero
          {...tid.mobile}
          {...gatherCommonViewProps({ ...props, showCustomize: false, testId: { ...tid.mobile } })}
        />
      )}
    </div>
  );
}

const AMENITY_FEATURE_BP = 1188;
function DesktopHero(props: HeroViewProps) {
  const {
    formattedGarageAttached,
    ctaBtns,
    heroImgElement,
    formattedOfferPrice,
    formattedSqft,
    formattedBedrooms,
    formattedGaragePort,
    propertyTag,
    formattedBathrooms,
    city,
    postalCode,
    state,
    streetAddress,
  } = props;
  const tid = useTestIds(props, "desktop");

  return (
    <>
      {/* Hero Image */}
      <div css={Css.pr("25%").ma.relative.z2.$}>{heroImgElement}</div>
      {/* Right Panel */}
      <div css={Css.absolute.rightPx(80).bottomPx(-56).bgGray50.maxwPx(520).plPx(60).pyPx(100).pr4.z3.$}>
        <div>
          <div>{propertyTag}</div>
        </div>

        <h1 css={Css.black.lh("50px").fs("32px").fw300.sansSerif.mt3.$} {...tid.title}>
          {streetAddress}
          <br />
          {city}, {state} {postalCode}
        </h1>

        <p css={Css.gray700.lh("50px").fs("18px").fw400.sansSerif.mt1.$}>{formattedOfferPrice}</p>

        <div css={Css.wPx(400).mt3.df.fdc.gap2.$}>{ctaBtns.map((btnElement) => btnElement)}</div>
      </div>
      {/* Home Feature Icons */}
      <div css={Css.bgOffWhite.absolute.bottomPx(-116).mlPx(80).w("calc(100% - 80px)").hPx(437).z1.df.$}>
        <div
          css={
            Css.mta.mb5.ml4.df.w100
              .mrPx(632)
              .addIn("div:not(:last-child)", Css.br.bGray400.$)
              .ifContainer({ lt: AMENITY_FEATURE_BP }).mb2.ml0.$
          }
        >
          <div css={Css.df.ma.ifContainer({ lt: AMENITY_FEATURE_BP }).ml2.mb2.tc.$}>
            <div css={Css.df.fdc.aic.px3.ifContainer({ lt: AMENITY_FEATURE_BP }).px2.nowrap.$}>
              <Icon name="two-pillow-bed" />
              <div css={HomeAmenityTextCss}>{formattedBedrooms}</div>
            </div>
            <div css={Css.df.fdc.aic.px3.ifContainer({ lt: AMENITY_FEATURE_BP }).px2.nowrap.$}>
              <Icon name="bath" />
              <div css={HomeAmenityTextCss}>{formattedBathrooms}</div>
            </div>
            <div css={Css.df.fdc.aic.px3.ifContainer({ lt: AMENITY_FEATURE_BP }).px2.nowrap.$}>
              <Icon name="triangle-ruler" />
              <div css={HomeAmenityTextCss}>{formattedSqft}</div>
            </div>
            {formattedGaragePort && (
              <div css={Css.df.fdc.aic.px3.ifContainer({ lt: AMENITY_FEATURE_BP }).px2.nowrap.$}>
                <Icon name="square-parking-lg" />
                <div css={HomeAmenityTextCss}>{formattedGaragePort}</div>
              </div>
            )}
            {formattedGarageAttached && (
              <div css={Css.df.fdc.aic.px3.ifContainer({ lt: AMENITY_FEATURE_BP }).px2.nowrap.$}>
                <Icon name="hero-garage" />
                <div css={HomeAmenityTextCss}>{formattedGarageAttached}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function MobileHero(props: HeroViewProps) {
  const {
    formattedGarageAttached,
    ctaBtns,
    heroImgElement,
    formattedOfferPrice,
    formattedSqft,
    formattedBedrooms,
    formattedGaragePort,
    propertyTag,
    formattedBathrooms,
    city,
    postalCode,
    state,
    streetAddress,
  } = props;
  const tid = useTestIds(props, "mobile");

  // Feature icons:
  // 1x3 if 3 features
  // 2x2 if 4 features
  // 2x3 if 5 features will show one empty space in final grid area
  const templateColumnsCSS =
    (formattedGarageAttached && formattedGaragePort) || (!formattedGaragePort && !formattedGarageAttached)
      ? Css.gtc("1fr 1fr 1fr").addIn("div:nth-of-type(3n + 2)", Css.bl.br.bGray400.$).$
      : Css.gtc("1fr 1fr").addIn("div:nth-of-type(2n)", Css.bl.bGray400.$).$;

  return (
    <>
      {/* Hero Image */}
      <div css={Css.relative.z2.$}>{heroImgElement}</div>
      {/* Gray background decreases margin equal to card bottom offset */}
      <div css={Css.bgOffWhite.mbPx(-37).$}>
        {/* White Center Panel */}
        <div css={Css.relative.bottomPx(37).bgGray50.py3.mx3.px2.gap2.z3.df.fdc.jcc.aic.$}>
          {propertyTag}
          <h1 css={Css.black.lh("40px").fs("24px").fw500.sansSerif.tc.$} {...tid.title}>
            {streetAddress},
            <br />
            {city}, {state},
            <br />
            {postalCode}
          </h1>
          <p css={Css.gray700.lh("28px").fs("18px").fw500.sansSerif.$}>{formattedOfferPrice}</p>
          {/* Btn section has increased top margin and side padding */}
          <div css={Css.w100.df.fdc.gap2.mt1.px1.$}>{ctaBtns.map((btnElement) => btnElement)}</div>
        </div>
      </div>
      {/* Home Feature icons */}
      <div css={{ ...Css.px3.relative.bgOffWhite.z1.jcc.dg.gtr("auto").$, ...templateColumnsCSS }}>
        {/* center divider line needs to be hidden if we have a single row */}
        {(formattedGaragePort || formattedGarageAttached) && (
          <span css={Css.absolute.top("50%").hPx(1).w("calc(100% - 48px)").rightPx(24).bgGray400.$} />
        )}
        <div css={Css.df.fdc.aic.myPx(15).px2.tc.$}>
          <Icon name="two-pillow-bed" />
          <span css={HomeAmenityTextCss}>{formattedBedrooms}</span>
        </div>
        <div css={Css.df.fdc.aic.myPx(15).px2.tc.$}>
          <Icon name="bath" />
          <span css={HomeAmenityTextCss}>{formattedBathrooms}</span>
        </div>
        <div css={Css.df.fdc.aic.myPx(15).px2.tc.$}>
          <Icon name="triangle-ruler" />
          <span css={HomeAmenityTextCss}>{formattedSqft}</span>
        </div>
        {formattedGaragePort && (
          <div css={Css.df.fdc.aic.myPx(15).px2.tc.$}>
            <Icon name="square-parking-lg" />
            <span css={HomeAmenityTextCss}>{formattedGaragePort}</span>
          </div>
        )}
        {formattedGarageAttached && (
          <div css={Css.df.fdc.aic.myPx(15).px2.tc.$}>
            <Icon name="hero-garage" />
            <span css={HomeAmenityTextCss}>{formattedGarageAttached}</span>
          </div>
        )}
      </div>
    </>
  );
}

interface CommonProps {
  showCustomize?: boolean;
  property: SummaryPropertyFragment;
  showContactUs: () => void;
  testId?: TestIds;
}

function gatherCommonViewProps({ showCustomize, property, showContactUs, testId }: CommonProps): HeroViewProps {
  const {
    imageGallery,
    priceInCents,
    city,
    postalCode,
    state,
    streetAddress,
    bedrooms,
    bathrooms,
    sqft,
    garageAttached,
    garagePort,
    mostRecentCutOff,
  } = property;

  const buttons = propertyButtonProps(property, showContactUs, showCustomize);
  const showGaragePort = !!garagePort && garagePort > 0;
  const showGarageAttached = !!garageAttached && garageAttached > 0;

  const hidePrice = priceHiddenTags.includes(mostRecentCutOff?.cutOff.marketingName.trim() || "");

  return {
    // Raw values
    city,
    postalCode,
    state,
    streetAddress,

    // Formatted strings
    formattedBedrooms: `${bedrooms} beds`,
    formattedBathrooms: `${bathrooms} baths`,
    formattedSqft: `${sqft} Sq Ft`,
    formattedOfferPrice: hidePrice ? "Inquire for Pricing" : `Offered at ${currencyFormatter(priceInCents)}`,

    // Strings with conditionals
    formattedGaragePort: showGaragePort && `${garagePort} Car Carport`,
    formattedGarageAttached: showGarageAttached && `${garageAttached} Car Garage`,

    // Custom elements
    heroImgElement: (
      <img
        onClick={() => openAboutThisHomeGallery(imageGallery[0].id)}
        css={Css.w100.cursorPointer.objectCover.ar("16 / 9").$}
        src={imageGallery[0].imageUrl}
        alt={streetAddress}
      />
    ),
    propertyTag: property.mostRecentCutOff ? (
      <Tag backgroundColor={property.mostRecentCutOff.cutOff.marketingHexColor} size="large">
        {property.mostRecentCutOff.cutOff.marketingName}
      </Tag>
    ) : (
      <></>
    ),
    ctaBtns: buttons.map(({ children, ...other }, idx) => {
      // determine whether its the learn more or customize button
      const buttonTid = typeof children === "string" ? defaultTestId(children) : "";
      return (
        <Button
          key={`Hero Button ${idx.toString()} Key`}
          {...other}
          size="large"
          data-testid={testId ? `${testId["data-testid"]}_${buttonTid}Button` : undefined}
        >
          {children}
        </Button>
      );
    }),
  };
}

const HomeAmenityTextCss = Css.body12.lhPx(20).gray900.sansSerif.$;

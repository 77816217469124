import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { AssetGallery, Icon, IconKeyProp, MapLightBox, OpenGallery } from "~components";
import { Css } from "~generated/css";
import { PlanFeatureFragment, PropertyCoordinatesFragment, SummaryPropertyFragment } from "~generated/graphql";
import { useTestIds } from "~utils";

type AboutThisHomeProps = {
  features: PlanFeatureFragment[];
  /** concatenation of {plan.description + Elevation.description + SpecLevel.description} */
  homeDescription: string;
  locationMetaData: PropertyCoordinatesFragment;
} & Pick<SummaryPropertyFragment, "tourUrl" | "floorPlans" | "imageGallery">;

/**
 * This is a local breakpoint which has been chosen to be when the layout starts
 * to degrade. This is specific to this component, and not global.
 **/
const DESKTOP_X_PADDING = 106;
const MOBILE_X_PADDING = 24;
const LOCAL_BREAKPOINT = 870 + DESKTOP_X_PADDING * 2;

export function AboutThisHome(props: AboutThisHomeProps) {
  const { homeDescription, features, imageGallery, tourUrl, floorPlans, locationMetaData } = props;
  const [collapsed, setCollapsed] = useState(true);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [showShowMoreButton, setShowShowMoreButton] = useState<boolean>(false);
  const tid = useTestIds(props, "aboutThisHome");

  // to ensure the element has time to render before checking if it exists
  useEffect(() => {
    if (descriptionRef.current) {
      const span = descriptionRef.current;
      setShowShowMoreButton(span.getBoundingClientRect().height > 192);
    }
  }, []);

  return (
    <div css={Css.container().$} {...tid}>
      <div
        css={
          Css.df.fdc
            .maxwPx(1280)
            .w100.pxPx(DESKTOP_X_PADDING)
            .mx("auto")
            .ifContainer({ lt: LOCAL_BREAKPOINT })
            .pxPx(MOBILE_X_PADDING).$
        }
      >
        <h2 css={Css.header24.$}>About this home</h2>

        <div css={Css.df.jcsb.pt1.ifContainer({ lt: LOCAL_BREAKPOINT }).fdc.$}>
          <div css={Css.df.fdc.flexW.ifContainer({ gt: LOCAL_BREAKPOINT }).maxw("664px").$}>
            <p
              css={
                Css.gray700.fw300
                  .fsPx(16)
                  .lh("32px")
                  .if(collapsed && showShowMoreButton)
                  .hPx(192).overflowHidden.$
              }
            >
              <span ref={descriptionRef}>{homeDescription}</span>
            </p>
            {showShowMoreButton && (
              <button css={Css.asfs.mt1.$} onClick={() => setCollapsed(!collapsed)} {...tid.showMoreButton}>
                {collapsed ? "+ Show More" : "- Show Less"}
              </button>
            )}

            <AboutThisHomeLinks
              tourUrl={tourUrl}
              imageGallery={imageGallery}
              floorPlans={floorPlans}
              {...tid.aboutThisHomeLinks}
            />
            <Location locationMetaData={locationMetaData} {...tid.location} />
          </div>
          <WhatWeLove lines={features} {...tid.whatWeLove} />
        </div>
      </div>
    </div>
  );
}

interface LocationProps {
  locationMetaData: PropertyCoordinatesFragment;
}

function Location(props: LocationProps) {
  const { locationMetaData } = props;
  const [expandMap, setExpandMap] = useState(false);
  const tid = useTestIds(props, "location");

  return (
    <>
      <h2 css={Css.header24.mt7.pb2.ifContainer({ lt: LOCAL_BREAKPOINT }).mt5.$}>Location</h2>
      <div css={Css.fg1.relative.$} {...tid.viewMap} onClick={() => setExpandMap(!expandMap)}>
        {/* React Leaflet Map has a default z index of 400 */}
        <div
          css={
            Css.absolute
              .add("transition", ".2")
              .top0.left0.right0.bottom0.df.aic.jcc.z(402)
              .w100.h100.cursorPointer.onHover.bgColor("rgba(0,0,0,0.2)").$
          }
          onClick={() => setExpandMap(!expandMap)}
        >
          <div css={Css.underline.fw500.fsPx(16).lhPx(28).add("letterSpacing", "2%").black.$}>View map</div>
        </div>
        <MapLightBox
          expandMap={expandMap}
          locationMetaData={locationMetaData}
          triggerClose={() => setExpandMap(false)}
        />
      </div>
    </>
  );
}

function WhatWeLove(props: { lines: PlanFeatureFragment[] }) {
  const { lines } = props;
  const tid = useTestIds(props, "whatWeLove");

  return (
    // Wrapping this section in a span with 2 divider line divs instead of cluttering
    // main component with lots of conditional css just to have this sections y borders go outside their container
    <span css={Css.ifContainer({ lt: LOCAL_BREAKPOINT }).mt5.$}>
      {/* Top Divider */}
      <div css={Css.w0.bgMoss.hPx(1).ifContainer({ lt: LOCAL_BREAKPOINT }).w("120%").mlPx(-MOBILE_X_PADDING).$} />
      <div
        css={
          Css.df
            .h("fit-content")
            .fdc.bsSolid.bw1.bMoss.maxw("334px")
            .w100.plPx(50)
            .py5.pr4.ifContainer({ lt: LOCAL_BREAKPOINT }).px3.py6.maxw100.bNone.aic.$ // Designs show 48 px of padding but parent container is 24 already
        }
        {...tid}
      >
        <div>
          <div css={Css.header20.$}>What we love</div>
          {lines.map((line) => {
            return (
              <div key={line.content} css={Css.df.pt2.gap2.aic.$}>
                <div>
                  <Icon name={line.iconName as IconKeyProp} />
                </div>
                <div css={Css.body14.lhPx(28).$}>{line.content}</div>
              </div>
            );
          })}
        </div>
      </div>
      {/* Bottom Divider */}
      <div css={Css.w0.bgMoss.hPx(1).ifContainer({ lt: LOCAL_BREAKPOINT }).w("120%").mlPx(-MOBILE_X_PADDING).$} />
    </span>
  );
}

type AboutThisHomeLinksProps = Pick<AboutThisHomeProps, "tourUrl" | "floorPlans" | "imageGallery">;

export const openAboutThisHomeGallery = (imageGalleryImageId: string) => {
  const assetPreviewId = `gallery-preview-${imageGalleryImageId}`;
  document.getElementById(`show-${assetPreviewId}`)?.click();
};

function AboutThisHomeLinks(props: AboutThisHomeLinksProps) {
  const { tourUrl, floorPlans, imageGallery } = props;
  const tid = useTestIds(props, "aboutThisHomeLinks");

  return (
    <div css={Css.df.pt5.gap6.ifContainer({ lt: LOCAL_BREAKPOINT }).gap2.fdc.$}>
      {tourUrl && (
        <AboutThisHomeLink label="View 3D tour" onClick={() => window.open(tourUrl, "_blank")} {...tid.tour} />
      )}
      <AssetGallery
        showZoomInOutIcons
        actualSize={false}
        download={true}
        zoom={true}
        scale={0.75}
        assets={imageGallery.map(({ imageUrl, id }) => {
          return {
            id: `gallery-preview-${id}`,
            downloadUrl: imageUrl,
          };
        })}
        {...tid.assetGallery}
      >
        {(openPhotoGallery: OpenGallery) => (
          <AboutThisHomeLink label="View Photo Gallery" onClick={() => openPhotoGallery()} {...tid.photoGallery} />
        )}
      </AssetGallery>
      {floorPlans.length > 0 && (
        <AssetGallery
          showZoomInOutIcons
          actualSize={false}
          download={true}
          zoom={true}
          scale={0.75}
          assets={floorPlans.map((floorPlan) => {
            return {
              id: floorPlan.name,
              downloadUrl: floorPlan.imageUrl,
            };
          })}
        >
          {(openFloorPlanGallery: OpenGallery) => (
            <AboutThisHomeLink
              label="View Floor Plans"
              onClick={() => openFloorPlanGallery()}
              {...tid.floorPlanGallery}
            />
          )}
        </AssetGallery>
      )}
    </div>
  );
}

interface AboutThisHomeLinkProps {
  label: string;
  onClick: () => void;
}

function AboutThisHomeLink(props: AboutThisHomeLinkProps) {
  const { label, onClick } = props;
  const [hovered, setHovered] = useState(false);
  const tid = useTestIds(props, "aboutThisHomeLink");

  return (
    <div
      css={Css.df.gap1.cursorPointer.overflowHidden.$}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...tid}
    >
      <div css={Css.fw500.fsPx(16).black.ifContainer({ lt: LOCAL_BREAKPOINT }).fsPx(14).$}>{label}</div>
      <div css={Css.df.relative.p("4px").br100.aic.jcc.w3.h3.$}>
        <motion.span
          initial={{ paddingLeft: 0 }}
          animate={hovered ? { paddingLeft: ["0px", "15px", "0px"] } : { paddingLeft: "0px" }}
          transition={{ duration: 0.5 }}
        >
          <Icon name="chevron-right" />
        </motion.span>
      </div>
    </div>
  );
}

import { ConfiguratorContext } from "src/pages/configurator/Configurator.context";
import { optionIsSiteCondition } from "src/pages/configurator/Configurator.utils";
import { AccordionOptionCard, IconTooltip } from "~components";
import { Css, Palette } from "~generated/css";
import { ConfiguratorConfigurationOptionFragment } from "~generated/graphql";
import { currencyFormatter, getProgramDataTotalStrings, useTestIds } from "~utils";

type SummaryCardListProps = {
  darkVariant?: boolean;
  /**
   * Toggles the visibility of the `Plan Cost` and `Est. Total Cost` card.
   * This is used for the `/hop-selections` page
   * @default false
   */
  hideTotals?: boolean;
} & Pick<
  ConfiguratorContext,
  "reservedAt" | "goToStep" | "selectedOptions" | "plan" | "basePlanPriceInCents" | "isReversed"
>;

export function SummaryCardList(props: SummaryCardListProps) {
  const {
    basePlanPriceInCents,
    darkVariant = false,
    goToStep,
    hideTotals = false,
    isReversed,
    plan,
    reservedAt,
    selectedOptions,
  } = props;
  const tid = useTestIds(props, "summary-card-list");

  return (
    <>
      {hideTotals ? null : (
        <BuildCostCard
          selectedOptions={selectedOptions}
          basePlanPriceInCents={basePlanPriceInCents}
          darkVariant={darkVariant}
          {...tid.buildCostCard}
        />
      )}
      <PlanExteriorCard
        plan={plan}
        basePlanPriceInCents={basePlanPriceInCents}
        goToStep={goToStep}
        selectedOptions={selectedOptions}
        reservedAt={reservedAt}
        isReversed={isReversed}
        darkVariant={darkVariant}
        {...tid.planExteriorCard}
      />
      <InteriorCard
        goToStep={goToStep}
        selectedOptions={selectedOptions}
        reservedAt={reservedAt}
        darkVariant={darkVariant}
        {...tid.interiorCard}
      />
      <AddOnsCard
        goToStep={goToStep}
        selectedOptions={selectedOptions}
        reservedAt={reservedAt}
        darkVariant={darkVariant}
        {...tid.addOnsCard}
      />
      <SiteConditionsCard selectedOptions={selectedOptions} darkVariant={darkVariant} {...tid.siteConditionsCard} />
    </>
  );
}

type SummaryCardBaseProps = {
  darkVariant?: boolean;
} & Pick<ConfiguratorContext, "reservedAt" | "goToStep" | "selectedOptions">;

type PlanExteriorCardProps = SummaryCardBaseProps &
  Pick<ConfiguratorContext, "plan" | "basePlanPriceInCents" | "isReversed">;

export const PlanExteriorCard = (props: PlanExteriorCardProps) => {
  const { plan, basePlanPriceInCents, goToStep, selectedOptions, reservedAt, isReversed, darkVariant = false } = props;
  const tid = useTestIds(props, "plan-exterior-card");

  const titleAction = reservedAt === null ? { triggerText: "Edit", onClick: () => goToStep("exterior") } : undefined;

  const { bedrooms, bathrooms, sqft } = getProgramDataTotalStrings(selectedOptions);
  const planDescription = !plan ? "" : `${plan.name}\n${bedrooms}, ${bathrooms} & ${sqft}`;

  const exteriorOption = selectedOptions.find((so) => so.option.planOptionType.name === "Exterior");
  const exteriorSchemeOption = selectedOptions.find((so) => so.option.planOptionType.name === "Exterior Scheme");
  const floorPlanOptions = selectedOptions.filter((so) => so.option.planOptionType.name === "Floor Plan Option");

  return (
    <AccordionOptionCard
      lightText={darkVariant}
      title="Plan & Exterior"
      titleAction={titleAction}
      children={
        <div css={Css.df.fdc.$}>
          <SummaryListItem
            lightText={darkVariant}
            title="Floor Plan"
            priceInCents={`${currencyFormatter(basePlanPriceInCents)}`}
            items={[{ title: planDescription, priceInCents: isReversed ? "Reversed" : undefined }]}
          />

          {exteriorOption && exteriorSchemeOption && (
            <SummaryListItem
              lightText={darkVariant}
              title="Exterior"
              priceInCents={exteriorOption.priceInCents + exteriorSchemeOption.priceInCents || "Included"}
              items={[
                {
                  title: `${exteriorOption.option.name}, ${exteriorSchemeOption.option.name}`,
                },
              ]}
            />
          )}
          <SummaryListItem
            title="Floor Plan Options"
            lightText={darkVariant}
            priceInCents={floorPlanOptions.reduce((acc, so) => acc + so.priceInCents, 0)}
            items={floorPlanOptions.map(({ priceInCents, option }) => ({ title: option.name, priceInCents }))}
            last
          />
        </div>
      }
      {...tid.accordionOptionCard}
    />
  );
};

export type InteriorTopUpgradeOptionWithChildrenProps = ConfiguratorConfigurationOptionFragment & {
  childrenOptionMap?: Record<string, { name: string; optionCode: string }[]>;
};

export const InteriorCard = (props: SummaryCardBaseProps) => {
  const { goToStep, selectedOptions, reservedAt, darkVariant = false } = props;
  const tid = useTestIds(props, "interior-card");

  const titleAction = reservedAt === null ? { triggerText: "Edit", onClick: () => goToStep("interior") } : undefined;

  const designPackageOption = selectedOptions.find((so) => so.option.planOptionType.name === "Design Package");
  const specLevelOption = selectedOptions.find((so) => so.option.planOptionType.name === "Spec Level");
  const interiorUpgradeOptions = selectedOptions.filter((so) =>
    [
      "Interior Option",
      "Whole House Cabinet Color - Primary",
      "Whole House Cabinet Color - Accent",
      "Kitchen Countertop",
      "Whole House Flooring",
    ].includes(so.option.planOptionType.name),
  );

  const topInteriorUpgradeOptions: InteriorTopUpgradeOptionWithChildrenProps[] = interiorUpgradeOptions
    .map((so) => {
      const childrenOptionMap: InteriorTopUpgradeOptionWithChildrenProps["childrenOptionMap"] = {};
      // if option has no child options then return the option
      if (so.option.options.length === 0) {
        return so;
      }
      let additionalPriceInCents = 0;
      let children = so.option.options
        .map((childOption) => {
          // get the interior upgrade option's child options
          return interiorUpgradeOptions.find((so) => so.option.id === childOption.id);
        })
        .filter(Boolean);
      while (children.length > 0) {
        // recursively update pricing to sum all child option pricing
        additionalPriceInCents += children.reduce((acc, child) => acc + (child?.priceInCents || 0), 0);
        children = children.flatMap((child) => {
          /* map child name to display in summary list */
          if (child?.option.parentOption && child?.option.parentOption.id === so.option.id) {
            // root level child - if no name yet then add to map
            if (!childrenOptionMap[child?.option.id]) {
              childrenOptionMap[child?.option.id] = [];
            }
            childrenOptionMap[child?.option.id] = [
              ...childrenOptionMap[child?.option.id],
              {
                name: child?.option.name,
                optionCode: child?.option.optionCode,
              },
            ];
          } else if (child?.option.parentOption && child?.option.parentOption.id !== so.option.id) {
            // sub-root level child - if no name yet then add to map
            if (!childrenOptionMap[child?.option.parentOption.id]) {
              childrenOptionMap[child?.option.parentOption.id] = [];
            }
            childrenOptionMap[child?.option.parentOption.id] = [
              ...childrenOptionMap[child?.option.parentOption.id],
              {
                name: child?.option.name,
                optionCode: child?.option.optionCode,
              },
            ];
          }
          return (
            child?.option.options
              .map((childOption) => {
                return selectedOptions.find((so) => so.option.id === childOption.id);
              })
              .filter(Boolean) || []
          );
        });
      }
      return {
        ...so,
        priceInCents: so.priceInCents + additionalPriceInCents,
        childrenOptionMap, // to display children names under parent / grandparent
      };
    })
    // filter out child options of interior upgrade options now we've used their names for the name map
    .filter(
      (so, i, sos) =>
        !(so.option.parentOption && sos.map((option) => option.option.id).includes(so.option.parentOption.id)),
    );

  return (
    <AccordionOptionCard
      lightText={darkVariant}
      title="Interior"
      titleAction={titleAction}
      children={
        <div css={Css.df.fdc.$}>
          <SummaryListItem
            lightText={darkVariant}
            title="Design Scheme"
            priceInCents={designPackageOption!.priceInCents || "Included"}
            items={[
              {
                title: designPackageOption!.option.name,
              },
            ]}
          />
          <SummaryListItem
            lightText={darkVariant}
            title="Finish Level"
            priceInCents={specLevelOption!.priceInCents || "Included"}
            items={[
              {
                title: specLevelOption!.option.name,
                priceInCents: specLevelOption!.priceInCents || undefined,
              },
            ]}
          />
          <SummaryListItem
            lightText={darkVariant}
            title="Options"
            priceInCents={topInteriorUpgradeOptions.reduce((acc, so) => acc + so.priceInCents, 0)}
            items={topInteriorUpgradeOptions.map(({ priceInCents, option, childrenOptionMap }) => ({
              title: option.name,
              priceInCents: priceInCents || "Included",
              childrenOptionMap,
            }))}
            last
          />
        </div>
      }
      {...tid.accordionOptionCard}
    />
  );
};

export const AddOnsCard = (props: SummaryCardBaseProps) => {
  const { goToStep, selectedOptions, reservedAt, darkVariant = false } = props;
  const tid = useTestIds(props, "add-ons-card");
  const titleAction = reservedAt === null ? { triggerText: "Edit", onClick: () => goToStep("add-ons") } : undefined;
  const addOnOptions = selectedOptions.filter((so) => so.option.planOptionType.name === "Add-On Option");

  return (
    <AccordionOptionCard
      lightText={darkVariant}
      title="Add-Ons"
      titleAction={titleAction}
      children={
        <div css={Css.df.fdc.$}>
          <SummaryListItem
            lightText={darkVariant}
            title="Add-Ons"
            priceInCents={addOnOptions.reduce((acc, so) => acc + so.priceInCents, 0)}
            items={addOnOptions.map(({ priceInCents, option }) => ({ title: option.name, priceInCents }))}
            last
          />
        </div>
      }
      {...tid.accordionOptionCard}
    />
  );
};

type SiteConditionsCardProps = Pick<SummaryCardBaseProps, "selectedOptions" | "darkVariant">;

export const SiteConditionsCard = (props: SiteConditionsCardProps) => {
  const { selectedOptions, darkVariant = false } = props;
  const tid = useTestIds(props, "site-conditions-card");
  const siteConditionOptions = selectedOptions.filter(optionIsSiteCondition);

  if (siteConditionOptions.length === 0) return null;

  return (
    <AccordionOptionCard
      title="Site Conditions"
      lightText={darkVariant}
      infoTooltip="Site Conditions are required for each build and will be specific to your property. These will be confirmed and detailed by a Homebound representative prior to contracting."
      children={
        <div css={Css.df.fdc.$}>
          <SummaryListItem
            lightText={darkVariant}
            priceInCents=""
            items={siteConditionOptions.map(({ priceInCents, option }) => ({
              title: option.name,
              priceInCents: priceInCents || "Price TBD",
            }))}
            last
          />
        </div>
      }
      {...tid.accordionOptionCard}
    />
  );
};

type BuildCostCardProps = Pick<SummaryCardBaseProps, "darkVariant" | "selectedOptions"> &
  Pick<ConfiguratorContext, "basePlanPriceInCents">;

export function BuildCostCard(props: BuildCostCardProps) {
  const { selectedOptions, basePlanPriceInCents, darkVariant = false } = props;
  const tid = useTestIds(props, "build-cost-card");
  //
  return (
    <div css={Css.ba.p3.borderRadius("0 0 4px 4px").bGray800.relative.$}>
      <div css={Css.aic.df.jcsb.mb1.$}>
        <h6 css={Css.df.header18.fw300.lhPx(28).gap1.if(darkVariant).white.$}>
          Plan Cost
          <div>
            <IconTooltip
              type="info"
              message="Cost to build your home including any fixed-cost options selected. In addition, your build will include site-specific costs, which are included in the total cost estimate below. Site-specific costs will be confirmed prior to signing your contract."
              {...tid.infoTooltipPlanCost}
            />
          </div>
        </h6>
        <h6 css={Css.header18.fw300.lhPx(28).gap1.if(darkVariant).white.$}>
          {currencyFormatter(
            selectedOptions
              .filter((so) => !optionIsSiteCondition(so))
              .reduce((acc, { priceInCents }) => acc + priceInCents, basePlanPriceInCents!),
          )}
        </h6>
      </div>
      <div css={Css.aic.df.jcsb.pt1.bt.bGray200.if(darkVariant).bWhite.$}>
        <h6 css={Css.df.header18.fw300.lhPx(28).gap1.if(darkVariant).white.$}>
          Est. Total Cost
          <div>
            <IconTooltip
              type="info"
              message="Price shown is an estimate for your build based on the selections detailed above. A Homebound representative will confirm a final total cost before contracting."
              {...tid.infoTooltipTotalCost}
            />
          </div>
        </h6>
        <h6 css={Css.header18.fw300.lhPx(28).gap1.if(darkVariant).white.$}>
          {currencyFormatter(
            selectedOptions.reduce((acc, { priceInCents }) => acc + priceInCents, basePlanPriceInCents!),
          )}
        </h6>
      </div>
    </div>
  );
}

type SummaryListItemProps = {
  title?: string;
  priceInCents: number | string;
  items: {
    title: string;
    priceInCents?: number | string;
    childrenOptionMap?: InteriorTopUpgradeOptionWithChildrenProps["childrenOptionMap"];
  }[];
  lightText?: boolean; // TODO: useLightText?
  last?: boolean;
};

export const SummaryListItem = (props: SummaryListItemProps) => {
  const { title, priceInCents, lightText, last, items } = props;

  function formatAmount(priceInCents: number | string) {
    if (typeof priceInCents === "string") return priceInCents;

    const sign = priceInCents === 0 ? "" : priceInCents > 0 ? "+ " : "- ";
    return `${sign}${(priceInCents / 100).toLocaleString("EN", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    })}`;
  }

  return (
    // TODO: Could also do ":not(:last-child)" on parent
    <div css={Css.pyPx(12).if(!last).add("borderBottom", `1px solid ${Palette.Gray200}`).$}>
      {title && (
        <div css={Css.df.w100.aic.jcsb.gap3.$}>
          <div css={Css.$}>
            <p css={Css.body14.fw500.lhPx(22).if(!!lightText).white.$}>{title}</p>
          </div>
          <div css={Css.flexShrink(1).$}>
            <span css={Css.body14.lhPx(24).wsnw.gray800.fw500.if(!!lightText).white.$}>
              {formatAmount(priceInCents)}
            </span>
          </div>
        </div>
      )}
      {items.map((item) => {
        const { childrenOptionMap, priceInCents, title } = item;
        // remove the required asterisk from the name
        const formattedTitle = title.replaceAll("*", "");
        return (
          <>
            <div key={formattedTitle} css={Css.df.w100.aic.jcsb.mt1.gap3.$}>
              <div>
                <p css={Css.body14.lhPx(22).gray700.wspl.if(!!lightText).white.$}>{formattedTitle}</p>
              </div>
              {!!priceInCents && (
                <div css={Css.flexShrink(1).$}>
                  <p css={Css.body14.lhPx(22).gray700.wsnw.if(!!lightText).white.$}>{formatAmount(priceInCents)}</p>
                </div>
              )}
            </div>
            {childrenOptionMap && displayChildNames(childrenOptionMap, lightText)}
          </>
        );
      })}
    </div>
  );
};

function displayChildNames(
  childrenOptionMap: InteriorTopUpgradeOptionWithChildrenProps["childrenOptionMap"],
  useLightText?: boolean,
) {
  if (!childrenOptionMap) return null;
  const options = Object.keys(childrenOptionMap).map((key) => {
    return (
      childrenOptionMap[key]
        // currently, for flooring, Tile and LVP are an "either or" so we need to avoid showing an empty Tile or LVP in the summary
        .filter((o) =>
          // checking length is less than 2 since a selection would mean 2 entries -> (0) Tile (1) "Natural" or (0) LVP (1) "Natural"
          (o.name.toLowerCase().includes("tile") || o.name.toLowerCase().includes("lvp")) &&
          childrenOptionMap[key].length < 2
            ? false
            : true,
        )
        .map((o) => {
          // remove the required asterisk from the name
          return o.name.replaceAll("*", "");
        })
        .join(": ")
    );
  });
  return options.map((name) => {
    if (!name) return null;
    return (
      <p key={name} css={Css.body12.lhPx(22).gray700.if(!!useLightText).white.$}>
        {name}
      </p>
    );
  });
}

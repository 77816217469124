import { Icon, ImagePlaceholder } from "~components";
import { Css } from "~generated/css";

type LearnMoreProps = {
  planContent: PlanContent;
};

type Feature = {
  id: string;
  content: string;
};

export type PlanContent = {
  name?: string;
  imgUrl?: string;
  description?: string;
  features?: Feature[];
  formattedBedrooms?: string;
  formattedBathrooms?: string;
  formattedSqft?: string;
  formattedGaragePort?: string;
  formattedGarageAttached?: string;
  formattedStory?: string;
};

const AMENITY_FEATURE_BP = 1188;
export function LearnMoreContent(props: LearnMoreProps) {
  const {
    name,
    imgUrl,
    description,
    features,
    formattedBathrooms,
    formattedBedrooms,
    formattedSqft,
    formattedGaragePort,
    formattedGarageAttached,
    formattedStory,
  } = props.planContent;
  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.w100.maxwPx(1440).ma.bgWhite.$}>
        <div css={Css.df.fdc.gap8.$}>
          <div css={{ ...Css.container().$, ...Css.relative.mbPx(116) }}>
            {/* hero image */}
            <div css={Css.pr("25%").ma.relative.z2.$}>
              {imgUrl ? <img src={imgUrl} alt={name}></img> : <ImagePlaceholder />}
            </div>
            {/* Floor plan Title */}
            <div
              css={
                Css.df.aic.absolute.right("12%").bottomPx(-40).bgWhite.maxwPx(411).maxhPx(137).pxPx(50).pyPx(40).z3.$
              }
            >
              <h1 css={Css.black.lh("50px").fs("32px").fw300.sansSerif.$}>{name}</h1>
            </div>
            {/* Home feature Icons */}
            <div css={Css.bgGray100.absolute.bottomPx(-116).mlPx(80).w("calc(100% - 80px)").hPx(278).z1.df.$}>
              <div
                css={
                  Css.mta.mb5.ml4.df.w100
                    .mrPx(540)
                    .addIn("div:not(:last-child)", Css.br.bGray400.$)
                    .ifContainer({ lt: AMENITY_FEATURE_BP }).mb2.ml0.$
                }
              >
                <div css={Css.df.ma.ifContainer({ lt: AMENITY_FEATURE_BP }).ml2.mb2.tc.$}>
                  <div css={Css.df.fdc.aic.px3.ifContainer({ lt: AMENITY_FEATURE_BP }).px2.nowrap.$}>
                    <Icon name="two-pillow-bed" />
                    <div css={HomeAmenityTextCss}>{formattedBedrooms}</div>
                  </div>
                  <div css={Css.df.fdc.aic.px3.ifContainer({ lt: AMENITY_FEATURE_BP }).px2.nowrap.$}>
                    <Icon name="bath" />
                    <div css={HomeAmenityTextCss}>{formattedBathrooms}</div>
                  </div>
                  <div css={Css.df.fdc.aic.px3.ifContainer({ lt: AMENITY_FEATURE_BP }).px2.nowrap.$}>
                    <Icon name="triangle-ruler" />
                    <div css={HomeAmenityTextCss}>{formattedSqft}</div>
                  </div>
                  {formattedGaragePort && (
                    <div css={Css.df.fdc.aic.px3.ifContainer({ lt: AMENITY_FEATURE_BP }).px2.nowrap.$}>
                      <Icon name="square-parking-lg" />
                      <div css={HomeAmenityTextCss}>{formattedGaragePort}</div>
                    </div>
                  )}
                  {formattedGarageAttached && (
                    <div css={Css.df.fdc.aic.px3.ifContainer({ lt: AMENITY_FEATURE_BP }).px2.nowrap.$}>
                      <Icon name="hero-garage" />
                      <div css={HomeAmenityTextCss}>{formattedGarageAttached}</div>
                    </div>
                  )}
                  <div css={Css.df.fdc.aic.px3.ifContainer({ lt: AMENITY_FEATURE_BP }).px2.nowrap.$}>
                    <Icon name="story-house" />
                    <div css={HomeAmenityTextCss}>{formattedStory}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div css={Css.w100.maxwPx(1440).ma.bgWhite.$}>
        <div css={Css.df.fdc.aic.maxwPx(1280).mx("auto").mtPx(170).mbPx(128).$}>
          <div css={Css.maxw("892px").df.fdc.$}>
            <div css={Css.flexW.body16.lh("24px").mb4.gray700.$}>{description}</div>
            <div>
              <h1 css={Css.header20.mb2.gray800.$}>Plan Features</h1>
              <ul css={Css.add("columns", 2).add("columnGap", "40px").body14.lh("22px").gray700.pl3.$}>
                {features?.length !== 0 &&
                  features?.map((f) => {
                    return (
                      <li key={f.id} css={Css.mb2.add("breakInside", "avoid-column").$}>
                        {f.content}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const HomeAmenityTextCss = Css.body12.lhPx(20).gray900.sansSerif.$;

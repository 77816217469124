// https://www.figma.com/file/l2AifZWDn0jFd8LEV3srCr/23-Q1-%E2%80%A2-Personalization-Front-End?type=design&node-id=2004-29408&t=OKKjcHNJbT5ewvMT-0

export const palette = {
  // Primary Palette
  White: "#FFFFFF",
  OffWhite: "#F6F6F0", // "rgba(246, 246, 240, 1)",
  Black: "#000000",

  Taupe: "#EBEAE4FF", // "rgba(235, 234, 228, 1)",
  Gray: "#888888", // "rgba(136, 136, 136, 1)",
  Transparent: "#00000000", // "rgba(0,0,0,0)",
  TranslucentBlack: "#000000B2", // "rgba(0,0,0,0.7)",

  // Brand Palette
  Moss: "#9CA084",

  // Extended Palette
  MidGray: "#66666DFF", // "rgba(102, 102, 109, 1)",
  Gray80: "#353535CC",

  Gray50: "#fffdfd",
  Gray100: "#F7F5F5",
  Gray200: "#ECEBEB",
  Gray300: "#DDDCDC",
  Gray400: "#C9C9C9",
  Gray500: "#AFAFAF",
  Gray600: "#8D8D8D",
  Gray700: "#646464",
  Gray800: "#353535",
  Gray900: "#242424",

  Green50: "#ecfdf5",
  Green100: "#d1fae5",
  Green200: "#a7f3d0",
  Green300: "#6ee7b7",
  Green400: "#34d399",
  Green500: "#10b981",
  Green600: "#059669",
  Green700: "#047857",
  Green800: "#065f46",
  Green900: "#064e3b",

  Purple50: "#f5f3ff",
  Purple100: "#ede9fe",
  Purple200: "#ddd6fe",
  Purple300: "#c4b5fd",
  Purple400: "#a78bfa",
  Purple500: "#8b5cf6",
  Purple600: "#7c3aed",
  Purple700: "#6d28d9",
  Purple800: "#5b21b6",
  Purple900: "#4c1d95",

  RedError: "#DA1E28",

  Red50: "#fef2f2",
  Red100: "#fee2e2",
  Red200: "#fecaca",
  Red300: "#fca5a5",
  Red400: "#f87171",
  Red500: "#ef4444",
  Red600: "#c86251",
  Red700: "#b91c1c",
  Red800: "#991b1b",
  Red900: "#7f1d1d",

  Yellow50: "#fefce8",
  Yellow100: "#fef9c3",
  Yellow200: "#fef08a",
  Yellow300: "#fde047",
  Yellow400: "#facc15",
  Yellow500: "#eab308",
  Yellow600: "#ca8a04",
  Yellow700: "#a16207",
  Yellow800: "#854d0e",
  Yellow900: "#713f12",

  Blue50: "#eff6ff",
  Blue100: "#dbeafe",
  Blue200: "#bfdbfe",
  Blue300: "#93c5fd",
  Blue400: "#60a5fa",
  Blue500: "#3b82f6",
  Blue600: "#2563eb",
  Blue700: "#1d4ed8",
  Blue800: "#1e40af",
  Blue900: "#1e3a8a",

  Orange50: "#fff7ed",
  Orange100: "#ffedd5",
  Orange200: "#fed7aa",
  Orange300: "#fdba74",
  Orange400: "#fb923c",
  Orange500: "#f97316",
  Orange600: "#ea580c",
  Orange700: "#c2410c",
  Orange800: "#9a3412",
  Orange900: "#7c2d12",
};

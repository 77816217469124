import { ReactNode } from "react";
import { Icon, IconKeyProp, TooltipV2, TooltipV2Props } from "~components";
import { Css } from "~generated/css";
import { useTestIds } from "~utils";

type IconTooltipProps = {
  type: "info" | "warning"; // | "error";
  icon?: IconKeyProp;
  message: ReactNode;
} & Partial<Pick<TooltipV2Props, "width" | "positionStrategy" | "noArrow" | "openOnClick">>;

export function IconTooltip(props: IconTooltipProps) {
  const { type, positionStrategy = "fixed", message, icon, ...tooltipStyle } = props;
  const tid = useTestIds(props, `${type}-icon-tooltip`);

  return (
    <div {...tid} css={Css.dif.relative.mrPx(-20).wPx(20).add("inset", "3px").$}>
      <div css={Css.df.asc.$} {...tid.trigger}>
        <Icon name={icon || type === "info" ? "info-icon-purple" : "warning-icon"} />
      </div>
      <TooltipV2
        {...tid.tooltip}
        positionStrategy={positionStrategy}
        icon={type === "warning" && "warning-icon"}
        // data-testid isn't a named property of TestIds
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        anchorSelect={`[data-testid='${tid.trigger["data-testid"]}']`}
        {...tooltipStyle}
      >
        {message}
      </TooltipV2>
    </div>
  );
}

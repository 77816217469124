import React from "react";
import { SpecDetails } from "src/pages/configurator/SpecDetails";
import { Css } from "~generated/css";
import { SummaryPrintConfigurationQuery } from "~generated/graphql";
import { currencyFormatter } from "~utils";
import { PDFPage } from "./PDFPage";

export function FinishLevelComparisonReport({
  data,
  shouldDisplayPricing,
}: {
  data: SummaryPrintConfigurationQuery;
  shouldDisplayPricing: boolean;
}) {
  const {
    customerConfiguration: { plan },
  } = data;

  const allOptions = plan.optionTypes.flatMap((ot) => ot.options).filter((o) => o.planOptionType.name === "Spec Level");

  const deluxePrice = allOptions.find((o) => o.name === "Deluxe")?.priceInCents;
  const premiumPrice = allOptions.find((o) => o.name === "Premium")?.priceInCents;

  return (
    <PDFPage>
      <div css={Css.relative.h100.w100.bgWhite.$}>
        <table css={Css.my8.$}>
          <tbody>
            {SpecDetails.map((specDetail, i) => {
              // if spec detial does not have children, return empty fragment
              if (!specDetail.children) {
                return <></>;
              } else {
                return (
                  <React.Fragment key={specDetail.title}>
                    <tr key={specDetail.title} css={headerStyle}>
                      <td css={Css.br.p1.pt3.$}>{specDetail.title}</td>

                      {i === 0 ? (
                        <>
                          <td css={Css.br.p2.$}>
                            Essential
                            {shouldDisplayPricing && (
                              <div css={Css.body10.lhPx(22).gray700.$}>Included in the base price</div>
                            )}
                          </td>
                          <td css={Css.br.p2.$}>
                            Deluxe
                            <p>(not available for The Finn)</p>
                            {shouldDisplayPricing && (
                              <div css={Css.body10.lhPx(22).gray700.$}>
                                + {deluxePrice && currencyFormatter(deluxePrice)}
                              </div>
                            )}
                          </td>
                          <td css={Css.p2.$}>
                            Premium
                            {shouldDisplayPricing && (
                              <div css={Css.body10.lhPx(22).gray700.$}>
                                + {premiumPrice && currencyFormatter(premiumPrice)}
                              </div>
                            )}
                          </td>
                        </>
                      ) : (
                        <>
                          <td css={Css.br.p1.$}></td>
                          <td css={Css.br.p1.$}></td>
                          <td css={Css.p1.$}></td>
                        </>
                      )}
                    </tr>
                    {specDetail.children.map((child) => {
                      if (!child.children) {
                        return (
                          <tr key={child.title} css={rowStyle}>
                            <td css={[columnStyle, Css.body10.fw400.$]}>
                              {child.title}
                              {child.disclaimer && <sup>*</sup>}
                              {child.disclaimer && (
                                <div css={Css.fsPx(9).fw300.pt1.$}>
                                  <sup>*</sup>
                                  {child.disclaimer}
                                </div>
                              )}
                            </td>
                            <td css={columnStyle}>
                              {child.essential && child.essential.length > 1 ? (
                                <ul css={Css.listReset.$}>
                                  {child.essential.map((li) => (
                                    <li key={li} css={Css.mb1.$}>
                                      {li}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                child.essential
                              )}
                            </td>
                            <td css={columnStyle}>
                              {child.deluxe && child.deluxe.length > 1 ? (
                                <ul css={Css.listReset.$}>
                                  {child.deluxe?.map((li) => (
                                    <li key={li} css={Css.mb1.$}>
                                      {li}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                child.deluxe
                              )}
                            </td>
                            <td css={rightColumnStyle}>
                              {child.premium && child.premium.length > 1 ? (
                                <ul css={Css.listReset.$}>
                                  {child.premium?.map((li) => (
                                    <li key={li} css={Css.mb1.$}>
                                      {li}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                child.premium
                              )}
                            </td>
                          </tr>
                        );
                      } else {
                        return (
                          <React.Fragment key={child.title}>
                            <tr key={child.title} css={Css.body10.fw400.$}>
                              <td css={[columnStyle, Css.body10.fw400.$]}>{child.title}</td>
                              <td css={columnStyle}></td>
                              <td css={columnStyle}></td>
                              <td></td>
                            </tr>
                            {child.children.map((grandChild) => {
                              return (
                                <tr key={grandChild.title} css={rowStyle}>
                                  <td css={columnStyle}>{grandChild.title}</td>
                                  <td css={columnStyle}>{grandChild.essential}</td>
                                  <td css={columnStyle}>{grandChild.deluxe}</td>
                                  <td css={rightColumnStyle}>{grandChild.premium}</td>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      }
                    })}
                  </React.Fragment>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    </PDFPage>
  );
}

const headerStyle = Css.bb.fsPx(12).fw700.lhPx(22).gray800.$;

const columnStyle = Css.br.p2.fsPx(9).fw300.lhPx(14).gray700.$;
const rightColumnStyle = Css.p1.fsPx(9).fw300.lhPx(14).gray700.$;
const rowStyle = Css.bb.bGray200.$;

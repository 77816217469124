import { format } from "date-fns";

/** Number Formatters **/
export const numberFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
}).format;

/** Currency Formatters **/
const _currentFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format;
export const currencyFormatter = (priceInCents?: number | null) => _currentFormatter((priceInCents ?? 0) / 100);

const _currentFormatterWithSign = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  signDisplay: "exceptZero",
}).format;
export const currencyFormatterWithSign = (priceInCents?: number) =>
  _currentFormatterWithSign((priceInCents ?? 0) / 100);

const _compactCurrentFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  notation: "compact",
}).format;
export const compactCurrencyFormatter = (priceInCents?: number) => _compactCurrentFormatter((priceInCents ?? 0) / 100);

/** Date Formatters **/
export function dateFormatter(date: string | Date, formatTemplate: string): string {
  if (typeof date === "string") return format(new Date(date), formatTemplate);
  return format(date, formatTemplate);
}

import { ImageDisclaimer } from "src/pages/configurator/components";
import { TRUNCATED_IMAGE_DISCLAIMER } from "src/pages/hop/components/YourHome.tab";
import { Button } from "~components";
import { Css } from "~generated/css";

type TourCardProps = {
  tourUrl: string;
};

export function TourCard({ tourUrl }: TourCardProps) {
  return (
    <div
      css={{
        ...Css.bgWhite.bshButton.relative.df.jcc.aic.w100.hPx(277).gap7.$,
        ...Css.ifContainer({ lt: 1200 }).gap4.$,
        ...Css.ifContainer({ lt: 915 }).hPx(475).br4.fdc.gap0.$,
      }}
    >
      <div
        css={{
          ...Css.df.jcc.aic.w50.h100.overflowHidden.relative.$,
          ...Css.ifContainer({ lt: 915 }).br4.w100.h("65%").$,
          ...Css.ifContainer({ lt: 600 }).br4.w100.h("55%").$,
        }}
      >
        <img css={Css.objectCover.h100.w100.$} src="/tour_preview_image.png" alt="tour preview" />
        <ImageDisclaimer ltHide={1200} title={TRUNCATED_IMAGE_DISCLAIMER} />
      </div>
      <div
        css={{
          ...Css.df.fdc.w50.h100.py5.pr8.gap2.$,
          ...Css.ifContainer({ lt: 915 }).w100.h("35%").py2.px4.gap0.jcsb.$,
          ...Css.ifContainer({ lt: 600 }).br4.w100.h("45%").p2.jcc.gap2.$,
        }}
      >
        <div css={Css.header20.lhPx(32).add("letterSpacing", "0.4px").$}>Virtual Plan Tour</div>
        <div css={Css.body14.gray700.lhPx(22).$}>
          Congratulations on your new home! Enjoy this immersive virtual experience as you envision the wonderful
          memories you'll create in your new interior haven!
        </div>
        <div css={Css.df.maxwPx(106).$}>
          <Button
            onClick={() => window.open(tourUrl, "_blank")}
            xss={Css.pyPx(6).br16.$}
            size="small"
            variant="secondary"
          >
            VIEW
          </Button>
        </div>
      </div>
    </div>
  );
}

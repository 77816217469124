import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";
import { ConfiguratorContext } from "src/pages/configurator/Configurator.context";
import { Icon, Modal, NewFieldLabel, NewFieldStyleOverrides, TextField } from "~components";
import { Css } from "~generated/css";

type SaveBuildModalProps = {
  onClose: () => void;
  /** User has pending changes that will affect selections already made */
  downstreamWarning?: boolean;
  saveBuild: ConfiguratorContext["updateConfigurationWMetaData"];
} & Pick<ConfiguratorContext, "customer" | "configurationName" | "plan">;

// Doesn't use an onContinue because the modal remains open after saving a build to allow users to copy url
export function SaveBuildModal(props: SaveBuildModalProps) {
  const { onClose, downstreamWarning = false, customer, saveBuild, configurationName, plan } = props;

  const [updating, setUpdating] = useState(false);
  const [saved, setSaved] = useState(false);
  const [buildName, setBuildName] = useState(configurationName || "");
  const [emailInput, setEmailInput] = useState("");

  const isValidEmail = useMemo(
    () => emailInput && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailInput),
    [emailInput],
  );

  useEffect(() => {
    customer && setEmailInput(customer.email);
  }, [customer]);

  let disabled;
  if (updating) {
    disabled = "Saving...";
  } else if (!buildName) {
    disabled = "Please enter a build name.";
  } else if (!isValidEmail) {
    disabled = "Please enter a valid email address.";
  }

  const modalTitle = saved ? (
    "See you soon!"
  ) : downstreamWarning ? (
    <div css={Css.df.jcfs.gap2.pb4.$}>
      <Icon name="warning-icon-large" />
      <span css={Css.header32.$}>Confirm & save changes</span>
    </div>
  ) : (
    "Save your progress"
  );

  const modalDescription = saved ? (
    <PersonalizeLink />
  ) : (
    <span css={Css.body18.fw300.gray700.lhPx(28).wPx(568).$}>
      {downstreamWarning
        ? "It looks like you've already made some selections beyond this step. Changing your selection here requires you to re-select those options because prices may be affected. Are you sure you want to continue?"
        : `Provide your email to save your progress. Pricing on your build is locked in for ${plan?.expirationDays} days, then is subject to change based on market supply chain price changes`}
    </span>
  );

  return (
    <Modal
      size={{ width: "700px", height: "auto" }}
      title={modalTitle}
      description={modalDescription}
      actionBtns={[
        {
          variant: "link",
          children: saved ? "Close" : "Cancel",
          onClick: onClose,
        },
        {
          children: "Save",
          tooltipPlacement: "top",
          disabled,
          onClick: async () => {
            setUpdating(true);
            await saveBuild({ customer: { email: emailInput }, name: buildName });
            setUpdating(false);
            setSaved(true);
          },
        },
      ]}
      loading={updating}
      isOpen
      close={onClose}
    >
      <div css={Css.px3.gap3.df.h100.$}>
        <div css={Css.f1.$}>
          <TextField
            value={buildName}
            disabled={updating}
            type="text"
            onChange={(e, name) => setBuildName(name)}
            placeholder="Build Name"
            label={NewFieldLabel("Build Name")}
            cssOverrides={NewFieldStyleOverrides}
          />
        </div>
        <div css={Css.f1.$}>
          <TextField
            value={emailInput}
            disabled={updating}
            type="email"
            autoComplete="email"
            onChange={(e, email) => setEmailInput(email)}
            placeholder="Email address"
            label={NewFieldLabel("Your Email")}
            cssOverrides={NewFieldStyleOverrides}
          />
        </div>
      </div>
    </Modal>
  );
}

const PersonalizeLink = () => {
  const locationHref = window.location.href;
  const [copiedToClipBoard, setCopiedToClipBoard] = useState(false);
  const [showCopyIcon, setShowCopyIcon] = useState(false);

  async function toClip() {
    await navigator.clipboard.writeText(locationHref);
    setCopiedToClipBoard(true);
  }

  return (
    <div css={Css.df.fdc.gap2.fg0.$}>
      <div css={Css.body16.fw300.gray700.lhPx(28).wPx(568).$}>
        Come back to your configuration anytime by using this personalized link:
      </div>
      <div
        onMouseOver={() => setShowCopyIcon(true)}
        onMouseOut={() => setShowCopyIcon(false)}
        css={Css.dif.aic.cursorPointer.h5.w100.gap1.$}
        onClick={toClip}
      >
        <div css={Css.body14.blue600.overflowHidden.add("textOverflow", "ellipsis").whiteSpace("nowrap").$}>
          {locationHref}
        </div>
        <AnimatePresence>
          {showCopyIcon && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              css={Css.body10.fw500.gray700.dif.asc.$}
            >
              {copiedToClipBoard ? <Icon name="circle-check" /> : "Copy"}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

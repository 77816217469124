import { Css } from "~generated/css";
import { CustomerConfigurationStatus, SummaryPrintConfigurationQuery } from "~generated/graphql";
import { PDFPage } from "./PDFPage";
import { PrintHeader } from "./PrintHeader";

export function DisclaimerReport({ data }: { data: SummaryPrintConfigurationQuery }) {
  const {
    customerConfiguration: { name, customer, reservedAt, expiredAt, status },
  } = data;

  return (
    <PDFPage>
      <div css={Css.relative.h100.w100.bgWhite.$}>
        <div css={Css.df.ma.fdc.gap2.p1.pb4.asc.maxwPx(1440).$}>
          <PrintHeader
            expiredAt={expiredAt!}
            isConfigurationExpired={status === CustomerConfigurationStatus.Expired}
            reservedAt={reservedAt!}
            configurationName={name}
            customer={customer!}
          />

          <div id="disclaimer" css={Css.body12.pt2.gray700.$}>
            Homeowner acknowledges that it is widely observed construction industry practice for construction plans and
            specifications for any home to be changed and adjusted from time to time in order to accommodate ongoing
            site conditions, in the field construction factors, or other matters beyond Homebound's control such as
            material price escalations or supply chain disruptions. These changes and adjustments may also relate to the
            standard inclusions (as provided herein Exhibit A.3) or to other components of the Project that impact
            installation of the standard inclusions. Based on the foregoing, Homeowner agrees that such changes and
            adjustments to the standard inclusions may occur and agrees that it is reasonable and to Homeowner's benefit
            to allow Homebound the flexibility to make such changes and adjustments provided any such change complies
            with applicable law and permits. In the event any item from the standard inclusions is omitted for any
            reason, then provided any change or substitution complies with applicable law and permits, Homebound's sole
            responsibility shall be limited to a credit against the Contract Price, provided that such substituted or
            changed item/material is of a lesser or like quality; and (b) Homeowner's sole remedy for such omission and
            substitution shall be to receive such credit (if such substituted or changed item/material is of a lesser
            quality). Any omission of the standard inclusions shall not invalidate the Agreement, constitute a breach of
            its terms or give rise to any claim for any remedy otherwise provided for by law or by the Agreement against
            Homebound.
          </div>
        </div>
      </div>
    </PDFPage>
  );
}

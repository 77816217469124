import { FetchResult } from "@apollo/client/link/core";
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { useModal, useToastMessage } from "src/contexts";
import {
  ConfigurationCustomerFragment,
  ConfigurationOptionInput,
  ConfiguratorConfigurationFragment,
  ConfiguratorConfigurationOptionFragment,
  ConfiguratorConfigurationPlanFragment,
  ConfiguratorOptionConflictFragment,
  ConfiguratorOptionFragment,
  ConfiguratorPlanOptionTypeFragment,
  CustomerConfigurationStatus,
  InputMaybe,
  Option,
  SaveCustomerConfigurationInput,
  useCustomerConfigurationByCodeQuery,
  useDuplicateCustomerConfigurationMutation,
  useSaveCustomerConfigurationMutation,
  useSaveNewCustomerConfigurationMutation,
  useToggleOptionMutation,
} from "~generated/graphql";

import { createNewDuplicateConfiguratorUrl } from "src/RouteUrls";
import { HBLoadingSpinner } from "~components";
import { SaveBuildModal, WarningModal } from "./components";
import {
  calculateTotalPriceInCents,
  ConfigurationOptionInputWithStatus,
  getOptionConflictsByOptionCode,
  mergeModifiedConfigurationOptions,
  toggleOption,
} from "./Configurator.utils";

export type Step = {
  title: string;
  path: string;
  sortOrder: number;
  optionTypes: string[];
};

// TODO: Real enum for stepTemplates
//   This will be done a future iteration so for now BoylFlowSteps mapper is fine
export const BoylFlowSteps: { [x: string]: Step } = {
  plans: {
    title: "Plans",
    path: "",
    sortOrder: 1,
    optionTypes: [],
  },
  exterior: {
    title: "Exterior",
    path: "steps/exterior",
    sortOrder: 2,
    optionTypes: ["Exterior", "Exterior Scheme"],
  },
  interior: {
    title: "Interior Package",
    path: "steps/interior",
    sortOrder: 3,
    optionTypes: ["Design Package", "Spec Level"],
  },
  upgrades: {
    title: "Upgrades",
    path: "steps/upgrades",
    sortOrder: 4,
    optionTypes: [
      "Interior Option",
      "Whole House Cabinet Color - Primary",
      "Whole House Cabinet Color - Accent",
      "Whole House Flooring",
      "Kitchen Countertop",
    ],
  },
  "floor-plan-options": {
    title: "Floor Plan Options",
    path: "steps/floor-plan-options",
    sortOrder: 5,
    optionTypes: ["Floor Plan Option"],
  },
  "add-ons": {
    title: "Add-Ons",
    path: "steps/add-ons",
    sortOrder: 6,
    optionTypes: ["Add-On Option", "Site Condition"],
  },
  summary: {
    title: "Summary",
    path: "summary",
    sortOrder: 7,
    optionTypes: [],
  },
};

export const OrderedBoylStepKeys = Object.keys(BoylFlowSteps).map(
  (key) => BoylFlowSteps[key]?.sortOrder && BoylFlowSteps[key].path,
);

type ConfiguratorShortcutsProps = {
  stepTitles: string[];
  sortOrder: number;
  stepKey: string;
};

// Despite building steps around URL paths, we need to "pretend" that some steps are nested since they've become highly coupled
export const ConfiguratorHeaderSteps: { [key: string]: ConfiguratorShortcutsProps } = {
  plans: {
    stepTitles: ["Plans"],
    sortOrder: 1,
    stepKey: "plans",
  },
  exterior: {
    stepTitles: ["Exterior"],
    sortOrder: 2,
    stepKey: "exterior",
  },
  interior: {
    stepTitles: ["Interior Package", "Upgrades"],
    sortOrder: 3,
    stepKey: "interior",
  },
  options: {
    stepTitles: ["Floor Plan Options", "Add-Ons"],
    sortOrder: 4,
    stepKey: "floor-plan-options",
  },
  summary: {
    stepTitles: ["Summary"],
    sortOrder: 5,
    stepKey: "summary",
  },
};

type BoylFlowStep = keyof typeof BoylFlowSteps;

type OptionConflictsByOptionCode = { [optionCode: string]: ConfiguratorOptionConflictFragment[] };

/** Configurator Context **/
export type ConfiguratorValues = {
  /** Configurator context sets this value based on initial queryloading, or in progress toggleOption|duplicateCC mutation loading. Initial queryLoad and duplicateCC will cause context to render spinner in place of children */
  loading: boolean;
  /** Optionally defined by user */
  configurationName?: string | null;
  optionConflictsByOptionCode: OptionConflictsByOptionCode;
  selectedOptions: ConfiguratorConfigurationOptionFragment[];
  availableOptionsByOptionType: ConfiguratorPlanOptionTypeFragment[];
  currentStep: Step;
  totalPriceInCents: number | null;
  plan: ConfiguratorConfigurationPlanFragment | null;
  basePlanPriceInCents: number | null;
  /** User configuration exists based on presence of code */
  isSavedUserConfiguration: boolean;
  expiredAt: Date | null;
  reservedAt: Date | null;
  customer?: ConfigurationCustomerFragment | null;
  status: CustomerConfigurationStatus | null;
  isReversed: boolean;
};

type UpdateConfigurationWMetaDataProps = {
  name?: string | null;
  customer: Omit<ConfiguratorValues["customer"], "id">;
  reservedAt?: Date | null;
};

/** Configurator Actions **/
export type ConfiguratorActions = {
  // These actions are used throughout the configuration step

  // ways to update the configuration
  saveOptions: (callBack: () => void) => Promise<void>;
  toggleOption: (option: ConfiguratorOptionFragment) => void;
  updateConfigurationWMetaData: (props: UpdateConfigurationWMetaDataProps) => Promise<FetchResult>;
  saveAndExit: () => void;

  /**
   * Duplicate Configuration creates and navigates to a new configuration with the same options selected.
   *
   * The term `duplicate` signals that the configuration record references its progenitor and its expiration date can NEVER be changed.
   */
  duplicateCurrentConfiguration: () => Promise<FetchResult>;
  goToStep: (stepTemplate: BoylFlowStep) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;

  // Note: This version of set plan is used only on market page. creates user configuration and continues flow
  //    Moves to next step if code already exists
  setPlan: (planId: string) => void;
};

export type ConfiguratorContext = ConfiguratorValues & ConfiguratorActions;
// Intentionally naming the variable the same as the type
// eslint-disable-next-line @typescript-eslint/no-redeclare
const ConfiguratorContext = createContext<ConfiguratorContext | null>(null);

/** Configurator Provider **/
type ConfiguratorProviderProps = {
  children: ReactNode;
  code: string;
};

// Note: _updateConfiguration, goToNextStep, and _goToStep could be combined depending on how we want to handle
// our calls. Will save that for future PRs as we start fleshing out steps

export function ConfiguratorProvider(props: ConfiguratorProviderProps) {
  const { children, code } = props;
  const { showToast } = useToastMessage();
  const { showModal, closeModal } = useModal();
  const { data, loading: initialLoading } = useCustomerConfigurationByCodeQuery({
    variables: { code },
    skip: !code,
  });
  const [saveNewCustomerConfiguration] = useSaveNewCustomerConfigurationMutation();
  const [saveCustomerConfiguration] = useSaveCustomerConfigurationMutation();
  const [toggleOptionMutation, { loading: toggleOptionLoading }] = useToggleOptionMutation();
  const [duplicateCCMutation, { loading: duplicationLoading }] = useDuplicateCustomerConfigurationMutation({
    variables: {
      input: { code },
    },
    onCompleted: ({ duplicateCustomerConfiguration }) => {
      const {
        customerConfiguration: { code },
      } = duplicateCustomerConfiguration;

      // NOTE: Configurator is only in sw-fl market atm
      window.open(createNewDuplicateConfiguratorUrl({ name: "Southwest Florida" }, code), "_blank");
    },
    onError: (e) => {
      console.error(e);
      showToast({
        variant: "error",
        title: "Something went wrong",
        body: "We were unable to create a duplicate of your current configuration.",
        duration: 3000,
      });
    },
  });

  const [modifiedConfigurationOptions, setModifiedConfigurationOptions] =
    useState<ConfigurationOptionInputWithStatus[]>();

  // TODO: Move these hooks out of context provider. THey will cause re-renders and realistically the child stepper
  //   should be the one that knows the steps and handles the switch statement (ie. <ConfiguratorStep />)
  const navigate = useNavigate();
  const location = useLocation();
  const currentStep = getStepFromLocation(location);

  // Throw when there are more than one context
  const configurationContext = useContext(ConfiguratorContext);
  if (configurationContext !== null)
    throw new Error("ConfiguratorProvider should not be a child of another ConfiguratorProvider");

  async function setPlan(planId: string, isReversed = false) {
    /*
      If the customer configuration doesn't exist we only need to return the code
      as there isn't anything in the graphql cache to update
     */
    await (data?.customerConfiguration.id ? saveCustomerConfiguration : saveNewCustomerConfiguration)({
      variables: {
        input: {
          id: data?.customerConfiguration.id,
          planId: planId,
          isReversed: isReversed,
        },
      },
      onError: (error) => {
        console.warn(error);
      },
      onCompleted: ({ saveCustomerConfiguration: { customerConfiguration } }) => {
        const { code } = customerConfiguration;
        const path = BoylFlowSteps["exterior"].path;
        navigate({
          pathname: path,
          search: `?code=${code}`,
        });
      },
    });
  }

  async function updateConfigurationWMetaData(props: UpdateConfigurationWMetaDataProps) {
    const input: SaveCustomerConfigurationInput = { ...props };
    if (input.customer) {
      input.customer.id = data?.customerConfiguration.customer?.id || undefined;
    }

    if (modifiedConfigurationOptions) {
      input.configurationOptions = modifiedConfigurationOptions.map((o) => ({ ...o, status: undefined }));
    }

    return saveCustomerConfiguration({
      variables: {
        input: {
          id: data?.customerConfiguration.id,
          ...input,
        },
      },
    });
  }

  // Note: We're saving the configuration upon each selection, so we shouldn't need to make any calls between steps
  function goToNextStep() {
    const stepKeys = Object.keys(BoylFlowSteps);
    const index = stepKeys.indexOf(currentStep as string);
    if (index < stepKeys.length - 1) {
      _goToStep(stepKeys[index + 1]);
    }
  }

  function goToPreviousStep() {
    const stepKeys = Object.keys(BoylFlowSteps);
    const index = stepKeys.indexOf(currentStep as string);
    if (index) {
      _goToStep(stepKeys[index - 1]);
    }
  }

  function _goToStep(stepTemplate: BoylFlowStep) {
    const path = BoylFlowSteps[stepTemplate].path;
    navigate({
      pathname: path,
      search: location.search,
    });
  }

  // Names are hard
  // Triggers a modal that will get user info and a build name. Warns them if changes on current step will affect other selectiosn
  function _saveAndExit() {
    showModal(
      <SaveBuildModal
        onClose={closeModal}
        downstreamWarning={modifiedConfigurationOptions && modifiedConfigurationOptions?.length > 0}
        customer={data?.customerConfiguration.customer}
        // This will NEVER actually be null. We're reusing query types
        // ATM we're creating configuration records FIRST then getting info later
        plan={data?.customerConfiguration.plan || null}
        configurationName={data?.customerConfiguration.name}
        saveBuild={async (props) => {
          const res = await updateConfigurationWMetaData(props);
          if (!res.errors) {
            setModifiedConfigurationOptions(undefined);
          }
          return res;
        }}
      />,
    );
  }

  function _showWarningModal(mutationOptions: InputMaybe<ConfigurationOptionInput[]>, callBack: () => void) {
    showModal(
      <WarningModal
        onContinue={async () => {
          await toggleOptionMutation({
            variables: { input: { id: data!.customerConfiguration.id, configurationOptions: mutationOptions } },
          });
          callBack();
          // Success Toast
          showToast({
            variant: "success",
            title: "Selections saved successfully",
            duration: 2000,
          });
        }}
        onClose={closeModal}
      />,
    );
  }

  async function _saveOptions(callBack: () => void) {
    if (modifiedConfigurationOptions) {
      const mutationOptionsWithoutStatus = modifiedConfigurationOptions.map((o) => ({ ...o, status: undefined }));
      const deletedChildrenOptions = modifiedConfigurationOptions
        .filter((o) => o.status === "deleted_by_toggle")
        .map((dco) => allOptions.find((o) => o.id === dco.option));
      // Warning Modal
      // Check if selection will cause deletions excluding current step option types
      if (shouldShowModal(BoylFlowSteps[currentStep!].optionTypes, deletedChildrenOptions as Option[])) {
        _showWarningModal(mutationOptionsWithoutStatus, callBack);
      } else {
        await toggleOptionMutation({
          variables: {
            input: { id: data!.customerConfiguration.id, configurationOptions: mutationOptionsWithoutStatus },
          },
        });
        callBack();
        // TODO: No requirements for this but we also dont have one for a success  :shrug:
        //  Could be in an `onSuccess`, await from callback, goToStep etc
        // Success Toast
        showToast({
          variant: "success",
          title: "Selections saved successfully",
          duration: 2000,
        });
      }
    } else {
      callBack();
    }
  }

  const configuratorContext = useMemo(() => {
    if (data) {
      const selectedOptions = getSelectedOptions(data.customerConfiguration, modifiedConfigurationOptions);
      return {
        isSavedUserConfiguration: data.customerConfiguration?.code !== null,
        configurationName: data.customerConfiguration?.name,
        selectedOptions: selectedOptions,
        optionConflictsByOptionCode: getOptionConflictsByOptionCode(data.customerConfiguration.plan.optionConflicts),
        availableOptionsByOptionType: data.customerConfiguration.plan.optionTypes,
        totalPriceInCents: calculateTotalPriceInCents(data.customerConfiguration, selectedOptions),
        plan: data.customerConfiguration.plan,
        basePlanPriceInCents: data.customerConfiguration.planPriceInCents,
        customer: data.customerConfiguration.customer || null,
        expiredAt: data.customerConfiguration.expiredAt || null,
        reservedAt: data.customerConfiguration.reservedAt || null,
        status: data.customerConfiguration.status,
        isReversed: data.customerConfiguration.isReversed,
      };
    }
    return {
      isSavedUserConfiguration: false,
      selectedOptions: [],
      optionConflictsByOptionCode: {},
      availableOptionsByOptionType: [],
      totalPriceInCents: null,
      plan: null,
      basePlanPriceInCents: null,
      customer: null,
      expiredAt: null,
      reservedAt: null,
      status: null,
      isReversed: false,
    };
  }, [data, modifiedConfigurationOptions]);

  // Redirect all pages to the summary page if the configuration is reserved
  useEffect(() => {
    if (data?.customerConfiguration.reservedAt && currentStep !== "summary") {
      _goToStep("summary");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => setModifiedConfigurationOptions(undefined), [location]);

  // Show success toast when new duplicate has been opened.
  useEffect(() => {
    if (location.search.includes("newDuplicate")) {
      location.search = location.search.replace("&newDuplicate=true", "");
      setTimeout(() => {
        showToast({
          variant: "success",
          title: "Successful Duplication",
          body: "We've set the same selected options from your previous configuration to get you started. Subsequent selections will only apply to this configuration",
        });
      }, 1000);
    }
  });

  const allOptions = configuratorContext.availableOptionsByOptionType.flatMap((optionType) => optionType.options);
  return (
    <ConfiguratorContext.Provider
      value={{
        ...configuratorContext,
        // values
        loading: initialLoading || toggleOptionLoading || duplicationLoading,
        currentStep: BoylFlowSteps[currentStep!],
        isSavedUserConfiguration: code !== null,

        // Actions
        goToStep: _goToStep,
        goToNextStep,
        goToPreviousStep,
        updateConfigurationWMetaData,
        setPlan,
        saveAndExit: _saveAndExit,
        saveOptions: _saveOptions,
        duplicateCurrentConfiguration: duplicateCCMutation,
        toggleOption: (option) => {
          // If we are going to the next page, don't do anything
          if (toggleOptionLoading) return;
          const selectedOptions = getSelectedOptions(data!.customerConfiguration, modifiedConfigurationOptions);
          // Get arrays of the changing options based upon a selection
          const mutationOptions = toggleOption(option, data!.customerConfiguration, selectedOptions);
          // If no options are changing, don't do anything
          if (mutationOptions.length === 0) return;

          setModifiedConfigurationOptions(
            mergeModifiedConfigurationOptions(
              data!.customerConfiguration,
              BoylFlowSteps[currentStep!].optionTypes,
              mutationOptions,
              modifiedConfigurationOptions,
            ),
          );

          // Show toast if deselected options due to conflicts
          const deselectedConflictOptions = mutationOptions.filter((o) => o.status === "deleted_by_conflict");
          if (deselectedConflictOptions.length > 0) {
            const names = [];
            for (const deselectedConflictItem of deselectedConflictOptions) {
              const option = allOptions.find((o) => o.id === deselectedConflictItem.option)!;
              names.push(option.name);
            }
            showToast({
              body: `${names.join(", ")} option${
                names.length > 1 ? "s" : ""
              } were deselected due to an option conflict.`,
              variant: "warning",
              title: "Option Conflict",
              duration: 6000,
            });
          }
        },
      }}
    >
      {initialLoading || duplicationLoading ? <HBLoadingSpinner /> : children}
    </ConfiguratorContext.Provider>
  );
}

function shouldShowModal(currentStepOptionTypes: string[], deletedChildrenOptions: Option[]): boolean {
  // if any deletedChildren options are not in the currentStepOptionTypes and aren't included, return true
  return deletedChildrenOptions.some(
    (deletedChild) => !currentStepOptionTypes.includes(deletedChild.planOptionType.name) && !deletedChild.included,
  );
}

/** Configurator Hook **/
export function useConfiguratorContext() {
  const context = useContext(ConfiguratorContext);
  // Throw when there is no ConfigurationProvider as a parent
  if (context === null) throw new Error("useConfiguratorContext must be used within a ConfiguratorProvider");

  return context;
}

function getStepFromLocation(location: Location): BoylFlowStep {
  const step = location.pathname.split("/").pop() || "plans";
  return step === "configurations" ? "plans" : step; // index route is plans
}

/*
  This either returns the customer configuration options or the modified configuration options.
  If it return the modified configuration options, it maps newly created options to a configuration option fragment
*/
function getSelectedOptions(
  customerConfiguration: ConfiguratorConfigurationFragment,
  modifiedConfigurationOptions?: ConfigurationOptionInputWithStatus[],
): ConfiguratorConfigurationOptionFragment[] {
  if (modifiedConfigurationOptions?.length) {
    const allOptions = customerConfiguration.plan.optionTypes.flatMap((optionType) => optionType.options);
    return modifiedConfigurationOptions
      .filter((o) => !o.delete)
      .map((o) => {
        // if the option hasn't changed return the original option
        if (!o.status) {
          return customerConfiguration.options.find((co) => co.id === o.id)!;
        }
        const option = allOptions.find((option) => option.id === o.option)!;
        return {
          id: `temp:${option.id}`,
          priceInCents: option.priceInCents,
          option,
        };
      });
  }
  return customerConfiguration.options;
}

import { palette } from ".truss/palette";
import { css as stitchesCss, VariantProps } from "@stitches/react";
import { motion } from "framer-motion";
import { AnchorHTMLAttributes, ButtonHTMLAttributes, CSSProperties, MouseEvent, ReactNode, useRef } from "react";
import { Icon, IconProps, Tooltip, TooltipProps } from "~components";
import { Css } from "~generated/css";
import { useTestIds } from "~utils";

const styles = stitchesCss("button, a", {
  // mini reset
  appearance: "none",
  border: "none",
  backgroundColor: "transparent",

  // base button style
  ...Css.py1.px2.br4.df.aic.jcc.gap1.relative.w100.nowrap.$,

  variants: {
    variant: {
      primary: {
        ...Css.bgGray900.white.$,
        "&:hover(:not(:disabled))": Css.bgBlack.$,
      },
      secondary: {
        ...Css.bgWhite.gray900.bGray300.boxShadow(`0 0 0 1px ${palette.Black}`).$,
        "&:hover(:not(:disabled))": Css.bgOffWhite.$,
      },
      shadowed: {
        ...Css.bgWhite.gray900.br4.bshButton.$,
        "&:hover(:not(:disabled))": Css.bgOffWhite.$,
      },
      link: {
        ...Css.bgTransparent.gray900.br4.$,
        "&:hover(:not(:disabled))": Css.underline.$,
      },
      inline: {
        ...Css.p0.br0.dib.ma.wa.bgTransparent.blue600.underline.tuoSmall.$,
      },
    },
    size: {
      xs: Css.h3.$,
      small: Css.h4.$,
      medium: Css.h5.$,
      large: Css.h6.$,
      xl: Css.h7.$,
    },
    outline: {
      gray200: Css.ba.bGray200.$,
    },
    btnFontSize: {
      reg: Css.btnFont.$,
      sm: Css.btnFontSm.$,
      xs: Css.btnFontXsm.$,
    },
    disabled: {
      true: Css.bgGray400.bGray400.gray700.cursorNotAllowed.$,
    },
    state: {
      active: Css.bgGray700.$,
      focus: Css.bgGray700.boxShadow(`0 0 0 1px ${palette.White}, 0 0 0 2px ${palette.Gray700}`).$,
    },
    color: {
      white: Css.white.$,
    },
  },

  defaultVariants: {
    variant: "primary",
    size: "medium",
    btnFontSize: "reg",
  },

  compoundVariants: [
    {
      variant: "link",
      disabled: true,
      css: Css.bgTransparent.gray700.cursorNotAllowed.$,
    },
    {
      variant: "secondary",
      state: "active",
      css: Css.bgGray300.bw2.gray900.$,
    },
    {
      variant: "secondary",
      state: "focus",
      css: Css.bgWhite.boxShadow(`0 0 0 1px ${palette.Black}, 0 0 0 2px ${palette.White}, 0 0 0 3px ${palette.Black} `)
        .$,
    },
    {
      variant: "secondary",
      disabled: true,
      css: Css.bgWhite.gray700.boxShadow(`0 0 0 1px ${palette.Gray700}`).$,
    },
    {
      variant: "primary",
      disabled: true,
      css: Css.bgGray300.gray400.$,
    },
    {
      variant: "inline",
      css: Css.ha.$,
    },
  ],
});

export type ButtonProps = Omit<VariantProps<typeof styles>, "disabled"> & {
  xss?: CSSProperties;
  iconLeft?: IconProps["name"];
  children: ReactNode;
  /** Optional href to switch to a tag */
  href?: string;
  /** Optional onClick since when used with forms, onClick is not required */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  /**
   * Disables the button when given a truthy value. When given a string, a
   * Tooltip is shown with the string as the content.
   *
   * NOTE: To customize the tooltip place, change the tooltipPlacement prop.
   */
  disabled?: boolean | string;
  /**
   * Tooltip placement for tooltip and disabled reason.
   * @default "right"
   */
  tooltipPlacement?: TooltipProps["placement"];
  openInNewTab?: boolean;
  /**
   * Prevents default and stops propagation of the event
   * @default false
   *
   * TODO: Should we be always stopping propagation and require an explicit
   * prop to allow propagation?
   * */
  stopPropagation?: boolean;
  /** Use for mapping when passing btn props */
  key?: string;
  /** Show spinner when loading */
  isLoading?: boolean;
  loadingText?: "Loading" | "Saving" | "Submitting";
};

export function Button(props: ButtonProps) {
  const {
    xss = {},
    iconLeft,
    children,
    href,
    onClick,
    variant,
    size,
    btnFontSize,
    disabled,
    tooltipPlacement = "right",
    state,
    openInNewTab,
    stopPropagation = false,
    type,
    outline,
    color,
    isLoading = false,
    loadingText = "Loading",
  } = props;
  const tid = useTestIds(props, "button");

  const ref = useRef<HTMLAnchorElement>(null);

  function handleClick(e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) {
    if (stopPropagation) {
      e.preventDefault();
      e.stopPropagation();
    }
    onClick?.(e);
  }

  // TODO: There should be a nicer way to do this...
  const Tag = href
    ? ("a" as React.ElementType<AnchorHTMLAttributes<HTMLAnchorElement>>)
    : ("button" as React.ElementType<ButtonHTMLAttributes<HTMLButtonElement>>);

  return (
    <Tag
      // Common props (available on both button and anchor)
      className={styles({
        variant,
        size,
        disabled: !!disabled || isLoading,
        state,
        outline,
        btnFontSize: btnFontSize ?? (size === "xs" ? "xs" : size === "small" ? "sm" : "reg"),
        color,
      })}
      // @ts-expect-error: due to not getting the types right, ref is erroring out.
      ref={ref}
      // Button props (only available on button)
      {...(Tag === "button" && {
        onClick: handleClick,
        disabled: !!disabled || isLoading,
        type,
        css: xss,
      })}
      // Anchor props (only available on anchor)
      {...(Tag === "a" && {
        onClickCapture: (e) => stopPropagation && e.stopPropagation(),
        href,
        target: openInNewTab ? "_blank" : undefined,
        rel: "noreferrer",
        css: xss,
      })}
      {...tid}
    >
      {isLoading ? (
        <Loader text={loadingText} />
      ) : iconLeft ? (
        <>
          <Icon name={iconLeft} />
          {children}
        </>
      ) : (
        children
      )}

      {/* When the disabled prop is a string, show a tooltip */}
      {typeof disabled === "string" && (
        <Tooltip triggerRef={ref} placement={tooltipPlacement} {...tid.tooltip}>
          {disabled}
        </Tooltip>
      )}
    </Tag>
  );
}

function Loader({ text }: { text: string }) {
  return (
    <>
      <motion.span
        animate={{ rotate: 360 }} // from 0 to 360 degrees
        transition={{
          repeat: Infinity, // infinite loop
          duration: 0.7, // duration in seconds
          ease: "linear", // easing function
        }}
        css={Css.df.aic.gap1.$}
      >
        <Icon name="loadingSpinner" />
      </motion.span>
      <span>{text}</span>
    </>
  );
}

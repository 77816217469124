import { Css } from "~generated/css";

type SwitchProps = {
  value: boolean;
  onClick: () => void;
};

export const Switch = (props: SwitchProps) => {
  const { value = false, onClick } = props;

  return (
    <div
      onClick={onClick}
      css={{
        ...Css.cursorPointer.df.relative
          .wPx(44)
          .hPx(24)
          .pPx(2)
          .br12.bgGray200.add("transition", "background-color 0.2s ease-in-out").$,
        ...Css.if(value).bgBlue600.$,
      }}
    >
      <div
        css={
          Css.relative
            .wPx(20)
            .hPx(20)
            .bgWhite.leftPx(value ? 20 : 0)
            .br12.add("transition", "left 0.2s ease-in-out").bshButton.$
        }
      />
    </div>
  );
};

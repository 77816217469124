import { createContext, ReactNode, useContext, useState } from "react";
import { Toast, ToastMessageProps } from "~components";

export type ToastContextProps = {
  showToast: (toastMessage: ToastMessageProps) => void;
};
const ToastContext = createContext<ToastContextProps | null>(null);

export function ToastProvider({ children }: { children: ReactNode }) {
  const [toastMessage, setToastMessage] = useState<ToastMessageProps>(null);

  function showToast(toastMessage: ToastMessageProps) {
    setToastMessage(toastMessage);
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast message={toastMessage} onClose={() => setToastMessage(null)} />
    </ToastContext.Provider>
  );
}

export function useToastMessage() {
  const context = useContext(ToastContext);
  // Throw when there is no ToastProvider as a parent
  if (context === null) throw new Error("useToastMessage must be used within a ToastProvider");

  return context;
}

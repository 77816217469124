import { Css } from "~generated/css";
import { CustomerConfigurationStatus, OptionImageType, SummaryPrintConfigurationQuery } from "~generated/graphql";
import { PDFPage } from "./PDFPage";
import { PrintHeader } from "./PrintHeader";
import { PrintListItem } from "./PrintListItem";

export function ExteriorReport({
  data,
  shouldDisplayPricing,
}: {
  data: SummaryPrintConfigurationQuery;
  shouldDisplayPricing: boolean;
}) {
  const {
    customerConfiguration: { name, options, customer, reservedAt, expiredAt, status, isReversed },
  } = data;

  const exteriorOption = options.find((so) => so.option.planOptionType.name === "Exterior");
  const exteriorSchemeOption = options.find((so) => so.option.planOptionType.name === "Exterior Scheme");
  const imageUrl = exteriorSchemeOption?.option.images.find((i) => i.type.code === OptionImageType.Display)?.imageUrl;

  return (
    <PDFPage>
      <div css={Css.relative.h100.w100.bgWhite.$}>
        <div css={Css.df.ma.fdc.gap2.p1.pb4.asc.maxwPx(1440).$}>
          <PrintHeader
            expiredAt={expiredAt!}
            isConfigurationExpired={status === CustomerConfigurationStatus.Expired}
            reservedAt={reservedAt!}
            configurationName={name}
            customer={customer!}
          />
          {exteriorOption && exteriorSchemeOption && (
            <PrintListItem
              title="Exterior"
              priceInCents={
                shouldDisplayPricing
                  ? exteriorOption.priceInCents + exteriorSchemeOption.priceInCents || "Included"
                  : ""
              }
              items={[
                {
                  title: `${exteriorOption.option.name}, ${exteriorSchemeOption.option.name}`,
                },
              ]}
              headerStyling
            />
          )}
          {imageUrl && <img src={imageUrl} css={Css.if(isReversed).add("transform", "scaleX(-1)").$} />}
        </div>
        {/* Disclaimer */}
        <div id="disclaimer" css={Css.body12.pt2.tc.gray700.$}>
          All images, design layouts, and renderings are intended for illustrative purposes only and may be subject to
          change. Landscaping and site conditions are artistic representations.
        </div>
      </div>
    </PDFPage>
  );
}

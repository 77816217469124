import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useEffect, useState } from "react";
import { Icon } from "~components";
import { Css } from "~generated/css";

export type ToastMessageProps = {
  title: ReactNode;
  variant: "success" | "warning" | "error";
  body?: ReactNode;
  duration?: number;
} | null;

type ToastProps = {
  message: ToastMessageProps;
  onClose?: () => void;
};

const toastVariants = {
  warning: Css.bYellow500.bgYellow50.$,
  success: Css.bGreen500.bgGreen50.$,
  error: Css.bRed500.bgRed50.$,
};

export const Toast = ({ message, onClose }: ToastProps) => {
  const [show, setShow] = useState(false);
  const { title, body, variant, duration } = message || {};

  useEffect(() => {
    if (message) {
      setShow(true);
      if (duration) {
        const timeout = setTimeout(() => {
          setShow(false);
        }, duration);
        return () => clearTimeout(timeout);
      }
    }
  }, [message, duration]);

  return (
    <AnimatePresence onExitComplete={() => onClose?.()}>
      {show && (
        <motion.div
          key="toast"
          // Animating up from the bottom was causing layout shifts. v8 has a fix for this
          initial={{ opacity: 0, x: "-500px" }}
          animate={{ opacity: 1, x: "3.5vw" }}
          exit={{ x: "-500px" }}
          css={{
            ...Css.absolute
              .bottomPx(120)
              .br4.px2.pt1.pb3.wPx(458)
              .add("borderStyle", "solid")
              .add("borderWidth", "1px 1px 1px 4px")
              .boxShadow("-4px -4px 10px rgba(0, 0, 0, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.1)").$,
            ...toastVariants[variant!],
          }}
        >
          <div css={Css.df.fdc.w100.h100.$}>
            <div css={Css.df.asfe.cursorPointer.relative.topPx(8).$} onClick={() => setShow(false)}>
              {!duration && <Icon name="x" />}
            </div>
            <div css={Css.df.w("90%").gap2.if(!!duration).pt3.$}>
              <div css={Css.df.aifs.$}>
                {/* We dont have an official `success-check` icon from design */}
                {variant === "success" && (
                  <div css={Css.df.h3.w3.aic.jcc.bgGreen500.br100.$}>
                    <Icon name="checkmark-white" />
                  </div>
                )}
                {variant === "warning" && <Icon name="warning-icon" />}
                {variant === "error" && <Icon name="error-icon" />}
              </div>
              <div css={Css.df.fdc.$}>
                <div css={Css.body16.lhPx(18).fw700.$}>{title}</div>
                {body && <div css={Css.body14.lhPx(22).pt1.$}>{body}</div>}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

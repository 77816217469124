import { ReactNode } from "react";
import { Checkbox, IconTooltip, Radio, TooltipV2 } from "~components";
import { Css, Palette } from "~generated/css";
import {
  ConfiguratorOptionConflictFragment,
  ConfiguratorOptionFragment,
  Maybe,
  OptionImageType,
} from "~generated/graphql";
import { defaultTestId, isDefined, useTestIds } from "~utils";

// Places border between all children except last
export const OptionListContainer = ({ children }: { children: ReactNode }) => (
  <div css={Css.df.fdc.addIn("> div:not(:last-child)", Css.add("borderBottom", `1px solid ${Palette.Gray200}`).$).$}>
    {children}
  </div>
);

type ChildOptionListItemProps = {
  option: ConfiguratorOptionFragment;
  selected: boolean;
  groupName?: string;
  onClick: () => void;
};

type OptionListItemProps = {
  title: string;
  description: Maybe<string>;
  // 0 renders "Included"
  // pass "" to hide price field
  priceInCents?: number | string;
  selected: boolean;
  onClick?: () => void;
  child?: boolean;
  conflict?: ConfiguratorOptionConflictFragment;
  optionId: string; // unique id for tooltip anchor

  showTBD?: boolean;
  // TODO: This will probably need some kind of grouping update if/when we create radioGroups
  // Uses radio button instead of checkbox
  isRadio?: boolean;
  groups?: { groupName: string; priceInCents: number; selected: boolean }[];
  // Render swatches
  children?: ChildOptionListItemProps[];
};

export const OptionListItem = (props: OptionListItemProps) => {
  const {
    title,
    description,
    priceInCents,
    selected,
    onClick,
    conflict,
    child = false,
    showTBD = false,
    isRadio = false,
  } = props;
  const tid = useTestIds(props, "option-list-item");

  function formatAmount(priceInCents: number | string) {
    if (typeof priceInCents === "string") return priceInCents;

    const sign = priceInCents === 0 ? "" : priceInCents > 0 ? "+ " : "- ";
    return `${sign}${(priceInCents / 100).toLocaleString("EN", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    })}`;
  }

  return (
    <div css={Css.df.fdc.w100.jcsb.pyPx(12).if(!props.groups).gap2.$} {...tid}>
      {/* ListItem Description - Price (price may not show in some scenarios based on groupings, totals) */}
      <div css={Css.df.aic.jcsb.gap2.$}>
        {/* 56% is sweetspot for line break */}
        <div css={Css.dif.w("56%").$}>
          <span css={{ ...Css.body14.fw500.lhPx(22).$, ...Css.if(child).pl1.fw300.if(child && !selected).gray700.$ }}>
            {title}
            {description && !conflict && <IconTooltip message={description} type="info" {...tid.infoTooltip} />}
            {conflict && (
              <IconTooltip
                {...tid.conflictTooltip}
                type="warning"
                width="320px"
                message={
                  <div css={Css.df.fdc.$}>
                    <div css={Css.body14.lhPx(18).fw700.gray800.mb1.$}>Option Conflict</div>
                    <div css={Css.body14.lhPx(22).gray700.$}>
                      {conflict.reason} These options will deselected automatically.
                    </div>
                  </div>
                }
              />
            )}
          </span>
        </div>
        <div css={Css.df.aic.gap("12px").$}>
          <span css={{ ...Css.body14.lhPx(24).gray700.whiteSpace("nowrap").$, ...(selected && Css.gray800.fw500.$) }}>
            {isDefined(priceInCents) && priceInCents !== 0 ? formatAmount(priceInCents) : showTBD ? "TBD" : "Included"}
          </span>
          {isRadio ? (
            <Radio selected={selected} onClick={onClick} />
          ) : (
            <Checkbox selected={selected} onClick={onClick} />
          )}
        </div>
      </div>
      {/* Selectable swatches */}
      {props.children && !props.groups && (
        <div css={Css.df.gap1.$}>
          {props.children.map(({ option, selected, onClick }) => {
            const image = option.images.find((i) => i.type.code === OptionImageType.Swatch);
            const optionImage = image?.imageUrl || "https://via.placeholder.com/40x40";
            const anchorId = option.id.replace(":", "");
            return (
              <div
                key={option.id}
                id={anchorId}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
                css={Css.cursorPointer.wPx(40).hPx(40).br100.ba.bw2.bGray700.if(selected).bGray800.bw2.$}
                {...tid[`${defaultTestId(option.name)}`]}
              >
                <img src={optionImage} css={Css.br100.w100.h100.if(selected).ba.bw2.bWhite.$} alt={option.name} />
                <TooltipV2 positionStrategy="fixed" anchorSelect={`#${anchorId}`}>
                  <SwatchTipContent imgSrc={optionImage} name={option.name} />
                </TooltipV2>
              </div>
            );
          })}
        </div>
      )}
      {/* {for n groups} */}
      {/* (indented) Child Group - Price */}
      {/* (indented) Selectable swatches */}
      {props.children &&
        props.groups &&
        props.groups.map(({ groupName, priceInCents, selected: groupSelected }, i) => {
          const children = props.children!.filter((child) => child.groupName === groupName);
          return (
            <div key={groupName} css={Css.df.gap2.fdc.$} {...tid[`${defaultTestId(groupName)}`]}>
              <div css={Css.df.aic.jcsb.gap2.pl1.pt2.$}>
                {/* 56% is sweetspot for line break */}
                <div css={Css.dif.w("56%").$}>
                  <div css={Css.body14.fw300.lhPx(22).if(!groupSelected).gray800.$}>{groupName}</div>
                </div>
                <div css={Css.df.gap("12px").$}>
                  <span
                    css={{
                      ...Css.body14.lhPx(24).gray700.whiteSpace("nowrap").$,
                      ...(groupSelected && Css.gray800.fw500.$),
                    }}
                  >
                    {priceInCents ? formatAmount(priceInCents) : groupSelected ? "Included" : "-"}
                  </span>
                </div>
              </div>
              {/* Flex wrap here is specifically for image swatches to not stretch */}
              <div css={Css.df.gap1.pl1.flexW.$}>
                {children.map(({ option, selected, onClick }) => {
                  const image = option.images.find((i) => i.type.code === OptionImageType.Swatch);
                  const optionImage = image?.imageUrl || "https://via.placeholder.com/40x40";
                  const anchorId = option.id.replace(":", "");
                  return (
                    <div
                      key={option.id}
                      id={anchorId}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick();
                      }}
                      css={
                        Css.cursorPointer.wPx(40).overflowHidden.hPx(40).br100.ba.bw2.bGray700.if(selected).bGray800.bw2
                          .$
                      }
                      {...tid[`${defaultTestId(option.name)}`]}
                    >
                      <img src={optionImage} css={Css.br100.w100.h100.if(selected).ba.bw2.bWhite.$} alt={option.name} />
                      <TooltipV2 positionStrategy="fixed" anchorSelect={`#${anchorId}`}>
                        <SwatchTipContent imgSrc={optionImage} name={option.name} />
                      </TooltipV2>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

function SwatchTipContent({ imgSrc, name }: { imgSrc: string; name: string }) {
  return (
    <div css={Css.df.fdc.gap(1.5).aic.$}>
      <span css={Css.fw500.gray800.$}>{name}</span>
      <img css={Css.bGray100.ba.hPx(155).wPx(152).objectCover.$} src={imgSrc} alt={name} />
    </div>
  );
}

import { motion } from "framer-motion";
import { ReactNode } from "react";
import { Overlay as AriaOverlay, usePreventScroll } from "react-aria";
import { Css } from "~generated/css";

type OverlayProps = {
  children: ReactNode;
  onClick?: () => void;
};

/** Storybook Note: You cannot use `const canvas = within(canvasElement);` if your component uses this overlay. It renders the overlay in a portal that is outside the canvas element so use `screen` instead. */
export function BasicOverlay({ children, onClick }: OverlayProps) {
  usePreventScroll();
  return (
    <AriaOverlay>
      <motion.div
        initial={{ opacity: 0, backdropFilter: "none" }}
        animate={{ opacity: 1, backdropFilter: "blur(3px)" }}
        exit={{ opacity: 0, backdropFilter: "none" }}
        transition={{ duration: 0.3 }}
        css={Css.fixed.z9999.top0.bottom0.left0.right0.df.bgTranslucentBlack.$}
        onClick={onClick}
      >
        {children}
      </motion.div>
    </AriaOverlay>
  );
}

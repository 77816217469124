import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ConfiguratorHeaderSteps, useConfiguratorContext } from "src/pages/configurator/Configurator.context";
import { getLastEnabledHeaderStepIndex } from "src/pages/configurator/Configurator.utils";
import { createConfiguratoryUrlFromSlug } from "src/RouteUrls";
import { Button } from "~components";
import { Css } from "~generated/css";
import { CustomerConfigurationStatus } from "~generated/graphql";
import { defaultTestId, useTestIds } from "~utils";

export function ConfiguratorHeader() {
  const { marketNameSlug } = useParams();
  const {
    configurationName,
    isSavedUserConfiguration,
    expiredAt,
    reservedAt,
    status,
    goToStep,
    currentStep,
    plan,
    selectedOptions,
  } = useConfiguratorContext();

  const headerSteps = Object.entries(ConfiguratorHeaderSteps);
  const [currentHeaderStep, setCurrentHeaderStep] = useState(headerSteps[0][1]);
  const lastEnabledHeaderStepIndex = useMemo(
    () => getLastEnabledHeaderStepIndex(selectedOptions, plan?.optionTypes),
    [plan?.optionTypes, selectedOptions],
  );

  return (
    <div css={Css.z1.bshBasic.hPx(72).w100.df.asfs.px4.fs0.$}>
      <div css={Css.w100.df.aic.jcsb.relative.$}>
        <img width="153" height="26" src="/logo.svg" alt="Homebound" />
        {/* Only show step shortcuts when edit is still allowed */}
        {!reservedAt && (
          <div css={Css.absolute.left("50%").tx("-50%").df.jcse.wPx(526).body14.fw400.h100.aic.$}>
            {headerSteps.map(([key, value], i) => {
              if (value.stepTitles.includes(currentStep.title) && value.sortOrder !== currentHeaderStep.sortOrder) {
                setCurrentHeaderStep(value);
              }
              return (
                <HeaderStep
                  key={key}
                  menuTitle={key!}
                  isActiveTitle={value.stepTitles.includes(currentStep.title)}
                  isLast={i === headerSteps.length - 1}
                  onClick={() => goToStep(value.stepKey)}
                  isDisabled={i > lastEnabledHeaderStepIndex}
                />
              );
            })}
          </div>
        )}

        {!!reservedAt ? (
          <div css={Css.df.aic.gap3.$}>
            {configurationName && (
              <div css={Css.gray800.fw200.$}>
                Build: <span css={Css.gray800.fw500.$}>{configurationName}</span>
              </div>
            )}
            <div css={Css.gray800.fw200.$}>
              Reserved:{" "}
              <span css={Css.gray800.fw500.$}>
                {new Date(reservedAt).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}
              </span>
            </div>
            {status === CustomerConfigurationStatus.Expired && (
              <div css={Css.gray800.fw200.$}>
                Expired:{" "}
                <span css={Css.gray800.fw500.$}>
                  {new Date(expiredAt!).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </span>
              </div>
            )}
            <div>
              <Button
                variant={status === CustomerConfigurationStatus.Expired ? "primary" : "secondary"}
                size="small"
                btnFontSize="sm"
                href={createConfiguratoryUrlFromSlug(marketNameSlug!)}
              >
                Design Another Home
              </Button>
            </div>
          </div>
        ) : (
          !isSavedUserConfiguration && <div id="headerPlaceholder"></div>
        )}
      </div>
    </div>
  );
}

const headerStepStyle = Css.df.cursorPointer.ttc.h100.aic.$;

type HeaderStepProps = {
  menuTitle: string;
  isActiveTitle: boolean;
  isLast?: boolean;
  isDisabled: boolean;
  onClick: () => void;
};

// Exported for use in Lot.layout.tsx in temp header
export const HeaderStep = (props: HeaderStepProps) => {
  const { menuTitle, isActiveTitle, isLast = false, onClick, isDisabled } = props;
  const tid = useTestIds(props, "header-step");
  return (
    <>
      <div css={Css.w100.h100.df.fdc.px1.aic.$}>
        <button
          css={[
            headerStepStyle,
            Css.if(isActiveTitle).fw700.gray800.$,
            Css.if(!isActiveTitle).if(!isDisabled).onHover.underline.fw700.gray800.$,
            Css.if(isDisabled).fw400.gray300.cursorNotAllowed.$,
          ]}
          onClick={onClick}
          disabled={isDisabled}
          {...tid[`${defaultTestId(menuTitle)}`]}
        >
          <span>{menuTitle}</span>
        </button>

        <div
          id="yellowBar"
          css={Css.w100.hPx(4).bgTransparent.relative.bottom0.br24.if(isActiveTitle).bgYellow400.$}
        ></div>
      </div>
      {!isLast && <>/</>}
    </>
  );
};

import { Css } from "~generated/css";
import { ConfigurationCustomerFragment } from "~generated/graphql";

type PrintHeaderProps = {
  reservedAt: Date;
  configurationName?: string | null;
  isConfigurationExpired: boolean;
  expiredAt: Date | null | undefined;
  customer: ConfigurationCustomerFragment;
};

export const PrintHeader = ({
  reservedAt,
  configurationName,
  isConfigurationExpired,
  expiredAt,
  customer,
}: PrintHeaderProps) => {
  const { email } = customer;
  return (
    <>
      <div css={Css.z1.w100.df.fg1.aic.jcsb.my1.$}>
        <img width="153" height="26" src="/logo.svg" alt="Homebound" />
      </div>
      <div css={Css.df.w100.jcsb.body16.gray800.fw200.$}>
        <div css={Css.df.fdc.gap1.$}>
          <div>
            Build: <span css={Css.body18.fw500.$}>{configurationName ?? "Custom"}</span>
          </div>
          {email && (
            <>
              <div>
                Homeowner Email: <span css={Css.fw500.$}>{email}</span>
              </div>

              <div css={Css.df.fdc.gap1.$}>
                <div>
                  Reserved:{" "}
                  <span css={Css.fw500.$}>
                    {new Date(reservedAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </span>
                </div>
                <div>
                  Expire{isConfigurationExpired ? "d" : "s"}:{" "}
                  <span css={Css.fw500.red600.$}>
                    {new Date(expiredAt!).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

import { CSSProperties, ReactNode, RefObject, useRef, useState } from "react";
import { AriaOverlayProps, DismissButton, useOverlay } from "react-aria";
import { Icon, IconTooltip } from "~components";
import { Css } from "~generated/css";
import { Maybe } from "~generated/graphql";
import { defaultTestId, useTestIds } from "~utils";

// info renders tooltip icon
export type SelectOption = string | { info?: Maybe<string>; value: string };

type SelectFieldProps = {
  label?: ReactNode;
  placeholder?: string;
  options: SelectOption[];
  selectedOptions: string[];
  onChange: (selectedOptions: string[]) => void;
  /** If true, the field cannot be set to empty after being chosen. Defaults to true */
  allowDeselect?: boolean;
  triggerOverrides?: CSSProperties;
};

export function SelectField(props: SelectFieldProps) {
  const {
    label,
    selectedOptions,
    onChange,
    options,
    placeholder = null,
    allowDeselect = true,
    triggerOverrides,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const tid = useTestIds(props, "selectField");

  const handleOnChange = (item: string) => {
    if (allowDeselect && selectedOptions.includes(item)) {
      onChange([]);
    } else {
      onChange([item]);
    }

    setIsOpen(false);
  };

  return (
    <div css={Css.df.fdc.gapPx(8).relative.w100.$} {...tid}>
      {label && (
        <label css={Css.fw600.body10.ttu.gray800.$} {...tid.label}>
          {label}
        </label>
      )}

      {/* Trigger */}
      <button
        css={{
          ...Css.p2.relative.bgGray50
            .boxShadow("0px 4px 8px rgba(53, 53, 53, 0.08), 0px 2px 16px rgba(53, 53, 53, 0.03)")
            .if(isOpen)
            .color("rgba(53, 53, 53, 0.65)").$,
          ...triggerOverrides,
        }}
        onClick={() => setIsOpen(!isOpen)}
        {...tid.button}
      >
        <div css={Css.body14.fw400.tl.if(!selectedOptions.length).gray600.$}>
          {!selectedOptions.length && (placeholder || "\u00A0")}
          {/* "\u00A0" is the same as &nbsp; maintains the line height if a placeholder isn't provided */}
          {!!selectedOptions.length && selectedOptions[0]}
        </div>

        <div aria-hidden={true} css={Css.absolute.right1.top("50%").add("transform", "translateY(-50%)").$}>
          <Icon name={isOpen ? "arrow-up" : "arrow-down"} />
        </div>
      </button>

      {isOpen && (
        // hack to prevent setIsOpen from being called twice
        <Popover isOpen={isOpen} onClose={() => setTimeout(() => setIsOpen(false), 0)}>
          <ListBox onChange={handleOnChange} options={options} selectedOptions={selectedOptions} {...tid.listBox} />
        </Popover>
      )}
    </div>
  );
}

type PopoverProps = AriaOverlayProps & {
  popoverRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
};

function Popover(props: PopoverProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { popoverRef = ref, isOpen, onClose, children } = props;
  // Handle interacting outside the dialog or pressing the `Escape` key to close the modal.
  const { overlayProps } = useOverlay({ isOpen, onClose, shouldCloseOnBlur: true, isDismissable: true }, popoverRef);

  return (
    <>
      <div {...overlayProps} ref={ref} css={Css.absolute.z1.br4.top("100%").w100.bgWhite.$}>
        {children}
      </div>
      <DismissButton onDismiss={() => onClose} />
    </>
  );
}

type ListBoxProps = {
  onChange: (selectedOptions: string) => void;
  options: SelectOption[];
  selectedOptions: string[];
};

function ListBox(props: ListBoxProps) {
  const { onChange, options, selectedOptions } = props;
  const tid = useTestIds(props, "listBox");

  return (
    <ul css={Css.maxhPx(300).overflowAuto.listReset.m0.boxShadow("0px 2px 6px #D5D5D5").$} {...tid}>
      {options.map((item, index) => {
        const isString = typeof item === "string";
        const itemValue = isString ? item : item.value;
        const selected = selectedOptions.includes(itemValue);
        return (
          <li
            css={{
              ...Css.listReset.body14.outline0.cursorPointer.relative.addIn(
                "::after",
                Css.if(index !== options.length - 1).add({
                  content: '""',
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "0",
                  height: "1px",
                  width: "95%",
                  borderBottom: "1px solid rgba(201,201,201,1)",
                }).$,
              ).onHover.bgGray100.$,
              ...(selected && Css.bgGray200.$),
            }}
            key={itemValue}
            {...tid.item}
          >
            <button
              css={Css.db.p2.w100.tl.$}
              onClick={() => onChange(itemValue)}
              {...tid[`button_${defaultTestId(itemValue)}`]}
            >
              {itemValue}
              {!isString && item.info && (
                // FIXME: openOnClick doesn't work inside this container
                // NOTE: IconTooltip does some style fanagling to force it to wrap with text.
                // If content is being squeezed try increasing the width of SelectField's parent container first before diving in here
                <IconTooltip
                  message={item.info}
                  type="info"
                  {...tid[`button_${defaultTestId(itemValue)}infoTooltip`]}
                />
              )}
            </button>
          </li>
        );
      })}
    </ul>
  );
}

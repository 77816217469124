import { useMemo } from "react";
import { ImageDisplay, StepWrapper } from "src/pages/configurator/components";
import { useConfiguratorContext } from "src/pages/configurator/Configurator.context";
import { SelectableOptionCard, TooltipV2 } from "~components";
import { Css, Palette } from "~generated/css";
import { ConfiguratorOptionFragment, OptionImageType } from "~generated/graphql";
import { defaultTestId, useTestIds } from "~utils";

const topStepOptionType = "Exterior";

export function ExteriorStep() {
  const { loading, availableOptionsByOptionType, toggleOption, selectedOptions, currentStep, isReversed } =
    useConfiguratorContext();
  const tid = useTestIds({}, "exterior-step");

  const [allOptions, topOptions] = useMemo(() => {
    // first filter to the only option types that should render on this step
    const filteredOptionTypes = availableOptionsByOptionType.filter((option) =>
      currentStep.optionTypes.includes(option.name),
    );
    // return the options for all steps and filter to the top step option type
    return [
      filteredOptionTypes.flatMap((optionType) => optionType.options),
      availableOptionsByOptionType.find((option) => option.name === topStepOptionType)?.options || [],
    ];
  }, [availableOptionsByOptionType, currentStep.optionTypes]);

  const [selectedOptionIds, selectedExterior] = useMemo(() => {
    const selectedOptionIds = selectedOptions.map((option) => option.option.id);
    const exterior = selectedOptions.find((so) => so.option.planOptionType.name === "Exterior")?.priceInCents || 0;
    const exteriorScheme = selectedOptions.find((so) => so.option.planOptionType.name === "Exterior Scheme");

    const selectedExterior = {
      exteriorTotal: (exteriorScheme?.priceInCents || 0) + exterior,
      exteriorScheme: exteriorScheme?.priceInCents || 0,
      image: exteriorScheme?.option.images.find((i) => i.type.code === OptionImageType.Display),
    };

    return [selectedOptionIds, selectedExterior];
  }, [selectedOptions]);

  return (
    <StepWrapper
      title="Exterior"
      loading={loading}
      description="Select your home's architecture style and exterior color scheme below."
      left={
        <ImageDisplay
          images={selectedExterior.image ? [selectedExterior.image] : []}
          disclaimerOverride={
            <span>
              All images, design layouts, and renderings are intended for illustrative purposes only and may be subject
              to change.{" "}
              <span css={Css.underline.fw700.$} id="DisplayOptions">
                Some upgrades are shown.
              </span>
              <TooltipV2 positionStrategy="fixed" anchorSelect="#DisplayOptions">
                <div css={Css.body14.sansSerif.add("fontStyle", "normal").$}>
                  <span css={Css.fw700.$}>The following are shown:</span>
                  <ul>
                    <li>Garage with Storage</li>
                    <li>Standing Seam Roof</li>
                    <li>9ft Ground Floor</li>
                    <li>Cable Railing (Contemporary)</li>
                    {/* TODO make this dynamic in the future */}
                  </ul>
                </div>
              </TooltipV2>
            </span>
          }
          isReversed={isReversed}
          {...tid.imageDisplay}
        />
      }
      right={topOptions.map((option) => {
        const selected = selectedOptionIds.includes(option.id);
        const topOptionPriceInCents = selected ? selectedExterior.exteriorTotal : option.priceInCents;
        return (
          <SelectableOptionCard
            key={option.id}
            priceLabel={topOptionPriceInCents === 0 ? "Included" : formatAmount(topOptionPriceInCents)}
            title={option.name}
            description={
              option.options.length ? (
                <div
                  css={Css.df.fdc.gap1.$}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div css={Css.df.jcsb.aic.gap3.pt2.cursor("auto").add("borderTop", `1px solid ${Palette.Gray200}`).$}>
                    <div css={Css.df.gap1.flexW.$}>
                      {option.options
                        .map((o) => allOptions.find((option) => option.id === o.id))
                        .filter((o) => !!o)
                        .map((o) => {
                          const childOption = o as ConfiguratorOptionFragment;
                          const image = childOption.images.find((i) => i.type.code === OptionImageType.Swatch);
                          const slicedOptionId = childOption.id.replace(/:/g, "");
                          return (
                            <div
                              key={childOption.id}
                              id={slicedOptionId}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleOption(childOption);
                              }}
                              css={
                                Css.cursorPointer
                                  .wPx(40)
                                  .hPx(40)
                                  .br100.ba.bw2.bGray700.if(selectedOptionIds.includes(childOption.id)).bw2.bGray800.$
                              }
                              {...tid[`${defaultTestId(option.name)}_${defaultTestId(childOption.name)}`]}
                            >
                              <img src={image?.imageUrl} css={Css.w100.h100.br100.$} alt={childOption.name} />
                              <TooltipV2 anchorSelect={`#${slicedOptionId}`} offset={15} cssOverrides={Css.p2.$}>
                                <span css={Css.body14.fw400.gray800.$}>{childOption.name}</span>
                              </TooltipV2>
                            </div>
                          );
                        })}
                    </div>
                    {selected && (
                      <span css={Css.body14.fw400.lhPx(24).nowrap.$}>
                        {selectedExterior.exteriorScheme > 0 && formatAmount(selectedExterior.exteriorScheme)}
                      </span>
                    )}
                  </div>
                </div>
              ) : null
            }
            onClick={() => toggleOption(option)}
            selected={selected}
            {...tid[`selectableOptionCard_${defaultTestId(option.name)}`]}
          />
        );
      })}
    />
  );
}

function formatAmount(priceInCents: number) {
  const sign = priceInCents === 0 ? "" : priceInCents > 0 ? "+ " : "- ";
  return `${sign}${(priceInCents / 100).toLocaleString("EN", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  })}`;
}

import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, RefObject, useEffect, useState } from "react";
import { Icon } from "src/components/atoms/Icon";
import { Css } from "~generated/css";
import { useTestIds } from "~utils";

type Placement = "right" | "top" | "left";
export type TooltipProps = {
  children: ReactNode;
  triggerRef: RefObject<HTMLElement>;
  /** Can be either an naive placement or a custom placement */
  placement?: Placement;
  /* If tip should only be shown on click*/
  clickTip?: boolean;
  /** Optional width of the tooltip. Removes `flex: nowrap` */
  width?: string;
};

/**
 * Generic tooltip component which will render it's children as a string and
 * will appear when the trigger is hovered.
 *
 * Be sure to set position relative on the element being tipped
 */
export function Tooltip(props: TooltipProps) {
  const { children, triggerRef, placement = "right", clickTip = false, width } = props;
  const [isVisible, setIsVisible] = useState(false);
  const tid = useTestIds(props, "tooltip");
  const hideTooltip = () => setIsVisible(false);

  // Update trigger listeners when triggerRef changes.
  useEffect(() => {
    const showTooltip = () => setIsVisible(true);

    const currentTrigger = triggerRef.current;

    // If tooltip is triggered on a click
    if (clickTip) {
      currentTrigger?.addEventListener("click", showTooltip);
    }
    // Add event listener to the triggerRef
    else {
      currentTrigger?.addEventListener("pointerenter", showTooltip);
      currentTrigger?.addEventListener("pointerleave", hideTooltip);
    }

    // Remove event listeners when the component is unmounted
    return () => {
      if (clickTip) {
        currentTrigger?.removeEventListener("click", showTooltip);
      } else {
        currentTrigger?.removeEventListener("pointerenter", showTooltip);
        currentTrigger?.removeEventListener("pointerleave", hideTooltip);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRef]);

  const placementStyles = getPlacementStyles(placement);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          css={[
            Css.absolute.bgWhite.bshModal.br4.w(width ?? "fit-content").df.fdc.aic.jcc.z5.if(!width).nowrap.$,
            placementStyles,
          ]}
        >
          <div css={Css.df.aifs.$}>
            <span
              css={{
                ...Css.body14.gray700.lhPx(22).pr1.mt2.if(!clickTip).mt0.pr0.pt1.$,
              }}
              {...tid}
            >
              {children}
            </span>
            {clickTip && (
              <div css={Css.df.cursorPointer.$} onClick={hideTooltip}>
                <Icon name="x" />
              </div>
            )}
          </div>
          {placement === "top" && (
            // the Arrow only shows on desktop
            <span
              css={
                Css.ifDesktop.bgWhite
                  .wPx(20)
                  .hPx(20)
                  .absolute.add("transform", "rotate(45deg)")
                  .bottomPx(-10)
                  .boxShadow("0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)").br4.z5.$
              }
            />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function getPlacementStyles(placement: Placement) {
  switch (placement) {
    case "right":
      return Css.left("calc(100% + 8px)").p1.top("50%").ty("-50%").$;
    case "left":
      return Css.right("calc(100% + 8px)").p1.top("50%").ty("-50%").$;
    case "top":
      // On mobile we want to position the tip over the description vs above the item
      return Css.bottom("calc(100% + 16px)").px2.pb3.pt2.left("50%").tx("-50%").ifMobileOrTablet.bottom("calc(40px)").$;
    default:
      throw new Error(`Invalid placement: ${placement}`);
  }
}

import PanoramaViewer from "src/3d/PanoramaViewer";
import { FEATURE_FLAGS, useFeatureFlags } from "src/contexts";
import { BasicOverlay, Icon } from "~components";
import { Css } from "~generated/css";

const TEST_PANO_URL = `/test_pano.jpg`;

export function ThreeDTourLightBox({ tourUrl, onClose }: { tourUrl: string; onClose: () => void }) {
  const featureFlagContext = useFeatureFlags();

  return (
    <div css={Css.df.add("transition", "1s").$}>
      <BasicOverlay onClick={onClose}>
        <div css={Css.df.fdc.jcc.px6.pb5.aic.vh100.w("100vw").$} onClick={onClose}>
          <div css={Css.df.asfe.p2.gap2.$}>
            <button onClick={onClose}>
              <Icon name="x-white" />
            </button>
          </div>
          <div css={Css.df.fdc.aic.jcc.h100.w100.$} onClick={(e) => e.stopPropagation()}>
            {featureFlagContext.getFeatureFlag(FEATURE_FLAGS.INTERNAL_360_RENDER) ? (
              <PanoramaViewer image={TEST_PANO_URL} />
            ) : (
              <>
                {/* embedded tour */}
                <iframe css={Css.df.fdc.aic.jcc.h100.w100.$} src={tourUrl} title="3D Floor plan tour" />
              </>
            )}
          </div>
        </div>
      </BasicOverlay>
    </div>
  );
}

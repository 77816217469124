import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useState } from "react";
import { usePreventScroll } from "react-aria";
import { Button, Icon } from "~components";
import { Css, Palette } from "~generated/css";
import { useEscCapture, useTestIds } from "~utils";

type ModalProps = {
  bg?: Palette;
  children?: ReactNode;
  close: () => void;
  isOpen: boolean;
};

export function ModalWrapper(props: ModalProps) {
  const { bg = Palette.White, children, close, isOpen } = props;
  const tid = useTestIds(props, "modal-wrapper");
  useEscCapture(close);
  usePreventScroll();

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="modal-wrapper"
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ duration: 0.3 }}
          css={Css.fixed.z9999.top0.bottom0.left0.right0.df.$}
          {...tid}
        >
          <div css={Css.w100.h100.bgColor(bg).df.ma.fdc.px3.pt4.overflowYAuto.$}>
            <div css={Css.asfe.cursorPointer.$} onClick={close}>
              <Icon name="x-32" />
            </div>
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

interface ModalWrapperTabsProps {
  currentTabName?: string;
  tabs: {
    tabName: string;
    tabContent: ReactNode;
  }[];
}

export function ModalWrapperTabs(props: ModalWrapperTabsProps) {
  const { currentTabName, tabs } = props;
  const [selectedTabName, setSelectedTabName] = useState<string>(currentTabName || tabs[0].tabName);
  const tabHeaders = tabs.map(({ tabName }, i) => (
    <>
      <span
        key={tabName}
        css={
          Css.addIn("& button", Css.fsPx(18).fw300.gray700.noUnderline.$)
            .if(tabName === selectedTabName)
            .addIn("& button", Css.fw500.gray900.underline.tuo("10px").tdc("#FACC15").tdt("4px").$).$
        }
      >
        <Button
          variant="inline"
          onClick={() => {
            setSelectedTabName(tabName);
          }}
        >
          {tabName}
        </Button>
      </span>
      {i < tabs.length - 1 && <span css={Css.px1.gray300.$}>|</span>}
    </>
  ));
  const tabContent = (
    <div>{selectedTabName ? tabs.find((tab) => tab.tabName === selectedTabName)?.tabContent : tabs[0].tabContent}</div>
  );
  return (
    <>
      <div css={Css.mb4.pb1.bb.bGray300.$}>{tabHeaders}</div>
      <div>{tabContent}</div>
    </>
  );
}

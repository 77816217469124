export function groupBy<T>(items: T[], keyFn: (item: T) => string) {
  return items.reduce<Record<string, T[]>>((acc, item) => {
    const key = keyFn(item);
    if (!acc[key]) acc[key] = [];
    acc[key].push(item);
    return acc;
  }, {});
}

/**
 * Dedupe an array of objects by a given object key
 * @param array An array of objects
 * @param key Object key used to find duplicates
 */
export function uniqueByKey<T extends object>(array: ReadonlyArray<T>, key: keyof T) {
  return [...new Map(array.map((item) => [item[key], item])).values()];
}

export function isDefined<T>(param: T | undefined | null): param is T {
  return param !== null && param !== undefined;
}

export function partition<T>(array: ReadonlyArray<T>, f: (el: T) => boolean): [T[], T[]] {
  const trueElements: T[] = [];
  const falseElements: T[] = [];

  array.forEach((el) => {
    if (f(el)) {
      trueElements.push(el);
    } else {
      falseElements.push(el);
    }
  });

  return [trueElements, falseElements];
}

import { Icon } from "~components";
import { Container, Css } from "~generated/css";

/**
 * This is a local breakpoint which has been chosen to be when the layout starts
 * to degrade. This is specific to this component, and not global.
 */
const LOCAL_BREAKPOINT = 500;

export function Footer() {
  return (
    <footer css={Css.bgOffWhite.container().$}>
      {/* When using CSS Container Queries, the @container width does not
      include padding or border sizes. Thus we need to include another wrapping
      container so that the parent width will be properly calculated.*/}
      <div css={Css.px4.py6.$}>
        {/* Content Container */}
        <div css={Css.df.fdc.gap6.$}>
          {/* Logo */}
          <a href="https://www.homebound.com/">
            <img width="158" height="26" src="/logo.svg" alt="Homebound" />
          </a>

          {/* Link Container */}
          <div
            css={{
              // Base Styles
              ...Css.dg.rowGap1.colGap8.wFit.body14.$,
              // 3 rows
              [Container({ gt: LOCAL_BREAKPOINT })]: Css.gtr("1fr 1fr 1fr").gafc.$,
              // 2 cols
              [Container({ lt: LOCAL_BREAKPOINT })]: Css.gtc("1fr 1fr").gafr.$,
            }}
          >
            <a href="https://www.homebound.com/browse-homes">Our homes</a>
            <a href="https://www.homebound.com/about">About us</a>
            <a href="https://www.homebound.com/how-it-works">How it works</a>

            <a href="https://www.homebound.com/trade-partners">Trade Partner Network</a>
            <a href="https://www.homebound.com/privacy-policy">Privacy Policy</a>
            <a href="https://www.homebound.com/faq">FAQs</a>

            <a href="https://www.homebound.com/careers">Careers</a>
            <a href="https://www.homebound.com/hurricaneian">Rebuild</a>
            <a href="https://www.homebound.com/learn">Blog</a>
          </div>

          {/* Social Icon Container */}
          <div
            css={{
              // Base Styles
              ...Css.df.gap3.$,
              // Center the icons
              [Container({ lt: LOCAL_BREAKPOINT })]: Css.jcc.$,
            }}
          >
            <a
              href="https://twitter.com/homeboundhomes"
              target="_blank"
              rel="noreferrer"
              aria-label="homebound twitter feed"
            >
              <Icon name="twitter" />
            </a>
            <a
              href="https://www.instagram.com/homebound/"
              target="_blank"
              rel="noreferrer"
              aria-label="homebound instagram page"
            >
              <Icon name="instagram" />
            </a>
            <a
              href="https://www.linkedin.com/company/homebound-construction/"
              target="_blank"
              rel="noreferrer"
              aria-label="homebound linkedin page"
            >
              <Icon name="linkedIn" />
            </a>
            <a
              href="https://www.facebook.com/buildhomebound/"
              target="_blank"
              rel="noreferrer"
              aria-label="homebound facebook page"
            >
              <Icon name="facebook" />
            </a>
          </div>

          {/* Legal Container */}
          <div
            css={{
              // Base Styles
              ...Css.df.jcsb.body12.midGray.gap1.$,
              // Vertical stack
              [Container({ lt: LOCAL_BREAKPOINT })]: Css.fdc.$,
            }}
          >
            {/* Left Container */}
            <div css={Css.df.fdc.gap2.maxwPx(549).$}>
              <p>&copy; Homebound Inc. {new Date().getFullYear()}</p>
              <p>
                We will only use your information to respond to you, regarding the reason you contacted us. We will not
                share your information with any third party outside of our organization.
              </p>
            </div>
            {/* Right Container */}
            <div
              css={{
                // Base Styles
                ...Css.df.fdc.gap1.$,
                // flex-end the text
                [Container({ gt: LOCAL_BREAKPOINT })]: Css.asfe.$,
              }}
            >
              <p>CA General Contractors</p>
              <p>License #1043883</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

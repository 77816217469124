import { useParams } from "react-router-dom";
import { Button, HBLoadingSpinner } from "~components";
import { Css } from "~generated/css";
import { OptionImageType, useContractedCustomerConfigurationByCodeQuery } from "~generated/graphql";

/**
 * Given a Customer Configuration Code, display the "Design Package" moodboard
 * image. This image of an Option image of type "Moodboard".
 *
 * URL: /moodboard/:code
 */
export function MoodboardPage() {
  const { code } = useParams<{ code: string }>();
  const { data, loading } = useContractedCustomerConfigurationByCodeQuery({
    variables: { code: code as string },
    skip: code === undefined,
  });

  if (loading || !data) {
    return (
      <div css={Css.df.fdc.fg1.h("100vh").$}>
        <HBLoadingSpinner />;
      </div>
    );
  }

  const moodboardImage = data.customerConfiguration.options
    .flatMap((o) => o.option.images)
    .find((i) => i.type.code === OptionImageType.Moodboard);

  if (!moodboardImage) {
    return (
      <div css={Css.df.fdc.aic.jcc.aic.gap2.overflowHidden.h("100vh").$}>
        <p>This configuration does not have an option with a moodboard image.</p>
      </div>
    );
  }

  return (
    <div css={Css.vh100.df.fdc.$}>
      <header css={Css.fs1.df.aic.jcsb.sticky.px5.py4.bb.bGray200.$}>
        <h1>{moodboardImage.name}</h1>
        <div css={Css.wFit.$}>
          <Button size="small" variant="link" href={moodboardImage.imageUrl}>
            Download
          </Button>
        </div>
      </header>
      <main css={Css.overflowHidden.fg1.$}>
        <img src={moodboardImage.imageUrl} alt={moodboardImage.name} css={Css.w100.h100.objectContain.$} />
      </main>
    </div>
  );
}

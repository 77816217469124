import { useMemo, useState } from "react";
import { ImageDisplay, StepWrapper } from "src/pages/configurator/components";
import { OptionListContainer, OptionListItem } from "src/pages/configurator/components/options";
import { useConfiguratorContext } from "src/pages/configurator/Configurator.context";
import {
  getReservedOptionPrice,
  useMemoizedConflictsByConflictingOptionCode,
} from "src/pages/configurator/Configurator.utils";
import { AccordionOptionCard } from "~components";
import { Css } from "~generated/css";
import { ConfiguratorConfigurationOptionFragment, OptionImageType } from "~generated/graphql";
import { defaultTestId, useTestIds } from "~utils";

const optionCodesBySection = {
  "Whole house": ["CABCLRWH", "FLRWH", "TRMWALL001", "TRMWALL002", "TRMCLG0001", "ACCESS0001", "CLGFAN0001"],
  Kitchen: ["CTOPKITC"],
};

// Note: Our designs refer to this step as "Interior Upgrades" but the step copies always say "Upgrades"
export function InteriorUpgradesStep() {
  const [lastOptionId, setLastOptionId] = useState<string>();
  const {
    loading,
    availableOptionsByOptionType,
    toggleOption,
    selectedOptions,
    optionConflictsByOptionCode,
    currentStep,
  } = useConfiguratorContext();
  const tid = useTestIds({}, "interior-upgrades-step");

  const [expandedCardTitle, setExpandedCardTitle] = useState<string>("Whole house");
  const selectedOptionIds = useMemo(() => selectedOptions.map((option) => option.option.id), [selectedOptions]);

  const soConflictsByConflictingOptionCode = useMemoizedConflictsByConflictingOptionCode(
    selectedOptions,
    optionConflictsByOptionCode,
  );

  const [allOptions, groupedSectionOptions] = useMemo(() => {
    // first filter to the only option types that should render on this step where the parent option is selected
    const allOptions = availableOptionsByOptionType.flatMap((optionType) => optionType.options);
    const filteredOptionTypes = availableOptionsByOptionType.filter((option) =>
      currentStep.optionTypes.includes(option.name),
    );
    const topOptions = filteredOptionTypes
      .flatMap((optionType) => optionType.options)
      .filter((o, i, options) => {
        if (!o.parentOption) return true;
        return (
          !options.find((option) => option.id === o.parentOption!.id) && selectedOptionIds.includes(o.parentOption.id)
        );
      });
    // return the options for all steps and filter to the top step option type
    return [
      allOptions,
      // group by the option codes
      Object.entries(optionCodesBySection)
        .map(([title, optionCodes]) => {
          const options = topOptions.filter((o) => optionCodes.includes(o.optionCode)).filter((o) => !!o);
          const selectedCount = options.filter((o) => selectedOptionIds.includes(o.id)).length;
          return {
            title,
            selectedCount,
            options,
          };
        })
        .filter((so) => so.options.length > 0),
    ];
  }, [availableOptionsByOptionType, currentStep.optionTypes, selectedOptionIds]);

  // Get option images for hero image display, if available
  // or
  // fallback to default kitchen image based on selected spec level of previous step
  const [displayImages, displayDisclaimer] = useMemo(() => {
    const optionImages = selectedOptions
      .find((option) => option.option.id === lastOptionId)
      ?.option.images.filter((i) => i.type.code === OptionImageType.Display);

    if (optionImages && optionImages.length > 0) {
      return [optionImages, standardDisclaimer];
    } else {
      return [getFallBackImageArray(selectedOptions), fallBackImageDisclaimer];
    }
  }, [lastOptionId, selectedOptions]);

  return (
    <StepWrapper
      title="Interior Options"
      description="Customize your interior spaces with available upgrades below"
      loading={loading}
      left={
        <ImageDisplay
          images={displayImages}
          disclaimerOverride={displayDisclaimer}
          placeholderOverride={<div css={Css.absolute.w100.h100.bgWhite.$}></div>}
          {...tid.imageDisplay}
        />
      }
      right={
        <>
          {groupedSectionOptions.map(({ title, selectedCount, options }) => (
            <AccordionOptionCard
              key={title}
              title={title}
              expanded={expandedCardTitle === title}
              onExpanded={() => setExpandedCardTitle(title)}
              counter={{ current: selectedCount, total: options.length }}
              children={
                <OptionListContainer>
                  {options.map((option, index) => {
                    const { id, optionCode, name, description, priceInCents: planOptionPriceInCents } = option;
                    // These top shouldn't show `included` but need to show an accumulated price of children if its > 0
                    const useSpecialPriceRules =
                      name.includes("Cabinet") || name.includes("Flooring") || name.includes("Countertop");

                    const conflictData = soConflictsByConflictingOptionCode[optionCode];
                    // If there aren't children we don't need any render calculations
                    if (option.options.length === 0) {
                      const selected = selectedOptionIds.includes(id);
                      const displayPriceInCents = selected
                        ? getReservedOptionPrice(selectedOptions, id)
                        : planOptionPriceInCents;

                      return (
                        <OptionListItem
                          key={name}
                          conflict={conflictData}
                          optionId={id}
                          title={name}
                          description={description}
                          selected={selected}
                          onClick={async () => {
                            await toggleOption(option);
                            setLastOptionId(option.id);
                          }}
                          priceInCents={displayPriceInCents}
                          {...tid[`optionListItem_${defaultTestId(name)}`]}
                        />
                      );
                    }

                    const childrenOptions = option.options
                      .map((o) => allOptions.find((option) => option.id === o.id)!)
                      .filter((o) => !!o);

                    const grandChildrenOptions = childrenOptions
                      .flatMap((o) => o.options)
                      .map((o) => allOptions.find((option) => option.id === o.id)!)
                      .filter((o) => !!o);

                    // If we are 3 levels deep we need to render the children as groups and grandchildren as individual options
                    if (grandChildrenOptions.length > 0) {
                      const children = grandChildrenOptions.map((o) => {
                        const group = childrenOptions.find((parent) => parent.id === o.parentOption?.id)!;
                        const selected = selectedOptionIds.includes(o.id);
                        const displayPriceInCents = selected
                          ? getReservedOptionPrice(selectedOptions, o.id)
                          : o.priceInCents;
                        return {
                          option: {
                            ...o,
                            priceInCents: displayPriceInCents,
                          },
                          selected,
                          onClick: async () => {
                            await toggleOption(o);
                            setLastOptionId(o.id);
                          },
                          groupName: group.name,
                        };
                      });

                      const groups = childrenOptions.map((group) => ({
                        groupName: group.name,
                        selected: children.some((c) => c.selected && c.groupName === group.name),
                        priceInCents: children.reduce((acc, child) => {
                          if (child.groupName !== group.name) return acc;
                          return acc + (child.selected ? child.option.priceInCents : 0);
                        }, 0),
                      }));

                      const totalPriceInCents = groups.reduce((acc, group) => acc + group.priceInCents, 0);

                      return (
                        <OptionListItem
                          key={id}
                          conflict={conflictData}
                          optionId={id}
                          title={name}
                          description={description}
                          selected={selectedOptionIds.includes(id)}
                          onClick={async () => {
                            await toggleOption(option);
                            setLastOptionId(option.id);
                          }}
                          priceInCents={
                            useSpecialPriceRules
                              ? totalPriceInCents === 0
                                ? ""
                                : totalPriceInCents
                              : totalPriceInCents
                          }
                          children={children}
                          groups={groups}
                          {...tid[`optionListItem_${defaultTestId(name)}`]}
                        />
                      );
                    }

                    // Otherwise we are only doing a single level of options
                    const children = childrenOptions.map((o) => {
                      const selected = selectedOptionIds.includes(o.id);
                      const displayPriceInCents = selected
                        ? getReservedOptionPrice(selectedOptions, o.id)
                        : o.priceInCents;
                      return {
                        option: {
                          ...o,
                          priceInCents: displayPriceInCents,
                        },
                        selected,
                        onClick: async () => {
                          await toggleOption(o);
                          setLastOptionId(o.id);
                        },
                      };
                    });

                    const totalPriceInCents = children.reduce(
                      (acc, child) => acc + (child.selected ? child.option.priceInCents : 0),
                      0,
                    );

                    return (
                      <OptionListItem
                        key={id}
                        conflict={conflictData}
                        optionId={id}
                        title={name}
                        description={description}
                        selected={selectedOptionIds.includes(id)}
                        onClick={async () => {
                          await toggleOption(option);
                          setLastOptionId(option.id);
                        }}
                        priceInCents={
                          useSpecialPriceRules
                            ? !selectedOptionIds.includes(id) && totalPriceInCents === 0
                              ? ""
                              : totalPriceInCents
                            : totalPriceInCents
                        }
                        children={children}
                        {...tid[`optionListItem_${defaultTestId(name)}`]}
                      />
                    );
                  })}
                </OptionListContainer>
              }
              {...tid.accordionOptionCard}
            />
          ))}
        </>
      }
    />
  );
}

const standardDisclaimer = "Image is representative for color only. Actual plan features and color locations may vary.";
const fallBackImageDisclaimer = (
  <span>
    <span css={Css.fw500.$}>Please note</span>: Interior renderings only shown in the Daniel plan. All images, design
    layouts, and renderings are intended for illustrative purposes only and may be subject to change.
  </span>
);

const getFallBackImageArray = (selectedOptions: ConfiguratorConfigurationOptionFragment[]) => {
  const specOpt = selectedOptions.filter((o) => o.option.planOptionType.name === "Spec Level")[0];
  const specLevelLabel = `${specOpt.option.parentOption!.name} ${specOpt.option.name} `;

  const kitchenImage = specOpt.option.images.filter(
    (i) => i.type.code === OptionImageType.Display && i.name.toLowerCase().trim() === "kitchen",
  )[0];

  return [
    {
      ...kitchenImage,
      name: specLevelLabel,
    },
  ];
};

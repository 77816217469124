import { useMemo } from "react";
import {
  BoylFlowSteps,
  ConfiguratorHeaderSteps,
  ConfiguratorValues,
  Step,
} from "src/pages/configurator/Configurator.context";
import {
  ConfigurationOptionInput,
  ConfiguratorConfigurationFragment,
  ConfiguratorConfigurationOptionFragment,
  ConfiguratorOptionConflictFragment,
  ConfiguratorOptionFragment,
  ConfiguratorPlanOptionTypeFragment,
  OptionType,
} from "~generated/graphql";
import { partition, uniqueByKey } from "~utils";

// Really this should be on the backend
export function calculateTotalPriceInCents(
  customerConfiguration: ConfiguratorConfigurationFragment,
  options: ConfiguratorConfigurationOptionFragment[],
) {
  return customerConfiguration.planPriceInCents + options.reduce((total, option) => total + option.priceInCents, 0);
}

export function getOptionConflictsByOptionCode(optionConflicts: ConfiguratorOptionConflictFragment[]) {
  const optionConflictsByOptionCode: Record<string, ConfiguratorOptionConflictFragment[]> = {};
  for (const optionConflict of optionConflicts) {
    const entry = optionConflictsByOptionCode[optionConflict.optionCode];
    if (entry) {
      entry.push(optionConflict);
    } else {
      optionConflictsByOptionCode[optionConflict.optionCode] = [optionConflict];
    }
  }
  return optionConflictsByOptionCode;
}

type ToggleOptionStatus = "deleted_by_conflict" | "deleted_by_toggle" | "newly_created";
export type ConfigurationOptionInputWithStatus = ConfigurationOptionInput & { status?: ToggleOptionStatus };

function getStepKeyFromOptionType(optionType: string): string {
  return Object.keys(BoylFlowSteps).find((key) => BoylFlowSteps[key].optionTypes.includes(optionType)) || "";
}

function getDownStreamPlanOptionTypes(step: string): string[] {
  const sortOrder = BoylFlowSteps[step].sortOrder;
  // get all boyl flow steps that are downstream of the current step
  const downstreamSteps = Object.values(BoylFlowSteps).filter((step) => step.sortOrder > sortOrder);
  return downstreamSteps.flatMap((step) => step.optionTypes);
}

// This takes a single option from a user click and figures out if it should be selected/deselected
// And what other options should be selected/deselected such as parent options or other options in the same option type
// TODO: this currently doesn't support required multi select option types
export function toggleOption(
  option: ConfiguratorOptionFragment,
  customerConfiguration: ConfiguratorConfigurationFragment,
  selectedOptions: ConfiguratorConfigurationOptionFragment[],
): ConfigurationOptionInputWithStatus[] {
  const allOptions = customerConfiguration.plan.optionTypes.flatMap((ot) => ot.options);
  const existingOptions = selectedOptions.map((o) => ({
    id: o.id,
    option: o.option.id,
    optionCode: o.option.optionCode,
  }));
  const optionConflictsByOptionCode = getOptionConflictsByOptionCode(customerConfiguration.plan.optionConflicts);

  // If the selected option is already chosen by the user
  if (existingOptions.find((o) => o.option === option.id)) {
    // Don't allow the user to deselect a required option on single select
    if (option.planOptionType.type.code === OptionType.SingleSelect && option.planOptionType.required) {
      return [];
    } else if (!option.planOptionType.required) {
      // delete the option and it's children
      const optionsToDelete = [option];
      let childOptions = option.options;
      while (childOptions.length > 0) {
        const mappedChildOptions = childOptions.map((co) => allOptions.find((o) => o.id === co.id)!);
        optionsToDelete.push(...mappedChildOptions);
        childOptions = mappedChildOptions.flatMap((co) => co.options);
      }

      return [
        ...existingOptions
          .map((eo) => ({ id: eo.id, option: eo.option })) // format back to expected type
          .filter((eo) => !optionsToDelete.find((otd) => otd.id === eo.option)),
        ...optionsToDelete
          .filter((otd) => existingOptions.find((eo) => eo.option === otd.id))
          .map((otd) => {
            const existingOption = existingOptions.find((eo) => eo.option === otd.id)!;
            return {
              id: existingOption.id,
              delete: true,
              option: otd.id,
              status: "deleted_by_toggle" as const,
            };
          }),
      ];
    }
  } else {
    // We are selecting a new option
    // First make sure the parent options are selected
    const newlySelectedOptions = [option];
    let existingOptionToDelete: ConfigurationOptionInputWithStatus[] = [];
    let traverseOption = option;
    // gets all the parents and adds them to newlySelectedOptions
    while (traverseOption.parentOption) {
      // FIXME: Warning:(165, 46) ESLint: Function declared in a loop contains unsafe references to variable(s) 'traverseOption'. (no-loop-func)
      const parentOption = allOptions.find((o) => o.id === traverseOption.parentOption!.id)!;
      if (!existingOptions.find((o) => o.option === parentOption.id)) {
        newlySelectedOptions.push(parentOption);
      }
      traverseOption = parentOption;
    }

    // Select newly selected option children that are marked as included
    let newChildOptions = newlySelectedOptions.flatMap(
      (delo) => delo.options.map((o) => allOptions.find((ao) => ao.id === o.id)!)!,
    );
    while (newChildOptions.length) {
      for (const newChildOptionOption of newChildOptions) {
        // If the child is included, select it if there isn't already a new single select plan option type selected
        if (
          newChildOptionOption.included &&
          (newChildOptionOption.planOptionType.type.code !== OptionType.SingleSelect ||
            !newlySelectedOptions.some((o) => o.planOptionType.id === newChildOptionOption.planOptionType.id))
        ) {
          newlySelectedOptions.push(newChildOptionOption);
        }
      }
      // Of the children that are included, check if any of their children are included
      newChildOptions = newChildOptions
        .filter((o) => o.included)
        .flatMap((cdo) => cdo.options.map((o) => allOptions.find((ao) => ao.id === o.id)!)!);
    }

    // If the newly selected options are in a single selection option type, remove the other options in the group
    for (const newOption of newlySelectedOptions) {
      if (newOption.planOptionType.type.code === OptionType.SingleSelect) {
        existingOptionToDelete = [
          ...existingOptionToDelete,
          ...existingOptions
            .filter((eo) => !!newOption.planOptionType.options.find((o) => o.id === eo.option))
            .map((eo) => ({
              id: eo.id,
              option: eo.option,
              delete: true,
              status: "deleted_by_toggle" as const,
            })),
        ];
      }
    }

    // This is the nuclear option code block for removing all options downstream of a selection
    // get current step option plan type
    const currentStep = getStepKeyFromOptionType(option.planOptionType.name);

    // get all downstream plan option types
    const downStreamPlanOptionTypes = getDownStreamPlanOptionTypes(currentStep);

    // get all downstream existing options
    const downStreamOptions = existingOptions.filter((eo) =>
      downStreamPlanOptionTypes.includes(allOptions.find((o) => o.id === eo.option)!.planOptionType.name),
    );
    // filter out the base configuration options
    const baseConfiguratorOptions = customerConfiguration.plan.baseConfiguration?.options || [];
    const nonBaseConfigurationDownStreamOptions = downStreamOptions.filter(
      (eo) => !baseConfiguratorOptions.find((bco) => bco.option.id === eo.option),
    );

    // if there are existing options remaining check confirm they are not "included"
    for (const eo of nonBaseConfigurationDownStreamOptions) {
      const optionsToDelete = allOptions.find((o) => o.id === eo.option)!;
      if (!optionsToDelete.included) {
        existingOptionToDelete.push({
          id: eo.id,
          option: eo.option,
          delete: true,
          status: "deleted_by_toggle" as const,
        });
        // if we are deleting a required option
        if (optionsToDelete.planOptionType.required) {
          // get all the options of that required option type
          for (const ro of optionsToDelete.planOptionType.options) {
            const requiredOptionChild = allOptions.find((o) => o.id === ro.id)!;
            // if the option is included option select it
            if (requiredOptionChild.included) {
              // and the parent option is selected
              const parentOption = requiredOptionChild.parentOption;
              if (newlySelectedOptions.find((o) => o.id === parentOption?.id)) {
                newlySelectedOptions.push(requiredOptionChild);
                break;
              }
            }
            // if it does not have a parent option and is a base configuration option, select it
            else if (!requiredOptionChild.parentOption) {
              if (baseConfiguratorOptions.find((bco) => bco.option.id === requiredOptionChild.id)) {
                newlySelectedOptions.push(requiredOptionChild);
                break;
              }
            }
          }
        }
      }
    }
    // This array is used to keep track of options that are deselected from a conflict to show in the toast
    const deselectedConflictItems: { id: string; option: string; optionCode: string }[] = [];
    // Find existing options that are in conflict and add them to list of existing options to delete
    for (const newOption of newlySelectedOptions) {
      const conflictOptions = optionConflictsByOptionCode[newOption.optionCode];
      if (conflictOptions) {
        // Loop through all options in conflict with newOption.optionCode
        for (const oc of conflictOptions) {
          const optionToDeselect = existingOptions.find((eo) => eo.optionCode === oc.conflictOptionCode);
          if (optionToDeselect) {
            deselectedConflictItems.push(optionToDeselect);
            existingOptionToDelete.push({
              id: optionToDeselect.id,
              option: optionToDeselect.option,
              delete: true,
              status: "deleted_by_conflict" as const,
            });
          }
        }
      }
    }

    /*
      Delete the children of the existing options to delete
      This loops through the children and children children and checks if they exists, if they do, add them to the list of options to delete
    */
    const deletedOptions = existingOptionToDelete.map((eotd) => allOptions.find((o) => o.id === eotd.option)!);
    let childDeletedOptions = deletedOptions.flatMap(
      (delo) => delo.options.map((o) => allOptions.find((ao) => ao.id === o.id)!)!,
    );

    while (childDeletedOptions.length) {
      for (const newlyDeletedOption of childDeletedOptions) {
        const existingOption = existingOptions.find((eo) => eo.option === newlyDeletedOption.id);
        if (existingOption) {
          existingOptionToDelete.push({
            id: existingOption.id,
            option: existingOption.option,
            delete: true,
            status: "deleted_by_toggle" as const,
          });
        }
      }
      childDeletedOptions = childDeletedOptions.flatMap(
        (cdo) => cdo.options.map((o) => allOptions.find((ao) => ao.id === o.id)!)!,
      );
    }

    return [
      ...existingOptions
        .map((eo) => ({ id: eo.id, option: eo.option })) // format back to expected type
        .filter((eo) => !existingOptionToDelete.find((eod) => eod.id === eo.id)),
      ...uniqueByKey(existingOptionToDelete, "id"),
      ...newlySelectedOptions.map((o) => ({ option: o.id, status: "newly_created" as const })),
    ];
  }
  return [];
}

export function optionIsSiteCondition(option: ConfiguratorConfigurationOptionFragment) {
  return option.option.planOptionType.name === "Site Condition";
}

export function getConflictsByConflictingOptionCode(
  selectedOptions: ConfiguratorValues["selectedOptions"],
  optionConflictsByOptionCode: ConfiguratorValues["optionConflictsByOptionCode"],
) {
  const _soConflictsByConflictingOptionCode: Record<string, ConfiguratorOptionConflictFragment> = {};
  for (const option of selectedOptions) {
    const {
      option: { optionCode },
    } = option;
    const conflict = optionConflictsByOptionCode[optionCode];
    if (conflict) {
      for (const co of conflict) {
        _soConflictsByConflictingOptionCode[co.conflictOptionCode] = co;
      }
    }
  }

  return _soConflictsByConflictingOptionCode;
}

// Names are hard

// NOTE: We CAN do this in context. But since we're already mapping selectedOptionIds in each step it would be best
// be part of a bigger refactor as components are consolidated.
export function useMemoizedConflictsByConflictingOptionCode(
  selectedOptions: ConfiguratorValues["selectedOptions"],
  optionConflictsByOptionCode: ConfiguratorValues["optionConflictsByOptionCode"],
) {
  return useMemo(() => {
    return getConflictsByConflictingOptionCode(selectedOptions, optionConflictsByOptionCode);
  }, [selectedOptions, optionConflictsByOptionCode]);
}

export function getLastEnabledHeaderStepIndex(
  selectedOptions: ConfiguratorConfigurationOptionFragment[] | null,
  planOptionTypes?: ConfiguratorPlanOptionTypeFragment[] | null,
) {
  // If the config isn't created yet, then the first step is the last enabled step
  if (!selectedOptions || !planOptionTypes) {
    return 0;
  }

  // If the config can be reserved, then all steps should be enabled
  const disabledReasons = getStepDisabledReasons(selectedOptions, planOptionTypes);

  if (disabledReasons.length === 0) {
    return Object.keys(ConfiguratorHeaderSteps).length;
  }
  // Otherwise, find the boyl steps that are disabled
  const disableBoylSteps = Object.values(BoylFlowSteps).filter((step) => {
    for (const ot of step.optionTypes) {
      for (const disabledReason of disabledReasons) {
        if (disabledReason.type.name === ot) {
          return true;
        }
      }
    }
    return false;
  });
  const disableBoylStepTitles = disableBoylSteps.map((step) => step.title);

  // Then find the first header step that has a disabled BOYL step
  return Object.values(ConfiguratorHeaderSteps).findIndex((step) => {
    for (const st of step.stepTitles) {
      if (disableBoylStepTitles.includes(st)) {
        return true;
      }
    }
    return false;
  });
}

export type DisabledStepReason = {
  type: ConfiguratorPlanOptionTypeFragment;
  disabledReason: string;
};

// Return disabled reasons based on the plan option types relevant to current step.
// Does not care about conflicts or parent options. Those are handled in toggleOption()
export function getStepDisabledReasons(
  selectedOptions: ConfiguratorConfigurationOptionFragment[],
  planOptionTypes: ConfiguratorPlanOptionTypeFragment[],
  currentStep?: Step,
): DisabledStepReason[] {
  const selectedOptionIds = selectedOptions.map(({ option }) => option.id);

  // ----Disabled because current step option types are required but not selected----
  // Filter option types from a specific step if currentStep is provided
  const stepPlanOptionTypes = currentStep
    ? // Note: BoylFlowSteps does not give us an id value to compare
      planOptionTypes.filter((ot) => currentStep.optionTypes.includes(ot.name))
    : planOptionTypes;

  const requiredOptionTypesWithoutOptions = stepPlanOptionTypes
    .filter((ot) => ot.required)
    .filter((ot) => !selectedOptionIds.some((soi) => ot.options.map((o) => o.id).includes(soi)))
    .map((ot) => {
      return {
        type: ot,
        disabledReason: `${ot.name} is required but not selected`,
      };
    });

  // ----Disabled because a single select has been selected more than once----
  const singleSelectsWithManyOptions = stepPlanOptionTypes
    .filter((ot) => ot.type.code === OptionType.SingleSelect)
    .filter((ot) => selectedOptionIds.filter((soi) => ot.options.map((o) => o.id).includes(soi)).length > 1)
    .map((ot) => {
      return {
        type: ot,
        disabledReason: `${ot.name} is a single select but multiple options have been selected`,
      };
    });

  return [...requiredOptionTypesWithoutOptions, ...singleSelectsWithManyOptions];
}

/*
  This functions goal is to resolve when there are 2 sets of configuration option changes. Supported cases:
   - When a user has toggled an option on and off and we don't want to delete the original option
   - When an original option has been added back and it has downstream options that need to be added back
*/
export function mergeModifiedConfigurationOptions(
  customerConfiguration: ConfiguratorConfigurationFragment,
  currentStepOptionTypes: string[],
  newOptions: ConfigurationOptionInputWithStatus[],
  existingOptions?: ConfigurationOptionInputWithStatus[],
): ConfigurationOptionInputWithStatus[] {
  if (!existingOptions) return newOptions;

  let returnOptions = existingOptions;

  // For deleted options, we want to remove them if they are newly created or overwrite them if they are existing
  const newlyDeletedOptionIds = newOptions
    .filter((o) => o.status && ["deleted_by_conflict", "deleted_by_toggle"].includes(o.status))
    .map((o) => o.option);

  returnOptions = returnOptions
    .filter((o) => !(newlyDeletedOptionIds.includes(o.option) && o.status === "newly_created"))
    .map((o) => {
      if (newlyDeletedOptionIds.includes(o.option)) {
        return newOptions.find((no) => no.option === o.option)!;
      }
      return o;
    });

  // For newly created options, we want to add them if they don't exist, or undelete them if they do
  const [existingNewlyCreatedOptions, newNewlyCreatedOptions] = partition(
    newOptions.filter((o) => o.status === "newly_created"),
    (no) => !!existingOptions.find((eo) => eo.option === no.option),
  );
  const existingNewlyCreatedOptionIds = existingNewlyCreatedOptions.map((o) => o.option);

  // clear out delete and status on existing options that are newly created
  returnOptions = returnOptions.map((o) => {
    if (existingNewlyCreatedOptionIds.includes(o.option)) {
      return {
        ...o,
        status: undefined,
        delete: undefined,
      };
    }
    return o;
  });

  // If any of the undeleted options had children that are in the current configuration options on another page, we need to add them back
  if (existingNewlyCreatedOptions.length > 0) {
    const allOptions = customerConfiguration.plan.optionTypes.flatMap((optionType) => optionType.options);
    let existingChildOptions = existingNewlyCreatedOptions
      .map((enco) => allOptions.find((ao) => ao.id === enco.option)!)
      .flatMap((o) => o.options.map((co) => allOptions.find((ao) => ao.id === co.id)!));
    while (existingChildOptions.length) {
      const newExistingChildOptions = [];
      for (const existingChildOptionOption of existingChildOptions) {
        // If the child is on another page and it has been deleted
        const index = returnOptions.findIndex(
          (eo) =>
            eo.option === existingChildOptionOption.id &&
            eo.status &&
            ["deleted_by_conflict", "deleted_by_toggle"].includes(eo.status),
        );
        if (!currentStepOptionTypes.includes(existingChildOptionOption.planOptionType.name) && index !== -1) {
          // Add it back
          returnOptions[index] = {
            ...returnOptions[index],
            status: undefined,
            delete: undefined,
          };

          // If it's a single select, remove all other options of the same type this could be caused by an included option
          if (existingChildOptionOption.planOptionType.type.code === OptionType.SingleSelect) {
            returnOptions = returnOptions.filter((ro) => {
              const o = allOptions.find((ao) => ao.id === ro.option)!;
              return (
                existingChildOptionOption.id === ro.option ||
                o.planOptionType.id !== existingChildOptionOption.planOptionType.id
              );
            });
          }

          // Then check it's children
          newExistingChildOptions.push(
            ...existingChildOptionOption.options.map((co) => allOptions.find((ao) => ao.id === co.id)!),
          );
        }
      }
      existingChildOptions = newExistingChildOptions;
    }
  }

  return [...returnOptions, ...newNewlyCreatedOptions];
}

// Finds option for a given id and returns its priceInCents.
export function getReservedOptionPrice(
  reservedOptions: ConfiguratorConfigurationOptionFragment[],
  planOptionId: string,
): number {
  if (reservedOptions.length === 0) {
    console.error(`Reserved configuration does not have any selected options`);
    return 0;
  }

  const resConfiguratorOption = reservedOptions.find((o) => o.option.id === planOptionId);

  if (!resConfiguratorOption) {
    console.error(`Reserved configuration does not have option ${planOptionId} to find reserved price for`);
    return 0;
  } else {
    return resConfiguratorOption.priceInCents;
  }
}

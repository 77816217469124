import startCase from "lodash/startCase";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ThreeDTourLightBox } from "src/components/molecules/ThreeDTourLightbox";
import { useModal } from "src/contexts";
import {
  ConfiguratorStepper,
  ImageDisplay,
  ImageDisplayImageProps,
  ModalWrapper,
  StepWrapper,
  ToggleComponent,
  WarningModal,
  WhatsIncludedContent,
} from "src/pages/configurator/components";
import { useConfiguratorContext } from "src/pages/configurator/Configurator.context";
import { Button, Checkbox, HBLoadingSpinner, SelectableOptionCard, TooltipV2 } from "~components";
import { Css, Palette } from "~generated/css";
import {
  ConfiguratorConfigurationOptionFragment,
  MarketPlanStepPlanFragment,
  MarketPlanStepPropertyDetailsFragment,
  OptionImageType,
  useMarketPlansStepQuery,
} from "~generated/graphql";
import {
  defaultTestId,
  getPlanBathroomRangeString,
  getPlanBedroomRangeString,
  getPlanSqftRangeString,
  useTestIds,
} from "~utils";
import { LearnMoreContent, PlanContent } from "../components/LearnMoreContent";

/**
 * MarketPlansStep step is unique enough to have its own query to fetch all plans for a market.
 *
 * Renders its own stepper to better control when a configuration is created without excessive logic in context
 */
export function MarketPlansStep() {
  const { marketNameSlug } = useParams();
  const { loading: configurationLoading, plan, setPlan, isReversed, selectedOptions } = useConfiguratorContext();
  const { data, loading, error } = useMarketPlansStepQuery({
    variables: {
      filter: { name: transformSlugForQuery(marketNameSlug!) },
    },
  });

  if (loading || configurationLoading) {
    return <HBLoadingSpinner />;
  }

  if (error) {
    console.error(error.message);
    return null;
  }

  const plans = data?.market?.developments?.[0]?.plans ?? [];
  let initialPlan = plans[0];
  if (plan) {
    const p = plans.find((p) => p.id === plan.id);
    if (p) {
      initialPlan = p;
    }
  }

  return (
    <MarketPlansStepView
      plans={plans}
      setPlan={setPlan}
      initialPlan={initialPlan}
      isLoading={loading || configurationLoading}
      isReversed={isReversed}
      selectedOptions={selectedOptions}
    />
  );
}

function MarketPlansStepView({
  plans = [],
  initialPlan,
  setPlan,
  isLoading,
  isReversed,
  selectedOptions,
}: {
  plans: MarketPlanStepPlanFragment[];
  initialPlan: MarketPlanStepPlanFragment;
  setPlan: (planId: string, isReversed?: boolean) => void;
  isLoading: boolean;
  isReversed: boolean;
  selectedOptions: ConfiguratorConfigurationOptionFragment[];
}) {
  const [showWhatsIncludedModal, setShowWhatsIncludedModal] = useState(false);
  // warning modal hook
  const { showModal, closeModal } = useModal();
  const [showLearnModal, setShowLearnModal] = useState<MarketPlanStepPlanFragment | undefined>(undefined);
  const [showTour, setShowTour] = useState(false);
  const tid = useTestIds({}, "market-plans-step");
  /**
   * Handle Floor Plans
   */
  // used to determine whether image or floor plan is shown (toggle)
  const [viewFloorplan, setViewFloorplan] = useState(false);
  // currently selected plan in the UI (not the saved plan on the configuration) although this is in sync
  const [selectedPlan, setSelectedPlan] = useState(initialPlan);

  const [localIsReversed, setLocalIsReversed] = useState(isReversed);

  function _showWarningModal(selectedPlanId: string, isReversed: boolean) {
    showModal(<WarningModal onContinue={() => setPlan(selectedPlan.id, isReversed)} onClose={closeModal} />);
  }

  // access current plan's floor plans
  const planFloorPlans: ImageDisplayImageProps[] = useMemo(
    () => selectedPlan.floorPlans?.filter((fp) => !!fp) || [],
    [selectedPlan],
  );

  // PlanPreviewCard data
  // Map planOptionType `Exterior Scheme` images onto `Exterior` planOptionType
  const selectedElevationPreviews: ImageDisplayImageProps[] = useMemo(() => {
    const availableExteriorOptions = selectedPlan.optionTypes?.[0]?.options || [];

    const exteriorO = availableExteriorOptions[0];
    const { id, options } = exteriorO;
    const schemeOptions = options.filter((o) => o.planOptionType.name === "Exterior Scheme");

    let imageUrl;
    for (const scheme of schemeOptions) {
      const image = scheme.images.find((i) => i.type.code === OptionImageType.Display);
      if (image) {
        imageUrl = image.imageUrl;
        break;
      }
    }

    const description = selectedPlan?.description;
    const descriptionSentences = description?.split(". ");
    // conditionally omit the second sentece if the first is too long
    // (to select only the first sentence of the Catalina description)
    const twoSentenceDescription =
      descriptionSentences &&
      `${descriptionSentences?.[0]}. ${descriptionSentences?.[0].length < 120 ? descriptionSentences?.[1] : ""}.`;

    return [
      {
        id,
        description: twoSentenceDescription,
        imageUrl,
      } as ImageDisplayImageProps,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan, showLearnModal]);

  return (
    <>
      <StepWrapper
        title="Floor Plan"
        loading={isLoading}
        description={
          <div>
            Choose from one of our thoughtfully designed floor plans to get started. Next, you can pick your exterior
            style and add on more features.{" "}
            <Button
              variant="inline"
              onClick={() => setShowWhatsIncludedModal(true)}
              size="small"
              {...tid.whatsIncluded_button}
            >
              What's included?
            </Button>
          </div>
        }
        left={
          <>
            {/* Toggle Container */}
            <div css={Css.z1.absolute.topPx(24).leftPx(48).$}>
              <Button variant="shadowed" iconLeft="floorplan-small" onClick={() => setShowTour(true)}>
                <p css={Css.ml1.$}>3D Plan Tour</p>
              </Button>
              {showTour && (
                <ThreeDTourLightBox tourUrl={selectedPlan.tourUrl || ""} onClose={() => setShowTour(false)} />
              )}
            </div>
            <div css={Css.z1.absolute.topPx(24).left("50%").add("transform", "translate(-50%)").$}>
              <ToggleComponent
                offLabel="Exterior"
                onLabel="Floor Plans"
                value={viewFloorplan}
                onClick={() => setViewFloorplan(!viewFloorplan)}
                {...tid.toggle}
              />
            </div>
            <div css={Css.df.fdc.h100.w100.if(viewFloorplan).bgOffWhite.$}>
              <ImageDisplay
                images={viewFloorplan ? planFloorPlans : selectedElevationPreviews}
                title={viewFloorplan ? "\xa0" : "Available architecture styles"}
                disclaimerOverride={
                  <span>
                    All images, design layouts, and renderings are intended for illustrative purposes only and may be
                    subject to change.{" "}
                    <span css={Css.underline.fw700.$} id="DisplayOptions">
                      Some upgrades are shown.
                    </span>
                    <TooltipV2 positionStrategy="fixed" anchorSelect="#DisplayOptions">
                      <div css={Css.body14.sansSerif.add("fontStyle", "normal").$}>
                        <span css={Css.fw700.$}>The following are shown:</span>
                        <ul>
                          <li>Garage with Storage</li>
                          <li>Standing Seam Roof</li>
                          <li>9ft Ground Floor</li>
                          <li>Cable Railing (Contemporary)</li>
                          {/* TODO make this dynamic in the future */}
                        </ul>
                      </div>
                    </TooltipV2>
                  </span>
                }
                showDisclaimer={!viewFloorplan}
                displayingStaticSvgs={false}
                displayingDynamicSvgs={viewFloorplan}
                isReversed={localIsReversed}
                {...tid.imageDisplay}
              />
            </div>
          </>
        }
        right={plans.map((plan) => (
          <SelectableOptionCard
            key={plan.id}
            title={plan.name}
            titleAction={{
              triggerText: "Learn more",
              onClick: () => setShowLearnModal(plan),
            }}
            description={<PlanStepCardContent {...plan} />}
            onClick={() => {
              setSelectedPlan(plan);
            }}
            selected={selectedPlan?.id === plan.id}
            {...tid[`selectableOptionCard_${defaultTestId(plan.name)}`]}
          />
        ))}
        rightFooter={
          <div css={Css.df.fdr.mt0.$}>
            <Checkbox selected={localIsReversed} onClick={() => setLocalIsReversed(!localIsReversed)} />
            <p css={Css.ml1.$}>Reverse Plan</p>
          </div>
        }
      />
      <ConfiguratorStepper
        actionBts={[
          {
            onClick: () => {
              // and selected plan is not the current plan
              if (selectedOptions.length > 0 && selectedPlan.id !== initialPlan.id) {
                _showWarningModal(selectedPlan.id, localIsReversed);
              } else {
                setPlan(selectedPlan.id, localIsReversed);
              }
            },
            disabled: !selectedPlan,
            children: "Next",
            key: "market-plans-continue",
            size: "large",
            btnFontSize: "sm",
            variant: "secondary",
            isLoading: isLoading,
          },
        ]}
      />
      {/* Modals */}
      <ModalWrapper
        isOpen={showWhatsIncludedModal}
        close={() => setShowWhatsIncludedModal(false)}
        bg={Palette.OffWhite}
      >
        <WhatsIncludedContent />
      </ModalWrapper>
      <ModalWrapper isOpen={showLearnModal !== undefined} close={() => setShowLearnModal(undefined)} bg={Palette.White}>
        {showLearnModal && <LearnMoreContent planContent={gatherLearnMoreContentProps(showLearnModal)} />}
      </ModalWrapper>
    </>
  );
}

// Replace `-` with ` ` and startCase
const transformSlugForQuery = (slug: string) => {
  return startCase(slug.replace(/-/g, " "));
};

// Could be cool to have the config layout coordinate a layout group where our header/footer come in top/bottom

type PlanStepCardContentProps = Pick<
  MarketPlanStepPropertyDetailsFragment,
  | "minBedrooms"
  | "maxBedrooms"
  | "minFullBathrooms"
  | "maxFullBathrooms"
  | "minHalfBathrooms"
  | "maxHalfBathrooms"
  | "minSqft"
  | "maxSqft"
  | "priceInCents"
>;

const PlanStepCardContent = (props: PlanStepCardContentProps) => {
  const { priceInCents } = props;

  return (
    <div css={Css.df.fdc.gap("4px").$}>
      <div css={Css.body14.lhPx(22).gray700.$}>{`Starting at ${formatAmount(priceInCents)}`}</div>
      <div css={Css.df.flexWrap("wrap").colGap(3).rowGap(1).$}>
        <div css={Css.df.aic.$}>
          <p css={Css.body12.fw400.lhPx(28).gray700.$}>{getPlanBedroomRangeString(props)}</p>
        </div>
        <div css={Css.df.aic.$}>
          <p css={Css.body12.fw400.lhPx(28).gray700.$}>{getPlanBathroomRangeString(props)}</p>
        </div>
        <div css={Css.df.aic.$}>
          <p css={Css.body12.fw400.lhPx(28).gray700.$}>{getPlanSqftRangeString(props)}</p>
        </div>
      </div>
    </div>
  );
};

function formatAmount(priceInCents: number | string) {
  if (typeof priceInCents === "string") return priceInCents;

  return `${(priceInCents / 100).toLocaleString("EN", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  })}`;
}

function gatherLearnMoreContentProps(plan: MarketPlanStepPlanFragment): PlanContent {
  // lets get baths
  let bathValue = `${plan.maxFullBathrooms}`;
  if (plan.minFullBathrooms !== plan.maxFullBathrooms) {
    bathValue = plan.minFullBathrooms + "-" + plan.maxFullBathrooms;
  }

  // Bedrooms
  let bedValue = `${plan.maxBedrooms}`;
  if (plan.maxBedrooms !== plan.minBedrooms) {
    bedValue = plan.minBedrooms + "-" + plan.maxBedrooms;
  }

  // Garages
  let garagePortValue = `${plan.maxGaragePort}`;
  if (plan.maxGaragePort !== plan.minGaragePort) {
    garagePortValue = plan.minGaragePort + "-" + plan.maxGaragePort;
  }

  let garageAttachedValue = `${plan.maxGarageAttached}`;
  if (plan.maxGarageAttached !== plan.minGarageAttached) {
    garageAttachedValue = plan.minGarageAttached + "-" + plan.maxGarageAttached;
  }

  // TODO: make this not hardcoded in the future
  let storyValue = "1";
  if (
    plan.name === "The Catalina" ||
    plan.name === "The Jacquelyn" ||
    plan.name === "The Finn-Front Views" ||
    plan.name === "The Finn-Rear Views"
  ) {
    storyValue = "2";
  }

  // Img URL
  const availableExteriorOptions = plan.optionTypes?.[0]?.options || [];
  const schemeOption = availableExteriorOptions[0].options.find((o) => o.planOptionType.name === "Exterior Scheme");
  const image = schemeOption?.images.find((i) => i.type.code === OptionImageType.Display);

  return {
    name: plan.name,
    imgUrl: image?.imageUrl,
    features: plan?.features || [],
    description: plan?.description || "",
    formattedBathrooms: `${bathValue} baths`,
    formattedBedrooms: `${bedValue} beds`,
    formattedSqft: `${plan.minSqft}+ sq Ft`,
    formattedGaragePort: `${garagePortValue} Car Carport`,
    formattedGarageAttached: `${garageAttachedValue} Car Garage`,
    formattedStory: `${storyValue} Story`,
  };
}

import { motion } from "framer-motion";
import { ReactNode } from "react";
import { Tag, TagProps, TransparentHBLoadingSpinnerOnly } from "~components";
import { Css } from "~generated/css";

type StepWrapperProps = {
  /** Component to rendered in the left column */
  left: ReactNode;
  /** Right column tag. */
  tag?: TagProps;
  /** Right column title under tag. */
  title: string;
  /** Right column description under title. */
  description: string | ReactNode;
  /** Component to rendered in the right column under description. */
  right: ReactNode;
  /** Component to rendered under the right column. */
  rightFooter?: ReactNode;
  /** Lot steps don't use standard border logic. Taking a shortcut here until we're asked to do a full implementation. Lot steps are not part of our configurator flow but are used for stakeholder showcases */
  hideBorder?: boolean;
  // FIXME: Would it be better to pull this from context since we KNOW this component only exists within?
  /** Renders Loading spinner over right column. Blocks pointer events. */
  loading?: boolean;
};

// Keeping it separate from ConfiguratorStep so children animate in
export function StepWrapper(props: StepWrapperProps) {
  const { loading = false, left, right, rightFooter, description, title, tag, hideBorder = false } = props;
  return (
    <div
      css={
        Css.bgWhite.fg1.overflowHidden.asc.h100.w100
          .maxwPx(1584)
          .dg.gtc("repeat(16, 1fr)")
          .gap4.boxShadow("-4px -4px 16px rgba(201, 201, 201, 0.2), 4px 4px 32px rgba(228, 228, 231, 0.2)").$
      }
    >
      {/* LEFT */}
      <div css={Css.gc("1 / 12").overflowHidden.relative.z0.df.fdc.bgGray50.$}>{left}</div>
      {/* RIGHT */}
      <motion.div
        layoutScroll
        id="right-container"
        css={Css.gc("12 / 17").overflowAuto.relative.z1.df.fdc.pt4.pb5.pr4.$}
      >
        <motion.div layout="position" css={Css.df.fdc.gap3.w100.$}>
          <div>
            {tag && (
              <div>
                <Tag key={tag.children} {...tag} />
              </div>
            )}
            <div css={Css.header32.lhPx(48).$}>{title}</div>
            <div css={Css.fsPx(14).fw300.lhPx(22).gray700.$}>{description}</div>
          </div>
          {/* Center spinner over right content to indicate loading */}
          {loading && (
            <motion.div css={Css.w100.h100.z3.absolute.df.aic.jcc.$}>
              <TransparentHBLoadingSpinnerOnly />
            </motion.div>
          )}
          <motion.div
            layout="position"
            css={{
              ...Css.df.fdc.$,
              ...(!hideBorder && Css.bGray700.ba.br4.addIn("> div:not(:last-child)", Css.br0.bb.bGray700.$).$),
              // Loading state will block pointer events while selections are being saved
              ...(loading && Css.pointerNone.italic.bgColor("rgba(0,0,0,0.2)").add("filter", "opacity(.5)").$),
            }}
          >
            {right}
          </motion.div>
          {rightFooter}
        </motion.div>
      </motion.div>
    </div>
  );
}

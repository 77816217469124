import { Css } from "~generated/css";
import { CustomerConfigurationStatus, OptionImageType, SummaryPrintConfigurationQuery } from "~generated/graphql";
import { PDFPage } from "./PDFPage";
import { PrintHeader } from "./PrintHeader";
import { PrintListItem } from "./PrintListItem";

export function InteriorReport({
  data,
  shouldDisplayPricing,
}: {
  data: SummaryPrintConfigurationQuery;
  shouldDisplayPricing: boolean;
}) {
  const {
    customerConfiguration: { name, options, customer, reservedAt, expiredAt, status },
  } = data;

  const designPackageOption = options.find((so) => so.option.planOptionType.name === "Design Package");

  const images = options
    .filter((o) => o.option.planOptionType.name === "Spec Level")
    .flatMap((o) => o.option.images)
    .filter((i) => i.type.code === OptionImageType.Display);

  return (
    <>
      {images.map((i) => (
        <PDFPage>
          <div css={Css.relative.h100.w100.bgWhite.$}>
            <div css={Css.df.ma.fdc.gap2.p1.pb4.asc.maxwPx(1440).$}>
              <PrintHeader
                expiredAt={expiredAt!}
                isConfigurationExpired={status === CustomerConfigurationStatus.Expired}
                reservedAt={reservedAt!}
                configurationName={name}
                customer={customer!}
              />
              {designPackageOption && (
                <PrintListItem
                  title="Design Scheme"
                  priceInCents={shouldDisplayPricing ? designPackageOption.priceInCents || "Included" : ""}
                  items={[
                    {
                      title: `${designPackageOption.option.name}`,
                    },
                  ]}
                  headerStyling
                />
              )}
              <div css={Css.body12.gray700.fw300.$}>{i.name}</div>
              <img src={i.imageUrl} />
            </div>
            {/* Disclaimer */}
            <div id="disclaimer" css={Css.body12.pt2.tc.gray700.$}>
              All images, design layouts, and renderings are intended for illustrative purposes only and may be subject
              to change. Interior images are based on The Daniel plan. Layout and details will vary depending on
              selected floor plan.
            </div>
          </div>
        </PDFPage>
      ))}
    </>
  );
}

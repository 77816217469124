import { Css } from "~generated/css";
import {
  CustomerConfigurationStatus,
  SummaryPrintConfigurationFragment,
  SummaryPrintConfigurationQuery,
} from "~generated/graphql";
import { currencyFormatter, getProgramDataTotalStrings } from "~utils";
import { PDFPage } from "./PDFPage";
import { PrintHeader } from "./PrintHeader";
import { InteriorTopUpgradeOptionWithChildrenProps, PrintListItem } from "./PrintListItem";

// TODO: Consolidate cards with SummaryCards.tsx
export function SummaryReport({
  data,
  shouldDisplayPricing,
}: {
  data: SummaryPrintConfigurationQuery;
  shouldDisplayPricing: boolean;
}) {
  const {
    customerConfiguration: {
      name,
      options,
      plan,
      planPriceInCents,
      customer,
      reservedAt,
      expiredAt,
      status,
      isReversed,
    },
  } = data;

  return (
    <PDFPage>
      <div css={Css.relative.h100.w100.bgWhite.$}>
        <div css={Css.df.ma.fdc.gap2.p1.pb4.asc.maxwPx(1440).$}>
          <PrintHeader
            expiredAt={expiredAt!}
            isConfigurationExpired={status === CustomerConfigurationStatus.Expired}
            reservedAt={reservedAt!}
            configurationName={name}
            customer={customer!}
          />
          {/* Customer and Build section */}
          {/* TODO: These cards */}
          <div>
            <PlanExteriorCard
              plan={plan}
              planPriceInCents={planPriceInCents}
              shouldDisplayPricing={shouldDisplayPricing}
              options={options}
              isReversed={isReversed}
            />
            <InteriorCard options={options} shouldDisplayPricing={shouldDisplayPricing} />
            <AddOnsCard options={options} shouldDisplayPricing={shouldDisplayPricing} />
            <SiteConditionsCard options={options} shouldDisplayPricing={shouldDisplayPricing} />
          </div>
          <div css={Css.add({ breakInside: "avoid-page" }).$}>
            {/* Plan Cost and Estimated Total Cost */}
            {shouldDisplayPricing && (
              <div css={Css.df.fdc.aife.ptPx(12).add({ breakInside: "avoid-page" }).$}>
                <div css={Css.df.colGap1.mb("12px").$}>
                  <h6 css={Css.header20.fw500.lhPx(32).gap1.$}>Plan Cost</h6>
                  <h6 css={Css.header20.fw500.lhPx(32).gap1.$}>
                    {currencyFormatter(
                      options
                        .filter((so) => so.option.planOptionType.name !== "Site Condition")
                        .reduce((acc, { priceInCents }) => acc + priceInCents, planPriceInCents),
                    )}
                  </h6>
                </div>
                <div css={Css.df.colGap1.$}>
                  <h6 css={Css.header20.fw500.lhPx(32).gap1.$}>
                    <sup>*</sup>Est. Total Cost
                  </h6>
                  <h6 css={Css.header20.fw500.lhPx(32).gap1.$}>
                    {currencyFormatter(options.reduce((acc, { priceInCents }) => acc + priceInCents, planPriceInCents))}
                  </h6>
                </div>
              </div>
            )}
            {/* Disclaimer */}
            <div id="disclaimer" css={Css.body12.pt2.tc.gray700.$}>
              <sup>*</sup>Estimated total cost includes site-specific conditions that may vary based on lot conditions.
              All options and prices will be confirmed by a Homebound representative prior to final signature.
            </div>
          </div>
        </div>
      </div>
    </PDFPage>
  );
}

type PlanExteriorCardProps = { shouldDisplayPricing: boolean } & Pick<
  SummaryPrintConfigurationFragment,
  "plan" | "planPriceInCents" | "options" | "isReversed"
>;

export const PlanExteriorCard = ({
  plan,
  planPriceInCents,
  options,
  isReversed,
  shouldDisplayPricing,
}: PlanExteriorCardProps) => {
  const { bedrooms, bathrooms, sqft } = getProgramDataTotalStrings(options);
  const planDescription = `${plan?.name}\n${bedrooms}, ${bathrooms} & ${sqft} sqft`;

  const exteriorOption = options.find((so) => so.option.planOptionType.name === "Exterior");
  const exteriorSchemeOption = options.find((so) => so.option.planOptionType.name === "Exterior Scheme");
  const floorPlanOptions = options.filter((so) => so.option.planOptionType.name === "Floor Plan Option");

  return (
    <div css={Css.df.fdc.add({ breakInside: "avoid-page" }).$}>
      <PrintListItem
        title="Floor Plan"
        priceInCents={shouldDisplayPricing ? `${currencyFormatter(planPriceInCents!)}` : ""}
        items={[{ title: planDescription, priceInCents: isReversed ? "Reversed" : undefined }]}
      />

      {exteriorOption && exteriorSchemeOption && (
        <PrintListItem
          title="Exterior"
          priceInCents={
            shouldDisplayPricing ? exteriorOption.priceInCents + exteriorSchemeOption.priceInCents || "Included" : ""
          }
          items={[
            {
              optionCode: exteriorOption.option.optionCode,
              title: `${exteriorOption.option.name}, ${exteriorSchemeOption.option.name}`,
            },
          ]}
        />
      )}
      <PrintListItem
        title="Floor Plan Options"
        priceInCents={shouldDisplayPricing ? floorPlanOptions.reduce((acc, so) => acc + so.priceInCents, 0) : ""}
        items={floorPlanOptions.map(({ priceInCents, option }) => ({
          title: option.name,
          priceInCents: shouldDisplayPricing ? priceInCents : "",
          optionCode: option.optionCode,
        }))}
      />
    </div>
  );
};

type InteriorCardProps = { shouldDisplayPricing: boolean } & Pick<SummaryPrintConfigurationFragment, "options">;

export const InteriorCard = ({ options, shouldDisplayPricing }: InteriorCardProps) => {
  const designPackageOption = options.find((so) => so.option.planOptionType.name === "Design Package");
  const specLevelOption = options.find((so) => so.option.planOptionType.name === "Spec Level");
  const interiorUpgradeOptions = options.filter((so) =>
    [
      "Interior Option",
      "Whole House Cabinet Color - Primary",
      "Whole House Cabinet Color - Accent",
      "Kitchen Countertop",
      "Whole House Flooring",
    ].includes(so.option.planOptionType.name),
  );

  const topInteriorUpgradeOptions: InteriorTopUpgradeOptionWithChildrenProps[] = interiorUpgradeOptions
    .map((so) => {
      const childrenOptionMap: InteriorTopUpgradeOptionWithChildrenProps["childrenOptionMap"] = {};
      // if option has no child options then return the option
      if (so.option.options.length === 0) {
        return so;
      }
      let additionalPriceInCents = 0;
      let children = so.option.options
        .map((childOption) => {
          // get the interior upgrade option's child options
          return interiorUpgradeOptions.find((so) => so.option.id === childOption.id);
        })
        .filter(Boolean);
      while (children.length > 0) {
        // recursively update pricing to sum all child option pricing
        additionalPriceInCents += children.reduce((acc, child) => acc + (child?.priceInCents || 0), 0);
        children = children.flatMap((child) => {
          /* map child name to display in summary list */
          if (child?.option.parentOption && child?.option.parentOption.id === so.option.id) {
            // root level child - if no name yet then add to map
            if (!childrenOptionMap[child?.option.id]) {
              childrenOptionMap[child?.option.id] = [];
            }
            childrenOptionMap[child?.option.id] = [
              ...childrenOptionMap[child?.option.id],
              {
                name: child?.option.name,
                optionCode: child?.option.optionCode,
              },
            ];
          } else if (child?.option.parentOption && child?.option.parentOption.id !== so.option.id) {
            // sub-root level child - if no name yet then add to map
            if (!childrenOptionMap[child?.option.parentOption.id]) {
              childrenOptionMap[child?.option.parentOption.id] = [];
            }
            childrenOptionMap[child?.option.parentOption.id] = [
              ...childrenOptionMap[child?.option.parentOption.id],
              {
                name: child?.option.name,
                optionCode: child?.option.optionCode,
              },
            ];
          }
          return (
            child?.option.options
              .map((childOption) => {
                return options.find((so) => so.option.id === childOption.id);
              })
              .filter(Boolean) || []
          );
        });
      }
      return {
        ...so,
        priceInCents: so.priceInCents + additionalPriceInCents,
        childrenOptionMap, // to display children names under parent / grandparent
      };
    })
    // filter out child options of interior upgrade options now we've used their names for the name map
    .filter(
      (so, i, sos) =>
        !(so.option.parentOption && sos.map((option) => option.option.id).includes(so.option.parentOption.id)),
    );

  return (
    <div css={Css.df.fdc.add({ breakInside: "avoid-page" }).$}>
      <PrintListItem
        title="Design Scheme"
        priceInCents={shouldDisplayPricing ? designPackageOption!.priceInCents || "Included" : ""}
        items={[
          {
            optionCode: designPackageOption!.option.optionCode,
            title: designPackageOption!.option.name,
          },
        ]}
      />
      <PrintListItem
        title="Finish Level"
        priceInCents={shouldDisplayPricing ? specLevelOption!.priceInCents || "Included" : ""}
        items={[
          {
            optionCode: specLevelOption!.option.optionCode,
            title: specLevelOption!.option.name,
            priceInCents: shouldDisplayPricing ? specLevelOption!.priceInCents || undefined : "",
          },
        ]}
      />
      <PrintListItem
        title="Options"
        priceInCents={
          shouldDisplayPricing ? topInteriorUpgradeOptions.reduce((acc, so) => acc + so.priceInCents, 0) : ""
        }
        items={topInteriorUpgradeOptions.map(({ priceInCents, option, childrenOptionMap }) => ({
          title: option.name,
          priceInCents: shouldDisplayPricing ? priceInCents || "Included" : "",
          optionCode: option.optionCode,
          childrenOptionMap,
        }))}
      />
    </div>
  );
};

type AddOnsCardProps = { shouldDisplayPricing: boolean } & Pick<SummaryPrintConfigurationFragment, "options">;

export const AddOnsCard = ({ options, shouldDisplayPricing }: AddOnsCardProps) => {
  const addOnOptions = options.filter((so) => so.option.planOptionType.name === "Add-On Option");

  return addOnOptions.length > 0 ? (
    <div css={Css.df.fdc.add({ breakInside: "avoid-page" }).$}>
      <PrintListItem
        title="Add-Ons"
        priceInCents={shouldDisplayPricing ? addOnOptions.reduce((acc, so) => acc + so.priceInCents, 0) : ""}
        items={addOnOptions.map(({ priceInCents, option }) => ({
          title: option.name,
          priceInCents: shouldDisplayPricing ? priceInCents : "",
          optionCode: option.optionCode,
        }))}
      />
    </div>
  ) : null;
};

type SiteConditionsCardProps = { shouldDisplayPricing: boolean } & Pick<SummaryPrintConfigurationFragment, "options">;

export const SiteConditionsCard = ({ options, shouldDisplayPricing }: SiteConditionsCardProps) => {
  const siteConditionOptions = options.filter((o) => o.option.planOptionType.name === "Site Condition");

  if (siteConditionOptions.length === 0) return null;

  return (
    <div css={Css.df.fdc.add({ breakInside: "avoid-page" }).$}>
      <PrintListItem
        title="Site Conditions"
        priceInCents=""
        items={siteConditionOptions.map(({ priceInCents, option }) => ({
          title: option.name,
          priceInCents: shouldDisplayPricing ? priceInCents || "TBD" : "",
          optionCode: option.optionCode,
        }))}
      />
    </div>
  );
};

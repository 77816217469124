import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { createListingUrl } from "src/RouteUrls";
import { ContactUsFlyout, Footer, HBLoadingSpinner, Icon, Nav } from "~components";
import { Css } from "~generated/css";
import { SummaryPropertyFragment, useSummaryPropertyQuery } from "~generated/graphql";
import { useElementVisible, useTestIds } from "~utils";
import { AboutThisHome, ExploreSpaces, Hero, LocationSection } from "./pageComponents";

export function SummaryPage() {
  const { propertySlug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const bpProjectId = propertySlug!.split("-").pop();
  const { data, loading } = useSummaryPropertyQuery({
    variables: {
      id: bpProjectId!,
    },
    skip: !bpProjectId,
    onError: () => window.location.replace("https://www.homebound.com/browse-homes"),
  });

  if (loading) {
    <HBLoadingSpinner />;
  }

  const property = data?.propertyByBlueprintId;

  useEffect(() => {
    if (property) {
      const path = location.pathname;
      const expectedPath = createListingUrl(property.development.market, property);
      if (path !== expectedPath) {
        navigate(createListingUrl(property.development.market, property), {
          replace: true,
        });
      }
    }
  }, [location.pathname, navigate, property]);

  return property ? <SummaryPageView property={property} /> : <></>;
}

function SummaryPageView({ property }: { property: SummaryPropertyFragment }) {
  const { blueprintProjectId, floorPlans, isCustomizable, tourUrl, imageGallery, baseConfiguration } = property;
  const tid = useTestIds({}, `summaryPage_${blueprintProjectId}`);
  // We should NEVER not have a baseConfiguration or plan
  const {
    elevation,
    plan: { description: planDesc, features, locations },
  } = baseConfiguration!;
  const aboutThisHomeDesc = `${planDesc} ${elevation.description}`;

  const heroRef = useRef<HTMLDivElement>(null);
  const locationScrollRefs = useRef<{ [x: string]: HTMLDivElement | HTMLSpanElement | null }>({});
  const [showContactUs, setShowContactUs] = useState<boolean>(false);
  // Show scroll to top once users have gone past hero - add additional margin based on height of hero
  const targetElHeight = heroRef.current ? `${Math.floor(heroRef.current.offsetHeight)}px` : "0px";
  const showScrollToTop = !useElementVisible(heroRef, { rootMargin: `${targetElHeight} 0px 0px 0px` });

  function onPreviewClick(space: string) {
    locationScrollRefs.current[space]?.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  return (
    <>
      <Helmet>
        <title>
          {`${property.streetAddress} ${property.city}, ${property.state} ${property.postalCode}`} | Homebound
        </title>
        <meta
          name="description"
          content={`This ${property.bedrooms} beds, ${property.bathrooms} bath, ${property.sqft} square foot home is available now. ${aboutThisHomeDesc}`}
        />
      </Helmet>
      <Nav />
      <div css={Css.w100.maxwPx(1440).ma.bgWhite.$}>
        <div css={Css.df.fdc.gap8.$}>
          <div ref={heroRef}>
            <Hero property={property} showContactUs={() => setShowContactUs(true)} {...tid.hero} />
          </div>
          <AboutThisHome
            locationMetaData={{
              id: property.id,
              latitude: property.latitude,
              longitude: property.longitude,
              streetAddress: property.streetAddress,
              city: property.city,
              state: property.state,
              postalCode: property.postalCode,
            }}
            imageGallery={imageGallery}
            floorPlans={floorPlans}
            tourUrl={tourUrl}
            features={features ?? []}
            homeDescription={aboutThisHomeDesc}
            {...tid.aboutThisHome}
          />

          {locations.length > 0 && (
            <ExploreSpaces
              imageGallery={imageGallery}
              locations={locations}
              onPreviewClick={onPreviewClick}
              {...tid.exploreSpaces}
            />
          )}

          <div css={Css.df.fdc.mt1.$}>
            <div css={Css.df.fdc.gap8.$}>
              {locations.map(({ id, name, description }, idx) => (
                <span key={`location-preview-${id}`} ref={(elm) => (locationScrollRefs.current[name] = elm)}>
                  <LocationSection
                    isCustomizable={isCustomizable}
                    name={name}
                    imageGallery={imageGallery}
                    description={description}
                    reverse={idx % 2 !== 0}
                  />
                </span>
              ))}
            </div>
          </div>
          {showContactUs && (
            <ContactUsFlyout
              property={property}
              onClose={() => {
                setShowContactUs(false);
              }}
              hideProperty
              {...tid.contactUsFlyout}
            />
          )}
        </div>
        <AnimatePresence>
          {heroRef.current && showScrollToTop && (
            <motion.button
              initial={{ scale: 0, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0, opacity: 0 }}
              transition={{ type: "spring" }}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              css={
                Css.fixed.z1.right("80px").bottom("80px").df.bgGray900.w6.h6.aic.jcc.br100.ifMobileOrTablet.right2
                  .bottom2.w4.h4.$
              }
              {...tid.backToTopButton}
            >
              {/* TODO: Our icons need another look SC-28947. Would be nice to be able to pass size/color */}
              <Icon name="chevron-up-md" />
            </motion.button>
          )}
        </AnimatePresence>
      </div>
      <Footer />
    </>
  );
}

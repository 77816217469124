import { Icon } from "~components";
import { Css } from "~generated/css";

export function ImagePlaceholder() {
  return (
    <div css={Css.df.jcc.aic.w100.h100.z0.bgMoss.$}>
      <div>
        <Icon name="combo-house" />
        <p css={Css.mt8.tc.header24.$}>Image coming soon!</p>
      </div>
    </div>
  );
}

import { useRef } from "react";
import { Carousel, CarouselControls, CarouselRef, Icon, Tooltip } from "~components";
import { Css } from "~generated/css";
import { ImageGalleryFragment } from "~generated/graphql";
import { defaultTestId, useTestIds } from "~utils";
import { openAboutThisHomeGallery } from "./AboutThisHome";

type LocationSectionProps = {
  name: string;
  reverse: boolean;
  description: string;
  isCustomizable: boolean;
  imageGallery: ImageGalleryFragment[];
};

const LOCAL_BREAKPOINT = 1276;

export function LocationSection(props: LocationSectionProps) {
  const { name, reverse, description, isCustomizable, imageGallery } = props;
  const tid = useTestIds(props, "locationSection");
  const tooltipRef = useRef<HTMLAnchorElement>(null);

  const carouselRef = useRef<CarouselRef>(null);

  // If we don't have a desc we won't show the `make it your own` section but need to notify datadog
  function getLocationCustomizeDesc() {
    const description = locationToCopiesMap[name.toLowerCase().trim()]?.customizeDesc;

    if (!description) {
      console.error(`No customization description available for location ${name}. Data may be missing.`);
    }

    return description;
  }

  const customizeDesc = getLocationCustomizeDesc();

  const mappedImageGallery = imageGallery
    .filter(({ planLocation }) => planLocation && planLocation.name.toLowerCase().trim() === name.toLowerCase().trim())
    .map((image) => {
      const { imageUrl, id } = image;

      return (
        <div
          // Sweet Spot snagged from guesstimating our average image widths in airtable
          css={
            Css.cursorPointer.maxwPx(925).w("67vw").h100.ifContainer({ lt: LOCAL_BREAKPOINT }).maxw("unset").w("100vw")
              .$
          }
          onClick={() => openAboutThisHomeGallery(id)}
          key={id}
        >
          <img css={Css.z1.ar("16 / 9").objectCover.w100.$} src={imageUrl} alt={`Location photograph ${id}`} />
        </div>
      );
    });

  return (
    <div css={Css.container().$}>
      <div
        css={{
          ...Css.df.relative.z0.hPx(614).w("calc(100% - 80px)").aic.if(reverse).mrPx(80).fdrr.else.mlPx(80).$,
          ...Css.ifContainer({ lt: LOCAL_BREAKPOINT }).static.w100.h("fit-content").fdc.m0.mb4.$,
        }}
      >
        {/* location-section-carousel-container */}
        <div
          css={{
            ...Css.df.fg1.w("67%").absolute.z1.topPx(-28).if(reverse).left0.else.right0.$,
            ...Css.ifContainer({ lt: LOCAL_BREAKPOINT }).static.df.fdc.w100.$,
          }}
        >
          <Carousel ref={carouselRef} items={mappedImageGallery} />
          <div
            id="controls-container-mobile"
            css={Css.dn.bgOffWhite.py2.jcc.ifContainer({ lt: LOCAL_BREAKPOINT }).df.$}
          >
            <CarouselControls carouselRef={carouselRef} imageCount={mappedImageGallery.length} />
          </div>
        </div>
        {/* Main container with Gray background */}
        <div
          css={{
            ...Css.df.relative.fdc.mba.bgOffWhite.w100.hPx(549).if(reverse).mlPx(160).else.mrPx(160).$,
            ...Css.ifContainer({ lt: LOCAL_BREAKPOINT }).h("fit-content").gap4.px3.mx0.mt0.pb4.$,
          }}
        >
          {/* Location Desc */}
          <div
            css={{
              ...Css.ptPx(60).relative.df.fdc.h100.w("33%").if(reverse).plPx(20).prPx(60).asfe.else.prPx(20).plPx(60).$,
              ...Css.ifContainer({ lt: LOCAL_BREAKPOINT }).w100.h("fit-content").pt4.px0.$,
            }}
          >
            <div css={Css.df.$}>
              <h2 css={Css.lhPx(52).gray800.fw400.fsPx(32).add("fontStyle", "normal").mb3.ttc.z3.$}>{name}</h2>
            </div>
            {/* The tooltip is absolute relative to it's parent, on mobile devices we want the tooltip centered */}
            <div
              css={{
                ...Css.lhPx(30).gray800.fw300.fsPx(16).add("fontStyle", "normal").$,
                ...Css.ifMobileOrTablet.relative.$,
              }}
            >
              {description}{" "}
              <div css={Css.dib.ifDesktop.relative.$}>
                <span
                  css={
                    Css.relative.topPx(-10).underline.fsPx(20).lhPx(32).fw400.gray800.onHover.gray600.cursorPointer.$
                  }
                  ref={tooltipRef}
                >
                  *
                </span>
                <Tooltip triggerRef={tooltipRef} placement="top" clickTip={true}>
                  Imagery may not be exact <br />
                  representations of layout, <br />
                  color schemes and finishes.
                </Tooltip>
              </div>
            </div>
          </div>
          {/* White box overlaps with image. Based on Cutoff dates */}
          {isCustomizable && customizeDesc && (
            <div
              css={{
                ...Css.p3.z2.absolute.bottomPx(30).bgWhite.hPx(146).wPx(303).if(reverse).left("40%").else.left("33%").$,
                ...Css.ifContainer({ lt: LOCAL_BREAKPOINT }).static.df.fdc.h("fit-content").w100.aic.jic.$,
              }}
            >
              <h4
                {...tid[defaultTestId(name)]}
                css={{
                  ...Css.pb1.df.aic.gap1.body14.fsPx(14).fw400.$,
                  ...Css.ifContainer({ lt: LOCAL_BREAKPOINT }).lhPx(20).fw600.gray900.$,
                }}
              >
                <div css={Css.df.jcc.aic.wPx(28).hPx(28).bgGray200.br100.$}>
                  <Icon name="paint-roller"></Icon>
                </div>
                MAKE IT YOUR OWN
              </h4>
              <div
                css={{
                  ...Css.body14.lhPx(22).fsPx(12).$,
                  ...Css.ifContainer({ lt: LOCAL_BREAKPOINT }).gray700.lhPx(22).fsPx(14).fw300.tc.$,
                }}
              >
                {customizeDesc}
              </div>
            </div>
          )}
          <div
            id="controls-container"
            css={Css.df.pb1.if(reverse).jcfs.pl5.else.jcfe.pr5.ifContainer({ lt: LOCAL_BREAKPOINT }).dn.$}
          >
            <CarouselControls
              buttonPosition={reverse ? "left" : "right"}
              carouselRef={carouselRef}
              imageCount={mappedImageGallery.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

interface LocationsToCopiesMapType {
  [x: string]: {
    customizeDesc: string;
  };
}

// This assumes that the more generic details of a location section will be the same regardless of plan
// Maybe just transform strings instead of passing straight thru
const locationToCopiesMap: LocationsToCopiesMapType = {
  "the kitchen": {
    customizeDesc: "Buyers can customize finishes for cabinetry, flooring, countertops and more.",
  },
  "the living area": {
    customizeDesc: "You can customize finishes for flooring and explore adding custom built-ins",
  },
  "the primary suite": {
    customizeDesc: "Customize the flooring, decorative tile, counterops, and more in the primary suite",
  },
  "working from home": {
    customizeDesc: "Explore adding custom built-ins and accent walls to create the perfect work-from-home setup",
  },
  "bonus room": {
    customizeDesc: "Buyers can customize finishes for paint and flooring in the bonus room",
  },
  "outdoor living": {
    customizeDesc: "Customize this space by adding a built-in outdoor kitchen",
  },
  loft: {
    customizeDesc: "Make this space your own by customizing the paint finish or adding custom millwork",
  },
  media: {
    customizeDesc:
      "Complete the media room by adding speakers, a subwoofer, A/V reciever and wiring for a wall-mounted TV",
  },
  flex: {
    customizeDesc: "Make this space your own by customizing the paint finish or adding custom millwork",
  },
};

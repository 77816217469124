import { ImageDisplay } from "src/pages/configurator/components";
import { Button } from "~components";
import { Css } from "~generated/css";

type Floorplan = {
  id: string;
  name: string;
  imageUrl: string;
};

type FloorPlansTabProps = {
  configCode: string;
  floorPlans: Floorplan[];
  planOptions: string[];
  isReversed?: boolean;
  onCardClick?: (configCode: string, fpIndex: number) => void | undefined;
  onDownloadClick?: (configCode: string) => void | undefined;
};

export function FloorPlansTab(props: FloorPlansTabProps) {
  const { configCode, floorPlans, planOptions, onCardClick, onDownloadClick, isReversed = false } = props;

  return (
    <div css={Css.mh("calc(100vh - 112px)").$}>
      <div css={Css.dg.gtc("repeat(auto-fit, min(calc(100vw - 64px), 404px))").gap2.h100.mb6.$}>
        {floorPlans.map((floorplan, index) => (
          <AFloorPlanCard
            key={floorplan.name}
            floorplan={floorplan}
            selectedOptionCodes={planOptions}
            configCode={configCode}
            onClick={() => {
              onCardClick
                ? onCardClick(configCode, index)
                : window.open(`/floor-plan/${configCode}/${index}`, "_blank");
            }}
            onDownloadClick={onDownloadClick}
            isReversed={isReversed}
          />
        ))}
      </div>
      <div css={Css.fixed.bottom4.left4.body12.fw400.$}>
        *Exact Floor Plan(s) may vary from what is shown due to developments during the construction process.
      </div>
    </div>
  );
}

type AFloorPlanCardProps = {
  onClick?: () => void;
  onDownloadClick?: (configCode: string) => void;
  floorplan: Floorplan;
  isReversed: boolean;
  selectedOptionCodes: string[];
  configCode: string;
};

// These are kind of just big property inventory cards
function AFloorPlanCard({
  onClick,
  onDownloadClick,
  floorplan,
  isReversed,
  selectedOptionCodes,
  configCode,
}: AFloorPlanCardProps) {
  const { name } = floorplan;
  return (
    <div
      css={
        Css.ma
          .wPx(380)
          .hPx(320)
          .cursorPointer.maxw("calc(100vw - 64px)")
          .df.fdc.bgOffWhite.bshButton.add("transition", "0.2s ease")
          .br8.overflowHidden.onHover.add("transform", "scale(1.02)").$
      }
      onClick={onClick}
    >
      <div css={Css.df.fg1.hPx(250).$}>
        <ImageDisplay
          images={[{ ...floorplan, name: "" }]}
          showDisclaimer={false}
          displayingDynamicSvgs
          selectedOptionCodes={selectedOptionCodes}
          isReversed={isReversed}
        />
      </div>
      <div css={Css.df.jcsb.hPx(67).w100.bgWhite.pPx(24).px("10px").$}>
        <div>{name}*</div>
        <Button
          xss={Css.gray.mrPx(10).$}
          size="small"
          variant="inline"
          onClick={(evt) => {
            evt.stopPropagation();
            onDownloadClick
              ? onDownloadClick(configCode)
              : window.open(`/configurations/download/${configCode}?reports=floorPlans`, "_blank");
          }}
        >
          Download PDF
        </Button>
      </div>
    </div>
  );
}

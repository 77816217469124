import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useMemo } from "react";
import { ImageDisplay, ImageDisplayImageProps, SummaryCardList } from "src/pages/configurator/components";
import { useConfiguratorContext } from "src/pages/configurator/Configurator.context";
import { Icon } from "~components";
import { Css } from "~generated/css";
import { OptionImageType } from "~generated/graphql";
import { useEscCapture } from "~utils";

type SummaryDrawerProps = {
  onClose: () => void;
  isOpen: boolean;
  bottomOffset?: number;
};

export function SummaryDrawer(props: SummaryDrawerProps) {
  const { onClose, isOpen, bottomOffset = 72 } = props;
  const { configurationName, selectedOptions, basePlanPriceInCents, goToStep, reservedAt, plan, isReversed } =
    useConfiguratorContext();

  const images: ImageDisplayImageProps[] = useMemo(() => {
    const exteriorScheme = selectedOptions
      .find((o) => o.option.planOptionType.name === "Exterior Scheme")
      ?.option.images.find((i) => i.type.code === OptionImageType.Display);
    const exteriorOption = selectedOptions.find((o) => o.option.planOptionType.name === "Exterior")?.option;
    const interiorImages = selectedOptions
      .filter((o) => o.option.planOptionType.name === "Spec Level")
      .flatMap((o) => o.option.images)
      .filter((i) => i.type.code === OptionImageType.Display);
    if (exteriorScheme && exteriorOption) {
      // This is to set a presentable name for the exterior image
      const previewCardData = {
        id: exteriorOption.id,
        name: exteriorOption.name,
        imageUrl: exteriorScheme.imageUrl,
        isReversed: isReversed,
      } as ImageDisplayImageProps;
      return [previewCardData, ...interiorImages];
    }
    return interiorImages;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const drawerGoToStep = useCallback(
    (step: string | number) => {
      goToStep(step);
      onClose();
    },
    [onClose, goToStep],
  );

  useEscCapture(onClose);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          key="summary-drawer"
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: `calc(100% - ${bottomOffset}px)` }}
          exit={{ height: 0 }}
          css={Css.fixed.bgGray800.z1.bottomPx(bottomOffset).w100.$}
        >
          {/* Content Container */}
          <div css={Css.df.fdc.gap1.ma.px4.pt4.h100.w100.maxwPx(1584).overflowHidden.$}>
            {/* Header */}
            <div css={Css.w100.df.aic.jcsb.$}>
              {/* Your Home */}
              <div css={Css.header24.white.lhPx(40).$}>{configurationName ?? "Your Home"}</div>
              {/* Close Button */}
              <div onClick={onClose}>
                <Icon name="x-white" />
              </div>
            </div>
            <div css={Css.df.h100.w100.overflowHidden.gap4.$}>
              {/* Left */}
              <div css={Css.relative.overflowHidden.z0.df.fdc.w("67%").$}>
                <ImageDisplay title="Your Spaces" images={images} inlineNavBtns={false} lightText />
              </div>
              {/* Right */}
              <div
                css={{
                  ...Css.relative.overflowAuto.z1.df.fdc.mwPx(484).w("33%").gap1.$,
                  // TODO: Add dark mode scrollbar to truss / config
                  ...Css.addIn("::-webkit-scrollbar", { ...Css.wPx(10).$ }).$,
                  ...Css.addIn("::-webkit-scrollbar-thumb", { ...Css.bgGray400.br24.$ }).$,
                }}
              >
                <SummaryCardList
                  plan={plan}
                  selectedOptions={selectedOptions}
                  goToStep={drawerGoToStep}
                  reservedAt={reservedAt}
                  basePlanPriceInCents={basePlanPriceInCents}
                  isReversed={isReversed}
                  darkVariant
                />
              </div>
            </div>
          </div>
          {/* Decorative shadow over stepper */}
          <div
            css={
              Css.w100.absolute
                .bottomPx(-12)
                .bgColor("inherit")
                .hPx(12)
                .add(
                  "filter",
                  "drop-shadow(-4px -4px 12px rgba(254, 254, 254, 0.1)) drop-shadow(4px 4px 12px rgba(254, 254, 254, 0.1));",
                ).$
            }
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

import { motion } from "framer-motion";
import { ReactNode, useEffect, useState } from "react";
import { useConfiguratorContext } from "src/pages/configurator/Configurator.context";
import {
  BasicOverlay,
  Button,
  Icon,
  NewFieldLabel,
  NewFieldStyleOverrides,
  PhoneField,
  Radio,
  TextField,
  TransparentHBLoadingSpinnerOnly,
} from "~components";
import { Css } from "~generated/css";
import { useTestIds } from "~utils";

type ReserveBuildFormState = {
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  configurationName: string | null;
};

type ReserveYourBuildFlyoutProps = {
  onClose: () => void;
};

export function ReserveYourBuildFlyout(props: ReserveYourBuildFlyoutProps) {
  const { onClose } = props;
  const { customer, configurationName, updateConfigurationWMetaData, plan } = useConfiguratorContext();
  const tid = useTestIds(props, "reserve-your-build-flyout");
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [userAcknowledgement, setUserAcknowledgement] = useState(false);

  const [form, setForm] = useState<ReserveBuildFormState>(() => {
    const { email = "" } = customer ?? {};

    return {
      firstName: "",
      lastName: "",
      email,
      phoneNumber: "",
      configurationName: configurationName ?? "",
    };
  });

  async function onSubmit(form: ReserveBuildFormState) {
    setError(false);
    setSaving(true);
    const { configurationName, ...customer } = form;

    await updateConfigurationWMetaData({
      name: configurationName,
      reservedAt: new Date(),
      customer,
    })
      .then(() => {
        onClose();
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <Flyout testId="reserve-build-flyout" onClose={onClose} {...tid}>
      <form>
        <div css={Css.header32.lhPx(48).ifMobileOrTablet.header40.$}>Reserve Home</div>
        <div css={Css.mt2.body18.gray700.ifMobileOrTablet.fsPx(24).$}>
          Get a detailed quote of your home. Your pricing will be locked in for {plan?.expirationDays} days.
        </div>
        {/* FIELDS */}
        {/* Configuration Name*/}
        <div css={Css.relative.$}>
          {saving && (
            <motion.div
              initial={{ backdropFilter: "blur(0px)" }}
              animate={{ backdropFilter: "blur(.5px)" }}
              exit={{ backdropFilter: "blur(0px)" }}
              css={Css.absolute.w100.h100.df.aic.jcc.$}
            >
              <TransparentHBLoadingSpinnerOnly />
            </motion.div>
          )}
          <div css={Css.mt3.$}>
            <TextField
              disabled={saving}
              required
              value={form.configurationName || undefined}
              onChange={(e, buildName) => setForm((prev) => ({ ...prev, configurationName: buildName }))}
              placeholder="Your Dream Home"
              label={NewFieldLabel("Build name")}
              cssOverrides={NewFieldStyleOverrides}
              {...tid.buildName}
            />
          </div>
          {/* Names */}
          <div css={Css.df.gap3.$}>
            <div css={Css.mt3.w100.$}>
              <TextField
                disabled={saving}
                required
                value={form.firstName || undefined}
                onChange={(e, firstName) => setForm((prev) => ({ ...prev, firstName }))}
                placeholder="First"
                label={NewFieldLabel("First name")}
                cssOverrides={NewFieldStyleOverrides}
                {...tid.firstName}
              />
            </div>
            <div css={Css.mt3.w100.$}>
              <TextField
                disabled={saving}
                required
                value={form.lastName || undefined}
                onChange={(e, lastName) => setForm((prev) => ({ ...prev, lastName }))}
                placeholder="Last"
                label={NewFieldLabel("Last name")}
                cssOverrides={NewFieldStyleOverrides}
                {...tid.lastName}
              />
            </div>
          </div>
          {/* Email */}
          <div css={Css.mt3.$}>
            <TextField
              disabled={saving}
              value={form.email || undefined}
              onChange={(e, email) => setForm((prev) => ({ ...prev, email }))}
              placeholder="homeIsHow@dream.com"
              label={NewFieldLabel("Email address")}
              cssOverrides={NewFieldStyleOverrides}
              {...tid.emailAddress}
            />
          </div>
          {/* Phone Number */}
          <div css={Css.mt3.$}>
            <PhoneField
              disabled={saving}
              useConfigurationStyle
              value={form.phoneNumber || undefined}
              onChange={(phoneNumber) => setForm((prev) => ({ ...prev, phoneNumber }))}
              placeholder="(XXX) XXX-XXXX"
              {...tid.phoneNumber}
            />
          </div>
          {error ? (
            <div css={Css.mt5.body14.fw400.redError.$}>Oops! Something went wrong, please resubmit form.</div>
          ) : (
            <div css={Css.mt6.$} />
          )}
        </div>
        <div css={Css.df.fdc.gap3.$}>
          <div css={Css.df.gap2.$}>
            <div>
              <Radio
                selected={userAcknowledgement}
                onClick={() => setUserAcknowledgement((prev) => !prev)}
                {...tid.userAcknowledgement_button}
              />
            </div>
            <div css={Css.body14.fw500.lhPx(22).$}>
              I understand that by reserving my build, I will not be able to make any changes.
            </div>
          </div>
          <Button
            disabled={
              !(
                userAcknowledgement &&
                form.lastName &&
                form.firstName &&
                form.configurationName &&
                form.firstName?.length > 0 &&
                form.lastName?.length > 0 &&
                form.configurationName?.length > 0 &&
                form?.email?.match(/.+@.+\..+/) &&
                // if raw phone number is not empty then must be 10 digits
                (form.phoneNumber ? form.phoneNumber.length === 10 : true)
              ) || saving
            }
            onClick={() => onSubmit(form)}
            type="button"
            {...tid.submit_button}
          >
            Submit
          </Button>
        </div>
      </form>
      <div css={Css.df.fdc.gap2.mt3.body12.lhPx(20).gray700.$}>
        <div>
          By clicking Submit, your home design will be saved and you will receive a digital code to reference your
          selections. A Homebound representative will connect with you to confirm availability and pricing for your
          home.
        </div>
        <div>
          Reserving Now also confirms your place in the Homebound queue for homes to be built; this means you will have
          preference getting your build started based on the time of your reservation.
        </div>
        <div>* This is an estimated price and options will need to be confirmed by a sales rep.</div>
      </div>
    </Flyout>
  );
}

type FlyoutProps = {
  flyoutMaxWidth?: number;
  onClose: () => void;
  children: ReactNode;
  testId: string;
};

function Flyout(props: FlyoutProps) {
  const { flyoutMaxWidth = 544, onClose, children } = props;
  const tid = useTestIds(props, "flyout");

  useEffect(() => {
    // When the flyout is shown, we want to prevent scrolling
    document.body.style.overflow = "hidden";
    return () => {
      // When the flyout is hidden, we want to allow scrolling again
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <BasicOverlay onClick={onClose}>
      <motion.div
        css={
          Css.h("100vh")
            .wPx(flyoutMaxWidth)
            .overflowAuto.bgWhite.boxShadow("0px 4px 8px rgba(53, 53, 53, 0.1), 0px 2px 24px rgba(53, 53, 53, 0.08)")
            .fixed.container().overflowXHidden.ifMobileOrTablet.w100.$
        }
        initial={{ right: -flyoutMaxWidth }}
        animate={{ right: 0 }}
        transition={{ duration: 1 }}
        {...tid}
      >
        <div css={Css.py4.px6.ifMobileOrTablet.pt8.px3.$} onClick={(e) => e.stopPropagation()}>
          <button css={Css.absolute.topPx(30).rightPx(30).$} onClick={onClose} {...tid.closeButton}>
            <Icon name="x" />
          </button>
          {children}
        </div>
      </motion.div>
    </BasicOverlay>
  );
}

import React from "react";
import { Css } from "~generated/css";
import { SummaryPrintConfigurationOptionFragment } from "~generated/graphql";

export type InteriorTopUpgradeOptionWithChildrenProps = SummaryPrintConfigurationOptionFragment & {
  childrenOptionMap?: Record<string, { name: string; optionCode: string }[]>;
};

type PrintListItemProps = {
  title?: string;
  priceInCents: number | string;
  items: {
    title: string;
    priceInCents?: number | string;
    optionCode?: string;
    childrenOptionMap?: InteriorTopUpgradeOptionWithChildrenProps["childrenOptionMap"];
  }[];
  headerStyling?: boolean;
};

export const PrintListItem = (props: PrintListItemProps) => {
  const { title, priceInCents, items, headerStyling = false } = props;

  function formatAmount(priceInCents: number | string, hideSign = false) {
    if (typeof priceInCents === "string") return priceInCents;

    const sign = priceInCents === 0 || hideSign ? "" : priceInCents > 0 ? "+ " : "- ";
    return `${sign}${(priceInCents / 100).toLocaleString("EN", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    })}`;
  }

  return (
    <div css={Css.pyPx(12).$}>
      {title && (
        <div css={Css.df.w100.aic.jcsb.$}>
          {/* 56% is sweetspot for line break */}
          <div css={Css.dif.w("56%").$}>
            <div css={Css.body14.fw500.lhPx(22).$}>{title}</div>
          </div>
          <div css={Css.df.gap("12px").$}>
            <span css={{ ...Css.body14.lhPx(24).gray700.whiteSpace("nowrap").$, ...Css.gray800.fw500.$ }}>
              {formatAmount(priceInCents, true)}
            </span>
          </div>
        </div>
      )}
      {items.map((item) => {
        const { childrenOptionMap, optionCode, priceInCents, title } = item;
        // remove the required asterisk from the name
        const formattedTitle = title.replaceAll("*", "");
        return (
          <React.Fragment key={formattedTitle}>
            <div
              css={
                Css.df
                  .w("95%")
                  .mxa.aife.jcsb.mt1.add("borderBottom", "dotted")
                  .add("borderBottomWidth", "thin")
                  .if(headerStyling).w100.bb.$
              }
            >
              {/* 56% is sweetspot for line break */}
              <div css={Css.dif.w("56%").$}>
                <div css={Css.body12.lhPx(22).gray700.wspl.if(headerStyling).body14.lhPx(22).gray700.$}>
                  {optionCode && `[${optionCode}] `}
                  {formattedTitle}
                </div>
              </div>
              {priceInCents && (
                <div css={Css.df.gap("12px").$}>
                  <span
                    css={Css.body12.lhPx(22).gray700.whiteSpace("nowrap").if(headerStyling).body14.lhPx(22).gray700.$}
                  >
                    {formatAmount(priceInCents)}
                  </span>
                </div>
              )}
            </div>
            {childrenOptionMap && displayChildNames(childrenOptionMap)}
          </React.Fragment>
        );
      })}
    </div>
  );
};

// TODO: handle this better
const IGNORE_OPTION_CODE = ["primary", "accent", "tile", "lvp"];

function displayChildNames(childrenOptionMap: InteriorTopUpgradeOptionWithChildrenProps["childrenOptionMap"]) {
  if (!childrenOptionMap) return null;
  const options = Object.keys(childrenOptionMap).map((key) => {
    return (
      childrenOptionMap[key]
        // currently, for flooring, Tile and LVP are an "either or" so we need to avoid showing an empty Tile or LVP in the summary
        .filter((o) =>
          // checking length is less than 2 since a selection would mean 2 entries -> (0) Tile (1) "Natural" or (0) LVP (1) "Natural"
          (o.name.toLowerCase().includes("tile") || o.name.toLowerCase().includes("lvp")) &&
          childrenOptionMap[key].length < 2
            ? false
            : true,
        )
        .map((o) => {
          // remove the required asterisk from the name
          const formattedName = o.name.replaceAll("*", "");
          // ignore option code in favor of the child option code
          if (IGNORE_OPTION_CODE.includes(formattedName.toLowerCase())) {
            return formattedName;
          }
          return `[${o.optionCode}] ${formattedName}`;
        })
        .join(": ")
    );
  });
  return options.map((name) => {
    if (!name) return null;
    return (
      <div
        key={name}
        css={Css.df.w("95%").mxa.aic.jcsb.mt1.add("borderBottom", "dotted").add("borderBottomWidth", "thin").$}
      >
        <p css={Css.pl2.body12.lhPx(22).gray700.$}>{name}</p>
      </div>
    );
  });
}

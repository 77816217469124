import { useLeafletContext } from "@react-leaflet/core";
import { motion } from "framer-motion";
import { latLngBounds, LatLngLiteral, Map as LeafletMap } from "leaflet";
import { ReactNode, RefObject, useCallback, useEffect, useState } from "react";
import { MapContainer, TileLayer, useMapEvents } from "react-leaflet";
import { MapMarker } from "~components";
import { Css } from "~generated/css";
import { useTestIds } from "~utils";

export type MapTriggerFunctions = {
  /**
   * Re-centers the map via either the `center` prop, the center or all the
   * `markers`, or the default center point.
   */
  centerMap: () => void;
};

export type MapProps = {
  /**
   * (Optional) `center` point to position the map. If not specified, the `center`
   * point will be calculated based on the given `markers` (only on initial
   * render), or it will default to Louisville, CO, USA.
   */
  center?: LatLngLiteral;
  /** (Optional) list of marker positions and renderers */
  markers?: Array<{
    /**
     * A unique ID is required for each marker since that is used as the key.
     * Using the center points as the key is not recommended since the center
     * points can change and two markers can have the same center point.
     */
    id: string | number;
    /** The position of the marker in lat and long */
    center: LatLngLiteral;
    /**
     * (Optional) marker component which can be any React component. If not
     * specified, the default marker will be used.
     */
    marker?: ReactNode;
    /**
     * (Optional) When true, sets the zIndex of the marker wrapper to 1 and
     * when using the default marker, it will be highlighted.
     *
     * Note: When using a custom marker, setting this to true will make sure
     * that the marker is on top of other markers. Setting the zIndex of a
     * custom market will not change the stacking order since this component
     * wraps all customer markers in an `absolute` positioned div.
     *
     */
    isActive?: boolean;
  }>;
  /** (Optional) When given a ref, helper functions will be attached to it. */
  triggersRef?: RefObject<MapTriggerFunctions>;
  /** (Optional) Enable certain map features */
  features?: {
    /**
     * (Optional) Enable the mouse wheel to zoom in and out.
     * @default true
     */
    scrollWheelZoom?: boolean;
    /**
     * (Optional) Enable zooming when double clicking the same location with
     * a mouse or double tapping.
     * @default true
     */
    doubleClickZoom?: boolean;
    /**
     * (Optional) Enable a mobile device touch zoom.
     * @default true
     */
    touchZoom?: boolean;
    /**
     * (Optional) Show a zoom control on the top left of the map.
     * @default true
     */
    zoomControl?: boolean;
    /**
     * (Optional) Enable dragging the map with the mouse or touch.
     * @default true
     */
    dragging?: boolean;
    /**
     * (Optional) Enable tapping on the map with a mobile device.
     * default true
     */
    tap?: boolean;
  };
};

/**
 * A map component is used the `react-leaflet` library which is a React wrapper
 * around the `leaflet` mapping library.
 *
 * This map will match the size of the parent container and will render the map
 * at the given `center` point. If no `center` point is given, it will calculate
 * the center point based on the given `markers` or it will default to Louisville.
 */
export function Map(props: MapProps) {
  const { center, markers, triggersRef, features } = props;
  const {
    scrollWheelZoom = true,
    doubleClickZoom = true,
    touchZoom = true,
    zoomControl = true,
    dragging = true,
    tap = true,
  } = features ?? {};

  const tid = useTestIds(props, "map");

  // Note: We are using `useState` here instead of `useRef` since we want to
  // trigger a re-render when the DOM element is set.
  const [mapRef, setMapRef] = useState<LeafletMap | null>(null);

  /**
   * Re-center the map when the map initially loads, or when the `center` prop
   * is set/updated.
   */
  useEffect(() => {
    centerMap();
    // Purposefully not including `markers` in the dependency array
    // since we don't want to re-center when the `markers` prop changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef, center]);

  /**
   * Helpers which centers the map based on the following criteria:
   * 1. If `center` is given, use that
   * 2. Otherwise if `markers` are given, use that
   * 3. Lastly, use Louisville (default)
   */
  const centerMap = useCallback(() => {
    if (!mapRef) return;

    // If `center` is given, use that
    if (center !== undefined) {
      // This determines the zoom level and the center point to fit all markers
      // in the map view.
      mapRef.fitBounds(latLngBounds([center]));
      // FIXME: We can't set an initial zoom level with our map implementation
      // initialZoom && initialization && mapRef.setZoom(initialZoom, { animate: false });
    }
    // Otherwise if `markers` are given, use that
    else if (markers !== undefined && markers.length > 0) {
      mapRef.fitBounds(latLngBounds(markers.map((marker) => marker.center)));
    }
    // Lastly, use Louisville (default)
    // Ft Myers, FL, USA = { lat: 26.640629, lng: -81.872307 }
    // Ft Myers BEACH = { lat: 26.45182917025719, lng: -81.94824993610384 }
    else {
      mapRef.fitBounds(latLngBounds([{ lat: 39.9777778, lng: -105.1313889 }]));
    }
  }, [mapRef, center, markers]);

  /**
   * Passes important callbacks to the parent when the `triggers` prop is set.
   */
  useEffect(() => {
    if (!triggersRef) return;

    // @ts-expect-error - We are attaching helper functions to the `current` key
    // of the ref since we can use this to pass functions to the parent.
    triggersRef.current = { centerMap };
  }, [triggersRef, centerMap]);

  return (
    <div
      {...tid}
      css={{
        // Sizing the map container to match the parent container
        ...Css.h("inherit").w("inherit").$,
        // Forcing the leaflet (map) to use a `z-index: 0` since the default is
        // `z-index: 400` which is too high and hard for child markers to know
        // what z-index to use.
        ".leaflet-pane": Css.z0.important.$,
      }}
    >
      {/* This is the `react-leaflet` context provider */}
      <MapContainer
        ref={setMapRef}
        css={Css.h("inherit").w("inherit").$}
        // Removes the bottom left attribution which says "Leaflet | OpenStreetMap ..."which is the "Map data © OpenStreetMap contributors"
        attributionControl={false}
        // Features
        scrollWheelZoom={scrollWheelZoom}
        doubleClickZoom={doubleClickZoom}
        touchZoom={touchZoom}
        zoomControl={zoomControl}
        dragging={dragging}
        tap={tap}
        inertia={true}
        bounceAtZoomLimits={true}
      >
        {/*
          TILE LAYER

          More tile layers can be added and toggled to show different tiles on
          or over the current map. This could be for a different terrain views,
          traffic views, pollution views, etc.

          To find more free tile layers, see https://leaflet-extras.github.io/leaflet-providers/preview/
        */}
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        />
        {/*
          MARKER LAYER

          Markers are visual points on the map which can be used the show where
          a point of interest is. Markers are React components which can be
          built to do anything you want.
        */}
        {markers?.map(
          ({ id, center, isActive, marker = <MapMarker isActive={isActive} {...tid[`mapMarker-${id}`]} /> }) => (
            <Marker key={id} center={center} isActive={isActive}>
              {marker}
            </Marker>
          ),
        )}
      </MapContainer>
    </div>
  );
}

type MarkerProps = {
  center: LatLngLiteral;
  children: ReactNode;
  isActive?: boolean;
};

/**
 * React-Leaflet / Leaflet marker component which will render it's children
 * at the correct position over the map.
 *
 * Heavily inspired by
 * https://github.com/holytrips/react-leaflet-marker/blob/master/src/Marker.tsx
 * and its associated hooks. We did not use this library since there were
 * issues with our MapContainer context and theirs, something to do with the
 * `@react-leaflet/core` library not working well together. The other issue is
 * that they used the `DomUtils.setPosition` which set a `translate3D` style
 * when positioning the DOM Node that causes issues when trying to change the
 * `zIndex` when hovering. We opted to handle this ourselves using Framer Motion.
 */
function Marker(props: MarkerProps) {
  const { center, children, isActive } = props;

  // Note: We are using `useState` here instead of `useRef` since we want to
  // trigger a re-render when the DOM element is set.
  const [markerRef, setMarkerRef] = useState<HTMLDivElement | null>(null);

  // Getting the map context of the nearest parent `<MapContainer />`
  const { map } = useLeafletContext();

  // Handles triggering re-render when certain events happen via tha `useMapEvents` hook.
  const [tick, setTick] = useState(0);

  // Attach event callback to the parent's `react-leaflet` context.
  useMapEvents({
    // https://leafletjs.com/reference.html#map-viewreset
    viewreset: () => setTick((tick) => tick + 1),
    /**
     * Forces this marker to re-render when a zoom event happens.
     *
     * NOTE: We are not listening to the `zoomend` event since we'd like to see
     * the markers continuously move as the map zooms.
     *
     * https://leafletjs.com/reference.html#map-zoom
     */
    zoom: () => setTick((tick) => tick + 1),
    /**
     * Handles when the map is is dragged, moved or dblClick move.
     *
     * NOTE: We are not using `moveend` since we'd like to see the markers
     * continuously move as the map moves.
     *
     * https://leafletjs.com/reference.html#map-move
     */
    move: () => setTick((tick) => tick + 1),
  });

  // Don't render or attempt to calculate the x, y on the first tick (render)
  // since we must wait for the first `viewreset` event before we can access any
  // function from the `map` key in the context.
  if (tick === 0) return null;

  // Finding the pixel height and width of the children so we can offset the
  // position of the marker to be centered on the point.
  const markerHeight = markerRef?.clientHeight ?? 0;
  const markerWidth = markerRef?.clientWidth ?? 0;
  // Finding the pixel position of the marker on the map given the current map
  // zoom level and the center point.
  const { x, y } = map
    .latLngToContainerPoint(center)
    // This removed the offset of the marker to be centered on the point.
    .subtract([markerHeight / 2, markerWidth / 2])
    .round();

  return (
    <motion.div
      ref={setMarkerRef}
      // These are styles which will not change
      css={Css.dib.absolute.$}
      // Since we DON'T want to `x` and `y` animate at the beginning we include
      // it here, but we DO want to animate the `opacity`.
      initial={{ x, y, opacity: 0 }}
      // Framer Motion is very performant when it comes to x and y transformations,
      // therefore we will leave this up to them to handle.
      animate={{ x, y, opacity: 1, zIndex: isActive ? 1 : 0 }}
      // Always place the highlighted marker above other markers
      whileHover={{ zIndex: 1 }}
    >
      {children}
    </motion.div>
  );
}

import { AnimatePresenceProps, motion } from "framer-motion";
import { Route, RouteProps, Routes } from "react-router-dom";

/** Animates in and out routes */
type AnimatedRoutesProps = AnimatePresenceProps & { children: RouteProps[] };
export function AnimatedRoutes(props: AnimatedRoutesProps) {
  const { children } = props;
  return (
    <Routes>
      {children.map(({ path, element, ...otherRouteProps }) => (
        <Route
          key={path}
          path={path}
          element={
            // element
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.2 } }}
              exit={{ opacity: 0, transition: { duration: 0.2 } }}
            >
              {element}
            </motion.div>
          }
          {...otherRouteProps}
        />
      ))}
    </Routes>
  );
}

import { createContext, ReactNode, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";

// add new feature flag identifiers here
export enum FEATURE_FLAGS {
  INTERNAL_360_RENDER = "360render",
}

/**
 * add default values here
 * when released, set them to true
 * after the feature has been released for a while and is stable
 * flags, defaults, and the switches that use them can be removed
 * in the future, we should load defaults from a service
 * so we can change them without engineering work and can do releases and A/B testing
 */
export const FEATURE_FLAG_DEFAULTS = {
  [FEATURE_FLAGS.INTERNAL_360_RENDER]: false,
};

type FeatureFlagContextActions = {
  getFeatureFlag: (flag: FEATURE_FLAGS) => boolean;
  setFeatureFlag: (flag: FEATURE_FLAGS, value: boolean) => void;
};

export type FeatureFlagContext = FeatureFlagContextActions;

const FeatureFlagContext = createContext<FeatureFlagContext | null>(null);

export type FeatureFlagContextProps = {
  children: ReactNode;
};

export function FeatureFlagProvider(props: FeatureFlagContextProps) {
  const { children } = props;
  const [params] = useSearchParams();

  // get keys from params that match values from FEATURE_FLAGS
  const paramKeys = Array.from(params.keys()).filter((key) => {
    return Object.values(FEATURE_FLAGS).includes(key as FEATURE_FLAGS);
  });
  const paramFlags: { [key: string]: boolean } = {};
  paramKeys.forEach((key) => {
    try {
      paramFlags[key] = !!JSON.parse(params.get(key) || "");
    } catch {
      paramFlags[key] = false;
    }
  });

  // local defaults overridden by params
  const [featureFlags, setFeatureFlags] = useState({ ...FEATURE_FLAG_DEFAULTS, ...paramFlags });

  // TODO: we also need the user here because we only want to allow query param control of feature flags for internal users

  // Throw when there are more than one context
  const ffContext = useContext(FeatureFlagContext);
  if (ffContext !== null) throw new Error("FeatureFlagProvider should not be a child of another FeatureFlagProvider");

  const featureFlagContext = {
    getFeatureFlag: (flag: FEATURE_FLAGS) => {
      return featureFlags[flag];
    },
    setFeatureFlag: (flag: FEATURE_FLAGS, value: boolean) => {
      setFeatureFlags({
        ...featureFlags,
        [flag]: value,
      });
    },
  };

  return <FeatureFlagContext.Provider value={featureFlagContext}>{children}</FeatureFlagContext.Provider>;
}

export function useFeatureFlags() {
  const context = useContext(FeatureFlagContext);
  if (context === null) throw new Error("useFeatureFlags must be used within a FeatureFlagProvider");
  return context;
}
